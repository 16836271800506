// @flow

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { CoreozInputBase } from 'coreoz-form-base';
import moment from 'moment';
import { composeValidators, isEmail, required } from '../../../services/validator';
import { currentUser, login, logout } from '../../../services/sessionService';
import { DATE_FORMAT } from '../../../const';

type Props = {
  form: Object,
  getCountryByCode: Function,
  parentUser: Object,
};

function ConnexionItem(props: Props) {

  const { form, getCountryByCode, parentUser } = props;

  const user = currentUser();
  const activeUser = parentUser && Object.entries(parentUser).length > 0 ? parentUser : user;

  useEffect(() => {
    changeForm(user);
  }, [user]);

  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(!!currentUser());
  const [showFailLogin, setShowFailLogin] = useState(false);

  function changeForm(value) {
    if (value) {
      form.change('user.gender', value.gender);
      form.change('user.name', value.lastname);
      form.change('user.surname', value.firstname);
      form.change('user.birthdate', moment(value.birthdate).format(I18n.t('date.DATE_FORMAT')));
      form.change('user.email', value.email);
      form.change('user.emailConfirmation', value.email);
      form.change('user.phone', value.phoneNumber);
      form.change('user.address', value.address);
      form.change('user.address_supp', value.address2);
      form.change('user.postcode', value.zipCode);
      form.change('user.city', value.city);
      form.change('user.country', getCountryByCode(value.countryCode, false));
    }
  }

  function resetForm() {
    form.change('user', { user: {} });
  }

  return (
    <div className="connexion">
      <div className="connexion-container">
        <div className={`login-text-container ${showLogin ? 'center' : ''}`}>
          {showLogout
            ? (
              <div className="login-form-title">
                <h2>
                  {I18n.t('travelInsuranceForm.step3.login.titleLogin')}
                  <span className="name">
                    {I18n.t('travelInsuranceForm.step3.login.titleLoginName', {
                      surname: activeUser && activeUser.firstname,
                      name: activeUser && activeUser.lastname,
                    })}
                  </span>
                </h2>
              </div>
            ) : (
              <h2
                className="login-form-title">{I18n.t('travelInsuranceForm.step3.login.title')}</h2>
            )}
          {!showLogin
          && (
            <div className="login-link">
              {showLogout
                ? (
                  <h2
                    onClick={() => {
                      setShowLogin(false);
                      setShowLogout(false);
                      resetForm();
                      logout();
                    }}
                  >
                    {I18n.t('travelInsuranceForm.step3.login.showLogout')}
                  </h2>
                ) : (
                  <h2
                    onClick={() => setShowLogin(true)}>{I18n.t('travelInsuranceForm.step3.login.showLogin')}</h2>
                )
              }
            </div>
          )}
        </div>
        {showLogin
        && (
          <div className="login-form">
            <Form
              onSubmit={(values) => {
                const actualValues = {
                  ...values,
                  birthdate: values.birthdate ? moment(values.birthdate).format(DATE_FORMAT.LOCAL_FORMAT) : null,
                };
                login(actualValues)
                  .then(() => {
                    setShowLogin(false);
                    setShowLogout(true);
                    setShowFailLogin(false);
                  })
                  .catch(() => {
                    setShowFailLogin(true);
                  });
              }}
              render={({ handleSubmit, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="login-form-container">
                    <div className="login-forms">
                      <div className="form-section">
                        <h3>
                          {I18n.t('travelInsuranceForm.step3.login.titleId')}
                        </h3>
                        <Field
                          name="username"
                          className="name-input"
                          type="email"
                          placeholder={I18n.t('travelInsuranceForm.step3.login.placeholderId')}
                          component={CoreozInputBase}
                          validate={composeValidators(required, isEmail)}
                        />
                      </div>
                      <div className="form-section">
                        <h3>
                          {I18n.t('travelInsuranceForm.step3.login.titlePassword')}
                        </h3>
                        <Field
                          name="password"
                          className="name-input"
                          type="password"
                          placeholder={I18n.t('travelInsuranceForm.step3.login.placeholderPassword')}
                          component={CoreozInputBase}
                          validate={required}
                        />
                      </div>
                    </div>
                    <div className="login-form-button">
                      {showFailLogin
                      && (
                        <div className="error-message">
                          {I18n.t('travelInsuranceForm.step3.login.errorMessage')}
                        </div>
                      )}
                      <div className={valid ? 'submit' : 'submit disabled'}>
                        <button type="submit">
                          <h3>{I18n.t('travelInsuranceForm.step3.login.loginButton')}</h3>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(connect((state) => ({
  quote: state.storage.quote,
}))(ConnexionItem));
