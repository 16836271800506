// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLocale } from 'react-redux-i18n';
import type { Language } from '../../services/languages';
import { LANGUAGE, languagesAvailable } from '../../services/languages';

type Props = {
  dispatch: Function,
  locale: string,
}

type State = {
  flagInfos: Language,
  isOpen: boolean,
}

const changeLanguage = (language, dispatch) => {
  dispatch(setLocale(language.code));
  localStorage.setItem(LANGUAGE, language.code);
};

const initLanguage = (i18nLanguage) => {
  if (!localStorage.getItem(LANGUAGE)) {
    localStorage.setItem(LANGUAGE, i18nLanguage);
  }

  return languagesAvailable.filter((infos) => infos.code === localStorage.getItem(LANGUAGE))[0];
};

class Flag extends React.Component<Props, State> {
  state = {
    flagInfos: initLanguage(this.props.locale),
    isOpen: false,
  };

  render() {
    return (
      <div className="flag">
        <img
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          src={this.state.flagInfos.imgPath}
          alt=""
        />
        {this.state.isOpen
        && (
        <div className="form-control">
          {
            languagesAvailable.map((language) => (
              <div
                key={language.code}
                onClick={() => {
                  changeLanguage(language, this.props.dispatch);
                  this.setState({ isOpen: !this.state.isOpen, flagInfos: language });
                }}
              >
                <img
                  src={language.imgPath}
                  alt={language.code}
                />
              </div>
            ))
          }
        </div>
        )}
      </div>
    );
  }
}

export default withRouter(connect((state) => ({
  locale: state.i18n.locale,
}))(Flag));
