// @flow
import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import {
    DASHBOARD_PAGE_ROUTE, FIRST_CONNECTION_CONFIRMATION_PAGE_ROUTE,
    FIRST_CONNECTION_PAGE_ROUTE,
    FORGOTTEN_CONFIRMATION_PAGE_ROUTE,
    FORGOTTEN_PAGE_ROUTE,
    LOGIN_PAGE_ROUTE,
    MODIFY_CONFIRMATION_PAGE_ROUTE,
    MODIFY_PAGE_ROUTE,
    REACTIVATE_CONNECTION_PAGE_ROUTE,
    REACTIVATE_PAGE_ROUTE,
} from '../../const';
import LoginForm from './LoginForm';
import { login } from '../../services/sessionService';
import userApi from '../../network/api/userApi';
import Flag from './Flag';
import { notifyError, notifySuccess } from '../../network/notification';

type Props = {
  match: any,
  history: any,
  dispatch: Function,
};

type State = {};

class LoginModule extends React.Component<Props, State> {
  generateForm = (path) => {
    switch (path) {
      default:
      case LOGIN_PAGE_ROUTE:
        return (
          <LoginForm
            title="spaceClient.form.connectionTitle"
            form={{
              fields: [
                {
                  name: 'username',
                  type: 'email',
                  label: 'spaceClient.form.username',
                  placeholder: 'spaceClient.form.username',
                },
                {
                  name: 'password',
                  type: 'password',
                  label: 'spaceClient.form.password',
                  placeholder: 'spaceClient.form.password',
                },
              ],
              submitLabel: 'actions.CONNECTION',
              onSubmit: (credentials) => {
                login(credentials)
                  .then(() => {
                    userApi.updateUserLastConnectionDate({ username: credentials.username })
                        .catch(notifyError(this.props.dispatch));
                    this.props.history.push(DASHBOARD_PAGE_ROUTE);
                    dataLayer.showGTM(TAG_EVENT.CONNECTION);
                  })
                  .catch(notifyError(this.props.dispatch));
              },
            }}
            back={{
              label: 'spaceClient.form.forgottenLink',
              action: () => {
                dataLayer.showGTM(TAG_EVENT.CLIC_FORGOTTEN_PASSWORD);
                this.props.history.push(FORGOTTEN_PAGE_ROUTE);
              },
            }}
          />
        );

      case MODIFY_PAGE_ROUTE:
        return (
          <LoginForm
            title="spaceClient.form.modifyTitle"
            form={{
              fields: [
                {
                  name: 'password',
                  type: 'password',
                  label: 'spaceClient.form.modifyPassword',
                  placeholder: 'spaceClient.form.modifyPassword',
                  description: null,
                },
                {
                  name: 'confirmPassword',
                  type: 'password',
                  label: 'spaceClient.form.modifyPasswordConfirm',
                  placeholder: 'spaceClient.form.modifyPasswordConfirm',
                  description: null,
                },
              ],
              submitLabel: 'actions.VALIDATE',
              onSubmit: (value) => {
                dataLayer.showGTM(TAG_EVENT.CLIC_VALIDATE_NEW_PASSWORD);
                userApi
                  .updatePassword(value, this.props.match.params.token)
                  .then(() => {
                    this.props.history.push(MODIFY_CONFIRMATION_PAGE_ROUTE);
                  })
                  .catch(notifyError(this.props.dispatch));
              },
            }}
            back={{
              label: 'actions.BACK_LOGIN',
              action: () => this.props.history.push(LOGIN_PAGE_ROUTE),
            }}
          />
        );

      case FIRST_CONNECTION_PAGE_ROUTE:
        dataLayer.showGTM(TAG_EVENT.FIRST_CONNECTION_LINK);
        return (
          <LoginForm
            title="spaceClient.form.fisrtConnectionTitle"
            subtitle="spaceClient.form.firstConnectionSubtitle"
            form={{
              fields: [
                {
                  name: 'password',
                  type: 'password',
                  label: 'spaceClient.form.fisrtConnectionPassword',
                  placeholder: 'spaceClient.form.fisrtConnectionPassword',
                  description: null,
                },
                {
                  name: 'confirmPassword',
                  type: 'password',
                  label: 'spaceClient.form.fisrtConnectionPasswordConfirm',
                  placeholder: 'spaceClient.form.fisrtConnectionPasswordConfirm',
                  description: null,
                },
              ],
              submitLabel: 'actions.VALIDATE',
              onSubmit: (value) => {
                userApi
                  .updatePassword(value, this.props.match.params.token)
                  .then(() => {
                    this.props.history.push(FIRST_CONNECTION_CONFIRMATION_PAGE_ROUTE);
                    dataLayer.showGTM(TAG_EVENT.ACTIVATE_ACCOUNT);
                  })
                  .then(notifySuccess(this.props.dispatch))
                  .catch(notifyError(this.props.dispatch));
              },
            }}
            back={{
              label: 'spaceClient.form.forgottenLink',
              action: () => this.props.history.push(FORGOTTEN_PAGE_ROUTE),
            }}
          />
        );

        case REACTIVATE_PAGE_ROUTE:
            dataLayer.showGTM(TAG_EVENT.REACTIVATE_ACCOUNT);
            return (
                <LoginForm
                    title="spaceClient.form.reactivateAccountTitle"
                    subtitle="spaceClient.form.reactivateAccountSubtitle"
                    form={{
                        fields: [
                            {
                                name: 'password',
                                type: 'password',
                                label: 'spaceClient.form.fisrtConnectionPassword',
                                placeholder: 'spaceClient.form.fisrtConnectionPassword',
                                description: null,
                            },
                            {
                                name: 'confirmPassword',
                                type: 'password',
                                label: 'spaceClient.form.fisrtConnectionPasswordConfirm',
                                placeholder: 'spaceClient.form.fisrtConnectionPasswordConfirm',
                                description: null,
                            },
                        ],
                        submitLabel: 'actions.VALIDATE',
                        onSubmit: (value) => {
                            userApi
                                .updatePassword(value, this.props.match.params.token)
                                .then(() => {
                                    this.props.history.push(REACTIVATE_CONNECTION_PAGE_ROUTE);
                                    dataLayer.showGTM(TAG_EVENT.VALIDATE_REACTIVATION);
                                })
                                .then(notifySuccess(this.props.dispatch))
                                .catch(notifyError(this.props.dispatch));
                        },
                    }}
                />
            );

      case FORGOTTEN_PAGE_ROUTE:
        return (
          <LoginForm
            title="spaceClient.form.forgottenTitle"
            form={{
              fields: [
                {
                  name: 'username',
                  type: 'email',
                  label: 'spaceClient.form.forgottenField',
                  placeholder: 'spaceClient.form.forgottenField',
                  description: 'spaceClient.form.forgottenMention',
                },
              ],
              submitLabel: 'actions.SEND_MAIL',
              onSubmit: (value) => {
                dataLayer.showGTM(TAG_EVENT.CLIC_SEND_MAIL_FORGOTTEN_PASSWORD);
                userApi
                  .modifyPassword(value)
                  .then(() => {
                    this.props.history.push(FORGOTTEN_CONFIRMATION_PAGE_ROUTE);
                  })
                  .catch(notifyError(this.props.dispatch));
              },
            }}
            back={{
              label: 'actions.BACK_LOGIN',
              action: () => this.props.history.push(LOGIN_PAGE_ROUTE),
            }}
          />
        );

      case FORGOTTEN_CONFIRMATION_PAGE_ROUTE:
        return (
          <LoginForm
            title="spaceClient.form.forgottenTitle"
            validate="spaceClient.form.forgottenConfirm"
            back={{
              label: 'actions.BACK_LOGIN',
              action: () => this.props.history.push(LOGIN_PAGE_ROUTE),
            }}
          />
        );

      case MODIFY_CONFIRMATION_PAGE_ROUTE:
        return (
          <LoginForm
            title="spaceClient.form.forgottenTitle"
            validate="spaceClient.form.modifyConfirm"
            back={{
              label: 'actions.BACK_LOGIN',
              action: () => this.props.history.push(LOGIN_PAGE_ROUTE),
            }}
          />
        );

      case FIRST_CONNECTION_CONFIRMATION_PAGE_ROUTE:
        return (
          <LoginForm
            title="spaceClient.form.fisrtConnectionTitle"
            validate="spaceClient.form.firstConnectionConfirm"
            back={{
              label: 'actions.BACK_LOGIN',
              action: () => this.props.history.push(LOGIN_PAGE_ROUTE),
            }}
          />
        );

        case REACTIVATE_CONNECTION_PAGE_ROUTE:
            return (
                <LoginForm
                    title="spaceClient.form.reactivateAccountTitle"
                    validate="spaceClient.form.reactivateAccountConfirm"
                    back={{
                        label: 'actions.BACK_LOGIN',
                        action: () => {
                          dataLayer.showGTM(TAG_EVENT.CLIC_BACK_LOGIN);
                          this.props.history.push(LOGIN_PAGE_ROUTE);
                        },
                    }}
                />
            );
    }
  };

  render() {
    return (
      <div className="space-client-module login">
        <div className="space-client-description">
          <div className="space-client-header">
            <div className="logo-container" />
            <Flag />
          </div>
          <div className="text">
            <h1><Translate value="spaceClient.description.title" /></h1>
            <li><Translate value="spaceClient.description.firstBlock" /></li>
            <li><Translate value="spaceClient.description.secondBlock" /></li>
            <li><Translate value="spaceClient.description.thirdBlock" /></li>
            <li><Translate value="spaceClient.description.fourthBlock" /></li>
            <li><Translate value="spaceClient.description.fifthBlock" /></li>
          </div>
        </div>
        <div className="space-client-form-container">
          {this.generateForm(this.props.match.path)}
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(LoginModule));
