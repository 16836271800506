import groupe from '../img/groupe.jpg';
import pro from '../img/pro.jpg';
import europe from '../img/europe.jpg';
import oceanie from '../img/oceanie.jpg';

export const STATUS = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
  OPTIONAL: 'OPTIONAL',
};

export const CODES = {
  REPATRIATION: 'G6',
  LOST_LUGGAGE: 'G18',
  LATE_LUGGAGE: 'G19',
  DAMAGED_HOME: 'G23',
  SPORT_OPTION: 'G24',
  RELAY_BK_OPTION: 'CB_PRE_INCL',
  PREMIUM_BK_OPTION: 'CB_PRE',
};

export const CODE_TO_LABEL = {
  G18: 'Bagage perdu, volé et endommagé',
  G19: 'Retard bagage',
  G20: 'Rachat de franchise d\'un véhicule loué',
  G21: 'Responsabilité civile',
  G24: 'Option sport',
  CB_PRE: 'Option relais carte bancaire',
  CB_PRE_INCL: 'Option complément carte bancaire premium',
};

export const FORMULA_CODES = {
  CONFORT: 'VOY_TRST_CONFORT',
  ZEN: 'VOY_TRST_ZEN',
  GROUPE: 'VOY_GPR',
  PRO: 'VOY_PRO',
  SCHENGEN: 'VOY_SCHGN',
};

export const FORMULA_NAMES = {
  VOY_TRST_ZEN: 'VOY_TRST_ZEN',
  VOY_TRST_CONFORT: 'VOY_TRST_CONFORT',
  VOY_SCHGN: 'VOY_SCHGN',
  VOY_GPR: 'VOY_GPR',
  VOY_PRO: 'VOY_PRO',
};

export const IMG_URL = {
  VOY_TRST_ZEN: oceanie,
  VOY_TRST_CONFORT: oceanie,
  VOY_GPR: groupe,
  VOY_SCHGN: europe,
  VOY_PRO: pro,
};

export const I18N_LABEL = {
  VOY_TRST_ZEN: 'zen',
  VOY_TRST_CONFORT: 'confort',
  VOY_GPR: 'groupe',
  VOY_SCHGN: 'schengen',
  VOY_PRO: 'pro',
};
