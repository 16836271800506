// @flow

import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import type { Formula } from '../steps/types/steps';
import { formatPercent, formatPrice } from '../../services/formator';
import quoteApi from '../../network/api/quoteApi';
import type { TripInfos } from '../../types/storage';
import { storeDiscount, storeQuote, storeTripInfos } from '../../state/storage/storageService';
import { FORMULA } from '../../state/storage/storageReducer';
import { setIsPromoLoading } from '../../state/submission/submissionService';

type Props = {
  ...ContextRouter,
  chosenFormula: Formula,
  formula: Formula,
  tripInfos: TripInfos,
  totalPrice: number,
  dispatch: Function,
  discount: number,
  isBasket: boolean,
  isPromoLoading: boolean,
};

function PromoCode(props: Props) {
  const {
    match,
    tripInfos,
    dispatch,
    formula,
    totalPrice,
    discount,
    isBasket,
    isPromoLoading,
  } = props;

  const step = parseInt(match.params.number, 10);

  const formulaInStorage = localStorage.getItem(FORMULA) ? JSON.parse(String(localStorage.getItem(FORMULA))) : undefined;
  const formulaInStorageCode = formulaInStorage ? formulaInStorage.code : '';

  const handleValidatePromoCode = () => {
    dispatch(setIsPromoLoading(true));
    dataLayer.showGTM(TAG_EVENT.CLIC_VALIDATE_PROMO);
    const quoteBody = JSON.parse(JSON.stringify(tripInfos));
    quoteBody.promoCode = discountCode;
    quoteApi.postTripInfos(quoteBody)
      .then((data) => data.json())
      .then((data) => {
        dispatch(setIsPromoLoading(false));
        const selectedOffer = data.offers.find((offer) => offer.code === formula.code);
        const promo = selectedOffer ? parseFloat(selectedOffer.promo) : 0;
        formula.promo = promo;
        setInvalidDiscount(!promo);
        if (!promo) {
          return;
        }
        dispatch(storeTripInfos({ ...quoteBody, promoCodeInUseId: null }, false));
        dispatch(storeQuote(data));
        dispatch(storeDiscount(promo, false));
      }).catch(() => dispatch(setIsPromoLoading(false)));
  };

  const handleDeleteDiscount = () => {
    const quoteBody = JSON.parse(JSON.stringify(tripInfos));
    quoteBody.promoCode = null;
    formula.promo = null;
    dispatch(storeTripInfos(quoteBody, false));
    dispatch(storeDiscount(0, false));
    setDiscountCode('');
    setInvalidDiscount(false);
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter' && discountCode && !isPromoLoading){
      handleValidatePromoCode()
    }
  }

  useEffect(() => {
    if(formula && formula.code !== formulaInStorageCode && step === 2) {
      handleDeleteDiscount()
    }
  }, [formula]);

  const [discountCode, setDiscountCode] = useState();
  const [invalidDiscount, setInvalidDiscount] = useState(false);

  return (
    <div>
      {parseFloat(discount) === 0 && step === 2 &&
      <div className="discount-code">
        <h4>
          {I18n.t('basket.discountCode')}
        </h4>
        <div className="discount-input">
          <input
            value={discountCode || ''}
            onChange={(event) => setDiscountCode(event.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            type="button"
            className={(discountCode && !isPromoLoading) ? 'promo-button' : 'promo-button disabled'}
            disabled={!discountCode || isPromoLoading}
            onClick={handleValidatePromoCode}
          >
            {
              isPromoLoading ?
                <i className="fa fa-circle-notch fa-spin" /> :
                <h6>{I18n.t('basket.validateDiscount')}</h6>
            }
          </button>
        </div>
        {invalidDiscount && <div className="error-custom">{I18n.t('basket.invalidDiscountCode')}</div>}
      </div>
      }
      {parseFloat(discount) !== 0 &&
      (isBasket ?
          <div className="data-item">
            <div className="option">
              {step === 2 && <i role="presentation" className="fa fa-times" onClick={handleDeleteDiscount} />}
              <h4>{I18n.t('basket.discount', { price: formatPercent(discount) })}</h4>
            </div>
            <h4
              className="price">{I18n.t('basket.discountAmount', { amount: formatPrice(Number(parseFloat(discount * totalPrice).toFixed(2))) })}</h4>
          </div> :
          <div>
            <div className="recap-section-header">
              <h3>{I18n.t('travelInsuranceForm.step2.recap.discount')}</h3>
              <hr />
            </div>
            <div className="recap-section-body">
              <div className="recap-warranty-item">
                <i className="fa fa-plus" />
                <h3
                  className="optional-warranty"
                >
                  {I18n.t('travelInsuranceForm.step2.recap.discountAmount', { price: formatPercent(discount) })}
                </h3>
                <h3 className="recap-warranty-item-price">
                  {I18n.t('basket.discountAmount', { amount: formatPrice(Number(parseFloat(discount * totalPrice).toFixed(2))) })}
                </h3>
              </div>
            </div>
          </div>
      )}
    </div>
  );
}

export default withRouter(connect((state) => ({
  tripInfos: state.storage.tripInfos,
  formula: state.storage.formula,
  totalPrice: state.storage.totalPrice,
  discount: state.storage.discount,
  isPromoLoading: state.submission.isPromoLoading,
}))(PromoCode));
