// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import moment from 'moment';
import type { ContextRouter, Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import paymentApi from '../../../network/api/paymentApi';
import userApi from '../../../network/api/userApi';
import {
  storeMaxPage,
  storePaymentId,
  storeTripInfos,
} from '../../../state/storage/storageService';
import { DATE_FORMAT, PAYMENT_TYPE, STEP_ROOT_PAGE_ROUTE, WORD } from '../../../const';
import type { PaymentKeys, TripInfos } from '../../../types/storage';
import { formatPrice } from '../../../services/formator';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { gtagService } from '../../../services/gtagService';
import { currentUser } from '../../../services/sessionService';
import { fetchQuote, formatCoveragesBodyFromParams, getQueryParams } from '../../../utils';
import type { UserModification } from '../../../types/loginTypes';
import { setIsQuoteLoading } from '../../../state/submission/submissionService';

type Props = {
  ...ContextRouter,
  match: Match,
  history: RouterHistory,
  amount: number,
  tripInfos: TripInfos,
  travelerInfos: Object,
  finalPrice: number,
  options: Object[],
  optionalCoverages: Object[],
  formula: Object,
  dispatch: Function,
  paymentId: string,
  START_CONTRACT: string
};

function CheckoutForm(props: Props) {
  const user = currentUser();

  const [isCheckError, setIsCheckError] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [redirectStepUrl, setRedirectStepUrl] = useState<string>(undefined);
  const errorCode = {
    ORDER_REQUEST_ERROR: 'ORDER_REQUEST_ERROR',
    ORDER_ALREADY_CREATED: 'ORDER_ALREADY_CREATED',
    ORDER_ALREADY_PAID: 'ORDER_ALREADY_PAID',
    EXPIRED_CONTROL_TOKEN: 'EXPIRED_CONTROL_TOKEN',
    INVALID_CONTROL_TOKEN: 'INVALID_CONTROL_TOKEN',
  };

  const finalPrice = parseFloat(props.finalPrice).toFixed(2).toString();

  const body = {
    tripInfos: {
      ...props.tripInfos,
      amount: props.tripInfos.amount.toString().replace(',', '.'),
    },
    formula: props.formula,
    totalPrice: finalPrice,
    coverages: formatCoveragesBodyFromParams(props.formula, props.optionalCoverages, props.options),
    travelerInfos: {
      user: {
        ...props.travelerInfos.user,
        birthdate: moment(props.travelerInfos.user.birthdate, DATE_FORMAT.FORMAT)
          .format(DATE_FORMAT.LOCAL_FORMAT),
        country: props.travelerInfos.user.country.value,
        receiveCommonCertificate: props.travelerInfos.user.receiveCommonCertificate !== WORD.NO,
      },
      beneficiary: props.travelerInfos.beneficiary.map((b) => ({
        ...b,
        birthdate: moment(b.birthdate, DATE_FORMAT.FORMAT).format(DATE_FORMAT.LOCAL_FORMAT),
        country: (b.country && b.country.value)
          ? b.country.value
          : props.travelerInfos.user.country.value,
        address: b.address ? b.address : props.travelerInfos.user.address,
        address_supp: b.address_supp ? b.address_supp : props.travelerInfos.user.address_supp,
        city: b.city ? b.city : props.travelerInfos.user.city,
        postcode: b.postcode ? b.postcode : props.travelerInfos.user.postcode,
      })),
    },
  };

  const initPayment = (mustRefuse: boolean) => {
    setLoading(true);
    setError(null);
    dataLayer.showGTM(TAG_EVENT.CLIC_PAYER);
    paymentApi.getIntentKey(body, mustRefuse)
      .then((response) => response.json())
      .then((keys: PaymentKeys) => {
        setLoading(false);
        props.dispatch(storePaymentId(keys.swingIdentifier));
        window.open(keys.intentKey, '_self');
      })
      .catch((e) => e.then((exception) => {
        let code = errorCode.ORDER_REQUEST_ERROR;
        if(exception.errorCode === errorCode.EXPIRED_CONTROL_TOKEN) {
          code = errorCode.EXPIRED_CONTROL_TOKEN;
          setRedirectStepUrl(`${STEP_ROOT_PAGE_ROUTE}/2`);
        } else if(exception.errorCode === errorCode.INVALID_CONTROL_TOKEN) {
          code = errorCode.INVALID_CONTROL_TOKEN;
          setRedirectStepUrl(`${STEP_ROOT_PAGE_ROUTE}/1`);
        } else if (exception.statusArguments && exception.statusArguments.length > 0) {
          code = exception.statusArguments[0];
        }
        setLoading(false);
        setError(code);
      }));
  };

  const cancelLoadingAndNextStepIfLoading = () => {
    setLoading(false);
    handleNavigateToNextStep(props.paymentId);
  };

  const handleTrackingReadyOrTimeOut = () => {
    // will set a loader on the page
    setLoading(true);
    // will wait for the tracking to be ready
    window.addEventListener('tracking-ready', cancelLoadingAndNextStepIfLoading);
    // if the tracking was not loaded after 10 seconds, still going to the next page
    setTimeout(cancelLoadingAndNextStepIfLoading, 10000);
  };

  useEffect(() => {
    const queryParam = getQueryParams();
    let isRetry = false;
    // Après un paiement paybox on redirige vers l'étape 4
    // avec un query param qui donne le statut du paiement
    if (queryParam.fromPayBox === 'accepted') {
      handleTrackingReadyOrTimeOut();
      return;
    }
    if (queryParam.fromPayBox === 'retry') {
      isRetry = true;
    }
    paymentApi.checkPayment(body)
      .then(data => data.json())
      .then((data) => {
        props.dispatch(storeTripInfos({...props.tripInfos, promoCodeInUseId: data.promoCodeInUseId},true));
        setIsCheckError(false);
      })
      .catch((e) => {
        e.then((exception) => {
          const { statusArguments } = exception;
          let code = errorCode.ORDER_REQUEST_ERROR;
          if(exception.errorCode === errorCode.EXPIRED_CONTROL_TOKEN) {
            code = errorCode.EXPIRED_CONTROL_TOKEN;
            setRedirectStepUrl(`${STEP_ROOT_PAGE_ROUTE}/2`);
          } else if(exception.errorCode === errorCode.INVALID_CONTROL_TOKEN) {
            code = errorCode.INVALID_CONTROL_TOKEN;
            setRedirectStepUrl(`${STEP_ROOT_PAGE_ROUTE}/1`);
          } else if (statusArguments && statusArguments.length > 0) {
            code = statusArguments[0];
          }
          setIsCheckError(!isRetry);
          if (!isRetry) {
            setError(code);
          }
          if (code === errorCode.ORDER_ALREADY_CREATED) {
            setRedirectStepUrl(isRetry ? undefined : `${STEP_ROOT_PAGE_ROUTE}/1`);
          }
          if (code === errorCode.ORDER_ALREADY_PAID) {
            setError(code);
            setIsCheckError(true);
            setRedirectStepUrl(`${STEP_ROOT_PAGE_ROUTE}/1`);
          }
        });
      })
  }, []);

  const handleNavigateToNextStep = (swingIdentifier) => {
    dataLayer.updatePayment(PAYMENT_TYPE.CB, getQueryParams().cardType);
    gtagService.sendPurchase(
      props.formula,
      props.optionalCoverages,
      props.options,
      swingIdentifier,
      finalPrice,
      props.tripInfos.promoCode,
    );
    if (user) {
      const userToUpdate: UserModification = {
        ...user,
        address: props.travelerInfos.user.address,
        address2: props.travelerInfos.user.address_supp,
        birthdate: moment(props.travelerInfos.user.birthdate, DATE_FORMAT.FORMAT)
          .format(DATE_FORMAT.LOCAL_FORMAT),
        email: props.travelerInfos.user.email,
        phoneNumber: props.travelerInfos.user.phone,
        firstname: props.travelerInfos.user.surname,
        lastname: props.travelerInfos.user.name,
        zipCode: props.travelerInfos.user.postcode,
        countryCode: props.travelerInfos.user.country.value,
        city: props.travelerInfos.user.city,
        gender: props.travelerInfos.user.gender,
        modificationDate: null,
        passwordConfirm: null,
        password: null,
      };

      userApi.update(userToUpdate)
        .catch((e) => {
          console.error(e);
        });
    }

    props.dispatch(storeMaxPage(parseInt(props.match.params.number, 10) + 1));
    props.history.push(`${STEP_ROOT_PAGE_ROUTE}/5`);
  };

  const submitText = () => (
    props.travelerInfos.user.isBeneficiary && props.travelerInfos.beneficiary.length === 1
  );

  const handleSubmitCB = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_PAYER);
  };
  
  const onClickOnRedirectionErrorButton = () => {
    if (error === errorCode.EXPIRED_CONTROL_TOKEN) {
      props.dispatch(setIsQuoteLoading(true));
      fetchQuote(
        props.tripInfos,
        props.START_CONTRACT,
        parseInt(props.match.params.number, 10),
        props.history.push,
        props.dispatch,
        true
      );
    } else {
      props.history.push(redirectStepUrl);
    }
  }

  return (
    <Form
      onSubmit={() => {
        handleSubmitCB();
      }}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          {
            error
            && (
              <div className="checkout-form">
                <h6 className="error-msg">
                  {I18n.t(`travelInsuranceForm.step4.payment.${error}`)}
                </h6>
                {
                  redirectStepUrl
                  && (
                    <div className="submit">
                      <button
                        type="button"
                        onClick={onClickOnRedirectionErrorButton}
                      >
                        {
                          props.isQuoteLoading ?
                            <i className="fas fa-circle-notch fa-spin" /> :
                            I18n.t('travelInsuranceForm.step4.payment.ERROR_CLICK_HERE')
                        }
                      </button>
                    </div>
                  )
                }
              </div>
            )
          }
          {
            !redirectStepUrl
            && (
              <div className={(!loading && !isCheckError) ? 'submit' : 'submit disabled'}>

                <button
                  type="submit"
                  onClick={() => initPayment(false)}
                  disabled={loading || isCheckError}
                >
                  {
                    loading
                      ? (
                        <i className="fa fa-circle-notch fa-spin" />
                      )
                      : (
                        <Translate
                          value={`travelInsuranceForm.step4.payment.${submitText() ? 'submit' : 'submitGroupe'}`}
                          amount={formatPrice(props.finalPrice)}
                        />
                      )
                  }
                </button>
              </div>
            )
          }
          {
            /* A décommenter pour tester les paiements refusés
            !redirectStep1 &&
            <div className="submit">
              <button
                type="submit"
                onClick={() => initPayment(true)}
                disabled={loading || isCheckError}
                className='button-test'
              >
                {
                  loading ? <i className="fa fa-circle-notch fa-spin" />
                    : (
                      <div>
                        <Translate
                          value={'travelInsuranceForm.step4.payment.submit'}
                          amount={formatPrice(props.finalPrice)}
                        />
                        <div>{I18n.t('travelInsuranceForm.step4.payment.submitRefuse')}</div>
                      </div>
                    )
                }
              </button>
            </div>
            */
          }
        </form>
      )}
    </Form>
  );
}

export default withRouter(connect((state) => ({
  tripInfos: state.storage.tripInfos,
  travelerInfos: state.storage.travelerInfos,
  totalPrice: state.storage.totalPrice,
  finalPrice: state.storage.finalPrice,
  options: state.storage.options,
  optionalCoverages: state.storage.optionalCoverages,
  formula: state.storage.formula,
  redirect: state.storage.redirect,
  countries: state.storage.countries,
  paymentId: state.storage.paymentId,
  START_CONTRACT: state.storage.START_CONTRACT,
  isQuoteLoading: state.submission.isQuoteLoading,
}))(CheckoutForm));
