// @flow

import React from 'react';
import { Translate } from 'react-redux-i18n';

type Props = {
  icon: string,
  titleCode: string,
  redirectFunction: Function,
  isActive: boolean,
}

export default class DashboardTile extends React.PureComponent<Props> {
  render() {
    return (
      <div className={`dashboard-tile ${this.props.isActive ? 'active' : ''}`} onClick={this.props.redirectFunction}>
        <div className="tile-icon"><i className={this.props.icon} /></div>
        <div className="tile-title">
          <h3>
            <Translate value={this.props.titleCode} />
          </h3>
        </div>
      </div>
    );
  }
}
