// @flow

import moment from 'moment';
import { STEP_ROOT_PAGE_ROUTE } from '../const';
import type { TravelerInfos, TripInfos } from '../types/storage';
import type { Coverage, Formula, OptionalCoverage, Quote } from '../components/steps/types/steps';
import { FORMULA_CODES } from './coverages';
import { removeUnderscore } from './formator';
import { isSameAddress, mergeDeep, capitalize } from '../utils';
import crypto from './crypto';

const event = 'event';

export const TAG_EVENT = {
  REDIRECTION_SOUSCRIPTION: 'RedirectionSouscription',
  REDIRECTION_NO_SOUSCRIPTION: 'RedirectionNoSouscription',
  REDIRECTION_CHOICE: 'RedirectionAvecChoixPréalable : ',
  REDIRECTION_CHOICE_ZEN_CONFORT: 'RedirectionChoixZen/Confort',
  REDIRECTION_QUIT: 'RedirectionQuit',
  CG: 'cg',
  TEL: 'tel',
  REFERER_MUTUAIDE: 'referalMutuaide',
  PROPOSITION: 'clicDownloadProposition',
  CLIC_QUESTIONS: 'clicQuestionsFrequentes',
  CLIC_DIN: 'clicDin',
  CLIC_DIN_CONTRACT: 'clicDinContractPage',
  CLIC_NOTICE_INFORMATION: 'clicNoticeInformation',
  CLIC_NOTICE_INFORMATION_CONTRACT: 'clicNoticeInformationContractPage',
  CLIC_INFORMATIONS: 'clicMesInformations',
  CLIC_RETOUR: 'clicRetourEtape',
  CLIC_FLAG: 'clicDrapeau',
  CLIC_FORGOTTEN_PASSWORD: 'clicMotDePasseOublie',
  CLIC_SEND_MAIL_FORGOTTEN_PASSWORD: 'clicEnvoyerMailReinitialisationMotDePasse',
  CLIC_VALIDATE_NEW_PASSWORD: 'clicValiderNouveauMotDePasse',
  CLIC_ESPACE_CLIENT: (category : string) : string => `clic${category}Dashboard`,
  CLIC_NOUVEAU_DEVIS: 'clicNouveauDevis',
  CLIC_FAV_LINK_FAQ: 'clicPropositionRechercheFaq',
  CLIC_ARIANE_1: 'clicAriane1',
  CLIC_ARIANE_2: 'clicAriane2',
  CLIC_ARIANE_3: 'clicAriane3',
  CLIC_ARIANE_4: 'clicAriane4',
  CLIC_FIND_COVERAGE: 'clicTrouverMonAssurance',
  CLIC_TRAVEl_TYPE: (type: string): string => `clicVoyage${type.charAt(0).toUpperCase() + type.slice(1)}`,
  CLIC_DESTINATION_RESTRICTION_WEBSITE: 'clicLinkDestinationRestriction',
  CLIC_RETOUR_PAYMENT: 'clicRetourApresPaiement',
  CLIC_SHOW_MORE: 'clicVoirPlus',
  CLIC_SHOW_LESS: 'clicVoirMoins',
  CLIC_EDIT_BENEFICIARY: 'clicModifierBeneficiaire',
  CLIC_EDIT_TRIP: 'clicModifierVoyage',
  CLIC_PAYER: 'clicPayer',
  CLIC_EDIT_INFOS: 'clicModifierInformations',
  CLIC_ASSURANCE_RAPATRIEMENT: 'clicAssuranceRapatriement',
  CLIC_PROTECTION_MALADIE: 'clicProtectionMaladie',
  CLIC_OPTION_SPORT: 'clicOptionSport',
  CLIC_OPTION_CB_PREMIUM: 'clicOptionsCBPremium',
  CLIC_OPTION_CB_RELAIS: 'clicOptionsCBRelais',
  CLIC_VALIDATE_PROMO: 'clicValiderCodePromo',
  CLIC_SOUSCRIPTION: 'clicSouscrire',
  CLIC_ZEN: 'clicZen',
  CLIC_CONFORT: 'clicConfort',
  CLIC_POPUP_PREMIUM_ACCEPT: 'clicPopupCbPremiumAccept',
  CLIC_POPUP_PREMIUM_REFUSE: 'clicPopupCbPremiumRefuse',
  CLIC_POPUP_RELAIS_ACCEPT: 'clicPopupCbRelaisAccept',
  CLIC_POPUP_RELAIS_REFUSE: 'clicPopupCbRelaisRefuse',
  CLIC_SUBSCRIBER_IS_BENEFICIARY: 'clicCocheSouscripteurBeneficiaire',
  CLIC_GO_TO_PAYMENT: 'clicProcederAuPaiement',
  CLIC_BACK_LOGIN: 'clicRetourPageConnexion',
  FIRST_CONNECTION_LINK: 'firstConnection',
  REACTIVATE_ACCOUNT: 'reactivationPage',
  ACTIVATE_ACCOUNT: 'clicActivateAccount',
  VALIDATE_REACTIVATION: 'clicValiderReactivation',
  CONNECTION: 'clicConnexion',
  CONTRACT_DOWNLOAD: 'clicTelechargerContrat',
  ATTESTATION_DOWNLOAD: 'clicTelechargerAttestation',
  CLIC_LINK_MAIL: (utm_campaign: string) => `clicMail${utm_campaign}` ,
  SCROLL: (percentage:number, step: string): string => `scroll${percentage}${STEP_ROOT_PAGE_ROUTE}/${step}`,
};

export const COVERAGE_CODE = {
  ASSURANCE_RAPATRIEMENT: 'G6',
  PROTECTION_MALADIE: 'G26',
}

const keyGroupama = 'MRctQ1kGN3gJCgs3Qlhjfw==';

const NB_RETRIES = 5;
const DELAY_RETRY = 1000; // In milliseconds

let hasTrackingGoogle = false;
let hasTrackingAdobe = false;
let needToAnonymizePersonalData = true;

let currentStep: number = 0;

export const dataLayer = {
  updateFilAriane: (step: number) => {
    currentStep = step;
    let stepName;
    let satelliteTrack = '';
    switch (step) {
      case 1:
        stepName = 'Devis Voyage::Etape 1::Voyage';
        satelliteTrack = 'voyagePage';
        break;
      case 2:
        stepName = 'Devis Voyage::Etape 2.1::Devis - Tarif';
        satelliteTrack = 'devis2-1';
        break;
      case 2.2:
        stepName = 'Devis Voyage::Etape 2.2::Devis - Options';
        satelliteTrack = 'devisvoyage2-2';
        break;
      case 2.3:
        stepName = 'Devis Voyage::Etape 2.3::Devis - Recapitulatif';
        satelliteTrack = 'devisvoyage2-3';
        break;
      case 3:
        stepName = 'Devis Voyage::Etape 3::Voyageur';
        satelliteTrack = 'voyageursPage';
        break;
      case 4:
        stepName = 'Devis Voyage::Etape 4::Paiement';
        satelliteTrack = 'paiementPage';
        break;
      case 5:
        stepName = 'Devis Voyage::Etape 5::Confirmation';
        satelliteTrack = 'souscriptionPage';
        break;
      case -1:
        stepName = 'Devis Voyage::Debranchement';
        satelliteTrack = 'devisvoyagedebranchement';
        break;
      default:
        stepName = '';
    }

    updateDataLayer({
      contenu: {
        fil_ariane: stepName,
      },
    });

    sendEvent(satelliteTrack);
  },
  updateTripInformations: (value: TripInfos) => {
    const voyage = {
      type_voyage: value.travelType,
      nb_adultes: value.numberOfAdults,
      nb_enfants: value.numberOfKids,
      nb_bebes: value.numberOfBabies,
      pays_residence: value.countryOfResidence ? value.countryOfResidence.label : '',
      date_depart: value.travelDateRange ? value.travelDateRange.startDate : '',
      date_arrive: value.travelDateRange ? value.travelDateRange.endDate : '',
      montant_voyage: value.amount,
      services_proposes: value.expenses ? value.expenses.label : '',
      duree: value.travelDateRange ? moment(value.travelDateRange.endDate).diff(moment(value.travelDateRange.startDate), 'days') : 0,
      destination: (value.destinations && value.destinations.length > 0) ? value.destinations
        .map(destination => destination.label)
        .reduce((acc, value) => `${acc}|${value}`) : '',
    };
    updateDataLayer({ devis: { voyage } });
  },
  updateTravelerInformations: (value: TravelerInfos) => {
    if (value.user) {
      updateDataLayer({
        outils_an: {
          code_postal: value.user.postcode,
        },
        devis: {
          subscriber: {
            is_beneficiary: value.user.isBeneficiary,
            commercial_offer: value.user.acceptOffers ? value.user.acceptOffers : false,
            receive_multiple_docs: value.user.receiveDoc === 'yes',
            same_address: isSameAddress(value),
          },
          commun: {
            hash_phone: handlePersonalData(value.user.phone),
            hash_email: handlePersonalData(value.user.email),
            hash_nom: handlePersonalData(value.user.name),
            hash_prenom: handlePersonalData(value.user.surname),
          },
        },
      });
    }
  },
  updateQuoteInformations: (value: Quote) => {
    let prices: { [string]: string } = {};
    let offerFound;
    for (let [offerShort, offerCode] of Object.entries(FORMULA_CODES)) {
      offerFound = value.offers.find(offer => offer.code === offerCode);
      if (offerFound) {
        prices[`tarif_${offerShort.toLowerCase()}`] = offerFound.totalAmount;
      }
    }
    updateDataLayer({
      devis: {
        voyage: prices,
      },
    });
  },
  updateSelectedOptions: (formula: Formula, selectedOptions: Array<Coverage>, selectedOptionalCoverages: Array<OptionalCoverage>) => {
    let options = '';

    if (
      (selectedOptions && selectedOptions.length > 0) ||
      (selectedOptionalCoverages && selectedOptionalCoverages.length > 0)
    ) {
      const optionsTemp = [
        ...selectedOptions.filter((option) => option.offerCode === formula.code)
          .map((coverage) => coverage.coverage.name),
        ...selectedOptionalCoverages.filter((option) => option.offerCode === formula.code)
          .map((coverage) => coverage.coverage.name),
      ].filter(e => e);

      if (optionsTemp.length > 0) {
        options = optionsTemp.reduce((acc, val) => `${acc} | ${val}`);
      }
    }
    updateDataLayer({
      devis: {
        voyage: {
          formule: formula.name,
          options,
        },
      },
    });
  },
  updateTotalPrice: (tarif: number) => {
    updateDataLayer({
      devis: {
        voyage: {
          tarif_total: tarif,
        },
      },
    });
  },
  updatePayment: (type: string, brand: string) => {
    updateDataLayer({
      payment: {
        type,
        brand,
      },
    });
  },
  populateFixedValues: () => {
    updateDataLayer({
      contenu: {
        marche: 'particulier',
        type_content: 'Devis Voyage',
        region_enregistree: 'NAT',
      },
      contact: {
        origine: 'site marchand',
      },
    });
  },
  updateErrorCode: (errorCode: string) => {
    updateDataLayer({
      devis: {
        debranchement: {
          causeErreur: errorCode,
        },
      },
    });
  },
  updateOffreDebranchement: (offre: string) => {
    updateDataLayer({
      devis: {
        debranchement: {
          autreOffre: offre,
        },
      },
    });
  },
  updateDefaultOfferCode: (offerCode: ?string) => {
    updateDataLayer({
      contact: {
        origine_offer: offerCode || '',
      },
    });
  },
  show: (event: string) => {
    sendEvent(`devisvoyage${event}`);
  },
  showGTM: (event: string) => {
    sendEventGTM(`${event}`);
  },
  setTrackingGoogle: (trackingGoogle: boolean) => {
    hasTrackingGoogle = trackingGoogle;
  },
  setTrackingAdobe: (trackingAdobe: boolean) => {
    hasTrackingAdobe = trackingAdobe;
  },
  setAnonymization: (needToAnonymize: boolean) => {
    needToAnonymizePersonalData = needToAnonymize;
  },
};

export function initializeFilAriane() {
  if (currentStep) {
    dataLayer.updateFilAriane(currentStep);
  }
}

export function updateDataLayerAtRefresh(tripInfos: TripInfos, travelerInfos: TravelerInfos) {
  if (tripInfos) {
    dataLayer.updateTripInformations(tripInfos);
  }
  if (travelerInfos) {
    dataLayer.updateTravelerInformations(travelerInfos);
  }
}

function updateDataLayer(object: Object) {
  // Event Google
  updateDataLayerGTM(object);

  // Event Adobe
  updateDataLayerAdobe(object);
}

function updateDataLayerGTM(object: Object) {
  if (hasTrackingGoogle) {
    updateDataLayerGTMRetry(object, 1);
  }
}

function updateDataLayerGTMRetry(object: Object, currentRetry: number) {
  if (window.dataLayer) {
    window.dataLayer.push(flattenJson(object));
  } else if (currentRetry <= NB_RETRIES) {
    // We are trying again because the tracking js may not be loaded / initialized yet
    setTimeout(() => {
      updateDataLayerGTMRetry(object, currentRetry + 1);
    }, DELAY_RETRY);
  }
}

function updateDataLayerAdobe(object: Object) {
  if (hasTrackingAdobe) {
    updateDataLayerAdobeRetry(object, 1);
  }
}

function updateDataLayerAdobeRetry(object: Object, currentRetry: number) {
  // $FlowFixMe
  if (wa_gfr) {
    wa_gfr = mergeDeep(wa_gfr, object);
  } else if (currentRetry <= NB_RETRIES) {
    // We are trying again because the tracking js may not be loaded / initialized yet
    setTimeout(() => {
      updateDataLayerAdobeRetry(object, currentRetry + 1);
    }, DELAY_RETRY);
  }
}

function sendEvent(event: string) {
  // Event Google
  sendEventGTM(event);

  // Event Adobe
  sendEventAdobe(event);
}

function sendEventGTM(event: string) {
  if (hasTrackingGoogle) {
    sendEventGTMWithRetry(event, 1);
  }
}

function sendEventGTMWithRetry(event: string, currentRetry: number) {
  if (window.dataLayer) {
    window.dataLayer.push({ event });
  } else if (currentRetry <= NB_RETRIES) {
    // We are trying again because the tracking js may not be loaded / initialized yet
    setTimeout(() => {
      sendEventGTMWithRetry(event, currentRetry + 1);
    }, DELAY_RETRY);
  }
}

function sendEventAdobe(event: string) {
  if (hasTrackingAdobe) {
    sendEventAdobeWithRetry(event, 1);
  }
}

function sendEventAdobeWithRetry(event: string, currentRetry: number) {
  if (typeof _satellite !== 'undefined') {
    _satellite.track(event);
  } else if (currentRetry <= NB_RETRIES) {
    // We are trying again because the tracking js may not be loaded / initialized yet
    setTimeout(() => {
      sendEventAdobeWithRetry(event, currentRetry + 1);
    }, DELAY_RETRY);
  }
}

const flattenJson = (data: Object) => recurse({}, data, '');

function handlePersonalData(data: string) {
  if (needToAnonymizePersonalData) {
    return anonymizeData(data);
  }
  return encryptData(data);
}

function encryptData(data: string) {
  const encryptedData = crypto.AES.encrypt(data, crypto.enc.Base64.parse(keyGroupama), {
    mode: crypto.mode.ECB,
    padding: crypto.pad.Pkcs7,
  });

  return encodeURIComponent(encryptedData);
}

function anonymizeData(data: string) {
  return data.replace(/./g, 'X');
}

function recurse(result: Object, cur: any, prop: string) {
  let newResult = { ...result };
  if (Object(cur) !== cur) {
    newResult[prop] = cur;
  } else if (Array.isArray(cur)) {
    for (let i = 0, l = cur.length; i < l; i += 1) newResult = recurse(newResult, cur[i], `${prop}[${i}]`);
    if (cur.length === 0) newResult[prop] = [];
  } else {
    let isEmpty = true;
    Object.keys(cur).forEach((p) => {
      isEmpty = false;
      newResult = recurse(newResult, cur[p], prop ? prop + capitalize(removeUnderscore(p)) : p);
    });
    if (isEmpty && prop) newResult[prop] = {};
  }
  return newResult;
}
