// @flow

import React from 'react';
import type { Formula, OptionalCoverage } from '../types/steps';
import { CODES } from '../../../services/coverages';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { I18n } from 'react-redux-i18n';
import { gtagService } from '../../../services/gtagService';
import { MODAL_TYPES } from '../../../const';

type Props = {
  modalToShow: string,
  modalType: string,
  setModalToShow: Function,
  setSelectedOptions: Function,
  chosenFormula: Formula,
  selectedOptions: OptionalCoverage[],
  preSelectedOption: OptionalCoverage,
  mobileDisplay: boolean,
  cbPreModalText: string,
  cbPreModalTitle: string,
};

function PremiumCreditCardPopIn(
  {
    modalToShow,
    modalType,
    setModalToShow,
    setSelectedOptions,
    chosenFormula,
    selectedOptions,
    preSelectedOption,
    mobileDisplay,
    cbPreModalText,
    cbPreModalTitle,
  }: Props,
): React$Node {
  const textArray = cbPreModalText.toString().split(/\n/ig);
  // remove last item
  textArray.pop();
  const cases = [];
  textArray.forEach((value) => {
    if (value.startsWith('-')) {
      cases.push(value);
    }
  });
  return (
    <div className={modalToShow === CODES.RELAY_BK_OPTION ? 'modal' : 'modal hidden'}>
      <div className="modal-content">
        <button aria-label=" " type="button" className="close-modal" onClick={() => {
          setModalToShow('');
          dataLayer.showGTM(TAG_EVENT.CLIC_POPUP_PREMIUM_REFUSE);
        }}>
          <i
            className="fal fa-times"
          />
        </button>
        <h1 className="modal-header">
          {cbPreModalTitle}
        </h1>
        <h3 className="bankCard-modal-description">
          {textArray[0]}
        </h3>
        <div className="prerequisites">
          {cases.map((value) => (
            <h3 className="prerequisites-item" key={value}>
              {value}
            </h3>
          ))}
          {modalType === MODAL_TYPES.VALIDATION
            ? (
              <div className="bankCard-modal-buttons">
                <button
                  aria-label=" "
                  type="button"
                  className="refuse"
                  onClick={() => {
                    setModalToShow('');
                    dataLayer.showGTM(TAG_EVENT.CLIC_POPUP_PREMIUM_REFUSE);
                  }}
                >
                  <h3>{I18n.t('travelInsuranceForm.step2.completeOffer.option.refuse')}</h3>
                </button>
                <button
                  aria-label=" "
                  type="button"
                  className="accept"
                  onClick={() => {
                    setModalToShow('');
                    dataLayer.showGTM(TAG_EVENT.CLIC_POPUP_PREMIUM_ACCEPT);
                    gtagService.sendSelectedItem(preSelectedOption.coverage, chosenFormula, true);
                    gtagService.sendAddToCart(preSelectedOption.coverage, chosenFormula, true);
                    const filteredOptions = selectedOptions.filter((selectedOption) =>
                      (selectedOption.coverage.code !== CODES.SPORT_OPTION
                        || selectedOption.offerCode !== chosenFormula.code),
                    );
                    setSelectedOptions([...filteredOptions, preSelectedOption]);
                  }}
                >
                  <h3>{I18n.t('travelInsuranceForm.step2.completeOffer.option.accept')}</h3>
                </button>
              </div>
            )
            : (
              <div className="close-bank-card">
                <button
                  aria-label=" "
                  type="button"
                  className="close-button-bank-card"
                  onClick={() => {
                    setModalToShow('');
                  }}
                >
                  <h3>{I18n.t('travelInsuranceForm.step2.completeOffer.option.sport.modal.close')}</h3>
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default PremiumCreditCardPopIn;
