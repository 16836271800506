// @flow

import { RestClientAuthenticated } from '../network';

const fileApi = {
  fetchFileByUrl: (fileParams: string) => new RestClientAuthenticated(`/documents${fileParams}`, 'GET')
    .execute(),
};

export default fileApi;
