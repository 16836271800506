// @flow

import type { Formula, OptionalCoverage } from '../components/steps/types/steps';

export const FILE = {
  CG: 'CG',
  DIN: 'DIN',
};

export const buildUrl = (options: OptionalCoverage[], formula: Formula, type: string): ?string => {

  const filterOptionList = options.filter(
    (option: OptionalCoverage) => option.offerCode === formula.code && option.coverage.fileUrlList,
  );

  if (filterOptionList[0] && filterOptionList[0].coverage
    && filterOptionList[0].coverage.fileUrlList
  ) {
    const filterOptionFile = filterOptionList[0].coverage.fileUrlList.filter(
      (str) => str.offerCode === formula.code,
    );

    if (type === FILE.DIN && filterOptionFile[0] && filterOptionFile[0].dinFileUrl) {
      return filterOptionFile[0].dinFileUrl;
    }

    if (type === FILE.CG && filterOptionFile[0] && filterOptionFile[0].cgFileUrl) {
      return filterOptionFile[0].cgFileUrl;
    }
  }

  if (filterOptionList[1] && filterOptionList[1].coverage
    && filterOptionList[1].coverage.fileUrlList
  ) {
    const filterOptionFile = filterOptionList[1].coverage.fileUrlList.filter(
      (str) => str.offerCode === formula.code,
    );

    if (type === FILE.DIN && filterOptionFile[0] && filterOptionFile[0].dinFileUrl) {
      return filterOptionFile[0].dinFileUrl;
    }

    if (type === FILE.CG && filterOptionFile[0] && filterOptionFile[0].cgFileUrl) {
      return filterOptionFile[0].cgFileUrl;
    }
  }

  if (type === FILE.DIN) {
    return formula && formula.dinUrl && formula.dinUrl;
  }

  if (type === FILE.CG) {
    return formula && formula.cgUrl && formula.cgUrl;
  }

  return '';
};
