// @flow

import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { LANGUAGE } from '../../services/languages';
import footerApi from '../../network/api/footerApi';
import type { Site } from '../../types/storage';

type Props = {
  site: Site,
}

function StepsFooter({ site }: Props) {
  const language = localStorage.getItem(LANGUAGE);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    footerApi.get(null)
      .then((response) => response.json())
      .then(setLinks);
  }, [language]);

  return (
    <div className="footer">
      <div className="link-list">
        {
          links.sort((link, link2) => link.order - link2.order)
            .map((link, index) => (
              <div className="item-style" key={link.label}>
                <a target="_blank" rel="noopener noreferrer" href={link.target}>{link.label}</a>
              </div>
            ))
        }
        {
          site
          && site.oneTrustKey
          && (
            <div className="item-style">
              <a
                className="optanon-toggle-display"
                target="#"
                role="link"
              >
                {I18n.t('footer.cookiesHandle')}
              </a>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default connect(state => ({
  site: state.storage.site,
}))(StepsFooter);
