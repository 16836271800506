// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import type { ContextRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';

type Props = {
  ...ContextRouter,
  maxPage: number,
  offerCode: string,
};

function StepsHeader(props) {

  const step = parseInt(props.match.params.number, 10);

  const getStyle = (number) => {
    if (step === number) {
      return 'main';
    }
    if (number <= props.maxPage && number !== step) {
      return 'active';
    }
    return '';
  };

  const getPointer = (number) => {
    if (number <= props.maxPage && number !== step) {
      return 'pointer';
    }
    return '';
  };

  const getIcon = (number) => {
    switch (number) {
      case 1:
        return <i className="icon-plane" />;
      case 2:
        return <i className="icon-form" />;
      case 3:
        return <i className="icon-travelers" />;
      default:
        return <i className="icon-credit-card-thick" />;
    }
  };

  const handleClick = (number: number) => {
    if (number <= props.maxPage) {
      switch (number) {
        case 1 :
          dataLayer.showGTM(TAG_EVENT.CLIC_ARIANE_1);
          break;
        case 2 :
          dataLayer.showGTM(TAG_EVENT.CLIC_ARIANE_2);
          break;
        case 3 :
          dataLayer.showGTM(TAG_EVENT.CLIC_ARIANE_3);
          break;
        default :
          dataLayer.showGTM(TAG_EVENT.CLIC_ARIANE_4);
          break;
      }
      if (number === 1 && props.offerCode) {
        props.history.push(STEP_ROOT_PAGE_ROUTE + '/1?offerCode=' + props.offerCode);
      } else {
        props.history.push(STEP_ROOT_PAGE_ROUTE + '/' + number);
      }
    }
  };

  const line = (number, label) =>
    <li className={getStyle(number)}>
      <div className={`number ${getPointer(number)}`} onClick={() => handleClick(number)}>
        {getIcon(number)}
        <h2 className={`label ${getStyle(number)}`}>{label}</h2>
      </div>
    </li>;

  return (
    <ul className={`stepper ${step === 4 ? 'center' : ''}`}>
      {line(1, I18n.t('stepper.TRIP_LABEL'))}
      {line(2, I18n.t('stepper.DEVIS_LABEL'))}
      {line(3, I18n.t('stepper.CUSTOMER_LABEL'))}
      {line(4, I18n.t('stepper.PAYMENT_LABEL'))}
    </ul>
  );
}

export default withRouter(connect(state => ({
  maxPage: state.storage.maxPage,
  offerCode: state.storage.offerCode,
}))(StepsHeader));
