// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import thunk from 'redux-thunk';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
// $FlowFixMe
import 'array-flat-polyfill';
// $FlowFixMe
import 'font-awesome-5/src/lib/css/all.min.css';

import App from './App';
// $FlowFixMe
import './sass/app.scss';
import fr from './i18n/locales/fr';
import en from './i18n/locales/en';
import reducers from './state/reducers';
import { LANGUAGE } from './services/languages';
import configurationApi from './network/api/configurationApi';
import { storeLoadedApikey } from './state/storage/storageService';
import { initializeSessionService } from './services/sessionService';
import { addScriptOnElementByTagName } from './services/trackUtils';

const store = createStore(
  reducers,
  applyMiddleware(thunk),
);
initializeSessionService(store);
configurationApi.fetchGoogleApiKey()
  .then((response) => response.text())
  .then((apiKey) => {
    const language = localStorage.getItem('language') || 'FR';
    addScriptOnElementByTagName(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${language}`,
      '',
      () => {
        store.dispatch(storeLoadedApikey(true));
      },
      true,
      [
        {
          attrName: 'async',
          attrVal: 'true',
        },
      ],
      'head',
    );
  });

syncTranslationWithStore(store);
store.dispatch(loadTranslations({
  fr,
  en,
}));
store.dispatch(setLocale(localStorage.getItem(LANGUAGE) || 'fr'));

/* eslint-disable react/jsx-filename-extension */
function wrapApp(RootComponent, reduxStore) {
  // Handle utm URL from mail clicks
  const utmCampaign = new URLSearchParams(window.location.search).get("utm_campaign");

  return (
    <Provider store={reduxStore}>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" render={(props) => <RootComponent {...props} utmCampaign={utmCampaign} />} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

// $FlowFixMe
ReactDOM.render(wrapApp(App, store), document.getElementById('root'));
