// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormFooterItemLeft from './FormFooterItemLeft';
import FormFooterItemRight from './FormFooterItemRight';

const FormFooter = () => (
  <div className="footer-form-container">
    <div className="footer-form">
      <FormFooterItemLeft />
      <div className="line" />
      <FormFooterItemRight />
    </div>
  </div>

);

export default withRouter(connect()(FormFooter));
