// @flow

import { RestClient } from '../network';
import type { TripInfos } from '../../types/storage';

const quoteApi = {
  postTripInfos: (formValues: TripInfos) => new RestClient('/quote', 'POST').jsonBody(formValues).execute(),
  getQuoteFile: (formValues: TripInfos) => new RestClient('/quote/file', 'POST').jsonBody(formValues).execute(),
  sendQuoteMail: (formValues: TripInfos) => new RestClient('/quote/mail', 'POST').jsonBody(formValues).execute(),
};

export default quoteApi;
