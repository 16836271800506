// @flow

import React from 'react';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import { logout } from '../../services/sessionService';
import { currentSessionService } from '../../services/sessionServiceInstance';
import {
  CONTACT_PAGE_ROUTE,
  CONTRACT_PAGE_ROUTE,
  FAQ_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  PROFIL_PAGE_ROUTE, STEP_PAGE_ROUTE, STEP_ROOT_PAGE_ROUTE,
} from '../../const';
import DashboardTile from '../../components/client/DashboardTile';
import Flag from '../../components/client/Flag';

type Props = {
  history: any,
};

class Dashboard extends React.PureComponent<Props> {
  render() {
    const currentUser = currentSessionService().currentUser();
    return (
      <div className="dashboard-container">
        <Flag />
        <div className="dashboard">
          <div className="title">
            <h1>
              <Translate
                value="spaceClient.dashboard.title"
                firstname={currentUser && currentUser.firstname}
              />
            </h1>
          </div>
          <div className="description">
            <Translate value="spaceClient.dashboard.description" />
          </div>
          <div className="dashboard-tile-contailer">
            <DashboardTile
              icon="fal fa-file-alt"
              titleCode="spaceClient.dashboard.contracts"
              redirectFunction={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_ESPACE_CLIENT('Contrats'));
                this.props.history.push(CONTRACT_PAGE_ROUTE);
              }}
              isActive={false}
            />
            <DashboardTile
              icon="fal fa-file-edit"
              titleCode="spaceClient.dashboard.devis"
              redirectFunction={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_ESPACE_CLIENT('Devis'));
                window.open(`${STEP_ROOT_PAGE_ROUTE}/1`);
              }}
              isActive={false}
            />
            <DashboardTile
              icon="fal fa-address-book"
              titleCode="spaceClient.dashboard.contacts"
              redirectFunction={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_ESPACE_CLIENT('Contact'));
                this.props.history.push(CONTACT_PAGE_ROUTE);
              }}
              isActive={false}
            />
            <DashboardTile
              icon="fal fa-file-alt"
              titleCode="spaceClient.dashboard.FAQ"
              redirectFunction={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_ESPACE_CLIENT('FAQ'));
                window.open(FAQ_PAGE_ROUTE);
              }}
              isActive={false}
            />
            <DashboardTile
              icon="fal fa-user"
              titleCode="spaceClient.dashboard.profil"
              redirectFunction={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_ESPACE_CLIENT('Profil'));
                this.props.history.push(PROFIL_PAGE_ROUTE);
              }}
              isActive={false}
            />
          </div>
          <div
            className="dashboard-btn"
            type="submit"
            onClick={() => {
              logout();
              dataLayer.showGTM(TAG_EVENT.CLIC_ESPACE_CLIENT('Deconnexion'));
              this.props.history.push(LOGIN_PAGE_ROUTE);
            }}
          >
            <Translate value="actions.LOGOUT" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
