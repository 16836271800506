const en = {
  actions: {
    BACK_LOGIN: 'Back to the sign-in page',
    CANCEL: 'Cancel',
    EDIT: 'Modify',
    SAVE: 'Save',
    CONNECTION: 'Sign in',
    LOGOUT: 'Sign out',
    SEND_MAIL: 'Send email',
    VALIDATE: 'Confirm',
  },
  LOG_API: {
    TITLE: 'Swing Tunnel - Log API',
    DOWNLOAD: 'Download',
    FILTER_STATUS: 'Filter by status',
    FILTER_NAME: 'Filter by name',
    FILTER_URL: 'Filter by request URL',
    FILTER_METHOD: 'Filter by request méthod',
    REQUEST: 'Request',
    RESPONSE: 'Response',
    BODY: 'Body',
    HEADER: 'Header',
    EMPTY_BODY: 'Empty body',
    EMPTY_HEADER: 'Empty header',
    NO_CONTENT: 'No content for this search'
  },
  sentry: {
    TITLE: 'Oops...',
    SUBTITLE: 'Something went wrong',
    LINK_DESCRIPTION: 'Please retry later and refresh your page, you can also report a bug',
    LINK_ACTION: 'here',
  },
  message: {
    YES: 'Yes',
    NO: 'No',
  },
  errors: {
    noQuote: {
      title: 'Sorry, none of our policies seem to suit your needs.',
      message: 'The sales team is here to help. You can call them ',
      message_comfort: 'For your convenience, contact the sales department ',
      messagePhone: 'by phone at ',
      messageEmail: 'by mail at ',
      messageOr: ' or ',
      buttonEdit: 'Change my details',
      buttonBack: 'Back to the website',
    },
    inactive: {
      title: 'This site is temporarily unavailable. Please try again in a few moments.',
      description: 'Thank you for your loyalty! The Mutuaide team.',
    },
    tick: 'Please tick this box to continue',
    noAdaptedQuote: {
      title: 'Sorry, you are not eligible for our %{offer} policy for the following reason: ',
      titleNoReason: 'Sorry, you are not eligible for our %{offer}',
      titleNoOffer: 'Sorry, none of our policies seem to suit your needs for the following reason: ',
      titleGeneric: 'The information provided does not fully correspond to the chosen offer.',
      dash: '- ',
      advice: 'We recommend the following policy',
      adviceQuestion: 'Click Subscribe if that\'s okay with you.',
      information: 'If you are happy with the policy, please click on Choose to continue.',
      text: {
        pro: 'Suitable for long or short business trips to anywhere in the world.',
        groupe: 'A policy for groups of 10 or more people, in France and abroad.',
        schengen: 'An offer that allows you to travel with confidence across Europe.',
        confort: 'A comprehensive travel insurance offer that lets you travel lightly.',
        zen: 'An à la carte travel assistance offer that allows you to travel with complete peace of mind.',
      },
    },
    redirectQuote: {
      lonelySub: 'Please take out an individual policy for each traveller.',
      groupeOffer: 'We also offer Group Travel Insurance if you would like to take out group cover.',
      or: 'OR',
    },
  },
  date: {
    DATE_START: 'Start date',
    DATE_END: 'End date',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
  header: {
    TITLE: 'Travel Insurance quote',
  },
  stepper: {
    TRIP_LABEL: 'Travel',
    DEVIS_LABEL: 'Quote',
    CUSTOMER_LABEL: 'Travellers',
    PAYMENT_LABEL: 'Payment',
  },
  travelInsuranceForm: {
    step1: {
      travel: 'Your trip',
      description: 'Tell us more about your trip, so that our Assistant can find the best cover for you.',
      descriptionOfferCode: 'Tell us more about your trip.',
      insuranceType: {
        title: 'You are looking for insurance for:',
        tourism: 'Holiday / group travel',
        tourismClassic: 'Holiday /',
        tourismGroup: 'Group travel',
        business: 'Business trip',
        studying: 'Study abroad',
      },
      numberOfBeneficiaries: {
        title: 'Number of insured persons:',
        numberOfAdults: 'Adults',
        numberOfKids: 'Children (2 to 17 years old)',
        numberOfBabies: 'Babies (under 2 years old)',
        free: 'Free',
      },
      countryOfResidence: 'Country of residence:',
      countryPlaceholder: 'Choose a country',
      destination: 'Destination country:',
      destinationWarning: 'You can check whether your destination is subject to any restrictions on ',
      destinationLink: 'this site ',
      destinationWarningLink: 'www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs',
      addDestination: 'Add a destination',
      travelDate: {
        title: 'Date of your trip:',
        placeholder: 'Choose your dates',
        apply: 'Apply',
        errorEnd: 'The return date cannot be before today’s date',
        errorYear: 'Your trip is limited to 365 days',
        warning: 'Are you on a trip and realize that you have not purchased travel insurance? Mutuaide gives you the opportunity! See your policy information sheet for more information on your coverage.',
      },
      buyingDate:{
        title: 'Booking date:',
        placeholder: {
          normal: 'Choose your ',
          underline: 'booking date',
        },
      },
      amount: 'Total cost of trip (%{benef}incl. VAT):',
      ttc: 'for %{nbrBeneficiaries} travellers ',
      expenses: {
        title: 'Choose your expenses:',
        placeholder: 'Choose your expenses',
        transport: 'Transport',
        lodging: 'Accommodation',
        transport_and_lodging: 'Transport and accommodation',
      },
      trick: {
        title: 'Important',
        description1: 'Enter the total cost your trip: accommodation, transport or both.',
        description2: 'Indicate the date on which you purchased the service to be covered in case of cancellation:',
        description3: 'If there are multiple services to be covered, indicate the purchase date of the first one you acquired.',
        description4: 'If you purchased a bundle of services from a travel agency, indicate its date of purchase.',
      },
      submit: 'Find my insurance policy',
      submitOfferCode: 'Continue',
      noOptions: 'No results',
      placeholder: {
        amount: 'Enter the amount',
      },
    },
    step2: {
      choseOffer: {
        titleImg: 'Find the best cover for you:',
        title: '1. First, choose your policy...',
        description1: 'Choose travel insurance that protects what is important to you during your trip. Customise your cover to tailor it to your needs.',
        totalAmount: '%{amount}',
        amountPerDay: '(%{amount}/day)',
        amountWithCurrencyRate: '(%{amount})',
        completeFormula: 'Full cover',
        select: 'Choose',
        selectOffer: 'Choose a policy ',
        edit: 'Modify my trip',
        selected: 'Chosen',
        selectedFormula: 'Chosen policy',
        optional: 'Option',
        completeOffer: ' Complete offer',
        customizeFormula: 'Customize my formula',
      },
      completeOffer: {
        title: '2. Great! Now you can customise your cover...',
        description: 'Supplement your policy to tailor it to your needs.',
        option: {
          shortDescription: ' (%{description})',
          sport: {
            modal: {
              warning: 'Please note!',
              close: 'Close',
            },
          },
          refuse: 'Refuse',
          accept: 'Accept',
          onlyEnableFor: 'Only enable with formula: ',
          onBasket: 'on your basket',
          includedInFormula: 'Included in the formula: ',
          notCumulativeOption: '*Not cumulative with:',
          included: 'Included',
          and: ' and ',
        },
      },
      warranty: {
        medicalWarranty: {
          zen: 'Medical costs covered anywhere in the world',
          confort: 'Medical costs covered anywhere in the world',
          schengen: 'Medical repatriation full covered',
          groupe: 'Medical repatriation and medical costs covered anywhere in the world',
          pro: 'Medical repatriation and medical costs covered anywhere in the world',
        },
        sportWarranty: {
          zen: 'A la carte cover and a comprehensive sports option',
          confort: 'A la carte cover and a comprehensive sports option',
          schengen: 'Repatriation of family members or accompanying travellers',
          groupe: 'Compensation for damaged, lost or stolen baggage',
          pro: 'Coverage of your baggage and professional equipment damaged, lost, stolen or late',
        },
        bankCardWarranty: {
          zen: 'Complementing the cover provided by your credit card',
          confort: 'Complementing the cover provided by your credit card',
          schengen: 'Legal assistance to travel serenely',
          groupe: 'Legal assistance to travel serenely',
          pro: 'Legal assistance to travel serenely',
        },
      },
      recap: {
        title: 'Summary',
        titleQuote: 'Your quote',
        beneficiaries: 'for %{nbrBeneficiaries} beneficiaries',
        specificWording: {
          part1: 'This quote was produced thanks to the information provided by you. You can consult the offer ',
          cg: 'policy wording',
          part2: ' and ',
          din: 'insurance product information document',
          part3: '. If it suits you, click Subscribe to continue.'
        },
        warranties: 'Cover',
        warrantiesOptional: 'Optional cover',
        options: 'Options',
        discount: 'Discount',
        discountAmount: '%{price} off: ',
        download: 'Download this quote',
        almostFinished: 'You are almost done',
        subscribeOnlineNow: 'You can now sign up for your policy online',
        price: ': %{price}',
        priceNegative: ': -\u00a0%{price}',
      },
      goBack: 'Back',
      goBackChangeInfo: 'Change my details',
      submit: 'Sign up',
    },
    step3: {
      title: 'Subscriber: Enter your details',
      gender: {
        title: 'Title:',
        male: 'Mr',
        female: 'Ms',
      },
      login: {
        title: 'Already customer? Login to your Customer Area to find your subscription informations',
        placeholderId: 'Email address',
        placeholderPassword: 'Password',
        titleId: 'Login:',
        titlePassword: 'Password:',
        titleLogin: 'I am logged in as ',
        titleLoginName: '%{surname} %{name}',
        loginButton: 'Login',
        showLogin: 'Login',
        showLogout: 'Logout',
        errorMessage: 'Wrong email address or password'
      },
      name: 'Surname:',
      surname: 'First name:',
      birthDate: 'Date of birth:',
      email: 'Email:',
      emailConfirm: 'Confirm your email:',
      info: {
        title: 'Please note',
        content: 'We will send the confirmation email to this address.',
        content2: 'Practical: if you used this email address during a previous subscription, then your travel information will be linked to the same Customer Area.',
      },
      phone: 'Phone number:',
      address: 'Address line 1:',
      postcode: 'Town/city:',
      city: 'Postcode:',
      country: 'Country:',
      add_address: 'Add address line',
      sub_address: 'Delete ddress line',
      is_beneficiary: 'The subscriber benefits from travel insurance',
      placeholder: {
        surname: 'First name',
        name: 'Surname',
        birthdate: 'DD/MM/YYYY',
        email: 'Email address',
        emailConfirm: 'Confirm your email address',
        address: 'Address line 1',
        postcode: 'Postcode',
        city: 'Town/city',
        country: 'Country',
      },
      beneficiaries: {
        title: 'Who are the insured persons?',
        subtitle: 'Insured person n°%{number}',
        same_address: 'Do you have the same address as the policyholder?',
        yes: 'Yes',
        no: 'No',
      },
      receive_doc: {
        title: 'I would like to receive:',
        group: 'A Group travel insurance certificate (one for all the insured persons)',
        alone: 'Individual travel insurance certificates (one for each insured person)',
      },
      accept_policy: {
        full: 'I confirm that I have read, understood and accepted the Policy Wording, the Insurance Product Information Document and the Data Protection Policy.',
      },
      accept_offers: 'I would like to receive news and offers from %{cdo} and its partners.',
      fieldsMandatory: 'In order to create your file, all the fields below are mandatory.',
      submit: 'Continue to checkout',
      back: 'Back',
    },
    step4: {
      title: 'You have finished the form!',
      description: 'Our quote and the supporting documents have been sent to your email address %{mail}',
      showMore: 'Show +',
      showLess: 'Show -',
      renonciation: 'You have the right to cancel within 14 days. You will find all the necessary cancellation information in the Policy Wording.',
      contract: 'Your pre-contractual and contractual relationship with MUTUAIDE will be recorded exclusively on a permanent medium other than paper. However, you have the right to oppose the use of this medium at any time and to ask that the relationship continue on paper.',
      recap: {
        title: 'Your travel insurance summary',
      },
      beneficiaryRecap: {
        title: 'Insured person %{number}',
        mr: 'Mr',
        mme: 'Ms',
      },
      payment: {
        title: 'Payment',
        submit: 'I want to be insured for %{amount}',
        submitGroupe: 'Validate and pay %{amount}',
        submitRefuse: '(Refused payment)',
        ORDER_REQUEST_ERROR: 'An error occurred during payment. Please try again.',
        ORDER_ALREADY_PAID: 'Your payment has already been accepted or is being processed for this subscription.',
        ORDER_ALREADY_CREATED: 'An error occured during your payment. You have not been charged. Please check your banking information and make a new quote.',
        ERROR_CLICK_HERE: 'Make a new quote',
        EXPIRED_CONTROL_TOKEN: 'Your quote has expired. Please regenerate a quote.',
        INVALID_CONTROL_TOKEN: 'We are experiencing a problem with your subscription. Please generate a new quote from step 1.',
        noName: 'You must enter the name of the card holder.',
        invalidCard: 'The credit card information is invalid.',
        refuse: 'Your payment has been declined. Please try again.',
      },
    },
    step5: {
      title: 'Thank you for choosing us',
      confirmation: 'We confirm that you have taken out a %{offerName} Travel Insurance policy.',
      emailConfirmation: {
        title: 'You will receive a confirmation email in a few minutes with:',
        documents: '- Your supporting documents: contract with the payment confirmation, Policy Wording and travel insurance certificate',
        links: '- A link to activate your Account, where you’ll find all your documents, the frequently asked questions, contract details and other features to make your trip run smoothly, wherever you are.',
        emailNotReceived: 'If you do not receive a confirmation email in the next few minutes, please email us at: ',
      },
      gabriel: {
        title: 'Gabriel, the assistance application that accompanies you wherever you go!',
        description: 'Download the app for free on your smartphone and benefit from all the services by entering the Mutuaide activation code when launching the app : ',
        information: 'You will shortly receive an e-mail with more information about the app!'
      },
      goodTravel: 'Have a great trip!',
      buttonBack: 'Back to the site',
    },
  },
  information: {
    title: 'My details',
    noData: 'No details have been entered',
    travel: 'Travel',
    update: 'Change',
    data: {
      insuranceType: 'Insurance for: ',
      adults: 'Adults: ',
      kids: 'Children (2 to 17 years old): ',
      babies: 'Babies (under 2 years old): ',
      dates: 'Dates: ',
      datesContract: 'Your contract will be effective from: ',
      midday: ' at 12 p.m.',
      residence: 'Country of residence: ',
      destinations: 'Destination(s): ',
      total: 'Amount of travel: ',
      expenses: 'This amount includes: ',
      buying: 'Booking date: ',
      beneficiary: 'Number of beneficiary: ',
    },
  },
  basket: {
    title: 'Basket',
    noData: 'No policy has been selected',
    total: 'Total',
    formulaPrice: '%{price}',
    optionPrice: '+ %{price}',
    optionNegativePrice: '-\u00a0%{price}',
    discountAmount: '-\u00a0%{amount}',
    discountCode: 'Do you have a discount code?',
    validateDiscount: 'OK',
    discount: '%{price} off',
    invalidDiscountCode: 'Invalid discount code',
  },
  validator: {
    minMax: 'The total amount for the trip must be between %{min} and %{max}',
    mandatory: 'Mandatory field',
    invalid: 'The name entered is invalid',
    numSize: 'This field must contain %{size} numbers',
    emailFormat: 'The email entered is invalid',
    emailConfirm: 'The email entered is different',
    phoneFormat: 'The phone number is incorrect',
    phoneFormatNoZero: 'The number must not have a 0 just after the country code',
    postcodeFormat: 'The postcode is invalid',
    postcodeLength: 'Postcode cannot have more than 10 characters',
    postcodeLengthFive: 'Postcode must have 5 numbers',
    dateFormat: 'You must enter the date in DD/MM/YYYY format',
    dateLimitMajor: 'Sorry, you are underage. Please ask an adult to take out the insurance for you.',
    dateLimit: 'The date must be before today',
    dateMini: 'The year of birth must be after %{min}',
    tick: 'You must tick this box to continue',
    verifyBenef: 'Please check the beneficiaries\' dates of birth, they do not match the information entered during the travel step.',
  },
  footerForm: {
    need_help: 'Need help?',
    button_question: 'Frequently asked questions',
    download_title: 'Download supporting documents',
    dowload_din: 'Insurance Product Information Document',
    download_CGUV: 'Policy Wording',
    popupTitle: 'You need to choose a policy to be able to access this document',
  },
  footer: {
    cookiesHandle: 'Cookies management',
  },
  faq: {
    title: 'Frequently asked questions',
    category: 'Categories',
    search: 'Search',
    placeholder: 'Your search',
    noFaq: 'This heading frequently asked questions is not available',
    noResult: 'No question corresponds to your search',
    contact: 'Can\'t find the answer to your question?',
    buttonContact: 'Contact us',
  },
  spaceClient: {
    description: {
      title: 'Welcome to your account',
      firstBlock: 'Find all the information and documentation relating to your cover',
      secondBlock: 'Browse the frequently asked questions',
      thirdBlock: 'Manage your profile',
      fourthBlock: 'Get another quote',
      fifthBlock: 'Contact us easily',
    },
    form: {
      connectionTitle: 'Sign in',
      username: 'Username',
      password: 'Password',
      forgottenLink: 'Forgotten your password?',
      forgottenTitle: 'Forgotten your password?',
      forgottenField: 'Account email address',
      forgottenMention: 'An email will be sent to this address so you can access your account.',
      forgottenConfirm: 'A recovery email has just been sent.',
      modifyTitle: 'Change my password',
      modifyPassword: 'New password',
      modifyPasswordConfirm: 'Password confirmation',
      fisrtConnectionTitle: 'Activation of your account',
      firstConnectionSubtitle: 'Please enter a password to finalize the activation of your account',
      fisrtConnectionPassword: 'New password',
      fisrtConnectionPasswordConfirm: 'Password confirmation',
      modifyConfirm: 'Your password has been changed',
      firstConnectionConfirm: 'Your account has been activated',
      reactivateAccountTitle: 'Reactivation of your account',
      reactivateAccountSubtitle: 'You have not been active in your account for a while. Please enter a new password to finalize the reactivation of your account',
      reactivateAccountConfirm: 'Your account has been reactivated',
    },
    dashboard: {
      title: 'Welcome %{firstname}',
      contracts: 'Contracts',
      devis: 'Quote',
      contacts: 'Contact us',
      FAQ: 'Frequently asked questions',
      profil: 'Profil',
      description: 'Access your documents in just a few clicks, from anywhere in the world!\n' +
        'You can also change your details and easily get another quote for your next trip on your account page. If you need any help, take a look at the frequently asked questions or contact us.',
    },
    profil: {
      title: 'Profil',
      sectionTitle: 'Personal details',
      name: 'Fullname',
      firstname: 'Firstname',
      lastname: 'Lastname',
      birthdate: 'Date of birth',
      phoneNumber: 'Phone number',
      address: 'Address',
      addressCompl: 'Address Addition',
      city: 'Town/city',
      country: 'Country',
      zipCode: 'Postcode',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Password confirmation',
      warning: {
        on_change: 'You will be able to complete your future forms more quickly and with your updated personal information.',
        current_contract: 'Attention: if you have a current contract filled with old personal information, please communicate the change(s) to ',
        thanks: ' Thanks!',
      },
    },
    contract: {
      title: 'Contrats',
      summup: 'Summary',
      basket: 'Basket',
      adult: 'Adults',
      teen: 'Children (2 to 17 years old)',
      child: 'Babies (under 2 years old)',
      catchPhrase: 'Taking another trip?',
      btnLabel: 'Get another quote',
      btnContract: 'Download contract',
      btnAttest: 'Download certificate%{number}',
      contractName: 'Votre Contrat.pdf',
      attestName: 'Votre Attestation Assurance Voyage.pdf',
      total: 'Total',
      currency: '€%{price}',
      table: {
        date: 'Dates: ',
        country: 'Country of residence: ',
        destinations: 'Destination(s): ',
        amount: 'Trip total: ',
        buyingDate: 'Booking date: ',
        including: 'This amount includes: ',
        promoCode: 'Promo code',
      },
    },
    contact: {
      title: 'Contact us',
      noContent: 'There is no content available on this page for the moment',
    },
  },
  formula: {
    insurance: {
      VOY_TRST_ZEN: 'Tourist Travel Insurance',
      VOY_TRST_CONFORT: 'Tourist Travel Insurance',
      VOY_GPR: 'Group Travel Insurance',
      VOY_SCHGN: 'Schengen Travel Insurance',
      VOY_PRO: 'Professional Travel Insurance',
    },
    names: {
      VOY_TRST_ZEN: 'Tourist Travel - ZEN Formula',
      VOY_TRST_CONFORT: 'Tourist Travel - COMFORT Formula',
      VOY_GPR: 'Group Travel Insurance',
      VOY_SCHGN: 'Schengen Travel Insurance',
      VOY_PRO: 'Professional Travel Insurance',
    },
    shortNames: {
      VOY_TRST_ZEN: 'ZEN',
      VOY_TRST_CONFORT: 'COMFORT',
      VOY_GPR: 'Group',
      VOY_SCHGN: 'Schengen',
      VOY_PRO: 'Professional',
    }
  },
  errorMessage: {
    DURATION_NEGATIVE: 'The duration of the trip is inconsistent',
    RESIDENCE_COUNTRY_IN_SCHENGEN: 'Your country of residence is located in the Schengen area',
    RESIDENCE_COUNTRY_NOT_COVERED: 'Your country of residence is not covered',
    COUNTRY_NOT_FOUND: 'The selected country cannot be found',
    COUNTRY_COUPLE_NOT_COVERED: 'Your country of residence and your destination are outside the Schengen area',
    NOT_TOURISM_TRAVEL: 'You are not taking a Tourist trip',
    RESIDENCE_COUNTRY_OUT_SCHENGEN: 'Your country of residence is not located in the Schengen area',
    DESTINATION_COUNTRY_OUT_SCHENGEN: 'One or more destination countries are not located in the Schengen area',
    TOO_MANY_PEOPLE: 'You are a group of more than 9 people',
    TOURISM_INDIVIDUAL_FAMILIAL_TRAVEL: 'You are making a Tourist, Individual or Family trip',
    TRAVELERS_COUNT_TOO_LOW: 'You are a group of less than 9 people',
    LESS_BENEFICIARY_NEEDED: 'Your trip has more than 50 beneficiaries?',
    MORE_BENEFICIARY_NEEDED: 'The number of participants is insufficient',
    NO_PRODUCT_AVAILABLE: 'No offer is available',
    NOT_AVAILABLE_QUOTATION: 'No quote is available',
    CANT_TRAVEL_IN_THE_PAST_YET: 'Your trip must be in progress',
    MAXIMUM_DURATION_EXCEEDED: 'Your trip is too long',
    BENEFICIARY_QUANTITY_DOESNT_MATCH: 'The number of beneficiaries is inconsistent',
    ONE_ADULT_MINIMUM: 'There must be at least one adult during this trip',
    CANT_BE_BORN_IN_THE_FUTURE: 'Date of birth is inconsistent',
    TRAVEL_IN_MORE_THAN_TWO_YEARS: 'Your trip starts too late',
    TRAVEL_TOO_EARLY: 'Your journey has started for too long',
    NO_TRAVELERS_INFORMATION_FOUND: 'You have incorrectly entered the passenger information',
    CAN_T_BE_SELECTED_IN_THE_PAST: 'You are already traveling',
    GROUP_OFFER_MAY_NOT_BE_AVAILABLE: 'You are a group of more than 9 people',
    PRO_OFFER_MAY_NOT_BE_AVAILABLE: 'You are on a business trip',
    NO_RESULT_FOUND: 'No result',
    UNKNOWN_ERROR: 'Unknown error',
  },
  wsError: {
    WRONG_LOGIN_OR_PASSWORD: 'Wrong username or password',
    ALREADY_EXPIRED_SESSION_TOKEN: 'The period of validity of the link has expired. Click on forgot password.',
    SESSION_EXPIRED: 'The session has expired, you have to sign-in again',
    MISMATCHING_USER: 'The modification does not concern the connected user, please connect with the right account',
    PASSWORDS_DIFFERENT: 'The entered passwords do not match',
    FIELD_REQUIRED: 'Field Required',
    NOT_INITIALIZED: 'You must activate your account before sign in',
    PASSWORD_TOO_SHORT: 'The password must have 8 characters at least',
    PASSWORD_NOT_VALID: 'The password must contains at least one special character, one uppercase letter and one number',
    INNACTIVE_ACCOUNT: 'The account has been disabled',
  },
};

export default en;
