// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n, setLocale } from 'react-redux-i18n';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import { LANGUAGE, languagesAvailable } from '../../services/languages';
import type { Site } from '../../types/storage';

const changeLanguage = (language, dispatch) => {
  dispatch(setLocale(language.code));
  localStorage.setItem(LANGUAGE, language.code);
};

const initLanguage = (i18nLanguage) => {
  if (!localStorage.getItem(LANGUAGE)) {
    localStorage.setItem(LANGUAGE, i18nLanguage);
  }

  return languagesAvailable.filter(infos => infos.code === localStorage.getItem(LANGUAGE))[0];
};

type Props = {
  dispatch: Function,
  locale: string,
  site: Site
};

const StepsHeader = (props: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const [flagInfos, setFlagInfos] = useState(initLanguage(props.locale));
  (flagInfos: {
    code: string,
    imgPath: string,
  });

  return (
    <div className="header-container">
      <div
        className="logo"
        onClick={() => window.open(props.site.logoUrl, '_blank')}
      />
      <div className="fixed-header">
        <div className="title">
          {I18n.t('header.TITLE')}
        </div>
        <div className="flag">
          <img
            onClick={() => {
              dataLayer.showGTM(TAG_EVENT.CLIC_FLAG);
              setOpen(!open);
            }}
            src={flagInfos.imgPath}
            alt=""
          />
          {open &&
          <div className="form-control">
            {
              languagesAvailable.map(language => (
                <div
                  key={language.code}
                  onClick={() => {
                    changeLanguage(language, props.dispatch);
                    setOpen(!open);
                    setFlagInfos(language);
                  }}
                >
                  <img
                    src={language.imgPath}
                    alt={language.code}
                  />
                </div>
              ))
            }
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(state => ({
  locale: state.i18n.locale,
  site: state.storage.site,
}))(StepsHeader));
