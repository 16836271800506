// @flow

import { RestClient, RestClientAuthenticated } from '../network';
import type { UserModification } from '../../types/loginTypes';

const userApi = {
  get: () => new RestClientAuthenticated('/user', 'GET').execute(),
  fetchContract: () => new RestClientAuthenticated('/user/contracts', 'GET').execute(),
  update: (user: UserModification) => new RestClientAuthenticated('/user', 'POST').jsonBody(user).execute(),
  updatePassword: (user: UserModification, token: string) => new RestClientAuthenticated('/user/password', 'POST').headers({ Authorization: `Bearer ${token}` }).jsonBody(user).execute(),
  modifyPassword: (credentials: { username: string }) => new RestClient('/user/modify-password', 'POST').jsonBody(credentials).execute(),
  updateUserLastConnectionDate: (credentials: { username: string }) => new RestClient('/user/last-connection', 'POST').jsonBody(credentials).execute(),
};

export default userApi;
