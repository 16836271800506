// @flow

import { RestClient } from '../network';

const siteApi = {
  fetchSite: () => new RestClient('/site', 'GET').execute(),
  fetchFaq: (lang: string, query: string) => new RestClient('/site/faq', 'GET')
    .addParam('lang', lang)
    .addParam('query', query)
    .execute(),
};

export default siteApi;
