// @flow

import React from 'react';
import type { Coverage } from '../../../types/contractTypes';

type Props = {
  warranties: Coverage[],
  title: string,
}

export default class Warranty extends React.PureComponent<Props> {
  render() {
    const { warranties, title } = this.props;
    return (
      <div className="table warranty-client">
        <div className="table-header">
          <div>
            <i className="fal fa-star" />
            {title}
          </div>
        </div>
        <div className="table-body">
          {
            warranties.sort((a, b) => (a.order - b.order)).map((warranty) => (
              <div className="table-row" key={warranty.warrantyFormulaId}>
                <div className="name">{warranty.name}</div>
                <div className="long-description">{warranty.longDescription}</div>
                <div className="short-description">{warranty.shortDescription}</div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
