// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { CoreozInputBase } from 'coreoz-form-base';
import { Field } from 'react-final-form';
import { hasValue } from '../../services/validator';
import { TRAVEL_TYPES } from '../../const';
import type { Site } from '../../types/storage';

type Props = {
  form: Object,
  values: Object,
  onClick: () => void,
  site: Site,
  mobileDisplay: boolean,
};

function TravelType(props: Props) {
  const { form, values, onClick, site, mobileDisplay } = props;

  return (
    <Field
      name="travelType"
      component={CoreozInputBase}
      validate={hasValue(values.travelType)}
    >
      {() => (
        <div className="insurance-type-container" onClick={onClick} role="button" tabIndex="0"
             onKeyDown={() => {
             }}>
          {site && (
              <>
                {
                  site.isTourismTravelTypeActive
                  && (
                    <div
                      role="presentation"
                      className={values.travelType === TRAVEL_TYPES.VACATION ? 'highlighted-travel-type travel-type' : 'travel-type'}
                      onClick={() => {
                        form.change('travelType', TRAVEL_TYPES.VACATION);
                      }}
                    >
                      <i className="icon-travel insurance-type-icon" />
                      <h5 className={mobileDisplay ? 'one-line' : ''}>
                        <div>{I18n.t('travelInsuranceForm.step1.insuranceType.tourismClassic')}</div>
                        <div className="tourism-second-part">
                          {I18n.t('travelInsuranceForm.step1.insuranceType.tourismGroup')}
                        </div>
                      </h5>
                    </div>
                )}
                {
                  site.isBusinessTravelTypeActive
                  && (
                    <div
                      role="presentation"
                      className={values.travelType === TRAVEL_TYPES.BUSINESS_TRIP ? 'highlighted-travel-type travel-type' : 'travel-type'}
                      onClick={() => {
                        form.change('travelType', TRAVEL_TYPES.BUSINESS_TRIP);
                      }}
                    >
                      <i className="icon-business-luggage insurance-type-icon" />
                      <h5>
                        {I18n.t('travelInsuranceForm.step1.insuranceType.business')}
                      </h5>
                    </div>
                )}
                {
                  site.isStudentTravelTypeActive
                  && (
                    <div
                      role="presentation"
                      className={values.travelType === TRAVEL_TYPES.STUDENT_STAY ? 'highlighted-travel-type travel-type' : 'travel-type'}
                      onClick={() => {
                        form.change('travelType', TRAVEL_TYPES.STUDENT_STAY);
                      }}
                    >
                      <i className="icon-student-hat insurance-type-icon" />
                      <h5>
                        {I18n.t('travelInsuranceForm.step1.insuranceType.studying')}
                      </h5>
                    </div>
                  )}
              </>
          )}
        </div>
      )}
    </Field>
  );
}

export default TravelType;
