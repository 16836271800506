// @flow

export const baseApiUrl = '/api';

// Routes

export const STEP_ROOT_PAGE_ROUTE = '/etape';
export const STEP_PAGE_ROUTE = '/etape/:number';
export const FAQ_ROUTE = '/faq';
export const ERROR_ROUTE = '/error';
export const INACTIVE_ROUTE = '/inactive';
export const LOG_ROUTE = '/logs';

export const CLIENT_PAGE_ROUTE = '/espace-client';
export const LOGIN_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/connexion`;

export const DASHBOARD_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/dashboard`;
export const CONTRACT_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/espace-client/contrats`;
export const FAQ_PAGE_ROUTE = '/faq';
export const PROFIL_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/profil`;
export const CONTACT_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/contact`;

export const FORGOTTEN_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/oubli-mdp`;
export const FORGOTTEN_CONFIRMATION_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/oubli-confirmation`;
export const MODIFY_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/modifier-mdp/:token`;
export const MODIFY_CONFIRMATION_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/modifier-confirmation`;
export const FIRST_CONNECTION_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/premiere-connexion/:token`;
export const REACTIVATE_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/reactivate/:token`;
export const FIRST_CONNECTION_CONFIRMATION_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/premiere-connexion-confirmation`;
export const REACTIVATE_CONNECTION_PAGE_ROUTE = `${CLIENT_PAGE_ROUTE}/reactivate-confirmation`;

export const LINK_TYPE = {
  ACTIVE: 'ACTIVE',
  FIRST_CONNECTION: 'FIRST_CONNECTION',
  MODIFY_PASSWORD: 'MODIFY_PASSWORD',
};

export const ITEM_TYPES = {
  FONT_URL: 'FONT_URL',
};

export const URL_TUNNEL = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  error: 'error',
};

export const TRAVEL_TYPES = {
  VACATION: 'tourism',
  BUSINESS_TRIP: 'business',
  STUDENT_STAY: 'studying',
};

export const ENVIRONMENT = {
  dev: 'Développement',
  int: 'Intégration',
  preprod: 'Pré-production',
  prod: 'Production',
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const WORD = {
  YES: 'yes',
  NO: 'no',
};

export const ERROR_REASON = {
  noQuote: 'no_quote',
};

export const TRAVELER_TYPE = {
  USER: 'USER',
  BENEFICIARY: 'BENEFICIARY',
};

export const PAYMENT_TYPE = {
  CB: 'CB',
  APPLE: 'APPLE PAY',
  GOOGLE: 'GOOGLE PAY',
};

export const TRIP_AMOUNT = {
  MIN: 1,
  MAX: 1000000,
};

export const GROUP = {
  MIN: 1,
  MAX: 10,
};

export const DATE_FORMAT = {
  FORMAT: 'DD/MM/YYYY',
  LOCAL_FORMAT: 'YYYY-MM-DD',
};

export const MODAL_TYPES = {
  INFORMATION: 'INFORMATION',
  VALIDATION: 'VALIDATION',
};

export const MOBILE_DIMENSIONS = {
  MAX_WIDTH: 1025,
};

export const MUTUAIDE_URL = 'www.mutuaide.fr';
