// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import type { Quote } from '../../components/steps/types/steps';
import type { Site, TripInfos } from '../../types/storage';
import { ERROR_CODES } from '../../services/errorMessage';

type Props = {
  offerCode: string,
  dispatch: Function,
  quote: Quote,
  history: RouterHistory,
  tripInfos: TripInfos,
  wantShowErrorReason: boolean,
  wantShowContact: boolean,
  site: Site,
};

const ErrorMessageDisplay = (props: Props) => {

  const { offerCode, quote, wantShowErrorReason, wantShowContact, site } = props;

  const errorReason = quote && quote.error && quote.error.reason;
  const showErrorReason = errorReason && quote.error.reason !== ERROR_CODES.NO_RESULT_FOUND;

  const getProperTextHeader = () => {
    if (showErrorReason && wantShowErrorReason) {
      if (quote.error.reason === ERROR_CODES.LESS_BENEFICIARY_NEEDED) {
        return I18n.t(`errorMessage.${quote.error.reason}`);
      } else if (offerCode) {
        return I18n.t('errors.noAdaptedQuote.title', { offer: I18n.t(`formula.names.${offerCode}`) });
      } else {
        return I18n.t('errors.noAdaptedQuote.titleNoOffer');
      }
    } else {
      if (offerCode) {
        if ((quote.offers.length > 0 && quote.offers[0].productCode === offerCode) || offerCode === errorReason) {
          return I18n.t('errors.noAdaptedQuote.titleGeneric');
        }
        return I18n.t('errors.noAdaptedQuote.titleNoReason', { offer: I18n.t(`formula.names.${offerCode}`) });
      } else {
        return I18n.t('errors.noQuote.title');
      }
    }
  };

  return (
    <div className="block-and-line">
      <div className="line" />
      <div className="block-info-container">
        <div className="text">
          <h2>{getProperTextHeader()}</h2>
        </div>
        {
          showErrorReason
          && wantShowErrorReason
          && quote.error.reason !== ERROR_CODES.LESS_BENEFICIARY_NEEDED
          && (
            <div className="block-info">
              <div className="line" />
              <div className="text">
                <h5>{I18n.t('errors.noAdaptedQuote.dash') + I18n.t(`errorMessage.${quote.error.reason}`)}</h5>
              </div>
            </div>
          )
        }
        {
          wantShowContact
          && (
            <div className="block-info">
              {
                (site.hotlineNumber || site.hotlineMail)
                && (
                  <div className="text">
                    <h6>
                      {quote && quote.error && (
                        I18n.t(`errors.noQuote.message${quote.error.reason === ERROR_CODES.LESS_BENEFICIARY_NEEDED ? '_comfort' : ''}`)
                      )}
                      {
                        site.hotlineNumber
                        && (
                          <span>
                        {I18n.t('errors.noQuote.messagePhone')}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`tel:${site.hotlineNumber}`}
                            >{site.hotlineNumber}
                        </a>
                      </span>
                        )}
                      {
                        site.hotlineNumber
                        && site.hotlineMail
                        && (
                          <span>
                          {I18n.t('errors.noQuote.messageOr')}
                        </span>
                        )
                      }
                      {
                        site.hotlineMail
                        && (
                          <span>
                        {I18n.t('errors.noQuote.messageEmail')}
                            <a
                              className="mail"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`mailto:${site.hotlineMail}`}
                            >{site.hotlineMail}
                        </a>
                      </span>
                        )
                      }
                      {
                        site.hotlineText
                        && (
                          <div>
                            {site.hotlineText}
                          </div>
                        )
                      }
                    </h6>
                  </div>
                )}
            </div>
          )}
      </div>
    </div>
  );
};

export default withRouter(connect((state) => ({
  offerCode: state.storage.offerCode,
  quote: state.storage.quote,
  tripInfos: state.storage.tripInfos,
  site: state.storage.site,
}))(ErrorMessageDisplay));
