// @flow

import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import ErrorMessageDisplay from './ErrorMessageDisplay';
import { dataLayer } from '../../services/dataLayer';
import { connect } from 'react-redux';
import type { Site } from '../../types/storage';

type Props = {
  site: Site
};

const StepErrorPage = (props: Props) => {
  useEffect(() => {
    dataLayer.updateFilAriane(-1);
  }, []);

  const handleGoBackClick = () => {
    if (props.offerCode) {
      props.history.push(STEP_ROOT_PAGE_ROUTE + '/1?offerCode=' + props.offerCode);
    } else {
      props.history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  };

  return (
    <div className="step-error-page">
      <div className="step-error-page-content">
        <ErrorMessageDisplay wantShowErrorReason={false} wantShowContact={true} />
        <div className="button-block">
          <button className="button-back-step" onClick={handleGoBackClick}>
            <i className="far fa-edit" />
            <h3>{I18n.t('errors.noQuote.buttonEdit')}</h3>
          </button>
          <button className="button-back" onClick={() => window.open(props.site.logoUrl, '_blank')}>
            <h3>{I18n.t('errors.noQuote.buttonBack')}</h3>
          </button>
        </div>
      </div>
    </div>
  )
};

export default withRouter(connect(state => ({
  site: state.storage.site,
}))(StepErrorPage));
