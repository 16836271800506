// @flow

import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { ERROR_REASON, ERROR_ROUTE, STEP_ROOT_PAGE_ROUTE } from '../../const';
import { connect } from 'react-redux';
import {
  clearQuoteInfos,
  storeFormulaBoth,
  storeOfferCode,
  storeQuote, storeRedirect
} from '../../state/storage/storageService';
import { FORMULA_CODES, FORMULA_NAMES, I18N_LABEL, IMG_URL } from '../../services/coverages';
import type { Quote } from '../../components/steps/types/steps';
import FormFooter from '../../components/steps/footer/FormFooter';
import type { TripInfos } from '../../types/storage';
import quoteApi from '../../network/api/quoteApi';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import Warranty from '../steps/step2/Warranty';
import ErrorMessageDisplay from './ErrorMessageDisplay';

type Props = {
  offerCode: string,
  dispatch: Function,
  quote: Quote,
  history: RouterHistory,
  tripInfos: TripInfos,
};

const StepWrongOffer = (props: Props) => {

  const { offerCode, dispatch, quote, history, tripInfos } = props;
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    dataLayer.show(TAG_EVENT.REDIRECTION_CHOICE + offerCode);
    dataLayer.updateFilAriane(-1);
    dispatch(storeRedirect());
    if (quote.offers && quote.offers[0] && quote.offers[0].code) {
      dataLayer.updateOffreDebranchement(I18n.t(`formula.insurance.${quote.offers[0].code}`));
    }
    setLength(quote.offers.length);
  }, []);

  const clickFormula = (chosenFormula) => {
    if (chosenFormula.code === FORMULA_CODES.CONFORT || chosenFormula.code === FORMULA_CODES.ZEN) {
      dataLayer.show(TAG_EVENT.REDIRECTION_CHOICE_ZEN_CONFORT);
    }
    let formulaCode = FORMULA_NAMES[chosenFormula.code];
    if (formulaCode) {
      const valuesToSubmit = {
        ...tripInfos,
        offerCode: chosenFormula.code
      };
      quoteApi.postTripInfos(valuesToSubmit)
        .then((data) => data.json())
        .then((data) => {
          dispatch(clearQuoteInfos());
          dispatch(storeFormulaBoth(chosenFormula, [], []));
          dispatch(storeQuote(data));
          dispatch(storeOfferCode(chosenFormula.code))
        })
        .catch((responseError) => {
          responseError.then(() => {
            history.push(`${STEP_ROOT_PAGE_ROUTE}${ERROR_ROUTE}?reason=${ERROR_REASON.noQuote}`);
          });
        });
    }
  };

  const handleGoBackClick = () => {
    if (props.offerCode) {
      history.push(STEP_ROOT_PAGE_ROUTE + '/1?offerCode=' + props.offerCode);
    } else {
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  };

  const displayFormulas = () => Array(length || 1)
    .fill(null)
    .map((e, i) => (
      <div className="formula-item">
        <div className={`formulas ${quote.offers[i].code === FORMULA_CODES.CONFORT ? 'confort' : ''}`}>
          <div className="complete-formula">
            <i className="fas fa-star" />
            <h4>{I18n.t('travelInsuranceForm.step2.choseOffer.completeFormula')}</h4>
          </div>
          <div className="formulas-content">
            <div className="header-formula-container">
              <h2 className="title-header">{quote.offers[i] && I18n.t(`formula.names.${quote.offers[i].code}`)}</h2>
            </div>
            <div className="img-content">
              <img className="img-background" src={IMG_URL[quote.offers[i].code]} />
              <h6 className="price">{I18n.t(`errors.noAdaptedQuote.text.${I18N_LABEL[quote.offers[i].code]}`)}</h6>
              <button
                onClick={() => clickFormula(quote.offers[i])}>{I18n.t('travelInsuranceForm.step2.choseOffer.select').toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    ));

  const displayNextButton = () => {
    if (length === 1) {
      return (
        <div className="submit">
          <button type="button" onClick={() => clickFormula(quote.offers[0])}>
            <h3>{I18n.t('travelInsuranceForm.step2.choseOffer.selectOffer') + I18n.t(`formula.names.${quote.offers[0].code}`)}</h3>
            <i className="far fa-long-arrow-right" />
          </button>
        </div>
      )
    }
  };

  return (
    <div className="step-error-page">
      <div className="main-content">
        <div className="step-error-page-content">
          <ErrorMessageDisplay wantShowErrorReason={true} wantShowContact={false} />
        </div>
        <div className="formula-tip">
          <div className="formula-tip-container">
            <h1>{I18n.t('errors.noAdaptedQuote.advice')}</h1>
            <div className="line-horizon" />
            <div className="formula-item-container">
              {displayFormulas()}
            </div>
            <h3>
              {I18n.t('errors.noAdaptedQuote.information')}
            </h3>
          </div>
        </div>
        <div className={length === 1 ? 'buttons-footer' : 'bottom-button'}>
          {displayNextButton()}
          <div className="back white">
            <button type="button" onClick={handleGoBackClick}>
              <i className="far fa-long-arrow-left" />
              <h3>{I18n.t('travelInsuranceForm.step2.goBackChangeInfo')}</h3>
            </button>
          </div>
        </div>
      </div>
      <Warranty />
      <FormFooter />
    </div>
  );
};

export default withRouter(connect((state) => ({
  offerCode: state.storage.offerCode,
  quote: state.storage.quote,
  tripInfos: state.storage.tripInfos,
}))(StepWrongOffer));
