// @flow

import moment from 'moment';
import type { RouterHistory } from 'react-router-dom';
import { store } from '../../network/reduce';
import {
  actionMap,
  COUNTRIES,
  DISCOUNT,
  EXPIRES_ON,
  FINAL_PRICE,
  FORMULA,
  initTripInfos,
  LOADED_API_KEY,
  MAX_PAGE,
  OFFER_CODE,
  OPTIONAL_COVERAGES,
  OPTIONS,
  PAYMENT_ID,
  QUOTE,
  REDIRECT,
  SITE,
  START_CONTRACT,
  TOTAL_PRICE,
  TRAVELER_INFOS,
  TRIP_INFOS,
  VALID_TRAVELER_INFOS,
  VALID_TRIP_INFOS,
} from './storageReducer';
import type { Site, TravelerInfos, TripInfos } from '../../types/storage';
import type {
  Coverage,
  Formula,
  OptionalCoverage,
  Quote,
} from '../../components/steps/types/steps';
import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import { dataLayer } from '../../services/dataLayer';

function dispatchStorage(dispatch: Function, key: string) {
  const storageValueString = localStorage.getItem(key);
  let storageValue = null;
  try {
    if (storageValueString) {
      storageValue = JSON.parse(storageValueString);
    }
  } catch (e) {
    storageValue = storageValueString;
  }

  if (storageValue || storageValue === 0) {
    dispatch(store(key, storageValue));
  }
}

export function resetStoreWithStorageValues(dispatch: Function) {
  Object.keys(actionMap).forEach((key: string) => dispatchStorage(dispatch, key));
}

export function checkValidityStorage(history: RouterHistory, dispatch: Function) {
  const expiresOn = localStorage.getItem(EXPIRES_ON);
  if (expiresOn) {
    if (moment().isAfter(moment(expiresOn))) {
      localStorage.clear();
      dispatch(store(TRIP_INFOS, initTripInfos));
      dispatch(store(MAX_PAGE, 1));
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  }
}

function setStorageItem(key: string, value: string) {
  localStorage.setItem(key, value);
  localStorage.setItem(EXPIRES_ON, moment().add(1, 'd').toISOString());
}

export function storeTripInfos(tripInfos: TripInfos, saveInLocalStorage: boolean) {
  return (dispatch: Function) => {
    dataLayer.updateTripInformations(tripInfos);
    if (saveInLocalStorage) {
      setStorageItem(TRIP_INFOS, JSON.stringify(tripInfos));
    }
    dispatch(store(TRIP_INFOS, tripInfos));
  };
}

export function storeValidTripInfos(formValid: boolean) {
  return (dispatch: Function) => {
    dispatch(store(VALID_TRIP_INFOS, formValid));
  };
}

export function storeCountries(countries: []) {
  return (dispatch: Function) => {
    setStorageItem(COUNTRIES, JSON.stringify(countries));
    dispatch(store(COUNTRIES, countries));
  };
}

export function storeTravelerInfos(travelerInfos: TravelerInfos) {
  return (dispatch: Function) => {
    dataLayer.updateTravelerInformations(travelerInfos);
    setStorageItem(TRAVELER_INFOS, JSON.stringify(travelerInfos));
    dispatch(store(TRAVELER_INFOS, travelerInfos));
  };
}

export function storeTravelerInfosInStorage(travelerInfos: TravelerInfos) {
  return (dispatch: Function) => {
    dispatch(store(TRAVELER_INFOS, travelerInfos));
  };
}

export function storeValidTravelerInfos(formValid: boolean) {
  return (dispatch: Function) => {
    dispatch(store(VALID_TRAVELER_INFOS, formValid));
  };
}

export function storeStartContract(startContract: string, saveInLocalStorage: boolean) {
  return (dispatch: Function) => {
    if (saveInLocalStorage) {
      setStorageItem(START_CONTRACT, JSON.stringify(startContract));
    }
    dispatch(store(START_CONTRACT, startContract));
  };
}

export function storeQuote(quote: Quote) {
  return (dispatch: Function) => {
    dataLayer.updateQuoteInformations(quote);
    setStorageItem(QUOTE, JSON.stringify(quote));
    dispatch(store(QUOTE, quote));
  };
}

export function storeFormulaInStorage(formula: Formula, selectedOptions: Array<Coverage>, selectedOptionalCoverages: Array<OptionalCoverage>) {
  return () => {
    dataLayer.updateSelectedOptions(formula, selectedOptions, selectedOptionalCoverages);
    setStorageItem(FORMULA, JSON.stringify(formula));
    setStorageItem(OPTIONAL_COVERAGES, JSON.stringify(selectedOptionalCoverages));
    setStorageItem(OPTIONS, JSON.stringify(selectedOptions));
  };
}

export function storeFormula(formula: Formula, selectedOptions: Array<Coverage>, selectedOptionalCoverages: Array<OptionalCoverage>) {
  return (dispatch: Function) => {
    dataLayer.updateSelectedOptions(formula, selectedOptions, selectedOptionalCoverages);
    dispatch(store(FORMULA, formula));
    dispatch(store(OPTIONAL_COVERAGES, selectedOptionalCoverages));
    dispatch(store(OPTIONS, selectedOptions));
  };
}

export function storeFormulaBoth(formula: Formula, selectedOptions: Array<Coverage>, selectedOptionalCoverages: Array<OptionalCoverage>) {
  return (dispatch: Function) => {
    dataLayer.updateSelectedOptions(formula, selectedOptions, selectedOptionalCoverages);
    setStorageItem(FORMULA, JSON.stringify(formula));
    setStorageItem(OPTIONAL_COVERAGES, JSON.stringify(selectedOptionalCoverages));
    setStorageItem(OPTIONS, JSON.stringify(selectedOptions));
    dispatch(store(FORMULA, formula));
    dispatch(store(OPTIONAL_COVERAGES, selectedOptionalCoverages));
    dispatch(store(OPTIONS, selectedOptions));
  };
}

export function clearTripInfos() {
  return (dispatch: Function) => {
    localStorage.removeItem(TRIP_INFOS);
    dispatch(store(TRIP_INFOS, initTripInfos));
  }
}

export function clearTravelerInfos() {
  return (dispatch: Function) => {
    localStorage.removeItem(TRAVELER_INFOS);
    dispatch(store(TRAVELER_INFOS, undefined));
  };
}

export function clearQuoteInfos() {
  return (dispatch: Function) => {
    localStorage.removeItem(FORMULA);
    localStorage.removeItem(OPTIONAL_COVERAGES);
    localStorage.removeItem(OPTIONS);
    dispatch(store(FORMULA, undefined));
    dispatch(store(OPTIONAL_COVERAGES, []));
    dispatch(store(OPTIONS, []));
  };
}

export function clearQuote() {
  return (dispatch: Function) => {
    localStorage.removeItem(QUOTE);
    dispatch(store(QUOTE, undefined));
  };
}

export function storeTotalPrice(total: number) {
  return (dispatch: Function) => {
    setStorageItem(TOTAL_PRICE, String(total));
    dispatch(store(TOTAL_PRICE, total));
  };
}

export function storeFinalPrice(price: number) {
  // On arrondi le prix au centime supérieur pour correspondre au calcul du Quote dans le back
  const finalPrice = parseFloat(Math.round(price * 100) / 100).toFixed(2);
  return (dispatch: Function) => {
    dataLayer.updateTotalPrice(Number(finalPrice));
    setStorageItem(FINAL_PRICE, String(finalPrice));
    dispatch(store(FINAL_PRICE, finalPrice));
  };
}

export function storeMaxPage(maxPage: number) {
  return (dispatch: Function) => {
    setStorageItem(MAX_PAGE, String(maxPage));
    dispatch(store(MAX_PAGE, maxPage));
  };
}

export function storeRedirect() {
  return (dispatch: Function) => {
    setStorageItem(REDIRECT, 'true');
    dispatch(store(REDIRECT, 'true'));
  };
}

export function storeOfferCode(offerCode: string) {
  return (dispatch: Function) => {
    dataLayer.updateDefaultOfferCode(offerCode);
    if (offerCode) {
      setStorageItem(OFFER_CODE, offerCode);
      dispatch(store(OFFER_CODE, offerCode));
    } else {
      localStorage.removeItem(OFFER_CODE);
      dispatch(store(OFFER_CODE, undefined));
    }
  };
}

export function authorizeNavigation(step: string) {
  if (!localStorage.getItem(MAX_PAGE)) {
    localStorage.setItem(MAX_PAGE, '1')
  }
  return step === 'error' || parseInt(localStorage.getItem(MAX_PAGE), 10) >= parseInt(step, 10);
}

export function storeDiscount(discount: number, saveInLocalStorage: boolean) {
  return (dispatch: Function) => {
    if (saveInLocalStorage) {
      setStorageItem(DISCOUNT, discount.toString());
    }
    dispatch(store(DISCOUNT, discount));
  };
}

export function storeLoadedApikey(loaded: boolean) {
  return (dispatch: Function) => {
    dispatch(store(LOADED_API_KEY, loaded));
  };
}

export function storeSite(site: Site) {
  return (dispatch: Function) => {
    dispatch(store(SITE, site));
  };
}

export function storePaymentId(identifier: string) {
  return (dispatch: Function) => {
    dispatch(store(PAYMENT_ID, identifier));
    setStorageItem(PAYMENT_ID, identifier);
  };
}
