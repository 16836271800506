import { useEffect, useState } from 'react';

export const isMobileDimensions = (step: number) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const maxWidthMobile = getComputedStyle(document.documentElement).getPropertyValue((step === 2) ? '--laptop-min-width' : '--tablet-max-width');
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowWidth <= maxWidthMobile;
};
