// @flow

import { reduce } from '../../network/reduce';

export const IS_QUOTE_LOADING = 'IS_QUOTE_LOADING';
export const IS_QUOTE_FILE_LOADING = 'IS_QUOTE_FILE_LOADING';
export const IS_PROMO_LOADING = 'IS_PROMO_LOADING';

type submissionStateType = {
    isQuoteLoading: boolean,
    isQuoteFileLoading: boolean,
    isPromoLoading: boolean,
};

const initialState: submissionStateType = {
    isQuoteLoading: false,
    isQuoteFileLoading: false,
    isPromoLoading: false,
};

const submissionReducer = reduce(initialState, {
    [IS_QUOTE_LOADING]: 'isQuoteLoading',
    [IS_QUOTE_FILE_LOADING]: 'isQuoteFileLoading',
    [IS_PROMO_LOADING]: 'isPromoLoading',
});

export default submissionReducer;
