// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { FAQ_PAGE_ROUTE } from '../../../const';
import type { Site } from '../../../types/storage';
import type { Formula } from '../types/steps';
import { gtagService } from '../../../services/gtagService';

type Props = {
  site: Site,
  formula: Formula,
};

const FormFooterItemLeft = (props: Props) => {
  const { formula, site } = props;

  const sendEvent = () => {
    if (formula) {
      gtagService.sendViewItem(formula);
    }
  };

  if (!site) {
    return false;
  }

  return (
    <div className="leftContainer">
      <div className="footer-form-item">
        <div className="need-help">
          <i className="fal fa-question-circle icon" />
          <h2>{I18n.t('footerForm.need_help')}</h2>
        </div>
        <div className="number-field">
          <div className="number">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`tel:${site.hotlineNumber}`}
            >
              <h1 onClick={() => dataLayer.show(TAG_EVENT.TEL)}>{site.hotlineNumber}</h1>
            </a>
          </div>
        </div>
        <div className="schedule">
          <div className="week-hour">
            <h6>{site.hotlineText}</h6>
          </div>
          <a
            className="mail"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${site.hotlineMail}`}
          >
            <h6>{site.hotlineMail}</h6>
          </a>
        </div>
        <button
          type="button"
          onClick={() => {
            sendEvent();
            dataLayer.showGTM(TAG_EVENT.CLIC_QUESTIONS);
            window.open(FAQ_PAGE_ROUTE, '_blank');
          }}
        >
          <i className="fal fa-question-square icon" />
          <h3>{I18n.t('footerForm.button_question')}</h3>
        </button>
      </div>
    </div>
  );
};

export default withRouter(connect((state) => ({
  formula: state.storage.formula,
  site: state.storage.site,
}))(FormFooterItemLeft));
