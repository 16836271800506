// @flow

import React from 'react';
import type { SiteInfo } from '../../types/contactTypes';
import AsideNav from './AsideNav';
import { DASHBOARD_PAGE_ROUTE } from '../../const';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import contactApi from '../../network/api/contactApi';
import Flag from '../../components/client/Flag';
import { connect } from 'react-redux';

type Props = {
  history: any,
  locale: string,
};

type State = {
  siteInfoContact: SiteInfo,
}

class Contact extends React.Component<Props, State> {
  componentDidMount() {
    this.fetchContactPage();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.locale !== this.props.locale) {
      this.fetchContactPage();
    }
  }

  fetchContactPage = () => contactApi
    .get()
    .then((response) => response.json())
    .then((responseJson) => this.setState({ siteInfoContact: responseJson }));

  render() {
    return (
      <div className="space-client-container__connected">
        <AsideNav />
        <Flag />
        <div className="main-page">
          <div className="title">
            <i
              onClick={() => this.props.history.push(DASHBOARD_PAGE_ROUTE)}
              className="fal fa-arrow-left"
            />
            <h1><Translate value="spaceClient.contact.title" /></h1>
          </div>
          <div className="contact-body">
            {
              this.state && this.state.siteInfoContact.valueRichtext
                ? <div dangerouslySetInnerHTML={{ __html: this.state.siteInfoContact.valueRichtext }} />
                : <div><Translate value="spaceClient.contact.noContent" /></div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect((state) => ({
  locale: state.i18n.locale,
}))(Contact));
