// @flow

import React from 'react';
import { Translate, Localize } from 'react-redux-i18n';

import { formatPriceClean } from '../../../utils';
import type { SellingInformation } from '../../../types/contractTypes';

type Props = {
  contract: SellingInformation,
  countries: any,
};

export default class Summup extends React.PureComponent<Props> {

  findCountry = (country: string) => this.props.countries
    .filter((countryBean) => countryBean.isoCode === country)[0].label;

  render() {
    return (
      <div className="table summup">
        <div className="table-header">
          <div>
            <i className="fal fa-list-alt" />
            <Translate value="spaceClient.contract.summup" />
          </div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="label"><Translate value="spaceClient.contract.table.date" /></div>
            <div className="value">
              <Localize value={this.props.contract.effectiveDate} dateFormat="date.DATE_FORMAT" />
              <i className="fal fa-arrow-right" />
              <Localize value={this.props.contract.endEffectiveDate} dateFormat="date.DATE_FORMAT" />
            </div>
          </div>
          <div className="table-row">
            <div className="label"><Translate value="spaceClient.contract.table.country" /></div>
            <div className="value">{this.findCountry(this.props.contract.origin)}</div>
          </div>
          <div className="table-row">
            <div className="label"><Translate value="spaceClient.contract.table.destinations" /></div>
            <div className="value">{this.props.contract.destinations.map(this.findCountry).join(', ')}</div>
          </div>
          <div className="table-row">
            <div className="label"><Translate value="spaceClient.contract.table.amount" /></div>
            <div className="value">
              <Translate
                value="spaceClient.contract.currency"
                price={formatPriceClean(this.props.contract.travelPrice)}
              />
            </div>
          </div>
          <div className="table-row">
            <div className="label"><Translate value="spaceClient.contract.table.buyingDate" /></div>
            {this.props.contract.buyingDate ? <Localize value={this.props.contract.buyingDate} dateFormat="date.DATE_FORMAT" /> : '--'}
          </div>
          <div className="table-row">
            <div className="label"><Translate value="spaceClient.contract.table.including" /></div>
            <div className="value"><Translate value={`travelInsuranceForm.step1.expenses.${this.props.contract.travelPackage.toLowerCase()}`} /></div>
          </div>
        </div>
      </div>
    );
  }
}
