// @flow

import React from 'react';
import { I18n, Localize, Translate } from 'react-redux-i18n';
import type { SellingInformation } from '../../types/contractTypes';
import Summup from './contract/Summup';
import Basket from './contract/Basket';
import Beneficiary from './contract/Beneficiary';
import { currentAgeFromTimestamp } from '../../services/date';
import { fetchFile, formatPriceClean } from '../../utils';
import Warranty from './contract/Warranty';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';

type Props = {
  contract: SellingInformation,
  countries: any,
  openContract: Function,
  attestationUrls: string[],
};

const ADULT_AGE = 17;
const CHILD_AGE = 2;

export default class ContractDetails extends React.PureComponent<Props> {

  render() {
    return (
      <div className="contract-details">
        <div className="title">
          <div className="main-title">
            <h2>{this.props.contract.offerIntermediateBean.name}</h2>
            <h2>
              <Translate
                value="spaceClient.contract.currency"
                price={formatPriceClean(this.props.contract.pricePaid)}
              />
            </h2>
          </div>
          <div className="subtitle">
            <Localize value={this.props.contract.effectiveDate} dateFormat="date.DATE_FORMAT" />
            <i className="fal fa-arrow-right" />
            <Localize value={this.props.contract.endEffectiveDate} dateFormat="date.DATE_FORMAT" />
          </div>
        </div>
        <div className="contract-details-body">
          <Summup contract={this.props.contract} countries={this.props.countries} />
          <Basket contract={this.props.contract} countries={this.props.countries} />
          <Beneficiary
            // $FlowFixMe
            beneficiaries={this.props.contract.beneficiaries
              .filter((beneficiary) => currentAgeFromTimestamp(beneficiary.birthdate) > ADULT_AGE)}
            icon="fal fa-male"
            title="spaceClient.contract.adult"
          />
          <Beneficiary
            // $FlowFixMe
            beneficiaries={this.props.contract.beneficiaries
              .filter((beneficiary) => (currentAgeFromTimestamp(beneficiary.birthdate) <= ADULT_AGE
                && currentAgeFromTimestamp(beneficiary.birthdate) >= CHILD_AGE))}
            icon="fal fa-child"
            title="spaceClient.contract.teen"
          />
          <Beneficiary
            // $FlowFixMe
            beneficiaries={
              this.props.contract.beneficiaries
                .filter(
                  (beneficiary) => (currentAgeFromTimestamp(beneficiary.birthdate) < CHILD_AGE),
                )
            }
            icon="fal fa-baby"
            title="spaceClient.contract.child"
          />
          {
            this.props.contract.offerIntermediateBean.includedCoverages.length > 0
            && (
              <Warranty
                warranties={this.props.contract.offerIntermediateBean.includedCoverages}
                title={this.props.contract.offerIntermediateBean.productName}
              />
            )
          }
        </div>
        <div className="btn-container">
          <div className="btn-contract hide-mobile" onClick={this.props.openContract}>
            <Translate value="spaceClient.contract.btnContract" />
          </div>
          {
            this.props.attestationUrls
            && this.props.attestationUrls.map((attestationUrl, i: number) => (
              <div id={attestationUrl} className="btn-contract" onClick={() => {
                fetchFile(attestationUrl, I18n.t('spaceClient.contract.attestName'));
                dataLayer.showGTM(TAG_EVENT.ATTESTATION_DOWNLOAD);
              }}>
                <Translate value="spaceClient.contract.btnAttest" number={this.props.attestationUrls.length > 1 ? (` n°${i + 1}`) : ''} />
              </div>
            ))
          }
        </div>
        <div className="btn-container">
          {
            this.props.contract.formulaCgUrl &&
            <div className="btn-contract" onClick={() => {
              dataLayer.showGTM(TAG_EVENT.CLIC_NOTICE_INFORMATION_CONTRACT);
              fetchFile(this.props.contract.formulaCgUrl, null);
            }}>
              <Translate value="footerForm.download_CGUV" />
            </div>
          }
          {
            this.props.contract.formulaDinUrl &&
            <div className="btn-contract" onClick={() => {
              dataLayer.showGTM(TAG_EVENT.CLIC_DIN_CONTRACT);
              fetchFile(this.props.contract.formulaDinUrl, null);
            }}>
              <Translate value="footerForm.dowload_din" />
            </div>
          }
        </div>
      </div>
    );
  }
}
