// @flow

import { I18n, Translate } from 'react-redux-i18n';
import React, { useEffect, useState } from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { connect } from 'react-redux';
import * as locales from 'react-date-range/dist/locale';
import { gtagService } from '../../../services/gtagService';
import {
  composeValidators,
  minMaxValue,
  required,
  validDate,
} from '../../../services/validator';
import { isNumber } from '../../../services/formator';
import FormFooter from '../../../components/steps/footer/FormFooter';
import {
  storeCountries,
  storeOfferCode,
  storeStartContract,
  storeTripInfos,
  storeValidTripInfos,
} from '../../../state/storage/storageService';
import { isMobileDimensions } from '../../../services/dimensions';
import countryApi from '../../../network/api/countryApi';
import { fetchQuote, getQueryParams, isFutur, isPast } from '../../../utils';
import SwingSelect from '../../../components/form/SwingSelect';
import SwingSelectCountry from '../../../components/form/SwingSelectCountry';
import SwingInputNumber from '../../../components/form/SwingInputNumber';
import { DATE_FORMAT, TRIP_AMOUNT } from '../../../const';
import type { Site, TripInfos } from '../../../types/storage';
import { FORMULA_NAMES } from '../../../services/coverages';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import type { Formula } from '../../../components/steps/types/steps';
import { LANGUAGE } from '../../../services/languages';
import { setIsQuoteLoading } from '../../../state/submission/submissionService';
import TravelType from '../../../components/form/TravelType';
import { Country } from '../../../types/storage';

type Props = {
  match: Match,
  history: RouterHistory,
  dispatch: Function,
  tripInfos: TripInfos,
  locale: string,
  offerCode: string,
  startContract: string,
  maxPage: number,
  redirect: boolean,
  formula: Formula,
  isQuoteLoading: boolean,
  site: Site,
};

function Step1Container(props: Props) {
  const {
    match, history, dispatch, tripInfos, locale, offerCode, startContract,
    maxPage, redirect, formula, isQuoteLoading, site,
  } = props;

  let calendarRef;
  let buyingRef;
  let beneficiaryRef;

  const months = isMobileDimensions() ? 1 : 2;
  const [startContractDate, setStartContractDate] = useState(startContract);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showUniqueCalendar, setShowUniqueCalendar] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [previousAmountValue, setPreviousAmountValue] = useState('0');
  const [nbrBeneficiaries, setNbrBeneficiaries] = useState(tripInfos.numberOfAdults
    + tripInfos.numberOfKids + tripInfos.numberOfBabies);
  const [destinationSelectOpen, setDestinationSelectOpen] = useState(false);

  useEffect(() => {
    dataLayer.updateFilAriane(1);

    if (maxPage === 2 && redirect && !formula && offerCode) {
      dataLayer.show(TAG_EVENT.REDIRECTION_QUIT);
    }

    if (FORMULA_NAMES[getQueryParams().offerCode]) {
      dispatch(storeOfferCode(getQueryParams().offerCode));
    } else {
      dispatch(storeOfferCode(''));
    }
  }, []);

  useEffect(() => {
    countryApi.get()
      .then((response) => response.json())
      .then((countries: Country[]) => {
        setCountries(countries);
        dispatch(storeCountries(countries));
      });
  }, [localStorage.getItem(LANGUAGE)]);

  const EXPENSES = [
    { value: 'transport', label: I18n.t('travelInsuranceForm.step1.expenses.transport') },
    { value: 'lodging', label: I18n.t('travelInsuranceForm.step1.expenses.lodging') },
    {
      value: 'transport_and_lodging',
      label: I18n.t('travelInsuranceForm.step1.expenses.transport_and_lodging'),
    },
  ];

  const handleChangeRange = (range, form) => {
    form.change('travelDateRange.startDate', moment(range.startDate).startOf('day'));
    form.change('travelDateRange.endDate', moment(range.endDate).endOf('day'));

    const tempStartContractDate = isFutur(range.startDate)
      ? moment(range.startDate).startOf('day')
      : moment().startOf('day').add(1, 'd');

    form.change('startDateContract', tempStartContractDate.toString());
    setStartContractDate(tempStartContractDate.toString());
    dispatch(storeStartContract(tempStartContractDate.format(DATE_FORMAT.LOCAL_FORMAT).toString(), false));
  };

  const handleChangeUniqueRange = (ranges, form) => {
    const { startDate } = ranges.range1;
    form.change('buyingDate', moment(startDate).startOf('day'));
    setShowUniqueCalendar(false)
  }

  const isTraveling = (startDate) => startDate && moment(startDate).isBefore(moment());

  const mobileDisplay = isMobileDimensions();

  const getFormattedDates = (range) => {
    const dates = {
      startDate: '',
      endDate: '',
    };
    if (!range) {
      return dates;
    }
    if (range.startDate) {
      dates.startDate = `${moment(range.startDate).format(I18n.t('date.DATE_FORMAT'))}`;
    }
    if (!!range.endDate && !isPast(range.endDate)) {
      dates.endDate = `${moment(range.endDate).format(I18n.t('date.DATE_FORMAT'))}`;
    }
    return dates;
  };

  const isTravelDatePicked = (range) => {
    const dates = getFormattedDates(range);
    return dates.startDate !== '' && dates.endDate !== '';
  };

  const getFormatedBuyingDate = (buyingDate) => {
    return `${moment(buyingDate).format(I18n.t('date.DATE_FORMAT'))}`;
  }

  const getRange = (range) => {
    if (range) {
      return range;
    }
    return {
      startDate: moment(),
      endDate: moment(),
    };
  };

  const getUniqueRange = (buyingDate) => {
    if (buyingDate) {
      return {
        startDate: buyingDate,
        endDate: buyingDate,
      }
    }
    return {
      startDate: moment(),
      endDate: moment(),
    };
  }

  const scrollToElementAsync = (element: ?HTMLDivElement) => {
    if (!element) {
      return;
    }
    setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 10);
  };

  const destinationClass = (value) => {
    if (site.defaultIsoCodeCountry) {
      if (value){
        if (value.find((temp) => temp.value === site.defaultIsoCodeCountry)) {
          return 'destination-select filled-destination'
        }
        return 'destination-select filled-destination destination-select-var'
      }
      return 'destination-select destination-select-var'
    }
    if (value){
      return 'destination-select filled-destination'
    }
    return 'destination-select';
  }

  const isFormCompleted = (formSpyProps) => {
    return formSpyProps.values.travelType
    && formSpyProps.values.amount
    && formSpyProps.values.buyingDate
    && formSpyProps.values.countryOfResidence
    && formSpyProps.values.destinations
    && formSpyProps.values.expenses
    && (formSpyProps.values.numberOfAdults !== 0 || formSpyProps.values.numberOfBabies !== 0 || formSpyProps.values.numberOfKids !== 0)
    && formSpyProps.dirty === false;
  }

  return (
    <div className="step1-container">
      <div className="step1-form">
        <Form
          initialValues={tripInfos}
          onSubmit={(values) => {
            const newTripInfos = values;
            dispatch(setIsQuoteLoading(true));
            tripInfos.travelDateRange = ({
              startDate: moment(values.travelDateRange.startDate).format(DATE_FORMAT.LOCAL_FORMAT),
              endDate: moment(values.travelDateRange.endDate).format(DATE_FORMAT.LOCAL_FORMAT),
            });
            const valuesToSubmit = {
              ...newTripInfos,
              buyingDate: moment(values.buyingDate).format(DATE_FORMAT.LOCAL_FORMAT),
              offerCode: offerCode || null,
            };
            dataLayer.showGTM(TAG_EVENT.CLIC_TRAVEl_TYPE(tripInfos.travelType));
            dataLayer.showGTM(TAG_EVENT.CLIC_FIND_COVERAGE);
            fetchQuote(
              valuesToSubmit,
              startContractDate,
              parseInt(match.params.number, 10),
              history.push,
              dispatch,
            );
          }}
          render={(
            {
              handleSubmit,
              values,
              valid,
              form,
            },
          ) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormSpy
                subscription={{ values: true, dirty: true, valid: true }}
                onChange={(formSpyProps) => {
                  dispatch(storeTripInfos(formSpyProps.values, false));
                  dispatch(storeValidTripInfos(formSpyProps.valid));
                  if (isFormCompleted(formSpyProps)) {
                    gtagService.sendSetTripInfos(formSpyProps)
                  }
                  setNbrBeneficiaries(parseInt(formSpyProps.values.numberOfAdults, 10)
                    + parseInt(formSpyProps.values.numberOfKids, 10)
                    + parseInt(formSpyProps.values.numberOfBabies, 10));
                }}
              />
              <h1>
                {I18n.t('travelInsuranceForm.step1.travel')}
              </h1>
              <h3 className="no-bold">
                {I18n.t(`travelInsuranceForm.step1.${offerCode ? 'descriptionOfferCode' : 'description'}`)}
              </h3>
              <div className="form-section">
                <h3>{I18n.t('travelInsuranceForm.step1.insuranceType.title')}</h3>
                <TravelType
                  form={form}
                  values={values}
                  onClick={() => {
                    if (mobileDisplay) {
                      scrollToElementAsync(beneficiaryRef);
                    }
                  }}
                  site={site}
                  mobileDisplay={mobileDisplay}
                />
              </div>
              <div ref={(r: ?HTMLDivElement) => {
                if (r && !beneficiaryRef) {
                  beneficiaryRef = r;
                }
              }}
              >
                {
                  values.travelType
                  && (
                    <div className="form-section">
                      <h3>
                        {I18n.t('travelInsuranceForm.step1.numberOfBeneficiaries.title')}
                      </h3>
                      <Field
                        name="numberOfAdults"
                        component={SwingInputNumber}
                        max={999}
                        changeValue={form.change}
                        fieldValue={values.numberOfAdults}
                      />
                      <Field
                        name="numberOfKids"
                        component={SwingInputNumber}
                        max={999}
                        changeValue={form.change}
                        fieldValue={values.numberOfKids}
                      />
                      <Field
                        name="numberOfBabies"
                        component={SwingInputNumber}
                        max={998}
                        changeValue={form.change}
                        fieldValue={values.numberOfBabies}
                      />
                    </div>
                  )
                }
              </div>
              {
                (!!values.numberOfAdults || !!values.numberOfKids)
                && (
                  <div className="form-section">
                    <h3>
                      {I18n.t('travelInsuranceForm.step1.countryOfResidence')}
                    </h3>
                    <div className={site.defaultIsoCodeCountry ?'swing-select swing-select-var' : 'swing-select'}>
                      <Field
                        name="countryOfResidence"
                        validate={required}
                        className="select"
                      >
                        {({ input, meta }) => (
                          <SwingSelectCountry
                            input={input}
                            meta={meta}
                            classNamePrefix="swing-select"
                            placeholder={I18n.t('travelInsuranceForm.step1.countryPlaceholder')}
                            countries={countries}
                            isClearable
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )
              }
              {
                (!!values.numberOfAdults || !!values.numberOfKids)
                && !!values.countryOfResidence
                && (
                  <div className="form-section">
                    <h3>
                      {I18n.t('travelInsuranceForm.step1.destination')}
                    </h3>
                    <div
                      className={destinationClass(values.destinations)}
                    >
                      <Field
                        name="destinations"
                        validate={required}
                        format={(valuesToFormat) => (valuesToFormat
                          ? valuesToFormat.concat(fixedDestinationButton) : valuesToFormat)}
                        parse={(valuesToParse) => (valuesToParse
                          ? valuesToParse.filter((e) => !e.isFixed) : valuesToParse)}
                      >
                        {({ input, meta }) => (
                          <SwingSelectCountry
                            input={input}
                            meta={meta}
                            classNamePrefix="react-select"
                            placeholder={addDestinationButton()}
                            countries={countries}
                            isMulti
                            destinationSelectOpen={destinationSelectOpen}
                            onMenuOpen={() => setDestinationSelectOpen(true)}
                            onMenuClose={() => setDestinationSelectOpen(false)}
                          />
                        )}
                      </Field>
                      <small>
                        <h6>
                          {I18n.t('travelInsuranceForm.step1.destinationWarning')}
                        </h6>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://${I18n.t('travelInsuranceForm.step1.destinationWarningLink')}`}
                          onClick={() => dataLayer.showGTM(TAG_EVENT.CLIC_DESTINATION_RESTRICTION_WEBSITE)}
                        >
                          <h6 className="underline">
                            {I18n.t('travelInsuranceForm.step1.destinationLink')}
                          </h6>
                        </a>
                      </small>
                    </div>
                  </div>
                )
              }
              {
                (!!values.numberOfAdults || !!values.numberOfKids)
                && !!values.countryOfResidence
                && !!values.destinations && values.destinations.length !== 0
                && (
                  <div
                    className="form-section"
                    ref={(r: ?HTMLDivElement) => {
                      if (r && !calendarRef) {
                        calendarRef = r;
                      }
                    }}
                  >
                    <h3>
                      {I18n.t('travelInsuranceForm.step1.travelDate.title')}
                    </h3>
                    <div className="date-voyage-container">
                      <div className="date">
                        <div className="range-picker">
                          <Field
                            name="travelDateRange"
                            className="datepicker"
                            dateFormat={I18n.t('date.DATE_FORMAT')}
                            placeholder={I18n.t('travelInsuranceForm.step1.travelDate.placeholder')}
                            onlyDate
                            validate={validDate}
                          >
                            {({ meta }) => (
                              <div>
                                <div
                                  role="presentation"
                                  onClick={() => {
                                    setShowCalendar(
                                      (isTravelDatePicked(values.travelDateRange))
                                        ? !showCalendar
                                        : true,
                                    );
                                  }}
                                >
                                  {
                                    showCalendar
                                      ? (
                                        <>
                                          <button className="calendar-button" type="button">
                                            {
                                              getFormattedDates(values.travelDateRange).startDate === ''
                                                ? (
                                                  <span>
                                                    {I18n.t('travelInsuranceForm.step1.travelDate.placeholder')}
                                                  </span>
                                                ) : (
                                                  <h3 className="formatted-dates">
                                                    {getFormattedDates(values.travelDateRange).startDate}
                                                    <i className="fas fa-arrow-right" />
                                                    {getFormattedDates(values.travelDateRange).endDate}
                                                  </h3>
                                                )
                                            }
                                          </button>
                                          <i className="fal fa-calendar-alt icon" />
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="calendar-button"
                                            type="button"
                                            onClick={() => {
                                              scrollToElementAsync(calendarRef);
                                            }}
                                          >
                                            {
                                              getFormattedDates(values.travelDateRange).startDate === ''
                                                ? (
                                                  <span>
                                                    {I18n.t('travelInsuranceForm.step1.travelDate.placeholder')}
                                                  </span>
                                                ) : (
                                                  <h3 className="formatted-dates">
                                                    {getFormattedDates(values.travelDateRange).startDate}
                                                    <i className="fas fa-arrow-right" />
                                                    {getFormattedDates(values.travelDateRange).endDate}
                                                  </h3>
                                                )
                                            }
                                          </button>
                                          <i className="fal fa-calendar-alt icon" />
                                        </>
                                      )
                                  }
                                </div>
                                <div>
                                  {(showCalendar
                                    && (
                                      <div className="popup">
                                        <div className="popup_inner">
                                          <div className="sumup">
                                            <div className="sumup-date">
                                              <h3>
                                                {I18n.t('date.DATE_START')}
                                              </h3>
                                              <div className="date-container">
                                                {getFormattedDates(values.travelDateRange).startDate}
                                              </div>
                                            </div>
                                            <div className="sumup-date">
                                              <h3>
                                                {I18n.t('date.DATE_END')}
                                              </h3>
                                              <div className="date-container last">
                                                {getFormattedDates(values.travelDateRange).endDate}
                                              </div>
                                            </div>
                                          </div>
                                          <DateRangePicker
                                            locale={locales[locale]}
                                            dateDisplayFormat="L"
                                            ranges={[getRange(values.travelDateRange)]}
                                            showDateDisplay={false}
                                            direction="horizontal"
                                            months={months}
                                            onChange={(ranges) => {
                                              handleChangeRange(ranges.range1, form);
                                            }}
                                            minDate={
                                              (values.travelDateRange
                                                && values.travelDateRange.startDate
                                                && !values.travelDateRange.endDate)
                                                ? moment().add(1, 'd').toDate()
                                                : moment().subtract(1, 'y').toDate()
                                            }
                                            maxDate={moment().add(3, 'y').toDate()}
                                          />
                                          <button
                                            type="button"
                                            className={
                                              (
                                                values.travelDateRange
                                                && !!values.travelDateRange.startDate
                                                && !!values.travelDateRange.endDate
                                              )
                                                ? 'apply'
                                                : 'apply disable'
                                            }
                                            disabled={
                                              values.travelDateRange
                                              && (
                                                !values.travelDateRange.startDate
                                                || !values.travelDateRange.endDate
                                              )
                                            }
                                            onClick={() => {
                                              setShowCalendar(false);
                                            }}
                                          >
                                            <h3>
                                              {I18n.t('travelInsuranceForm.step1.travelDate.apply')}
                                            </h3>
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="error-custom">
                                  {meta.error}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      {
                        isTraveling(
                          values && values.travelDateRange && values.travelDateRange.startDate,
                        )
                        && (
                          <small>
                            <h6>
                              {I18n.t('travelInsuranceForm.step1.travelDate.warning')}
                            </h6>
                          </small>
                        )
                      }
                    </div>
                    {
                      showCalendar
                      && !isTravelDatePicked(values.travelDateRange)
                      && (
                        <div className="space-calendar" />
                      )
                    }
                  </div>
                )}
              {!!values.countryOfResidence
                && !!values.destinations
                && values.destinations.length !== 0
                && (!!values.numberOfAdults || !!values.numberOfKids)
                && isTravelDatePicked(values.travelDateRange)
                && (
                  <div>
                    <div>
                      <div className="trick">
                        <div className="text">
                          <h1 className="titre">
                            {I18n.t('travelInsuranceForm.step1.trick.title')}
                          </h1>
                          <ul>
                            <li>
                              <h4>
                                {I18n.t('travelInsuranceForm.step1.trick.description1')}
                              </h4>
                            </li>
                            <li>
                              <div className="trick-detail">
                                <h4>
                                  {I18n.t('travelInsuranceForm.step1.trick.description2')}
                                </h4>
                                <ul>
                                  <li className="fas fa-check"><h4><strong><u>
                                    {I18n.t('travelInsuranceForm.step1.trick.description3')}
                                  </u></strong></h4></li>
                                  <li className="fas fa-check"><h4>
                                    {I18n.t('travelInsuranceForm.step1.trick.description4')}
                                  </h4></li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <i className="icon-idea icon" />
                      </div>
                      <div className="form-section">
                        <div className="amount-input-icons">
                          <h3>
                            {I18n.t('travelInsuranceForm.step1.amount', {
                              benef:
                                nbrBeneficiaries === 1 || Number.isNaN(nbrBeneficiaries)
                                  ? '' : I18n.t('travelInsuranceForm.step1.ttc', { nbrBeneficiaries }),
                            })}
                          </h3>
                          <Field
                            name="amount"
                            validate={
                              composeValidators(
                                required,
                                minMaxValue(TRIP_AMOUNT.MIN, TRIP_AMOUNT.MAX),
                              )
                            }
                            format={(value) => {
                              if (value !== previousAmountValue) {
                                setPreviousAmountValue(value || '');
                              }
                              return value;
                            }}
                            parse={((value) => isNumber(value, previousAmountValue))}
                          >
                            {({ input, meta }) => (
                              <div>
                                <div className="input-icons">
                                  <input
                                    className="input-field"
                                    type="tel"
                                    placeholder={I18n.t('travelInsuranceForm.step1.placeholder.amount')}
                                    {...input}
                                  />
                                  <i className="far fa-euro-sign icon" />
                                </div>
                                <div className="error-custom">
                                  {meta.touched && meta.error}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className="form-section">
                      <div className="cost-type"
                           ref={(r: ?HTMLDivElement) => {
                             if (r && !buyingRef) {
                               buyingRef = r;
                             }
                           }}>
                        <h3>
                          {I18n.t('travelInsuranceForm.step1.buyingDate.title')}
                        </h3>
                        <div className="date-voyage-container">
                          <div className="date">
                            <div className="range-picker">
                              <Field
                                name="buyingDate"
                                className="datepicker"
                                dateFormat={I18n.t('date.DATE_FORMAT')}
                                validate={required}
                              >
                                {({ meta }) => (
                                  <div>
                                    <div
                                      role="presentation"
                                      onClick={() => {
                                        setShowUniqueCalendar(
                                          values.buyingDate
                                            ? !showUniqueCalendar
                                            : true,
                                        );
                                      }}
                                    >
                                      {
                                        showUniqueCalendar
                                          ? (
                                            <>
                                              <button className="calendar-button buying-date" type="button">
                                                {
                                                  !getFormattedDates(values.buyingDate) === ''
                                                    ? (
                                                      <span>
                                                  </span>
                                                    ) : (
                                                      <h3 className="formatted-dates">
                                                        {getFormatedBuyingDate(values.buyingDate)}
                                                      </h3>
                                                    )
                                                }
                                              </button>
                                              <i className="fal fa-calendar-alt icon" />
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="calendar-button buying-date"
                                                type="button"
                                                onClick={() => {
                                                  scrollToElementAsync(buyingRef);
                                                }}
                                              >
                                                {
                                                  values.buyingDate
                                                    ? (
                                                      <h3 className="formatted-dates">
                                                        {getFormatedBuyingDate(values.buyingDate)}
                                                      </h3>
                                                    ) : (
                                                      <div className="calendar-placeholder">
                                                        {I18n.t('travelInsuranceForm.step1.buyingDate.placeholder.normal')}
                                                        <u>
                                                          {I18n.t('travelInsuranceForm.step1.buyingDate.placeholder.underline')}
                                                        </u>
                                                      </div>
                                                    )
                                                }
                                              </button>
                                              <i className="fal fa-calendar-alt icon" />
                                            </>
                                          )
                                      }
                                    </div>
                                    <div>
                                      {(showUniqueCalendar
                                        && (
                                          <div className="popup">
                                            <div className="popup_inner">
                                              <DateRangePicker
                                                ranges={[getUniqueRange(values.buyingDate)]}
                                                locale={locales[locale]}
                                                dateDisplayFormat="L"
                                                direction="horizontal"
                                                onChange={(ranges) => handleChangeUniqueRange(ranges, form)}
                                                showDateDisplay={false}
                                                maxDate={moment().toDate()}
                                              />
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="error-custom">
                                      {meta.touched && meta.error}
                                    </div>
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-section total-amount">
                      <div className="cost-type">
                        <h3>
                          {I18n.t('travelInsuranceForm.step1.expenses.title')}
                        </h3>
                        <Field
                          component={SwingSelect}
                          name="expenses"
                          className="select"
                          validate={required}
                          options={EXPENSES}
                          isClearable
                          isSearchable={false}
                          classNamePrefix="expenses-select"
                          placeholder={I18n.t('travelInsuranceForm.step1.expenses.placeholder')}
                        />
                      </div>
                    </div>
                    <div className="button-footer-right">
                      <div className={valid && values.buyingDate ? 'submit' : 'submit disabled'}>
                        <button type="submit" disabled={isQuoteLoading}>
                          <h3>
                            {I18n.t(`travelInsuranceForm.step1.${offerCode ? 'submitOfferCode' : 'submit'}`)}
                          </h3>
                          {
                            isQuoteLoading
                              ? (
                                <i className="fa fa-circle-notch fa-spin" />
                              ) : (
                                <i className="far fa-long-arrow-right" />
                              )
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </form>
          )}
        />
      </div>
      <FormFooter />
    </div>
  );
}

function addDestinationButton() {
  return (
    <div className="destination-select-placeholder">
      <Translate value="travelInsuranceForm.step1.addDestination" />
      <i className="fas fa-plus-circle" />
    </div>
  );
}

export const fixedDestinationButton = {
  value: 'placeholder',
  label: addDestinationButton(),
  isFixed: true,
};

export default withRouter(connect((state) => ({
  tripInfos: state.storage.tripInfos,
  locale: state.i18n.locale,
  offerCode: state.storage.offerCode,
  startContract: state.storage.startContract,
  maxPage: state.storage.maxPage,
  redirect: state.storage.redirect,
  formula: state.storage.formula,
  isQuoteLoading: state.submission.isQuoteLoading,
  site: state.storage.site,
}))(Step1Container));
