// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  CONTACT_PAGE_ROUTE,
  CONTRACT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  FAQ_PAGE_ROUTE, PROFIL_PAGE_ROUTE,
  STEP_PAGE_ROUTE, STEP_ROOT_PAGE_ROUTE,
} from '../../const';
import DashboardTile from '../../components/client/DashboardTile';

type Props = {
  history: any,
  match: any,
}

class AsideNav extends React.PureComponent<Props> {
  render() {
    return (
      <div className="aside-nav">
        <div className="back-btn" onClick={() => this.props.history.push(DASHBOARD_PAGE_ROUTE)}>
          <i className="fal fa-arrow-alt-circle-left" />
        </div>
        <DashboardTile
          icon="fal fa-file-alt"
          titleCode="spaceClient.dashboard.contracts"
          redirectFunction={() => this.props.history.push(CONTRACT_PAGE_ROUTE)}
          isActive={this.props.match.path === CONTRACT_PAGE_ROUTE}
        />
        <DashboardTile
          icon="fal fa-file-edit"
          titleCode="spaceClient.dashboard.devis"
          redirectFunction={() => window.open(`${STEP_ROOT_PAGE_ROUTE}/1`)}
          isActive={this.props.match.path === STEP_ROOT_PAGE_ROUTE}
        />
        <DashboardTile
          icon="fal fa-address-book"
          titleCode="spaceClient.dashboard.contacts"
          redirectFunction={() => this.props.history.push(CONTACT_PAGE_ROUTE)}
          isActive={this.props.match.path === CONTACT_PAGE_ROUTE}
        />
        <DashboardTile
          icon="fal fa-file-alt"
          titleCode="spaceClient.dashboard.FAQ"
          redirectFunction={() => window.open(FAQ_PAGE_ROUTE)}
          isActive={this.props.match.path === FAQ_PAGE_ROUTE}
        />
        <DashboardTile
          icon="fal fa-user"
          titleCode="spaceClient.dashboard.profil"
          redirectFunction={() => this.props.history.push(PROFIL_PAGE_ROUTE)}
          isActive={this.props.match.path === PROFIL_PAGE_ROUTE}
        />
      </div>
    );
  }
}

export default withRouter(AsideNav);
