// @flow

import fr from '../flags/flag-fr.png';
import en from '../flags/flag-england.png';

export const LANGUAGE = 'language';

export type Language = {
  code: string,
  imgPath: string,
};

export const languagesAvailable: Language[] = [
  {
    code: 'fr',
    imgPath: fr,
  },
  {
    code: 'en',
    imgPath: en,
  },
];
