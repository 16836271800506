// @flow

import React, { useEffect, useState } from 'react';
// $FlowFixMe
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { I18n } from 'react-redux-i18n';
import { TRAVELER_TYPE } from '../../const';

type Props = {
  input: any,
  form: Object,
  travelerType: string,
  beneficiaryNumber: number,
  meta: Object,
  loadedApiKey: boolean,
  getCountryByCode: Function,
  countryCode: string,
};

function SwingAddressAutoComplete(props: Props) {
  const {
    input, form, travelerType, beneficiaryNumber, meta, loadedApiKey, getCountryByCode, countryCode
  } = props;

  const [address, setAddress] = useState<string>(input.value);
  const [basedSuggestionValue, setBasedSuggestionValue] = useState<string | undefined>(undefined);
  const [filteredAddress, setfilteredAddress] = useState([]);
  const [localizedSuggestions, setLocalizedSuggestions] = useState([]);

  useEffect(() => {
    setAddress(input.value);
  }, [input]);

  function handleChange(value) {
    setAddress(value);
    if (travelerType === TRAVELER_TYPE.USER) {
      form.change('user.address', value);
    } else {
      form.change(`beneficiary[${beneficiaryNumber}].address`, value);
    }
  }

  function handleSelect(value) {
    geocodeByAddress(value)
      .then((results) => {
        if (results[0]) {
          updateForm(results);
        }
      });
    setAddress(value);
  }

  function updateForm(geoAddress) {
    const fieldToChange = travelerType === TRAVELER_TYPE.USER ? 'user' : `beneficiary[${beneficiaryNumber}]`;
    const addressComponents = geoAddress[0].address_components;

    form.change(`${fieldToChange}.city`, '');
    form.change(`${fieldToChange}.country`, '');
    form.change(`${fieldToChange}.postcode`, '');

    let route = '';
    let number = '';
    let establishment = '';
    let colloquial_area = '';
    let sublocality = '';

    if (!addressComponents) {
      return;
    }

    addressComponents.forEach((addressComponent) => {
      addressComponent.types.forEach((type) => {
        switch (type) {
          case 'street_number':
            number = addressComponent.long_name;
            break;
          case 'route':
            route = addressComponent.long_name;
            break;
          case 'establishment':
            establishment = addressComponent.long_name;
            break;
          case 'colloquial_area':
            colloquial_area = addressComponent.long_name;
            break;
          case 'sublocality':
            sublocality = addressComponent.long_name;
            break;
          case 'locality':
            form.change(`${fieldToChange}.city`, addressComponent.long_name);
            break;
          case 'country':
            form.change(`${fieldToChange}.country`, getCountryByCode(addressComponent.short_name, true));
            break;
          case 'postal_code':
            form.change(`${fieldToChange}.postcode`, addressComponent.long_name);
            break;
          default:
        }
      });
    });

    if (route) {
      if (number) {
        form.change(`${fieldToChange}.address`, number + ' ' + route);
      } else {
        form.change(`${fieldToChange}.address`, route);
      }
    } else if (establishment) {
      form.change(`${fieldToChange}.address`, establishment);
    } else if (colloquial_area) {
      form.change(`${fieldToChange}.address`, colloquial_area);
    } else if (sublocality) {
      form.change(`${fieldToChange}.address`, sublocality);
    } else {
      form.change(`${fieldToChange}.address`, '');
    }
  }

  const orderAddressesByCountry = async (suggestions) => {
    const firstResult = [];
    const otherResult = [];

    if (basedSuggestionValue === address) {
      return;
    }

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    await autocompleteService.getPlacePredictions(
      { input: address, componentRestrictions: { country: countryCode } },
      (suggestions) => setLocalizedSuggestions(suggestions));

    if (localizedSuggestions === null || localizedSuggestions.length === 0) {
      return;
    }

    const placeIdLocalized = localizedSuggestions.map((suggestion) => suggestion.place_id);

    for (const suggestion of suggestions) {
      if (placeIdLocalized.indexOf(suggestion.placeId) > -1) {
        firstResult.push(suggestion);
      } else {
        otherResult.push(suggestion);
      }
    }
    setfilteredAddress([...firstResult, ...otherResult]);
    setBasedSuggestionValue(address);
  }

  return (
    <div>
      {!!loadedApiKey
      && (
        <PlacesAutocomplete
          value={address || ''}
          onChange={handleChange}
          onSelect={handleSelect}
          shouldFetchSuggestions={address.length > 3}
          debounce={800}
        >
          {({
              getInputProps, suggestions, getSuggestionItemProps,
            }) => (
            <div className="swing-address-container">
              <input
                {...input}
                {...getInputProps({
                  placeholder: I18n.t('travelInsuranceForm.step3.placeholder.address'),
                  className: 'swing-address-input',
                  autoComplete: 'none',
                })}
              />
              <div>
                {Object.keys(suggestions).length !== 0 && (
                  orderAddressesByCountry(suggestions) && (
                  <div className="autocomplete-dropdown-container">
                    {filteredAddress.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
      <div className="error-custom">{(meta.touched) && meta.error}</div>
    </div>
  );
}

export default SwingAddressAutoComplete;
