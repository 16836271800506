// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript.js';
import type { Header } from '../../types/logApiTypes';
import logApi from '../../network/api/logApi';

type Props = {
  isRequest: boolean,
  infos: {
    headerList: Header,
    body: string,
    isCompleteText: boolean,
    logId: string,
    method: string,
  },
}

const textDownload = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

class LogApiTileDetails extends React.Component<Props, void> {
  getFullResponse = () => {
    logApi
      .fetchFullBody(this.props.infos.id, this.props.isRequest)
      .then(res => res.text())
      .then(text => textDownload(`${this.props.infos.api}.txt`, text))
      .catch(console.log);
  };

  render() {
    return (
      <div className="log-container-details">
        <div
          className={`title title--${this.props.infos.method}`}
        >{this.props.isRequest ? I18n.t('LOG_API.REQUEST') : I18n.t('LOG_API.RESPONSE')}
        </div>
        <div className="subtitle">
          <div className="api-header-title"> {I18n.t('LOG_API.HEADER')}</div>
          {
            this.props.infos.headerList.headers.length > 0
            && this.props.infos.headerList.headers.map(headerInfo => (
              <div className="api-header" key={headerInfo.id}>
                <div className="header-key">{headerInfo.name}</div>
                <div>{headerInfo.value}</div>
              </div>
            ))
          }
          {
            this.props.infos.headerList.headers.length <= 0
            && (
              <div className="empty-body"> {I18n.t('LOG_API.EMPTY_HEADER')} </div>
            )
          }
        </div>
        <div className="subtitle">
          <div className="api-header-title"> {I18n.t('LOG_API.BODY')}</div>
          {
            !this.props.infos.body
            && (
              <div className="empty-body"> {I18n.t('LOG_API.EMPTY_BODY')} </div>
            )
          }

          {
            this.props.infos.body
            && (
              <button
                onClick={this.getFullResponse}
                className="button"
              > {I18n.t('LOG_API.DOWNLOAD')}
              </button>
            )
          }
          {
            this.props.infos.body
            && (
              <div className="bloc-body">
                <CodeMirror
                  value={this.props.infos.body}
                  autoCursor={false}
                  options={{
                    readOnly: true,
                    theme: 'default',
                    height: 'auto',
                    viewportMargin: Infinity,
                    mode: {
                      name: 'javascript',
                      json: true,
                      statementIndent: 2,
                    },
                    lineNumbers: true,
                    lineWrapping: true,
                    indentWithTabs: false,
                    tabSize: 2,
                  }}
                />
              </div>
            )
          }
        </div>
      </div>

    );
  }
}

export default LogApiTileDetails;
