// @flow

import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import type { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ERROR_REASON, ERROR_ROUTE, STEP_ROOT_PAGE_ROUTE, TRAVEL_TYPES } from '../../const';
import { connect } from 'react-redux';
import {
  clearQuoteInfos,
  clearTripInfos,
  storeFormulaBoth,
  storeMaxPage,
  storeQuote,
  storeTripInfos,
} from '../../state/storage/storageService';
import { FORMULA_CODES, FORMULA_NAMES, I18N_LABEL, IMG_URL } from '../../services/coverages';
import type { Quote } from '../../components/steps/types/steps';
import FormFooter from '../../components/steps/footer/FormFooter';
import type { TripInfos } from '../../types/storage';
import quoteApi from '../../network/api/quoteApi';
import { initTripInfos } from '../../state/storage/storageReducer';
import Warranty from '../steps/step2/Warranty';
import { dataLayer } from '../../services/dataLayer';
import { sourceForImg } from '../../utils';
import ErrorMessageDisplay from './ErrorMessageDisplay';

type Props = {
  offerCode: string,
  dispatch: Function,
  quote: Quote,
  history: RouterHistory,
  tripInfos: TripInfos,
};

const StepRedirectOffer = (props: Props) => {

  const { offerCode, dispatch, quote, history, tripInfos } = props;
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    dataLayer.updateFilAriane(-1);
    if (quote.offers && quote.offers[0] && quote.offers[0].code) {
      dataLayer.updateOffreDebranchement(I18n.t(`formula.insurance.${quote.offers[0].code}`));
    }
    setLength(quote.offers.length);
  }, []);

  const clickFormula = (chosenFormula) => {
    let formulaCode = FORMULA_NAMES[chosenFormula.code];
    if (formulaCode) {
      const valuesToSubmit = {
        ...tripInfos,
        offerCode: chosenFormula.code,
        travelType: TRAVEL_TYPES.VACATION
      };
      quoteApi.postTripInfos(valuesToSubmit)
        .then((data) => data.json())
        .then((data) => {
          dispatch(storeTripInfos(valuesToSubmit, true))
          dispatch(clearQuoteInfos());
          dispatch(storeFormulaBoth(chosenFormula, [], []));
          dispatch(storeQuote(data));
        })
        .catch((responseError) => {
          responseError.then(() => {
            history.push(`${STEP_ROOT_PAGE_ROUTE}${ERROR_ROUTE}?reason=${ERROR_REASON.noQuote}`);
          });
        });
    }
  };

  const handleGoBackClick = (emptyFields, isPro) => {
    if (emptyFields) {
      dispatch(clearTripInfos());
      dispatch(storeMaxPage(1));
      if (isPro) {
        const tripInfo = {
          ...initTripInfos,
          travelType: TRAVEL_TYPES.BUSINESS_TRIP,
        };
        dispatch(storeTripInfos(tripInfo, true))
      }
    }
    if (offerCode) {
      history.push(STEP_ROOT_PAGE_ROUTE + '/1?offerCode=' + offerCode);
    } else {
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  };

  const displayNextButton = () => {
    if (quote.offers[0] && quote.offers[0].code === FORMULA_CODES.SCHENGEN) {
      return (
        <div className="submit">
          <button type="button" onClick={() => clickFormula(quote.offers[0])}>
            <h3>{I18n.t('travelInsuranceForm.step2.submit')}</h3>
            <i className="far fa-long-arrow-right" />
          </button>
        </div>
      )
    }
  };

  const displayFormulas = () => Array(length || 1)
    .fill(null)
    .map((e, i) => (
      <div className="formula-item">
        <div className="formulas">
          <div className="formulas-content">
            <div className="header-formula-container">
              <h2 className="title-header">{quote.offers[i] && I18n.t(`formula.names.${quote.offers[i].code}`)}</h2>
            </div>
            <div className="img-content">
              <img className="img-background" src={quote.offers[i] && sourceForImg(quote)} alt="img-background" />
              <h6 className="price">{I18n.t(`errors.noAdaptedQuote.text.${I18N_LABEL[quote.offers[i].code]}`)}</h6>
              <button
                onClick={() => clickFormula(quote.offers[i])}>{I18n.t('travelInsuranceForm.step2.submit').toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    ));

  const displayBlock = (code) => {
    return (
      <div className="formula-item">
        <div className="formulas">
          <div className="formulas-content">
            <div className="header-formula-container">
              <h2 className="title-header">{I18n.t(`formula.names.${code}`)}</h2>
            </div>
            <div className="img-content">
              <img className="img-background" src={IMG_URL[code]} />
              <h6 className="price">{I18n.t(`errors.noAdaptedQuote.text.${I18N_LABEL[code]}`)}</h6>
              <button
                onClick={() => handleGoBackClick(true, code === FORMULA_CODES.PRO)}>{I18n.t('travelInsuranceForm.step2.choseOffer.edit').toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="step-error-page">
      <div className="main-content">
        <div className="step-error-page-content">
          {offerCode &&
          <ErrorMessageDisplay wantShowErrorReason={false} wantShowContact={false} />
          }
          <div className="block-info">
            <div className="line" />
            <div className="text center">
              <h1>{
                quote.offers.length > 0 && quote.offers[0].productCode === offerCode ?
                  I18n.t('errors.noAdaptedQuote.adviceQuestion') :
                  I18n.t('errors.noAdaptedQuote.advice')
              }</h1>
              <div className="line-horizon" />
              {quote.offers.length === 0 && FORMULA_CODES.SCHENGEN === quote.error.reason &&
              <div className="center">
                <div className="formula-tip-container">
                  <h3 className="text-above">{I18n.t('errors.redirectQuote.lonelySub')}</h3>
                  <div className="formula-item-container">
                    {displayBlock(FORMULA_CODES.SCHENGEN)}
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        {quote.offers[0] && quote.offers[0].code === FORMULA_CODES.SCHENGEN &&
        <div className="formula-tip">
          <div className="formula-tip-container">
            <div className="formula-item-container">

              {displayFormulas()}

            </div>
          </div>
        </div>
        }
        {quote.offers.length > 0 && FORMULA_CODES.GROUPE === quote.error.reason &&
        <div className="formula-tip">
          <div className="formula-tip-container">
            <h3 className="text-above">{I18n.t('errors.redirectQuote.lonelySub')}</h3>
            <div className="formula-item-container">
              {displayBlock(FORMULA_CODES.PRO)}
            </div>
          </div>
          <div className="formula-tip-container">
            <h1>{I18n.t('errors.redirectQuote.or')}</h1>
          </div>
          <div className="formula-tip-container">
            <h3 className="text-above">{I18n.t('errors.redirectQuote.groupeOffer')}</h3>
            <div className="formula-item-container">
              {displayFormulas()}
            </div>
          </div>
        </div>
        }
        <div className={quote.offers[0] && quote.offers[0].code === FORMULA_CODES.SCHENGEN ? 'buttons-footer' : 'bottom-button'}>
          {displayNextButton()}
          <div className="back">
            <button aria-label=" " type="button" onClick={() => handleGoBackClick(false)}>
              <i className="far fa-long-arrow-left" />
              <h2>
                {I18n.t('travelInsuranceForm.step2.goBackChangeInfo')}
              </h2>
            </button>
          </div>
        </div>
      </div>
      <Warranty />
      <FormFooter />
    </div>
  );
};

export default withRouter(connect((state) => ({
  offerCode: state.storage.offerCode,
  quote: state.storage.quote,
  tripInfos: state.storage.tripInfos,
}))(StepRedirectOffer));
