import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import type { Quote } from '../../../components/steps/types/steps';
import { I18n } from 'react-redux-i18n';
import { I18N_LABEL } from '../../../services/coverages';

type Props = {
  quote: Quote,
}

function Warranty(props: Props) {

  const { quote } = props;

  const [offerCodeLabel, setOfferCodeLabel] = useState('zen');

  useEffect(() => {
    if (quote.offers.length > 0) {
      I18N_LABEL[quote.offers[0].code] && setOfferCodeLabel(I18N_LABEL[quote.offers[0].code])
    }
  }, []);

  return (
    <div className="warranty">
      <div className="warranty-item">
        <i className="icon-heart" />
        <h3 className="warranty-text">
          {I18n.t(`travelInsuranceForm.step2.warranty.medicalWarranty.${offerCodeLabel}`)}
        </h3>
      </div>
      <div className="warranty-item">
        <i className="icon-check" />
        <h3 className="warranty-text">
          {I18n.t(`travelInsuranceForm.step2.warranty.sportWarranty.${offerCodeLabel}`)}
        </h3>
      </div>
      <div className="warranty-item">
        <i className="icon-credit-card-thin" />
        <h3 className="warranty-text">
          {I18n.t(`travelInsuranceForm.step2.warranty.bankCardWarranty.${offerCodeLabel}`)}
        </h3>
      </div>
    </div>
  );
}

export default withRouter(connect((state) => ({
  offerCode: state.storage.offerCode,
  quote: state.storage.quote,
}))(Warranty));
