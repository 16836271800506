// @flow

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import type { TripInfos } from '../../types/storage';

type Props = {
  input: {
    onChange: Function,
    onBlur: Function,
    value: string,
  },
  meta: {
    error: string,
    touched: boolean,
  },
  tripInfos: TripInfos,
}

function PhoneInputComponent({ meta, input, tripInfos }: Props) {

  const defaultCountryCode: string = tripInfos.countryOfResidence.code.toLowerCase();

  return (
    <div className="base-container-input-coreoz">
      <PhoneInput
        defaultCountry={defaultCountryCode}
        enableSearchField
        autoFormat={false}
        countryCodeEditable={false}
        value={input.value}
        inputExtraProps={{
          onChange: (e) => {
            input.onChange(e.target.value);
          },
          onBlur: () => {
            input.onBlur();
          },
        }}
      />
      <div className="base-error-form error-form--field">
        {meta.error && meta.touched && <span>{meta.error}</span>}
      </div>
    </div>
  );
}

export default connect((state) => ({
  tripInfos: state.storage.tripInfos,
}))(PhoneInputComponent);
