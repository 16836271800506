// @flow

import React, { useEffect, useMemo, useState } from 'react';
import type { ContextRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { Field, Form, FormSpy } from 'react-final-form';
import { CoreozInputBase } from 'coreoz-form-base';
import { connect } from 'react-redux';
import 'react-phone-input-2/dist/style.css';
import moment from 'moment';
import diff from 'object-diff';
import Beneficiary from '../../../components/steps/beneficiary/Beneficiary';
import {
  DATE_FORMAT,
  GROUP,
  STEP_ROOT_PAGE_ROUTE,
  WORD,
} from '../../../const';
import FormFooter from '../../../components/steps/footer/FormFooter';
import {
  composeValidators,
  isEmail,
  isName,
  isSameEmail,
  isValidPostCode,
  isValidPostCodeFive,
  required,
  validateBirthDate,
  validatePhone,
} from '../../../services/validator';
import { formatDate, onlyText } from '../../../services/formator';
import {
  storeMaxPage,
  storeTravelerInfos,
  storeTravelerInfosInStorage,
  storeValidTravelerInfos,
} from '../../../state/storage/storageService';
import {
  adultAge, babyAge, childAge, currentAge, dateFormatLength,
} from '../../../services/date';
import type { Site, TravelerInfos, TripInfos } from '../../../types/storage';
import PhoneInputComponent from '../../../components/form/PhoneInputComponent';
import type {
  Coverage, Formula, OptionalCoverage, Quote,
} from '../../../components/steps/types/steps';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import quoteApi from '../../../network/api/quoteApi';
import { formatQuoteBodyFromParams, sourceForImg } from '../../../utils';
import ConnexionItem from './ConnexionItem';
import GenderInput from '../../../components/form/GenderInput';
import { gtagService } from '../../../services/gtagService';
import userApi from '../../../network/api/userApi';
import { isUserLoggedOn } from '../../../services/sessionService';
import type { UserModification } from '../../../types/loginTypes';
import AddressItem from './AddressFormItem';
import SwingSelectCountry from '../../../components/form/SwingSelectCountry';

type Props = {
  ...ContextRouter,
  dispatch: Function,
  onFormLoaded: Function,
  tripInfos: TripInfos,
  travelerInfos: TravelerInfos,
  formula: Formula,
  optionalCoverages: OptionalCoverage[],
  options: Coverage[],
  loadedApiKey: boolean,
  quote: Quote,
  finalPrice: string,
  countries: Object[],
  site: Site,
}

function Step3Container(props: Props) {
  const {
    match, history, dispatch, onFormLoaded, tripInfos, optionalCoverages,
    options, formula, travelerInfos, loadedApiKey, quote, finalPrice, countries, site,
  } = props;

  const [verifyBenefShowError, setVerifyBenefShowError] = useState<boolean>(false);
  const [showPolicyNotAccepted, setShowPolicyNotAccepted] = useState<boolean>(false);
  const [user, setUser] = useState<UserModification>({ countryCode: tripInfos.countryOfResidence.value });

  useEffect(() => {
    dataLayer.updateFilAriane(3);
    if (isUserLoggedOn()) {
      userApi.get()
        .then((response) => response.json())
        .then((data) => {
          setUser(data);
        })
        .catch(() => console.error("Erreur lors du chargement de l'utilisateur"));
    }
  }, []);

  const handleGoBackClick = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_RETOUR);
    history.push(`${STEP_ROOT_PAGE_ROUTE}/2`);
  };

  const handleNextStepClick = (acceptOffers) => {
    gtagService.sendCheckoutProgress(formula, optionalCoverages, options, tripInfos.promoCode);
    gtagService.sendSetCheckoutOption(acceptOffers);
    dispatch(storeMaxPage(parseInt(match.params.number, 10) + 1));
    history.push(`${STEP_ROOT_PAGE_ROUTE}/4`);
  };

  const getCountries = () => (Array.isArray(JSON.parse(JSON.stringify(countries)))
    ? JSON.parse(JSON.stringify(countries)) : []);

  const getCountriesOptions = () => getCountries().map(
    (c) => ({ label: c.label, value: c.isoCode, id: c.id }),
  );

  const getCountryByCode = (code: string, isIso2Code: boolean) => {
    let basicSearch;

    if (isIso2Code) {
      basicSearch = getCountries().filter((country) => code === country.iso2Code);
    } else {
      basicSearch = getCountries().filter((country) => code === country.isoCode);
    }

    if (basicSearch.length < 1) {
      // No match was found between google and api country.
      // User must select on of the list
      console.error('No match were found with API list.');
    }

    if (basicSearch.length === 1) {
      // One match was found between google and api country.
      return {
        label: basicSearch[0].label,
        value: basicSearch[0].isoCode,
      };
    }

    // If not found, return nothing
    return undefined;
  };

  const buildUserBody = (values) => {
    if (!values) {
      return {
        user: {},
      };
    }
    return {
      user: {
        gender: values.gender,
        name: values.lastname,
        surname: values.firstname,
        birthdate: values.birthdate && moment(values.birthdate).format(I18n.t('date.DATE_FORMAT')),
        email: values.email,
        emailConfirmation: values.email,
        phone: values.phoneNumber,
        address: values.address,
        address_supp: values.address2,
        postcode: values.zipCode,
        city: values.city,
        country: values.countryCode && getCountryByCode(values.countryCode, false),
        receiveCommonCertificate: values.receiveCommonCertificate,
      },
    };
  };

  const createBeneficiaries = (nrbOfBenef, values, form) => Array(nrbOfBenef || 1)
    .fill(null)
    .map((e, i) => (
      <Beneficiary
        key={i}
        number={i}
        values={values}
        form={form}
        isBeneficiary={i === 0 && values.user && values.user.isBeneficiary}
        loadedApiKey={loadedApiKey}
        verifyBenef={verifyBenef}
        setVerifyBenefShowError={setVerifyBenefShowError}
        verifyBenefShowError={verifyBenefShowError}
        countriesOptions={getCountriesOptions()}
        countries={getCountries()}
        getCountryByCode={getCountryByCode}
        site={site}
      />
    ));

  const getNumberBeneficiaries = (beneficiaries) => beneficiaries.reduce((numberBenefs, benef) => {
    const newNumberBenef = numberBenefs;
    if (benef.birthdate) {
      const age = currentAge(benef.birthdate);
      if (age || (age === 0 && benef.birthdate.length === dateFormatLength)) {
        if (age < babyAge) {
          newNumberBenef.nbrBabies += 1;
        } else if (age < childAge) {
          newNumberBenef.nbrKids += 1;
        } else if (age < adultAge) {
          newNumberBenef.nbrAdults += 1;
        }
      }
    }
    return newNumberBenef;
  }, { nbrBabies: 0, nbrKids: 0, nbrAdults: 0 });

  const verifyBenef = (values) => {
    if (!values || !values.beneficiary) {
      return undefined;
    }

    const benefCategories = getNumberBeneficiaries(values.beneficiary);
    return tripInfos.numberOfAdults === benefCategories.nbrAdults
      && tripInfos.numberOfKids === benefCategories.nbrKids
      && tripInfos.numberOfBabies === benefCategories.nbrBabies;
  };

  const nbrBeneficiaries = parseInt(tripInfos.numberOfAdults, 10)
    + parseInt(tripInfos.numberOfKids, 10)
    + parseInt(tripInfos.numberOfBabies, 10);

  const builtUserBody = useMemo(() => {
    return travelerInfos && Object.entries(travelerInfos).length !== 0 ? travelerInfos : buildUserBody(user)
  }, [user]);

  return (
    <div className="step3">
      <div className="top-image">
        <img src={sourceForImg(quote)} alt="background-img" />
      </div>
      <Form
        initialValues={builtUserBody}
        onSubmit={(values) => {
          dispatch(storeTravelerInfosInStorage(values));
          dataLayer.showGTM(TAG_EVENT.CLIC_GO_TO_PAYMENT);
          if (verifyBenef(values) && values.user.acceptPolicy) {
            const quoteBody = formatQuoteBodyFromParams(formula, tripInfos, optionalCoverages, options);
            quoteBody.finalPrice = finalPrice;
            quoteBody.subscriber = {
              ...values.user,
              birthdate: moment(values.user.birthdate, DATE_FORMAT.FORMAT).format(DATE_FORMAT.LOCAL_FORMAT),
              country: values.user.country.value,
              receiveCommonCertificate: values.user.receiveCommonCertificate !== WORD.NO,
            };
            quoteApi.sendQuoteMail(quoteBody);
            handleNextStepClick(values.user.acceptOffers);
            dispatch(storeTravelerInfos(values));
          }
          if (!verifyBenef(values)) {
            setVerifyBenefShowError(true);
          }
          if (!values.user.acceptPolicy) {
            setShowPolicyNotAccepted(true);
          }
        }}
        render={({
                   handleSubmit, values, valid, form,
                 }) => {
          onFormLoaded(handleSubmit);
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              {site.isClientspaceActive
              && (
                <ConnexionItem form={form} getCountryByCode={getCountryByCode} parentUser={user} />
              )}
              <FormSpy
                subscription={{ values: true, dirty: true, valid: true }}
                onChange={(formSpyProps) => {
                  const differences = Object.keys(diff(values, formSpyProps.values));
                  if (values.user && values.user.isBeneficiary && differences[0] === 'user') {
                    form.change('beneficiary[0]', formSpyProps.values.user);
                  }
                  dispatch(storeValidTravelerInfos(
                    formSpyProps.valid
                    && formSpyProps.values.user
                    && formSpyProps.values.user.acceptPolicy
                    && verifyBenef(formSpyProps.values),
                  ));
                }}
              />
              <div className="step3-form">
                <div className="top-button-step3">
                  <div className="back">
                    <button type="button" onClick={handleGoBackClick}>
                      <i className="far fa-long-arrow-left" />
                      <h2>
                        {I18n.t('travelInsuranceForm.step2.goBack')}
                      </h2>
                    </button>
                  </div>
                </div>
                <div className="main-title">
                  <h1>{I18n.t('travelInsuranceForm.step3.title')}</h1>
                  <h3 className="no-bold">{I18n.t('travelInsuranceForm.step3.fieldsMandatory')}</h3>
                </div>
                <GenderInput form={form} values={values.user ? values.user : ''} formString="user" />
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.name')}
                  </h3>
                  <Field
                    name="user.name"
                    className="name-input"
                    type="text"
                    placeholder={I18n.t('travelInsuranceForm.step3.placeholder.name')}
                    component={CoreozInputBase}
                    validate={composeValidators(required, isName)}
                    parse={onlyText}
                  />
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.surname')}
                  </h3>
                  <Field
                    name="user.surname"
                    className="name-input"
                    type="text"
                    placeholder={I18n.t('travelInsuranceForm.step3.placeholder.surname')}
                    component={CoreozInputBase}
                    validate={composeValidators(required, isName)}
                    parse={onlyText}
                  />
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.birthDate')}
                  </h3>
                  <div>
                    <Field
                      name="user.birthdate"
                      type="tel"
                      className="name-input"
                      component={CoreozInputBase}
                      placeholder={I18n.t('travelInsuranceForm.step3.placeholder.birthdate')}
                      maxLength={10}
                      validate={composeValidators(required, validateBirthDate(true))}
                      parse={formatDate}
                    />
                  </div>
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.email')}
                  </h3>
                  <Field
                    name="user.email"
                    className="email-input"
                    type="email"
                    placeholder={I18n.t('travelInsuranceForm.step3.placeholder.email')}
                    component={CoreozInputBase}
                    validate={composeValidators(required, isEmail)}
                  />
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.emailConfirm')}
                  </h3>
                  <Field
                    type="email"
                    name="user.emailConfirmation"
                    component={CoreozInputBase}
                    validate={(value, allValues) => (
                      required(value) || isSameEmail(value, allValues.user.email)
                    )}
                  >
                    {({ input, meta }) => (
                      <div className="email-input">
                        <input
                          {...input}
                          className="input-field"
                          placeholder={I18n.t('travelInsuranceForm.step3.placeholder.emailConfirm')}
                          onPaste={(event) => event.preventDefault()}
                          autoComplete="none"
                        />
                        {meta.error && meta.touched && <span className="error-custom">{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="trick">
                  <div className="text">
                    <h2 className="titre">
                      {I18n.t('travelInsuranceForm.step3.info.title')}
                    </h2>
                    <h3 className="trick-amount">
                      {I18n.t('travelInsuranceForm.step3.info.content')}
                    </h3>
                    {site.isClientspaceActive
                    && (
                      <h3 className="trick-amount">
                        {I18n.t('travelInsuranceForm.step3.info.content2')}
                      </h3>
                    )}
                  </div>
                  <i className="icon-idea icon" />
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.phone')}
                  </h3>
                  <Field
                    name="user.phone"
                    component={PhoneInputComponent}
                    validate={validatePhone}
                  />
                </div>
                <AddressItem
                  form={form}
                  getCountryByCode={getCountryByCode}
                  loadedApiKey={loadedApiKey}
                  countryCode={tripInfos.countryOfResidence.code}
                />
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.postcode')}
                  </h3>
                  <Field
                    name="user.postcode"
                    className="postcode-input"
                    type="tel"
                    placeholder={I18n.t('travelInsuranceForm.step3.placeholder.postcode')}
                    component={CoreozInputBase}
                    validate={composeValidators(required, site.isZipcodeFrenchFormat ? isValidPostCodeFive : isValidPostCode)}
                    autoComplete="none"
                  />
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.city')}
                  </h3>
                  <Field
                    name="user.city"
                    className="name-input input_upercase"
                    type="text"
                    placeholder={I18n.t('travelInsuranceForm.step3.placeholder.city')}
                    component={CoreozInputBase}
                    validate={composeValidators(required, isName)}
                    autoComplete="none"
                  />
                </div>
                <div className="form-section">
                  <h3>
                    {I18n.t('travelInsuranceForm.step3.country')}
                  </h3>
                  <div className={site.defaultIsoCodeCountry ? 'swing-select swing-select-var' : 'swing-select'}>
                    <Field
                      name="user.country"
                      validate={required}
                      className="select"
                      type="select"
                    >
                      {({ input, meta }) => (
                        <SwingSelectCountry
                          input={input}
                          meta={meta}
                          classNamePrefix="swing-select"
                          placeholder={I18n.t('travelInsuranceForm.step3.placeholder.country')}
                          countries={getCountries()}
                          isClearable
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="beneficiaries-container">
                <h1 className="beneficiaries-title">
                  {I18n.t('travelInsuranceForm.step3.beneficiaries.title')}
                </h1>
                {createBeneficiaries(nbrBeneficiaries, values, form)}
              </div>
              <div className="option-container">
                {nbrBeneficiaries > GROUP.MIN && nbrBeneficiaries < GROUP.MAX
                && (
                  <div className="form-section">
                    <h2>
                      {I18n.t('travelInsuranceForm.step3.receive_doc.title')}
                    </h2>
                    <div className="receive-tick">
                      <Field
                        name="user.receiveCommonCertificate"
                        component="input"
                        type="radio"
                        id="receiveCommonCertificate"
                        className="radioButton"
                        value={WORD.YES}
                        validate={required}
                      />
                      <label htmlFor="receiveCommonCertificate">
                        <i
                          className={values.user && values.user.receiveCommonCertificate === WORD.YES ? 'fas fa-circle' : 'fal fa-circle'}
                        />
                        <h3>{I18n.t('travelInsuranceForm.step3.receive_doc.group')}</h3>
                      </label>
                      <Field
                        name="user.receiveCommonCertificate"
                        component="input"
                        type="radio"
                        id="receiveSeparateCertificate"
                        className="radioButton"
                        value={WORD.NO}
                      />
                      <label htmlFor="receiveSeparateCertificate">
                        <i
                          className={values.user && values.user.receiveCommonCertificate === WORD.NO ? 'fas fa-circle' : 'fal fa-circle'}
                        />
                        <h3>{I18n.t('travelInsuranceForm.step3.receive_doc.alone')}</h3>
                      </label>
                    </div>
                  </div>
                )}
                <div className="form-section">
                  <div className="tickbox">
                    <Field
                      name="user.acceptPolicy"
                      component="input"
                      type="checkbox"
                      id="acceptPolicy"
                      className="checkbox"
                    />
                    <label htmlFor="acceptPolicy" onClick={() => setShowPolicyNotAccepted(false)}>
                      <i
                        className={values.user && values.user.acceptPolicy ? 'fas fa-check-square' : 'fal fa-square'}
                      />
                      <h3>
                        {I18n.t('travelInsuranceForm.step3.accept_policy.full')}
                      </h3>
                    </label>
                  </div>
                  {showPolicyNotAccepted
                  && (
                    <div className="error-custom">
                      {I18n.t('errors.tick')}
                    </div>
                  )}
                </div>
                <div className="form-section">
                  <div className="tickbox">
                    <Field
                      name="user.acceptOffers"
                      component="input"
                      type="checkbox"
                      id="acceptOffers"
                      className="checkbox"
                    />
                    <label htmlFor="acceptOffers">
                      <i
                        className={values.user && values.user.acceptOffers ? 'fas fa-check-square' : 'fal fa-square'}
                      />
                      <h3>{I18n.t('travelInsuranceForm.step3.accept_offers', { cdo: site.name })}</h3>
                    </label>
                  </div>
                </div>
                <div className="buttons-footer">
                  <div className={valid ? 'submit' : 'submit disabled'}>
                    <button type="submit">
                      <h3>
                        {I18n.t('travelInsuranceForm.step3.submit')}
                      </h3>
                      <i className="far fa-long-arrow-right" />
                    </button>
                  </div>
                  <div className="back">
                    <button type="button" onClick={handleGoBackClick}>
                      <i className="far fa-long-arrow-left" />
                      <h3>
                        {I18n.t('travelInsuranceForm.step3.back')}
                      </h3>
                    </button>
                  </div>
                </div>
                {verifyBenefShowError && (
                  <div className="error-benef">
                    {I18n.t('validator.verifyBenef')}
                  </div>
                )}
              </div>
            </form>
          );
        }}
      />
      <FormFooter />
    </div>
  );
}

export default withRouter(connect((state) => ({
  tripInfos: state.storage.tripInfos,
  optionalCoverages: state.storage.optionalCoverages,
  options: state.storage.options,
  formula: state.storage.formula,
  travelerInfos: state.storage.travelerInfos,
  locale: state.i18n.locale,
  loadedApiKey: state.storage.loadedApiKey,
  quote: state.storage.quote,
  finalPrice: state.storage.finalPrice,
  countries: state.storage.countries,
  site: state.storage.site,
}))(Step3Container));
