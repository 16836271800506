// @flow

import React from 'react';
import { I18n, Localize, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import type { SellingInformation } from '../../types/contractTypes';
import userApi from '../../network/api/userApi';
import AsideNav from './AsideNav';
import ContractDetails from './ContractDetails';
import countryApi from '../../network/api/countryApi';
import { fetchFile, formatPriceClean } from '../../utils';
import { DASHBOARD_PAGE_ROUTE, STEP_ROOT_PAGE_ROUTE } from '../../const';
import Flag from '../../components/client/Flag';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';

type Props = {
  history: any,
};

type State = {
  sellingInformationBean: SellingInformation[],
  activeContract: ?SellingInformation,
  countries: ?string[],
  isMenuDisplayed: boolean,
}

class Contract extends React.Component<Props, State> {
  state = {
    sellingInformationBean: [],
    activeContract: null,
    countries: null,
    isMenuDisplayed: false,
  };

  componentDidMount() {
    userApi.fetchContract()
      .then((response) => response.json())
      .then(this.initializeContract);

    countryApi.get()
      .then((response) => response.json())
      .then(this.setCountryList);
  }

  setCountryList = (list) => {
    this.setState({
      countries: list,
    });
  };

  initializeContract = (value: SellingInformation[]) => {
    // $FlowFixMe
    const sortedContract = value.sort((a, b) => moment(b.creationDate) - moment(a.creationDate));
    this.setState({
      sellingInformationBean: sortedContract,
      activeContract: sortedContract[0],
    });
  };

  selectContract = (contract: SellingInformation) => {
    this.setState({
      activeContract: contract,
      isMenuDisplayed: false,
    });
  };

  openContract = () => {
    if (this.state.activeContract) {
      fetchFile(this.state.activeContract.contractUrl, I18n.t('spaceClient.contract.contractName'));
      dataLayer.showGTM(TAG_EVENT.CONTRACT_DOWNLOAD);
    }
  };

  render() {
    return (
      <div className="space-client-container__connected">
        <AsideNav />
        <Flag />
        <div className="main-page contract">
          <div className="contract-left">
            <div className="title">
              <i
                onClick={() => this.props.history.push(DASHBOARD_PAGE_ROUTE)}
                className="fal fa-arrow-left"
              />
              <i
                onClick={() => this.setState({ isMenuDisplayed: !this.state.isMenuDisplayed })}
                className="fal fa-bars"
              />
              <h1><Translate value="spaceClient.contract.title" /></h1>
              <i
                onClick={() => this.state.activeContract && this.openContract()}
                className="download-icon fal fa-arrow-to-bottom"
              />
            </div>
            <div className={`contractList ${this.state && this.state.isMenuDisplayed ? 'displayed' : 'hidden'}`}>
              {
                this.state && this.state.sellingInformationBean.map(contract => (
                  <div
                    key={contract.sellingId}
                    className={`contract-item ${this.state.activeContract && contract.sellingId === this.state.activeContract.sellingId ? 'active' : ''}`}
                    onClick={() => this.selectContract(contract)}
                  >
                    <div className="contract-title">
                      <p>{contract.offerIntermediateBean.name}</p>
                      <p>
                        <Translate
                          value="spaceClient.contract.currency"
                          price={formatPriceClean(contract.pricePaid)}
                        />
                      </p>
                    </div>
                    <div className="contract-date">
                      <Localize value={contract.effectiveDate} dateFormat="date.DATE_FORMAT" />
                      <i className="fal fa-arrow-right" />
                      <Localize value={contract.endEffectiveDate} dateFormat="date.DATE_FORMAT" />
                    </div>
                  </div>
                ))
              }
            </div>
            <div className={`btn-container ${this.state && this.state.isMenuDisplayed ? 'displayed' : 'hidden'}`}>
              <Translate value="spaceClient.contract.catchPhrase" />
              <div className="btn-devis" onClick={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_NOUVEAU_DEVIS);
                window.open(`${STEP_ROOT_PAGE_ROUTE}/1`);
              }}>
                <Translate value="spaceClient.contract.btnLabel" />
              </div>
            </div>
          </div>
          <div className="contract-right">
            {this.state && this.state.activeContract && this.state.countries &&
            <ContractDetails
              contract={this.state.activeContract}
              countries={this.state.countries}
              openContract={this.openContract}
              attestationUrls={this.state.activeContract.attestationUrls}
            />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Contract);
