// @flow

import { RestClientAuthenticated } from '../network';

const logApi = {
  fetchAllWithFilters: (
    limit: number,
    method: string,
    statusCode: number,
    apiName: string,
    url: string,
    startDate: string,
    endDate: string
  ) =>
    new RestClientAuthenticated('/logs', 'GET')
      .addParam("limit", limit)
      .addParam("method", method)
      .addParam("statusCode", statusCode)
      .addParam("apiName", apiName)
      .addParam("url", url)
      .addParam("startDate", startDate)
      .addParam("endDate", endDate)
      .execute(),
  fetchById: (id: string) =>
    new RestClientAuthenticated(`/logs/${id}`, 'GET').execute(),
  fetchFullBody: (id: string, isRequest: boolean) =>
    new RestClientAuthenticated(`/logs/${id}/${isRequest}`, 'GET').execute(),
};

export default logApi;
