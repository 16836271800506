// @flow

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import StepsFooter from '../../components/steps/StepsFooter';
import {
  CONTACT_PAGE_ROUTE,
  CONTRACT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE, FIRST_CONNECTION_CONFIRMATION_PAGE_ROUTE,
  FIRST_CONNECTION_PAGE_ROUTE,
  FORGOTTEN_CONFIRMATION_PAGE_ROUTE,
  FORGOTTEN_PAGE_ROUTE, LINK_TYPE,
  LOGIN_PAGE_ROUTE, MODIFY_CONFIRMATION_PAGE_ROUTE,
  MODIFY_PAGE_ROUTE, PROFIL_PAGE_ROUTE, REACTIVATE_CONNECTION_PAGE_ROUTE, REACTIVATE_PAGE_ROUTE,
} from '../../const';
import LoginModule from '../../components/client/LoginModule';
import PrivateRoute from '../../components/client/PrivateRoute';
import Dashboard from './Dashboard';
import Contract from './Contract';
import Profil from './Profil';
import Contact from './Contact';
import { HOME_PAGE_ROUTE } from 'coreact-script-front/template/src/const';

type Props = {
  isClientSpaceActive: boolean,
  history: any,
};

class Login extends React.PureComponent<Props> {
  render() {
    if (!this.props.isClientSpaceActive) {
      this.props.history.push(HOME_PAGE_ROUTE);
    }
    return (
      <div className="space-client-container">
        <Switch>
          <Route path={LOGIN_PAGE_ROUTE} component={LoginModule} />
          <Route
            path={FORGOTTEN_PAGE_ROUTE}
            component={LoginModule}
          />
          <PrivateRoute
            path={DASHBOARD_PAGE_ROUTE}
            component={Dashboard}
            permissions={LINK_TYPE.ACTIVE}
          />
          <PrivateRoute
            path={CONTRACT_PAGE_ROUTE}
            component={Contract}
            permissions={LINK_TYPE.ACTIVE}
          />
          <PrivateRoute
            path={PROFIL_PAGE_ROUTE}
            component={Profil}
            permissions={LINK_TYPE.ACTIVE}
          />
          <PrivateRoute
            path={CONTACT_PAGE_ROUTE}
            component={Contact}
            permissions={LINK_TYPE.ACTIVE}
          />
          <Route path={FORGOTTEN_CONFIRMATION_PAGE_ROUTE} component={LoginModule} />
          <Route path={MODIFY_PAGE_ROUTE} component={LoginModule} />
          <Route path={FIRST_CONNECTION_PAGE_ROUTE} component={LoginModule} />
          <Route path={REACTIVATE_CONNECTION_PAGE_ROUTE} component={LoginModule} />
          <Route path={FIRST_CONNECTION_CONFIRMATION_PAGE_ROUTE} component={LoginModule} />
          <Route path={MODIFY_CONFIRMATION_PAGE_ROUTE} component={LoginModule} />
          <Route path={REACTIVATE_PAGE_ROUTE} component={LoginModule} />
          <Route path="*" render={() => <Redirect to={LOGIN_PAGE_ROUTE} />} />
        </Switch>
        <StepsFooter />
      </div>
    );
  }
}

export default withRouter(Login);
