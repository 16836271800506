// @flow

import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { GENDER, STEP_ROOT_PAGE_ROUTE } from '../../const';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import type { Beneficiary } from '../../types/storage';
import type { ContextRouter } from 'react-router-dom';

type Props = {
  ...ContextRouter,
  beneficiary: Beneficiary,
  user: Beneficiary;
  index: number
}

function BeneficiaryRecap(props: Props) {
  const handleUpdateClick = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_EDIT_BENEFICIARY);
    props.history.push(`${STEP_ROOT_PAGE_ROUTE}/3`);
  };
  const genderTranslate =  'travelInsuranceForm.step4.beneficiaryRecap.'.concat(props.beneficiary.gender === GENDER.MALE ? 'mr' : 'mme');
  return (
    <div className="beneficiary-recap">
      <div className="beneficiary-recap-header">
        <p className="title"><Translate value="travelInsuranceForm.step4.beneficiaryRecap.title" number={props.index} /></p>
        <p className="update" onClick={handleUpdateClick}><Translate value="information.update" /></p>
      </div>
      <div className="data">
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data">
            {`${I18n.t(genderTranslate)} ${props.beneficiary.surname} ${props.beneficiary.name}`}
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data">
            {props.beneficiary.birthdate}
          </p>
        </div>
        {props.beneficiary.email &&
        <div className="data-item">
          <i className="fas fa-diamond"/>
          <p className="beneficiary-data">{props.beneficiary.email}</p>
        </div>
        }
        <div className="data-item">
          <i className="fas fa-diamond"/>
          <p className="beneficiary-data">
            {props.beneficiary.address ?
              `${props.beneficiary.address}, ${props.beneficiary.postcode}, ${props.beneficiary.city}, ${props.beneficiary.country.label}` :
              `${props.user.address}, ${props.user.postcode}, ${props.user.city}, ${props.user.country.label}`
            }
          </p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(BeneficiaryRecap);
