// @flow

import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { dateFormatLength, maxYear, minYear } from './date';
import { formatPrice } from '../utils';
import { DATE_FORMAT } from '../const';

export type BirthDay = {
  birthday: string,
  birthmonth: string,
  birthyear: string,
}

export type RangeDate = {
  startDate: string,
  endDate: string,
}

export const composeValidators = (...validators: Function) => (value: string) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value: Object) => (value ? undefined : I18n.t('validator.mandatory'));

export const isSameEmail = (mail: string, confirmEmail: string) => {
  return mail === confirmEmail ? undefined : I18n.t('validator.emailConfirm')
};

export const requiredDate = (value: BirthDay) => {
  return !value || (value.birthday === '' || value.birthmonth === '' || value.birthyear === '') ?
    I18n.t('validator.mandatory') : undefined;
};

export const requiredNumber = (value: number) => (value || value === 0 ? undefined : I18n.t('validator.mandatory'));

export const minMaxValue = (min: number, max: number) => (value: string) =>
  ((parseInt(value, 10) < max && parseInt(value, 10) >= min) ? undefined : I18n.t('validator.minMax', {
    min: formatPrice(min),
    max: formatPrice(max)
  }));

export const minMaxNbr = (min: number, max: number) => (value: string) =>
  (isNaN(value) || (parseInt(value) <= max && parseInt(value) >= min) ? undefined : I18n.t('validator.invalid'));

export const numSize = (size: number) => (value: string) =>
  (isNaN(value) || value.length >= size ? undefined : I18n.t('validator.numSize', { size: size }));

export const hasValue = (v: string) => (value: string) => {
  return (v ? undefined : I18n.t('validator.mandatory'))
};

export const isTrue = (v: boolean) => (value: boolean) => {
  return (v ? undefined : I18n.t('validator.tick'))
};

export const isName = (value: string) => !value || lettersRegExp.test(value) ? undefined : I18n.t('validator.invalid');

export const isNum = (value: string) => !isNaN(value) && dateRegExp.test(value) ? undefined : I18n.t('validator.invalid');

export const isEmail = (value: string) => !value || emailRegExp.test(value) ? undefined : I18n.t('validator.emailFormat');

export const isPostcode = (value: string) => !value || postCodeRegExp.test(value) ? undefined : I18n.t('validator.postcodeFormat');

export const isValidPostCode = (value: string) => !value || value.length <= 10 ? undefined : I18n.t('validator.postcodeLength');

export const isValidPostCodeFive = (value: string) => !value || (value.length === 5 && /^\d+$/.test(value))? undefined : I18n.t('validator.postcodeLengthFive');

export function validatePhone(value: ?string) {
  if (value && ((value.match(/^\+(?:33\s?[1-9](?:[\s.-]?\d){8}$)|(?:\+(?!33)[1-9]{2}\s?[1-9](?:[\s.-]?\d){4,11})$/)))) {
    return undefined;
  }
  if (value && value.length <= 3) {
    return I18n.t('validator.mandatory')
  }
  if (value && value.match(/^\+[1-9]{2}0/)) {
    return I18n.t('validator.phoneFormatNoZero');
  }
  return I18n.t('validator.phoneFormat');
}

const lettersRegExp = RegExp('^[A-Za-zÀ-ÖØ-öø-ÿ]+(([\',. -][A-Za-zÀ-ÖØ-öø-ÿ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$');

const dateRegExp = RegExp('^(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[012])\/(?:(?:19|20)\d\d)$');

const emailRegExp = RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

const postCodeRegExp = RegExp('^[0-9]{5}$');

export const validateDay = (values: BirthDay, needMajor: boolean) => (value: string) => {
  if (!value) {
    return I18n.t('validator.mandatory')
  }
  if (parseInt(value) < 0 || parseInt(value) > 31) {
    return I18n.t('validator.dateFormat');
  }
  return validateGeneralBirthDate({ ...values, birthday: value }, needMajor);
};

export const validateMonth = (values: BirthDay, needMajor: boolean) => (value: string) => {
  if (!value) {
    return I18n.t('validator.mandatory')
  }
  if (parseInt(value) < 0 || parseInt(value) > 12) {
    return I18n.t('validator.dateFormat');
  }
  return validateGeneralBirthDate({ ...values, birthmonth: value }, needMajor);
};

export const validateYear = (values: BirthDay, needMajor: boolean) => (value: string) => {
  if (!value) {
    return I18n.t('validator.mandatory')
  }
  if (value.length < 4) {
    return I18n.t('validator.dateFormat');
  }
  if (parseInt(value) <= minYear) {
    return I18n.t('validator.dateMini', { min: minYear });
  }
  if (parseInt(value) > maxYear) {
    return I18n.t('validator.dateLimit');
  }
  return validateGeneralBirthDate({ ...values, birthyear: value }, needMajor);
};

export const validateGeneralBirthDate = (values: BirthDay, needMajor: boolean) => {
  if (!values || !values.birthday || !values.birthmonth || !values.birthyear) {
    return undefined;
  }
  const date = momentFromFragmentDate(values);
  if (date) {
    if (!date.isValid()) {
      return I18n.t('validator.dateFormat');
    }
    if (date.isAfter(moment())) {
      return I18n.t('validator.dateLimit');
    }
    if (needMajor && date.isAfter(majorMoment())) {
      return I18n.t('validator.dateLimitMajor');
    }
  }
  return undefined;
};

export const validateBirthDate = (needMajor: boolean) => (value: string) => {
  const date = moment(value, DATE_FORMAT.FORMAT);
  if (date) {
    if (!date.isValid()) {
      return I18n.t('validator.dateFormat');
    }
    if (value.length < dateFormatLength) {
      return I18n.t('validator.dateFormat');
    }
    if (date.year() < minYear + 1) {
      return I18n.t('validator.dateMini', { min: minYear });
    }
    if (date.isAfter(moment())) {
      return I18n.t('validator.dateLimit');
    }
    if (needMajor && date.isAfter(majorMoment())) {
      return I18n.t('validator.dateLimitMajor');
    }
  }
  return undefined;
};

const momentFromFragmentDate = (values: BirthDay) => {
  if (values && values.birthday && values.birthmonth && values.birthyear) {
    return moment(values.birthyear + '-' + values.birthmonth + '-' + values.birthday);
  }
  return undefined;
};

const majorMoment = () => {
  return moment().subtract(18, 'years');
};

export const validDate = (value: RangeDate) => {
  if (value && value.startDate && value.endDate) {
    if (moment(value.endDate).diff(moment(), 'days') < 0) {
      return I18n.t('travelInsuranceForm.step1.travelDate.errorEnd');
    }
    if (moment(value.endDate, I18n.t('date.DATE_FORMAT')).diff(moment(value.startDate, I18n.t('date.DATE_FORMAT')), 'days') > 365) {
      return I18n.t('travelInsuranceForm.step1.travelDate.errorYear');
    }
  }
  return undefined;
};

export const validBuyingDate = (value) => {
  if (!value){
    return undefined;
  }
  return

}