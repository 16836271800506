// @flow

import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { formatPrice } from '../../../services/formator';
import { calculateDiscount } from '../../../utils';
import type {
  Coverage,
  CoverageWithCategories,
  Formula,
  OfferStatus,
  Quote,
} from '../types/steps';
import { FORMULA_CODES, STATUS } from '../../../services/coverages';
import UnfoldableDesktopCoverage from './UnfoldableDesktopCoverage';
import type { Site } from '../../../types/storage';

type Props = {
  quote: Quote,
  formula: Formula,
  chosenFormula: Formula,
  getFormulaTitleByFormulaName: Function,
  calculateAmountPerDay: Function,
  setOptionToSelect: Function,
  totalPrice: number,
  handleChoseFormula: Function,
  site: Site,
}

const isSafari = (/constructor/i.test(window.HTMLElement)) || ((p) => {
  return p.toString() === '[object SafariRemoteNotification]';
})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

function DesktopFormulaTable(
  {
    quote,
    formula,
    chosenFormula,
    getFormulaTitleByFormulaName,
    calculateAmountPerDay,
    setOptionToSelect,
    totalPrice,
    handleChoseFormula,
    site,
  }: Props,
): React$Node {

  const [hiddenCategories, setHiddenCategories] = useState([]);

  const coverageRow = (coverage: Coverage, isSub: boolean) => {
    return (
      <td className="formula-item" >
        <div className={`text-item${isSub ? ' sub' : ''}`}>
          <i className="fal fa-info-circle">
            {
              coverage.description
              && (
                <div className="tooltiptext">
                  <h3>
                    {coverage.description}
                  </h3>
                </div>
              )
            }
          </i>
          <h3 className="formula-item-text">{coverage.name}</h3>
        </div>
        {
          coverage.twinsCoverages &&
          coverage.twinsCoverages.map((twinCoverage) => (
            <div className={`text-item${isSub ? ' sub' : ''}`} key={twinCoverage.code}>
              <i className="fal fa-info-circle">
                {
                  twinCoverage.description
                  && (
                    <div className="tooltiptext">
                      <h3>{twinCoverage.description}</h3>
                    </div>
                  )
                }
              </i>
              <h3 className="formula-item-text">{twinCoverage.name}</h3>
            </div>
          ))
        }
      </td>
    );
  };

  const addCoverageRow = (
    title: string,
    coverage: Coverage,
    sub,
  ) => {
    const getStyle = () => {
      if (hiddenCategories.includes(title)) {
        return 'hidden';
      }
      return null;
    };

    if (coverage.linkedCoverages && (coverage.linkedCoverages.length > 0)) {
      return (
        <UnfoldableDesktopCoverage
          key={title + coverage.code}
          coverage={coverage}
          addCoverageRow={(titleItem, coverageItem) => addCoverageRow(titleItem, coverageItem, true)}
          chosenFormulaCode={chosenFormula ? chosenFormula.code : null}
          isHidden={hiddenCategories.includes(title)}
        />
      );
    }
    return ([
        <tr
          key={coverage.code}
          className={getStyle()}
        >
          {coverageRow(coverage, sub)}
          {(coverage.offerStatus).map((offer: OfferStatus) => {
            switch (offer.status) {
              case STATUS.TRUE:
                return (
                  <td
                    className={
                      chosenFormula
                      && chosenFormula.code === offer.code
                      && (
                        // check if coverage is the last element of tables
                        (coverage === Object.values(quote.coverageWithCategories).pop().slice(-1).pop() && 'border-side-bottom')
                        || 'border-side'
                      ) || 'normal'
                    }
                    key={offer.name}
                  >
                    <i className="fa fa-check" />
                    <h4>
                      {coverage.shortDescription}
                    </h4>
                    {
                      coverage.twinsCoverages &&
                      coverage.twinsCoverages.map((twinCoverage) => (
                        <h4 key={twinCoverage.code}>{twinCoverage.shortDescription}</h4>
                      ))
                    }
                  </td>
                );
              case STATUS.FALSE:
                return (
                  <td
                    className={chosenFormula && chosenFormula.code === offer.code ? 'border-side' : ''}
                    key={offer.name}>
                    <i className="fa fa-times" />
                  </td>
                );
              case STATUS.OPTIONAL:
                return (
                  <td
                    className={`check-td ${chosenFormula && chosenFormula.code === offer.code ? 'border-side' : ''}`}
                    key={offer.name}>
                    <h4>{I18n.t('travelInsuranceForm.step2.choseOffer.optional')}</h4>
                  </td>
                );
              default:
            }
          })}
        </tr>,
      ]
    );
  };

  const addCategoryRow = (
    index,
    title,
  ) => {
    return (
      <tr
        className="table-section"
        key={index}
      >
        <td
          role="presentation"
          className="category"
          onClick={() => handleClickCategory(
            title,
          )}
        >
          <h3 className="category-name">{title}</h3>
          <i
            className={
              hiddenCategories.includes(title)
                ? 'fas fa-chevron-right chevron'
                : 'fas fa-chevron-down chevron'
            }
          />
        </td>
        {
          quote.offers.map((offer: Coverage) => (
            <td
              className={chosenFormula
              && chosenFormula.code === offer.code
                ? (
                  (index === 3 && hiddenCategories.includes(title))
                    ? 'border-side-bottom'
                    : 'border-side'
                ) : ''}
              key={offer.code}
            />
          ))
        }
      </tr>
    );
  };

  const addFirstRow = (numberColumns: number, quoteParam: Quote) => {
    return (
      <thead>
        {
          quoteParam.offers.filter((offer) => offer.code === FORMULA_CODES.CONFORT).length > 0 && (
            <tr>
              {[...Array(numberColumns).keys()].map((i) => <td className="top-empty" key={i} />)}
              <th className="complete-formula">
                <div className="complete-formula-content">
                  <i className="fas fa-star" />
                  <h6>{I18n.t('travelInsuranceForm.step2.choseOffer.completeFormula')}</h6>
                </div>
              </th>
            </tr>
          )
        }
      </thead>
    );
  };

  const handleClickCategory = (title) => {
    if (hiddenCategories.includes(title)) {
      const filteredResult = hiddenCategories.filter(
        (category) => category !== title,
      );
      setHiddenCategories(filteredResult);
    } else {
      setHiddenCategories([...hiddenCategories, title]);
    }
  };

  const getColumnNumber = () => quote ? quote.offers.length : 0;

  const getCurrentPrice = (offer: Coverage) => formula && formula.name === offer.name ? totalPrice - calculateDiscount() : offer.totalAmount;

  const getProperAmount = (offer: Coverage): string => {
    return formatPrice(getCurrentPrice(offer))
  };

  const getProperAmountPerDay = (offer: Coverage): string => {
    return formatPrice(formula && formula.name === offer.name ? calculateAmountPerDay() : offer.totalAmountPerDay)
  };

  const getProperCurrencyRateAmount = (offer: Coverage): string => {
    return formatPrice(Math.ceil(getCurrentPrice(offer) * site.currencyRate), site.currencyLabel);
  };

  return (
    <div className="table">
      <table>
        {addFirstRow(getColumnNumber(), quote)}
        <thead>
          <tr className={`general-header ${isSafari ? 'safari' : ''}`}>
            <th />
            {quote.offers.map((offer: Coverage) => (
              <th
                className={`table-header ${chosenFormula && chosenFormula.code === offer.code ? 'border' : ''}`}
                key={offer.code}
              >
                <div className="formula-header">
                  <h3 className="formula-name">
                    {getFormulaTitleByFormulaName(offer.name)}
                  </h3>
                  <h1 className="formula-price">
                    {I18n.t(
                      'travelInsuranceForm.step2.choseOffer.totalAmount',
                      { amount: getProperAmount(offer) },
                    )}
                  </h1>
                  {site.currencyLabel && site.currencyRate
                    ? (
                      <h4 className="formula-price-details custom-currency">
                        {I18n.t(
                          'travelInsuranceForm.step2.choseOffer.amountWithCurrencyRate',
                          { amount: getProperCurrencyRateAmount(offer) })}
                      </h4>
                    )
                    : (
                      <h4 className="formula-price-details">
                        {I18n.t(
                          'travelInsuranceForm.step2.choseOffer.amountPerDay',
                          { amount: getProperAmountPerDay(offer) })}
                      </h4>
                    )
                  }
                </div>
                <button
                  aria-label=" "
                  type="button"
                  className={chosenFormula && chosenFormula.code === offer.code ? 'selected-formula' : 'chose-button'}
                  onClick={() => handleChoseFormula(offer)}
                >
                  {
                    chosenFormula && chosenFormula.code === offer.code
                      ? (
                        <div>
                          <i className="fa fa-check" />
                          <h3>
                            {I18n.t('travelInsuranceForm.step2.choseOffer.selected')}
                          </h3>
                        </div>
                      )
                      : (
                        <h3>
                          {I18n.t('travelInsuranceForm.step2.choseOffer.select')}
                        </h3>
                      )
                  }
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(quote.coverageWithCategories).map((category: CoverageWithCategories) => {
            if (category[0] === '0') {
              setOptionToSelect(category);
              return;
            }
            return [
              addCategoryRow(
                parseInt(category[0]),
                quote.categoryWithKeys[category[0]],
              ),
              category[1].sort((current, next) => current.order - next.order).map((coverage: Coverage) => (
                addCoverageRow(
                  quote.categoryWithKeys[category[0]],
                  coverage,
                  false,
                )
              )),
            ];
          })}
        </tbody>
      </table>
    </div>
  );
}


export default connect((state) => ({
  site: state.storage.site,
}))(DesktopFormulaTable);
