// @flow

import React, { useEffect, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';
import moment from 'moment';

import { isMobileDimensions } from '../../../services/dimensions';
import { CODES, FORMULA_CODES, FORMULA_NAMES, STATUS } from '../../../services/coverages';
import { MOBILE_DIMENSIONS, MODAL_TYPES, STEP_ROOT_PAGE_ROUTE } from '../../../const';

import FormFooter from '../../../components/steps/footer/FormFooter';
import {
  clearTravelerInfos,
  storeDiscount,
  storeFormula,
  storeFormulaInStorage,
  storeMaxPage,
  storeStartContract,
  storeTripInfos,
} from '../../../state/storage/storageService';
import type {
  Coverage,
  Formula,
  OptionalCoverage,
  Quote,
} from '../../../components/steps/types/steps';
import Sumup from '../../../components/informations/Sumup';
import type { TripInfos } from '../../../types/storage';
import Warranty from './Warranty';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { calculateAmountPerDay, sourceForImg, transformLastSeparator } from '../../../utils';
import { gtagService } from '../../../services/gtagService';
import CreditCardRelayPopIn from '../../../components/steps/step2/CreditCardRelayPopIn';
import PremiumCreditCardPopIn from '../../../components/steps/step2/PremiumCreditCardPopIn';
import FormulaTable from '../../../components/steps/step2/FormulaTable';
import OptionStep2 from './OptionStep2';
import { formatPrice } from '../../../services/formator';

type Props = {
  match: Match,
  history: RouterHistory,
  quote: Quote,
  dispatch: Function,
  formula: Formula,
  optionalCoverages: OptionalCoverage[],
  options: OptionalCoverage[],
  offerCode: string,
  startContract: string,
  discount: number,
  totalPrice: number,
  finalPrice: number,
  tripInfos: TripInfos,
}

function FullFormStep2(props: Props) {
  const {
    match,
    history,
    quote,
    dispatch,
    formula,
    optionalCoverages,
    options,
    offerCode,
    startContract,
    discount,
    totalPrice,
    tripInfos,
    finalPrice,
  } = props;

  const ref = useRef(null);

  const step = parseInt(match.params.number, 10);

  const hasCoverage = Object.values(quote.coverageWithCategories).length > 0;

  const [chosenFormula, setChosenFormula] = useState(formula);

  const [selectedOptions, setSelectedOptions] = useState<OptionalCoverage[]>(options || []);
  const [preSelectedOption, setPreSelectedOption] = useState({});
  const [optionsToSelect, setOptionsToSelect] = useState<OptionalCoverage>({});
  const [selectedOptionalCoverages, setSelectedOptionalCoverages] = useState<OptionalCoverage[]>(optionalCoverages || []);
  const [modalToShow, setModalToShow] = useState('');
  const [modalType, setModalType] = useState('');
  const [scrolledStep2, setScrolledStep2] = useState(false);

  // To be updated if new option are added !
  const modalProps = {
    [CODES.SPORT_OPTION]: {
      img: 'fal fa-running',
    },
    [CODES.SPORT_EXCLUSIONS]: {
      text: '',
      title: '',
    },
    [CODES.RELAY_BK_OPTION]: {
      text: '',
      title: '',
      img: 'far fa-thumbs-up',
      tagEvent: 'CLIC_OPTION_CB_PREMIUM',
    },
    [CODES.PREMIUM_BK_OPTION]: {
      text: '',
      title: '',
      img: 'far fa-thumbs-up',
      tagEvent: 'CLIC_OPTION_CB_RELAIS',
    },
  };

  const mobileMode = window.innerWidth < MOBILE_DIMENSIONS.MAX_WIDTH;

  // Navigation
  const handleGoBackClick = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_RETOUR);
    if (props.offerCode) {
      props.history.push(STEP_ROOT_PAGE_ROUTE + '/1?offerCode=' + props.offerCode);
    } else {
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  };

  const handleNextStepClick = () => {
    document.removeEventListener('scroll', updateScrolledStep2, true);
    dataLayer.showGTM(TAG_EVENT.CLIC_SOUSCRIPTION);
    gtagService.sendOptionsSelected(selectedOptions);
    gtagService.sendBeginCheckout(chosenFormula, optionalCoverages, options, tripInfos.promoCode);
    dispatch(storeMaxPage(parseInt(match.params.number, 10) + 1));
    dispatch(clearTravelerInfos());
    dispatch(storeTripInfos(tripInfos, true));
    dispatch(storeStartContract(startContract, true));
    dispatch(storeFormulaInStorage(chosenFormula, selectedOptions, selectedOptionalCoverages));
    dispatch(storeDiscount(discount, true));
    history.push(`${STEP_ROOT_PAGE_ROUTE}/3`);
  };

  const mobileDisplay = isMobileDimensions();

  const calculatePriceAmountPerDay = () => {
    const range = moment(tripInfos.travelDateRange.endDate).diff(moment(tripInfos.travelDateRange.startDate), 'days') + 1;
    return calculateAmountPerDay(range, totalPrice, discount, finalPrice);
  };

  const handleChoseFormula = (formulaToChose: Formula) => {
    dataLayer.updateFilAriane(2.2);
    dataLayer.updateFilAriane(2.3);
    gtagService.sendSelectedAllItems(formulaToChose, selectedOptionalCoverages, selectedOptions);
    gtagService.sendAddAllToCart(formulaToChose, selectedOptionalCoverages, selectedOptions);
    if (chosenFormula && chosenFormula.code !== formulaToChose.code) {
      gtagService.sendRemoveAllFromCart(chosenFormula, optionalCoverages, options);
    }
    if (formulaToChose.code === FORMULA_CODES.ZEN) {
      dataLayer.showGTM(TAG_EVENT.CLIC_ZEN);
    }
    if (formulaToChose.code === FORMULA_CODES.CONFORT) {
      dataLayer.showGTM(TAG_EVENT.CLIC_CONFORT);
    }
    setChosenFormula(formulaToChose);
    // If content is not rendered, ref.current is null
    if (ref.current !== null) {
      window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
    }
  };

  const handleCheckOption = (option: Coverage) => {
    if (!chosenFormula) {
      return;
    }
    if (
      selectedOptions.filter((optionItem) => optionItem.offerCode === formula.code
        && optionItem.coverage.code === option.code).length > 0
    ) {
      gtagService.sendRemoveFromCart(option, formula, true);
    } else if (option.category === '0' && option.isUnique) {
      gtagService.sendSelectedItem(option, formula, true);
      gtagService.sendAddToCart(option, formula, true);
    }
    if (option.category === '0' && !option.isUnique
      && !selectedOptions.filter((selectedOption) => selectedOption.offerCode === chosenFormula.code)
        .map((selectedOption) => selectedOption.coverage.code).includes(option.code)) {
      // affichage du pop up de confirmation
      setModalToShow(option.code);
      gtagService.sendViewItem(option, formula, true);
      dataLayer.showGTM(TAG_EVENT[modalProps[option.code].tagEvent]);
      setPreSelectedOption({
        coverage: option,
        offerName: chosenFormula.name,
        offerCode: chosenFormula.code,
      });
    } else if (isComfortAndUniqueAndSpecialCategory(option)) {
      // formule confort : comportement de radio bouton entre les formules sport et complément cb
      if (selectedOptions.filter((selectedOption) => selectedOption.offerCode === chosenFormula.code)
        .map((selectedOption) => selectedOption.coverage.code).includes(option.code)) {
        const filteredOptions = selectedOptions.filter((selectedOption) => (
            selectedOption.coverage.code !== option.code
            || selectedOption.offerCode !== chosenFormula.code
          ),
        );
        setSelectedOptions(filteredOptions);
      } else {
        const filteredOptions = selectedOptions.filter(
          (selectedOption) => (
            (selectedOption.coverage.category === '0' && selectedOption.coverage.isUnique)
            || selectedOption.offerCode !== chosenFormula.code
          ),
        );
        setSelectedOptions([...filteredOptions, {
          coverage: option,
          offerName: chosenFormula.name,
          offerCode: chosenFormula.code,
        }]);
      }
    } else if (
      selectedOptions.filter((selectedOption) => selectedOption.offerCode === chosenFormula.code)
        .map((selectedOption) => selectedOption.coverage.code).includes(option.code)
    ) {
      const filteredOptions = selectedOptions.filter((selectedOption) =>
        (selectedOption.coverage.code !== option.code
          || selectedOption.offerCode !== chosenFormula.code),
      );
      setSelectedOptions(filteredOptions);
    } else {
      setSelectedOptions([...selectedOptions,
        {
          coverage: option,
          offerName: chosenFormula.name,
          offerCode: chosenFormula.code,
        }]);
    }
    setModalType(MODAL_TYPES.VALIDATION);
  };

  useEffect(() => {
    dataLayer.updateFilAriane(2);
    gtagService.sendViewItemList(quote);
    if (offerCode && !formula) {
      handleChoseFormula(quote.offers.find((offer) => offer.code === offerCode));
    }
    if (!formula && quote.offers.length === 1) {
      handleChoseFormula(quote.offers[0]);
    }
  }, []);

  useEffect(() => {
    if (chosenFormula) {
      dispatch(storeFormula(chosenFormula, selectedOptions, selectedOptionalCoverages));
    }
  }, [chosenFormula]);

  useEffect(() => {
    if (chosenFormula) {
      if (selectedOptions !== options) {
        dispatch(storeFormula(chosenFormula, selectedOptions, selectedOptionalCoverages));
      }
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (chosenFormula) {
      if (selectedOptions !== options) {
        setSelectedOptions(options);
        dispatch(storeFormula(chosenFormula, options, selectedOptionalCoverages));
      }
    }
  }, [options]);

  useEffect(() => {
    if (chosenFormula) {
      if (selectedOptionalCoverages !== optionalCoverages) {
        dispatch(storeFormula(chosenFormula, selectedOptions, selectedOptionalCoverages));
      }
    }
  }, [selectedOptionalCoverages]);

  useEffect(() => {
    document.addEventListener('scroll', updateScrolledStep2, true);
  }, []);

  useEffect(() => {
    if (chosenFormula) {
      if (selectedOptionalCoverages !== optionalCoverages) {
        setSelectedOptionalCoverages(optionalCoverages);
        dispatch(storeFormula(chosenFormula, selectedOptions, optionalCoverages));
      }
    }
  }, [optionalCoverages]);

  const updateScrolledStep2 = () => {
    if (ref && ref.current && ref.current.getBoundingClientRect() && ref.current.getBoundingClientRect().top <= 0) {
      setScrolledStep2(true);
    } else {
      setScrolledStep2(false);
    }
  };

  const addOptionIcon = (optionCode: string) =>
    <i className={`${modalProps[optionCode].img}`} />;

  const isMobileMode = isMobileDimensions(step);

  const isComfortAndUniqueAndSpecialCategory = (option) => (
    (chosenFormula.code === FORMULA_CODES.CONFORT)
    && (option.category === '0' && option.isUnique)
  );

  const getOfferStatusFromChosenFormula = (option: Coverage) => (option.offerStatus.find((offer) =>
    (offer.code === chosenFormula.code)));

  const isOptionInFormulaOrOptional = (option: Coverage) =>
    getOfferStatusFromChosenFormula(option).status !== STATUS.FALSE;

  return (
    <div className="step2">
      <div className="top-image">
        <img src={sourceForImg(quote)} alt="background-img" />
        {!offerCode && quote && quote.offers[0] && quote.offers[0].code
        && (
          <div className="text-img-container">
            <div className="text-img no-bold">
              <h1> {I18n.t('travelInsuranceForm.step2.choseOffer.titleImg').toUpperCase()} </h1>
            </div>
            <div className="text-img">
              <h1>{FORMULA_NAMES[quote.offers[0].code] && I18n.t(`formula.insurance.${quote.offers[0].code}`).toUpperCase()}</h1>
            </div>
          </div>
        )}
      </div>
      <div className={hasCoverage ? 'step2-form' : 'step2-form order'}>
        {
          quote
          && hasCoverage
          && (
            <div className="section1">
              {
                isMobileDimensions(step)
                && (
                  <div className="top-button">
                    <div className="back">
                      <button aria-label=" " type="button" onClick={handleGoBackClick}>
                        <i className="far fa-long-arrow-left" />
                        <h2>
                          {I18n.t('travelInsuranceForm.step2.goBack')}
                        </h2>
                      </button>
                    </div>
                  </div>
                )
              }
              <h1 className="section-title">
                {I18n.t('travelInsuranceForm.step2.choseOffer.title')}
              </h1>
              <h3 className="section-description">
                {I18n.t('travelInsuranceForm.step2.choseOffer.description1')}
              </h3>
              {
                <FormulaTable
                  step={step}
                  quote={quote}
                  formula={formula}
                  chosenFormula={chosenFormula}
                  setOptionToSelect={(category) => {
                    const [, values] = category;
                    setOptionsToSelect(values);
                  }}
                  calculateAmountPerDay={calculatePriceAmountPerDay}
                  totalPrice={totalPrice}
                  handleChoseFormula={handleChoseFormula}
                  sectionRef={ref}
                />
              }
            </div>
          )
        }
        {
          (Object.values(optionsToSelect).length > 0 || (chosenFormula && chosenFormula.options.length > 0))
          && (
            <div
              className={chosenFormula ? 'section2' : 'section2 section2-disabled'}
              id="customize"
              ref={ref}
            >
              <a name="customize" />
              <div className="section2-header">
                <h1 className="section-title">
                  {I18n.t('travelInsuranceForm.step2.completeOffer.title')}
                </h1>
                <h3 className="section-description">
                  {I18n.t('travelInsuranceForm.step2.completeOffer.description')}
                </h3>
              </div>
              {
                chosenFormula
                && (
                  <div className="section2-content">
                    <OptionStep2
                      quote={quote}
                      chosenFormula={chosenFormula}
                      selectedOptions={selectedOptions}
                      handleCheckOption={handleCheckOption}
                      addOptionIcon={addOptionIcon}
                      isComfortAndUniqueAndSpecialCategory={isComfortAndUniqueAndSpecialCategory}
                      isOptionInFormulaOrOptional={isOptionInFormulaOrOptional}
                    />
                    {
                      Object.values(optionsToSelect).map((option: Coverage) => {
                        if (modalProps[option.code]) {
                          modalProps[option.code].text = option.description;
                          modalProps[option.code].title = option.name;
                        }
                        return (
                          !isMobileMode
                          && option.offerStatus.find((offer) => (
                            (
                              offer.code === chosenFormula.code
                              && offer.status !== STATUS.FALSE
                            ) || (
                              offer.status === STATUS.OPTIONAL
                              && offer.code === FORMULA_CODES.CONFORT
                            )
                          )) && (
                            <div
                              className={isOptionInFormulaOrOptional(option) ? 'option' : 'option disabled'}
                              key={option.code}
                            >
                              <div className="option-items">
                                <div className="row">
                                  <div className="option-col left">
                                    {addOptionIcon(option.code)}
                                  </div>
                                  <div className="option-col middle">
                                    <div className="option-title">
                                      <div className="option-header">
                                        <button
                                          aria-label=" "
                                          type="button"
                                          disabled={!isOptionInFormulaOrOptional(option)}
                                          onClick={() => {
                                            handleCheckOption(option);
                                          }}
                                          className={
                                            selectedOptions.filter((selectedOption) =>
                                              (selectedOption.offerCode === chosenFormula.code))
                                              .map((item) => item.coverage.code)
                                              .includes(option.code) ? 'select-option-button fas fa-check-square' : 'select-option-button far fa-square'}
                                        />
                                        <h3 className="header-h3">{option.name}</h3>
                                      </div>
                                    </div>
                                    <div className="box-description">
                                      <h3>{option.shortDescription}</h3>
                                      {
                                        (option.linkedCoverages).map((coverage, index) => {
                                          if (modalProps[coverage.code]) {
                                            modalProps[coverage.code].text = coverage.description;
                                          }
                                          return (
                                            coverage.description
                                            && (
                                              <div className="option-item" key={coverage.code}>
                                                <i className="fas fa-diamond" />
                                                <div className="option-item-sub">
                                                  <div>
                                                    <h3>{coverage.name}</h3>
                                                    {
                                                      coverage.shortDescription
                                                      && (
                                                        <h3>
                                                          {I18n.t('travelInsuranceForm.step2.completeOffer.option.shortDescription', { description: coverage.shortDescription })}
                                                        </h3>
                                                      )
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          );
                                        })
                                      }
                                      {
                                        isComfortAndUniqueAndSpecialCategory(option)
                                        && (
                                          <p>
                                            {I18n.t('travelInsuranceForm.step2.completeOffer.option.notCumulativeOption')}&nbsp;
                                          </p>
                                        )
                                      }
                                      {
                                        isComfortAndUniqueAndSpecialCategory(option)
                                        && (
                                          <p>
                                            {
                                              transformLastSeparator(
                                                Object.values(quote.coverageWithCategories)[0].filter((option0) =>
                                                  !option0.isUnique
                                                  && option0.offerStatus.find((offer) =>
                                                    (offer.code === chosenFormula.code && offer.status !== STATUS.FALSE),
                                                  ),
                                                ).map((option0) => option0.name),
                                                ', ',
                                                I18n.t('travelInsuranceForm.step2.completeOffer.option.and'),
                                              )}
                                          </p>
                                        )
                                      }
                                    </div>
                                  </div>
                                  {
                                    !isOptionInFormulaOrOptional(option)
                                    && (
                                      <div className="option-col right">
                                        <i className="fas fa-star" />
                                        <p className="white">
                                          {I18n.t('travelInsuranceForm.step2.completeOffer.option.onlyEnableFor')}
                                          {
                                            option.offerStatus.filter((offer) => offer.status === STATUS.OPTIONAL)
                                              .map((offer) => (` ${I18n.t(`formula.shortNames.${offer.code}`)}`))
                                          }
                                        </p>
                                      </div>
                                    )
                                  }
                                  <div className="option-col right">
                                    {
                                      isOptionInFormulaOrOptional(option)
                                      && (
                                        formatPrice(
                                          getOfferStatusFromChosenFormula(option).price,
                                        )
                                      )
                                    }
                                    {
                                      getOfferStatusFromChosenFormula(option).price < 0
                                      && isOptionInFormulaOrOptional(option)
                                      && (
                                        <p>{I18n.t('travelInsuranceForm.step2.completeOffer.option.onBasket')}</p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ));
                      })}
                  </div>
                )
              }
            </div>
          )
        }
        <Warranty />
      </div>
      <Sumup
        chosenFormula={chosenFormula}
        step={2}
        quote={quote}
        selectedOptionalCoverages={selectedOptionalCoverages}
        selectedOptions={selectedOptions}
        isMobile={isMobileDimensions()}
        hasCoverage={hasCoverage}
        calculateAmountPerDay={calculatePriceAmountPerDay}
      />
      {
        !isMobileDimensions()
        && (
          <div className={hasCoverage ? 'buttons-step2' : 'buttons-step2 order'}>
            <div className="buttons-footer">
              <div className={chosenFormula ? 'submit' : 'submit disabled-grey'}>
                <button type="button" onClick={handleNextStepClick} disabled={!chosenFormula}>
                  <h3>{I18n.t('travelInsuranceForm.step2.submit')}</h3>
                  <i className="far fa-long-arrow-right" />
                </button>
              </div>
              <div className="back white">
                <button type="button" onClick={handleGoBackClick}>
                  <i className="far fa-long-arrow-left" />
                  <h3>{I18n.t('travelInsuranceForm.step2.goBack')}</h3>
                </button>
              </div>
            </div>
          </div>
        )
      }
      <div className={hasCoverage ? '' : 'order-footer'}>
        <FormFooter />
      </div>
      {
        mobileMode && chosenFormula && (
          <Sticky mode="bottom" disabled={!scrolledStep2}>
            <button
              type="button"
              className="submit-btn"
              onClick={handleNextStepClick}
              disabled={!chosenFormula}
            >
              <h3>{I18n.t('travelInsuranceForm.step2.submit')}</h3>
            </button>
          </Sticky>
        )
      }
      {
        chosenFormula
        && (
          <>
            <PremiumCreditCardPopIn
              modalToShow={modalToShow}
              setModalToShow={setModalToShow}
              chosenFormula={chosenFormula}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              modalType={modalType}
              preSelectedOption={preSelectedOption}
              mobileDisplay={mobileDisplay}
              cbPreModalText={modalProps[CODES.RELAY_BK_OPTION].text}
              cbPreModalTitle={modalProps[CODES.RELAY_BK_OPTION].title}
            />
            <CreditCardRelayPopIn
              modalToShow={modalToShow}
              setModalToShow={setModalToShow}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              modalType={modalType}
              preSelectedOption={preSelectedOption}
              relayBkModalText={modalProps[CODES.PREMIUM_BK_OPTION].text}
              relayBkModalTitle={modalProps[CODES.PREMIUM_BK_OPTION].title}
              chosenFormula={chosenFormula}
            />
          </>
        )
      }
    </div>
  );
}

export default withRouter(connect((state) => ({
  quote: state.storage.quote,
  formula: state.storage.formula,
  optionalCoverages: state.storage.optionalCoverages,
  options: state.storage.options,
  offerCode: state.storage.offerCode,
  startContract: state.storage.startContract,
  discount: state.storage.discount,
  totalPrice: state.storage.totalPrice,
  tripInfos: state.storage.tripInfos,
  finalPrice: state.storage.finalPrice,
}))(FullFormStep2));
