// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
// $FlowFixMe
import { Field } from 'react-final-form';
import { required } from '../../services/validator';
import { GENDER } from '../../const';

type Props = {
  form: Object,
  values: Object,
  formString: string,
};

export default function GenderInput(props: Props) {

  const { form, values, formString } = props;

  return (
    <div className="form-section">
      <h3>
        {I18n.t('travelInsuranceForm.step3.gender.title')}
      </h3>
      <Field
        name={`${formString}.gender`}
        validate={required}
      >
        {({ meta }) => (
          <>
            <div className="gender-select">
              <div
                className={values && (values.gender === GENDER.FEMALE) ? 'gender-item selected-gender' : 'gender-item'}
                onClick={() => form.change(`${formString}.gender`, GENDER.FEMALE)}
              >
                {I18n.t('travelInsuranceForm.step3.gender.female')}
              </div>
              <div
                className={values && (values.gender === GENDER.MALE) ? 'gender-item selected-gender' : 'gender-item'}
                onClick={() => form.change(`${formString}.gender`, GENDER.MALE)}
              >
                {I18n.t('travelInsuranceForm.step3.gender.male')}
              </div>
            </div>
            <div className="error-custom">{(meta.touched) && meta.error}</div>
          </>
        )}
      </Field>
    </div>
  );
}
