// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import type { UserModification } from '../../types/loginTypes';
import { DASHBOARD_PAGE_ROUTE } from '../../const';
import userApi from '../../network/api/userApi';
import ProfilInput from '../../components/client/ProfilInput';
import AsideNav from './AsideNav';
import { notifyError, notifySuccess } from '../../network/notification';
import Flag from '../../components/client/Flag';
import type { Site } from '../../types/storage';
import ProfilSelectInput from '../../components/client/ProfilSelectInput';
import countryApi from '../../network/api/countryApi';

type Props = {
  history: any,
  dispatch: Function,
  site: Site,
}

type State = {
  user: UserModification,
  countryList: [{
    label: string,
    isoCode: string,
  }]
};

class Profil extends React.Component<Props, State> {
  state = {
    user: {},
    countryList: [],
  };

  componentDidMount() {
    userApi.get()
      .then((response) => response.json())
      .then(this.updateUser);

    countryApi.get()
      .then((response) => response.json())
      .then((responseJson) => this.setState({
        countryList: responseJson,
      }));
  }

  updateUser = (user) => {
    this.setState({
      user,
    });
  };

  onSubmit = (value) => {
    userApi.update({
      ...this.state.user,
      ...value,
    })
      .then(() => {
        this.setState({
          user: {
            ...this.state.user,
            ...value,
          },
        });
      })
      .then(notifySuccess(this.props.dispatch))
      .catch(notifyError(this.props.dispatch));
  };

  render() {
    return (
      <div className="space-client-container__connected">
        <AsideNav />
        <Flag />
        <div className="main-page">
          <div className="title">
            <i
              onClick={() => this.props.history.push(DASHBOARD_PAGE_ROUTE)}
              className="fal fa-arrow-left"
            />
            <h1><Translate value="spaceClient.profil.title" /></h1>
          </div>
          <div className="profil-array">
            <div className="profil-header">
              <Translate value="spaceClient.profil.sectionTitle" />
            </div>
            <ProfilInput
              field={{
                label: 'spaceClient.profil.lastname',
                placeholder: 'spaceClient.profil.lastname',
                value: this.state && this.state.user.lastname,
                key: 'lastname',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.firstname',
                placeholder: 'spaceClient.profil.firstname',
                value: this.state && this.state.user.firstname,
                key: 'firstname',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.birthdate',
                placeholder: 'spaceClient.profil.birthdate',
                value: this.state && moment(this.state.user.birthdate).format(I18n.t('date.DATE_FORMAT')),
                key: 'birthdate',
                type: 'text',
              }}
              format={(date) => {
                return date;
              }}
              onSubmit={(date) => {
                this.onSubmit({
                  birthdate: moment(date.birthdate, I18n.t('date.DATE_FORMAT')),
                });
              }}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.address',
                placeholder: 'spaceClient.profil.address',
                value: this.state && this.state.user.address,
                key: 'address',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.addressCompl',
                placeholder: 'spaceClient.profil.addressCompl',
                value: this.state && this.state.user.address2,
                key: 'address2',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilSelectInput
              field={{
                label: 'spaceClient.profil.country',
                placeholder: 'spaceClient.profil.country',
                value: this.state && this.state.user.countryCode,
                key: 'countryCode',
                valueList: this.state && this.state.countryList.map((country) => ({
                  label: country.label,
                  value: country.isoCode,
                }))
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.city',
                placeholder: 'spaceClient.profil.city',
                value: this.state && this.state.user.city,
                key: 'city',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.zipCode',
                placeholder: 'spaceClient.profil.zipCode',
                value: this.state && this.state.user.zipCode,
                key: 'zipCode',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.phoneNumber',
                placeholder: 'spaceClient.profil.phoneNumber',
                value: this.state && this.state.user.phoneNumber,
                key: 'phoneNumber',
                type: 'text',
              }}
              onSubmit={this.onSubmit}
            />
            <ProfilInput
              field={{
                label: 'spaceClient.profil.password',
                placeholder: 'spaceClient.profil.password',
                value: this.state && this.state.user.password,
                key: 'password',
                type: 'password',
              }}
              onSubmit={this.onSubmit}
            />
          </div>
          <p>
            {I18n.t('spaceClient.profil.warning.on_change')}
          </p>
          <p>
            {I18n.t('spaceClient.profil.warning.current_contract')} <a href={`mailto:${this.props.site.hotlineMail}`}>{this.props.site.hotlineMail}</a>.
            {I18n.t('spaceClient.profil.warning.thanks')}
          </p>
        </div>
      </div>);
  }
}

export default withRouter(connect((state) => ({
  site: state.storage.site,
}))(Profil));
