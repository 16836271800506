// @flow

import { reduce } from '../../network/reduce';
import type { TripInfos, Beneficiary } from '../../types/storage';

export const EXPIRES_ON = 'EXPIRES_ON';
export const COUNTRIES = 'COUNTRIES';
export const TRIP_INFOS = 'TRIP_INFOS';
export const VALID_TRIP_INFOS = 'VALID_TRIP_INFOS';
export const TRAVELER_INFOS = 'TRAVELER_INFOS';
export const VALID_TRAVELER_INFOS = 'VALID_TRAVELER_INFOS';
export const FORMULA = 'FORMULA';
export const OPTIONAL_COVERAGES = 'OPTIONAL_COVERAGES';
export const OPTIONS = 'OPTIONS';
export const QUOTE = 'QUOTE';
export const TOTAL_PRICE = 'TOTAL_PRICE';
export const FINAL_PRICE = 'FINAL_PRICE';
export const MAX_PAGE = 'MAX_PAGE';
export const OFFER_CODE = 'OFFER_CODE';
export const REDIRECT = 'REDIRECT';
export const DISCOUNT = 'DISCOUNT';
export const LOADED_API_KEY = 'LOADED_API_KEY';
export const START_CONTRACT = 'START_CONTRACT';
export const SITE = 'SITE';
export const PAYMENT_ID = 'PAYMENT_ID';

type storageStateType = {
  tripInfos: {
    travelType: string,
    numberOfAdults: number,
    numberOfKids: number,
    numberOfBabies: number,
    countryOfResidence: {
      label: string,
      value: string,
      code: string,
    },
    destinations: [
      {
        label: string,
        value: string,
        code: string,
      }
    ],
    travelDateRange: {
      startDate: string,
      endDate: string,
    },
    amount: string,
    expenses: {
      value: string,
      label: string,
    },
    startDateContract: string,
    promoCode: string,
  },
  optionalCoverages: Object[],
  options: Object[],
  offerCode: ?string,
  startContract: ?string,
  validTripInfos: boolean,
};

export const initTripInfos: TripInfos = {
  travelType: undefined,
  numberOfAdults: 0,
  numberOfKids: 0,
  numberOfBabies: 0,
  countryOfResidence: undefined,
  destinations: undefined,
  travelDateRange: undefined,
  amount: undefined,
  expenses: undefined,
  buyingDate: undefined,
  promoCode: undefined,
};

export const initBenefInfos: Beneficiary = {
  gender: undefined,
  name: '',
  surname: '',
  birthday: undefined,
  birthmonth: undefined,
  birthyear: undefined,
  email: '',
  address: '',
  postcode: '',
  city: '',
  country: undefined,
};

const initialState: storageStateType = {
  tripInfos: localStorage.getItem(TRIP_INFOS)
    ? JSON.parse(String(localStorage.getItem(TRIP_INFOS))) : initTripInfos,
  travelerInfos: localStorage.getItem(TRAVELER_INFOS)
    ? JSON.parse(String(localStorage.getItem(TRAVELER_INFOS))) : {},
  quote: localStorage.getItem(QUOTE)
    ? JSON.parse(String(localStorage.getItem(QUOTE))) : undefined,
  formula: localStorage.getItem(FORMULA)
    ? JSON.parse(String(localStorage.getItem(FORMULA))) : undefined,
  optionalCoverages: localStorage.getItem(OPTIONAL_COVERAGES)
    ? JSON.parse(String(localStorage.getItem(OPTIONAL_COVERAGES))) : [],
  options: localStorage.getItem(OPTIONS)
    ? JSON.parse(String(localStorage.getItem(OPTIONS))) : [],
  maxPage: localStorage.getItem(MAX_PAGE)
    ? localStorage.getItem(MAX_PAGE) : 1,
  discount: localStorage.getItem(DISCOUNT)
    ? localStorage.getItem(DISCOUNT) : 0,
  countries: localStorage.getItem(COUNTRIES)
    ? localStorage.getItem(COUNTRIES) : [],
  offerCode: localStorage.getItem(OFFER_CODE),
  redirect: localStorage.getItem(REDIRECT),
  finalPrice: localStorage.getItem(FINAL_PRICE)
    ? localStorage.getItem(FINAL_PRICE) : undefined,
  paymentId: localStorage.getItem(PAYMENT_ID) ? localStorage.getItem(PAYMENT_ID) : undefined,

  startContract: localStorage.getItem(START_CONTRACT),
  loadedApiKey: false,
  validTripInfos: false,
  validTravelerInfos: false,
};

export const actionMap = {
  [TRIP_INFOS]: 'tripInfos',
  [TRAVELER_INFOS]: 'travelerInfos',
  [QUOTE]: 'quote',
  [FORMULA]: 'formula',
  [OPTIONAL_COVERAGES]: 'optionalCoverages',
  [OPTIONS]: 'options',
  [TOTAL_PRICE]: 'totalPrice',
  [FINAL_PRICE]: 'finalPrice',
  [MAX_PAGE]: 'maxPage',
  [OFFER_CODE]: 'offerCode',
  [START_CONTRACT]: 'startContract',
  [DISCOUNT]: 'discount',
  [LOADED_API_KEY]: 'loadedApiKey',
  [VALID_TRIP_INFOS]: 'validTripInfos',
  [VALID_TRAVELER_INFOS]: 'validTravelerInfos',
  [REDIRECT]: 'redirect',
  [COUNTRIES]: 'countries',
  [SITE]: 'site',
  [PAYMENT_ID]: 'paymentId',
};

const storageReducer = reduce(initialState, actionMap);

export default storageReducer;
