// @flow

import { store } from '../../network/reduce';
import { IS_PROMO_LOADING, IS_QUOTE_FILE_LOADING, IS_QUOTE_LOADING } from './submissionReducer';

export function setIsQuoteLoading(isLoading: boolean) {
    return (dispatch: Function) => {
        dispatch(store(IS_QUOTE_LOADING, isLoading));
    };
}

export function setIsQuoteFileLoading(isLoading: boolean) {
    return (dispatch: Function) => {
        dispatch(store(IS_QUOTE_FILE_LOADING, isLoading));
    };
}

export function setIsPromoLoading(isLoading: boolean) {
    return (dispatch: Function) => {
        dispatch(store(IS_PROMO_LOADING, isLoading));
    };
}
