const fr = {
  actions: {
    BACK_LOGIN: 'Retour à la page de connexion',
    CANCEL: 'Annuler',
    EDIT: 'Modifier',
    SAVE: 'Enregistrer',
    CONNECTION: 'Connexion',
    LOGOUT: 'Déconnexion',
    SEND_MAIL: 'Envoyer l\'email',
    VALIDATE: 'Valider',
  },
  LOG_API: {
    TITLE: 'Swing Tunnel - Log API',
    DOWNLOAD: 'Télécharger',
    FILTER_STATUS: 'Filtrer par statut',
    FILTER_NAME: 'Filtrer par nom',
    FILTER_URL: 'Filtrer par URL de requête',
    FILTER_METHOD: 'Filtrer par méthode de requête',
    REQUEST: 'Requête',
    RESPONSE: 'Réponse',
    BODY: 'Body',
    HEADER: 'Header',
    EMPTY_BODY: 'Body vide',
    EMPTY_HEADER: 'Header vide',
    NO_CONTENT: 'Aucun contenu pour cette recherche'
  },
  sentry: {
    TITLE: 'Oops...',
    SUBTITLE: 'Une erreur est survenue',
    LINK_DESCRIPTION: 'Veuillez réessayer plus tard et actualiser votre page, vous pouvez également signaler un bug',
    LINK_ACTION: 'ici',
  },
  message: {
    YES: 'Oui',
    NO: 'Non',
  },
  errors: {
    noQuote: {
      title: 'Désolé, aucune de nos offres ne semble correspondre à vos besoins.',
      message: 'Pour plus d\'information, contactez le service commercial ',
      message_comfort: 'Pour votre confort, contactez le service commercial ',
      messagePhone: 'par téléphone au ',
      messageEmail: 'par email à ',
      messageOr: ' ou ',
      buttonEdit: 'Modifier mes informations',
      buttonBack: 'Revenir au site',
    },
    inactive: {
      title: 'Ce site est momentanément indisponible. Veuillez réessayer dans quelques instants.',
      description: 'Merci de votre fidélité ! L\'équipe Mutuaide.',
    },
    tick: 'Vous devez cocher cette case pour poursuivre',
    noAdaptedQuote: {
      title: 'Désolé vous n\'êtes pas éligible à notre offre %{offer} pour la raison suivante : ',
      titleNoReason: 'Désolé vous n\'êtes pas éligible à notre offre %{offer}',
      titleNoOffer: 'Désolé, aucune de nos offres ne semble correspondre à vos besoins pour la raison suivante : ',
      titleGeneric: 'Les informations renseignées ne correspondent pas intégralement à l\'offre choisie.',
      dash: '- ',
      advice: 'Nous vous conseillons l\'offre suivante',
      adviceQuestion: 'Cliquez sur Souscrire si cela vous convient.',
      information: 'Si cela vous convient, cliquez sur Sélectionner pour continuer.',
      text: {
        pro: 'Adaptée aux exigences des courts ou longs séjours professionnels partout dans le monde.',
        groupe: 'Une offre dédiée à tous les groupes à partir de 10 personnes à l\'étranger comme en France.',
        schengen: 'Une offre qui vous permet de voyager en toute sérénité à travers l\'Europe.',
        confort: 'Une offre d\'assurance voyage complète qui vous permet de voyager l\'esprit léger.',
        zen: 'Une offre d\'assistance voyage à la carte qui vous permet de voyager en toute sérénité.',
      },
    },
    redirectQuote: {
      lonelySub: 'Nous vous invitons à réaliser une souscription individuelle pour chaque voyageur.',
      groupeOffer: 'Nous vous proposons également l\'assurance voyage Groupe si vous souhaitez souscrire collectivement.',
      or: 'OU',
    },
  },
  date: {
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
  header: {
    TITLE: 'Devis assurance Voyage',
  },
  stepper: {
    TRIP_LABEL: 'Voyage',
    DEVIS_LABEL: 'Devis',
    CUSTOMER_LABEL: 'Voyageurs',
    PAYMENT_LABEL: 'Paiement',
  },
  travelInsuranceForm: {
    step1: {
      travel: 'Votre voyage',
      description: 'Dites-nous en plus sur votre voyage, notre Assistant trouvera l\'offre la plus adaptée à vos besoins.',
      descriptionOfferCode: 'Dites-nous en plus sur votre voyage.',
      insuranceType: {
        title: 'Vous recherchez une assurance pour :',
        tourism: 'Vacances / voyage groupe',
        tourismClassic: 'Vacances /',
        tourismGroup: ' Voyage Groupe',
        business: 'Déplacement professionnel',
        studying: 'Séjour étudiant',
      },
      numberOfBeneficiaries: {
        title: 'Nombre de bénéficiaire(s) :',
        numberOfAdults: 'Adulte(s)',
        numberOfKids: 'Enfant(s) (de 2 ans à 17 ans)',
        numberOfBabies: 'Bébé(s) (de moins de 2 ans)',
        free: 'Gratuit',
      },
      countryOfResidence: 'Pays où vous résidez :',
      countryPlaceholder: 'Sélectionner un pays',
      destination: 'Pays où vous voyagez :',
      destinationWarning: 'Vous pouvez vérifier que votre destination ne fait pas l’objet de restriction sur ',
      destinationLink: 'ce site ',
      destinationWarningLink: 'www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs',
      addDestination: 'Ajouter une destination',
      travelDate: {
        title: 'Date de votre voyage :',
        placeholder: 'Sélectionner vos dates',
        apply: 'Appliquer',
        errorEnd: 'La date de retour ne peut être antérieure à celle d\'aujourd\'hui',
        errorYear: 'Votre voyage est limité à 365 jours',
        warning: 'Vous êtes en cours de voyage et vous vous rendez compte que vous n’avez pas souscrit d’assurance voyage ? Mutuaide vous en donne la possibilité ! Consultez la notice d’information de votre contrat pour en savoir plus sur votre couverture.',
      },
      buyingDate:{
        title: 'Date d\'achat de votre voyage :',
        placeholder: {
          normal: 'Sélectionner la ',
          underline: 'date du premier achat de votre voyage',
        },
      },
      amount: 'Montant total du voyage (TTC%{benef}) :',
      ttc: ' pour %{nbrBeneficiaries} voyageurs',
      expenses: {
        title: 'Sélectionner vos dépenses :',
        placeholder: 'Sélectionner vos dépenses',
        transport: 'Transport',
        lodging: 'Hébergement',
        transport_and_lodging: 'Transport et hébergement',
      },
      trick: {
        title: 'Important',
        description1: 'Évaluez le montant des prestations de votre voyage : hébergement, transport ou les deux. Ce montant sera celui de votre niveau de couverture en cas d’annulation.',
        description2: 'Indiquez la date à laquelle vous avez acheté la ou les prestations à couvrir en cas d’annulation :',
        description3: 'En cas de plusieurs prestations à couvrir, indiquez la date de début de la première prestation.',
        description4: 'En cas d’achat d’un ensemble de prestations auprès d’une agence de voyage, mentionnez la date d’achat de ce dernier.',
      },
      submit: 'Trouver mon assurance',
      submitOfferCode: 'Poursuivre',
      noOptions: 'Aucun résultat',
      placeholder: {
        amount: 'Saisir le montant',
      },
    },
    step2: {
      choseOffer: {
        titleImg: 'Découvrez l\'offre adaptée à vos besoins : \n',
        title: '1. Tout d\'abord, choisissez votre formule...',
        description1: 'Profitez d\'une assurance voyage qui protège ce qui est important pour vous lors de votre séjour. Personnalisez-la pour l\'adapter à vos besoins.',
        totalAmount: '%{amount}',
        amountPerDay: '(soit %{amount} / jour)',
        amountWithCurrencyRate: '(soit %{amount})',
        completeFormula: 'Formule complète',
        select: 'Sélectionner',
        selectOffer: 'Sélectionner l\'offre ',
        edit: 'Modifier mon voyage',
        selected: 'Sélectionnée',
        selectedFormula: 'Formule Sélectionnée',
        optional: 'En option',
        completeOffer: ' Formule complète',
        customizeFormula: 'Personnaliser ma formule',
      },
      completeOffer: {
        title: '2. Super ! Maintenant, personnalisez votre offre...',
        description: 'Complétez votre formule en fonction de vos besoins.',
        option: {
          shortDescription: ' (%{description})',
          sport: {
            modal: {
              warning: 'Attention !',
              close: 'Fermer',
            },
          },
          refuse: 'Refuser',
          accept: 'Accepter',
          onlyEnableFor: 'Disponible uniquement avec la formule',
          onBasket: 'sur votre panier',
          includedInFormula: 'Inclus dans la formule',
          notCumulativeOption: '*Non cumulable avec :',
          included: 'Inclus',
          and: ' et ',
        },
      },
      warranty: {
        medicalWarranty: {
          zen: 'Prise en charge des frais médicaux partout dans le monde',
          confort: 'Prise en charge des frais médicaux partout dans le monde',
          schengen: 'Rapatriement médical totalement pris en charge',
          groupe: 'Rapatriement médical et prise en charge des frais médicaux partout dans le monde',
          pro: 'Rapatriement médical et prise en charge des frais médicaux partout dans le monde',
        },
        sportWarranty: {
          zen: 'Des garanties à la carte et une option sport musclée',
          confort: 'Des garanties à la carte et une option sport musclée',
          schengen: 'Rapatriement des membres de la famille ou d\'un accompagnant',
          groupe: 'Une indemnisation pour les bagages endommagés, perdus ou volés',
          pro: 'Couverture de vos bagages et matériel professionnel endommagés, perdus, volés ou en retard',
        },
        bankCardWarranty: {
          zen: 'Prise en compte des garanties présentes dans votre carte bancaire',
          confort: 'Prise en compte des garanties présentes dans votre carte bancaire',
          schengen: 'Une assistance juridique pour voyager sereinement',
          groupe: 'Une assistance juridique pour voyager sereinement',
          pro: 'Une assistance juridique pour voyager sereinement',
        },
      },
      recap: {
        title: 'Récapitulatif',
        titleQuote: 'Votre devis',
        beneficiaries: 'pour %{nbrBeneficiaries} bénéficiaires',
        specificWording: {
          part1: 'Ce devis a été réalisé grâce aux informations renseignées par vos soins. Vous pouvez consulter la ',
          cg: 'notice d\'information',
          part2: ' et le ',
          din: 'document d\'information normalisé',
          part3: ' de l\'offre. Si cela vous convient, cliquez sur Souscrire pour continuer.',
        },
        warranties: 'Garanties',
        warrantiesOptional: 'Garanties optionnelles',
        options: 'Options',
        discount: 'Remise',
        discountAmount: 'Réduction de %{price} : ',
        download: 'Télécharger cette proposition',
        almostFinished: 'Vous avez bientôt terminé',
        subscribeOnlineNow: 'Vous pouvez souscrire dès maintenant en ligne',
        price: ' : %{price}',
        priceNegative: ' : -\u00a0%{price}',
      },
      goBack: 'Retour',
      goBackChangeInfo: 'Modifier mes informations',
      submit: 'Souscrire',
    },
    step3: {
      title: 'Souscripteur : Saisissez vos coordonnées',
      gender: {
        title: 'Civilité :',
        male: 'Monsieur',
        female: 'Madame',
      },
      login: {
        title: 'Déjà client ? Connectez-vous à votre Espace Client pour retrouver vos informations de souscription',
        placeholderId: 'Adresse email',
        placeholderPassword: 'Mot de passe',
        titleId: 'Identifiant :',
        titlePassword: 'Mot de passe :',
        titleLogin: 'Je suis connecté(e) en tant que ',
        titleLoginName: '%{surname} %{name}',
        loginButton: 'Connexion',
        showLogin: 'Se connecter',
        showLogout: 'Se déconnecter',
        errorMessage: 'Adresse email ou mot de passe incorrect',
      },
      name: 'Nom :',
      surname: 'Prénom :',
      birthDate: 'Date de naissance :',
      email: 'Email :',
      emailConfirm: 'Confirmez votre email :',
      info: {
        title: 'Information',
        content: 'Nous vous enverrons l\'email de confirmation à cette adresse.',
        content2: 'Pratique : si vous avez utilisé cette adresse email lors d\'une précédente souscription, alors vos informations de voyage seront rattachées au même Espace Client.',
      },
      phone: 'Téléphone :',
      address: 'Adresse :',
      postcode: 'Code postal :',
      city: 'Ville :',
      country: 'Pays :',
      add_address: 'Ajouter un complément',
      sub_address: 'Supprimer le complément',
      is_beneficiary: 'Le souscripteur bénéficie de l’assurance voyage',
      placeholder: {
        surname: 'Prénom',
        name: 'Nom',
        birthdate: 'JJ/MM/AAAA',
        email: 'Adresse email',
        emailConfirm: 'Confirmez votre adresse email',
        address: 'Adresse',
        postcode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
      },
      beneficiaries: {
        title: 'Qui en bénéficie ?',
        subtitle: 'Bénéficiaire n°%{number}',
        same_address: 'Avez-vous la même adresse que le souscripteur ?',
        yes: 'Oui',
        no: 'Non',
      },
      receive_doc: {
        title: 'Je souhaite recevoir une attestation d\'assurance voyage :',
        group: 'Commune (une pour tous les bénéficiaires)',
        alone: 'Individuelle (une pour chaque bénéficiaire)',
      },
      accept_policy: {
        full: 'Je confirme avoir bien lu, compris et accepté la notice d\'information du contrat, le document d\'information normalisé, ainsi que les politiques des données personnelles.',
      },
      accept_offers: 'J\'accepte de recevoir des actualités et des offres commerciales de la part de %{cdo} et de ses partenaires.',
      fieldsMandatory: 'Afin de créer votre dossier, tous les champs ci-dessous sont obligatoires.',
      submit: 'Procéder au paiement',
      back: 'Retour',
    },
    step4: {
      title: 'Vous avez terminé le formulaire !',
      description: 'Notre proposition tarifaire et vos documents utiles viennent de vous être envoyés à votre adresse email %{mail}',
      showMore: 'Voir +',
      showLess: 'Voir -',
      renonciation: 'Vous disposez d’un délai de renonciation de 14 jours. Afin d’exercer ce droit, vous trouverez tous les éléments nécessaires dans la notice d’information de votre contrat.',
      contract: 'Votre relation précontractuelle et contractuelle avec MUTUAIDE sera exclusivement réalisée sur un support durable autre que papier. Toutefois, vous avez le droit de vous opposer à l\'utilisation de ce support durable à tout moment et de demander la poursuite des relations au format papier.',
      recap: {
        title: 'Récapitulatif de votre assurance voyage',
      },
      beneficiaryRecap: {
        title: 'Bénéficiaire %{number}',
        mr: 'Monsieur',
        mme: 'Madame',
      },
      payment: {
        title: 'Paiement',
        submit: 'Je m\'assure pour %{amount}',
        submitGroupe: 'Valider et payer %{amount}',
        submitRefuse: '(Paiement refusé)',
        ORDER_REQUEST_ERROR: 'Une erreur est survenue lors de votre paiement. Merci de réessayer.',
        ORDER_ALREADY_PAID: 'Votre paiement a déjà été validé ou est en cours de validation pour cette souscription.',
        ORDER_ALREADY_CREATED: 'Une erreur est survenue lors de votre paiement. Vous n\'avez pas été débité. Merci de vérifier vos informations bancaires et de réaliser un nouveau devis.',
        EXPIRED_CONTROL_TOKEN: 'Votre devis a expiré. Veuillez recommencer votre souscription.',
        INVALID_CONTROL_TOKEN: 'Nous rencontrons un souci avec votre souscription. Veuillez réessayer.',
        ERROR_CLICK_HERE: 'Réaliser un nouveau devis',
        noName: 'Vous devez renseigner le nom du titulaire de la carte.',
        invalidCard: 'Les informations de la carte banquaire sont invalides.',
        refuse: 'Votre paiement a été refusé. Merci de réessayer.',
      },
    },
    step5: {
      title: 'Merci de votre confiance',
      confirmation: 'Nous vous confirmons que votre demande de souscription à l\'offre %{offerName} a bien été prise en compte.',
      emailConfirmation: {
        title: 'Vous allez recevoir un email de confirmation dans quelques instants avec :',
        documents: '- Vos documents utiles : contrat avec la validation de paiement, notice d\'information et attestation d\'assurance voyage',
        links: '- Un lien pour activer votre Espace Client. Retrouvez tous vos documents, les questions fréquentes, le détail de vos contrats et d\'autres fonctionnalités pour vous simplifier le voyage, où que vous soyez.',
        emailNotReceived: 'Si vous ne recevez pas votre email de confirmation dans les prochaines minutes, merci de prendre contact avec nous par email : ',
      },
      gabriel: {
        title: 'Gabriel, l\'application d’assistance qui vous accompagne dans tous vos déplacements !',
        description: 'Téléchargez gratuitement l\'appli sur votre mobile et bénéficiez de tous les services grâce au code d\'activation %{cdo} à renseigner dès l\'ouverture de l\'appli : ',
        information: 'Vous recevrez bientôt un email contenant plus d’information sur l’appli !'
      },
      goodTravel: 'Bon voyage de la part de toute l\'équipe %{name} !',
      buttonBack: 'Revenir au site',
    },
  },
  information: {
    title: 'Mes informations',
    noData: 'Aucune information n\'a été renseignée',
    travel: 'Voyage',
    update: 'Modifier',
    data: {
      title: 'Information sur le voyage',
      insuranceType: 'Assurance pour : ',
      adults: 'Adulte(s) : ',
      kids: 'Enfant(s) (de 2 ans à 17 ans) : ',
      babies: 'Bébé(s) (de moins de 2 ans) : ',
      dates: 'Dates : ',
      datesContract: 'Votre contrat prendra effet le : ',
      midday: ' à 12h',
      residence: 'Pays résidentiel : ',
      destinations: 'Destination(s) : ',
      total: 'Montant du voyage : ',
      expenses: 'Ce montant comprend : ',
      buying: 'Date d\'achat : ',
      beneficiary: 'Nombre de bénéficiaire(s): ',
    },
  },
  basket: {
    title: 'Panier',
    noData: 'Aucune formule n\'a été sélectionnée',
    total: 'Total',
    formulaPrice: '%{price}',
    optionPrice: '+ %{price}',
    optionNegativePrice: '-\u00a0%{price}',
    discountAmount: '-\u00a0%{amount}',
    discountCode: 'Avez-vous un code promo ?',
    validateDiscount: 'OK',
    discount: 'Remise de %{price}',
    invalidDiscountCode: 'Code promo invalide',
  },
  validator: {
    minMax: 'Le montant total du voyage doit être compris entre %{min} et %{max}',
    mandatory: 'Champ obligatoire',
    invalid: 'Le nom saisi est invalide',
    numSize: 'Ce champ doit contenir %{size} chiffres',
    emailFormat: 'L\'email saisi est invalide',
    emailConfirm: 'L\'email saisi n\'est pas identique',
    phoneFormat: 'Le numéro de téléphone est incorrect',
    phoneFormatNoZero: 'Le numéro ne doit pas avoir de 0 juste après le code pays',
    postcodeFormat: 'Le code postal est invalide',
    postcodeLength: 'Le code postal ne peut pas contenir plus de 10 caractères',
    postcodeLengthFive: 'Le code postal doit contenir 5 chiffres',
    dateFormat: 'Vous devez respecter le format JJ/MM/AAAA',
    dateLimitMajor: 'Désolé, vous êtes mineur. Veuillez faire appel à une personne majeure pour souscrire l\'assurance.',
    dateLimit: 'La date doit être avant le jour en cours',
    dateMini: 'L\'année de naissance doit être supérieure à %{min}',
    tick: 'Vous devez cocher cette case pour continuer',
    verifyBenef: 'Veuillez vérifier les dates de naissance des bénéficiaires, elles ne correspondent pas aux informations saisies lors de l\'étape Voyage.',
  },
  footerForm: {
    need_help: 'Besoin d\'aide ?',
    button_question: 'Questions fréquentes',
    download_title: 'Téléchargement des documents pratiques',
    dowload_din: 'Document d\'information normalisé',
    download_CGUV: 'Notice d’information',
    popupTitle: 'Vous devez sélectionner une offre pour accéder à ce document',
  },
  footer: {
    cookiesHandle: 'Gérer mes cookies',
  },
  faq: {
    title: 'Questions fréquentes',
    category: 'Catégories',
    search: 'Rechercher',
    placeholder: 'Votre recherche',
    noFaq: 'La rubrique questions fréquentes n\'est actuellement pas disponible',
    noResult: 'Aucune question ne correspond à votre recherche',
    contact: 'Vous ne trouvez pas la réponse à votre question ?',
    buttonContact: 'Nous contacter',
  },
  spaceClient: {
    description: {
      title: 'Bienvenue dans votre espace client',
      firstBlock: 'Retrouvez toutes les informations et les documents liés à vos contrats',
      secondBlock: 'Accédez aux questions fréquentes',
      thirdBlock: 'Gérez votre profil',
      fourthBlock: 'Réalisez un nouveau devis',
      fifthBlock: 'Contactez-nous facilement',
    },
    form: {
      connectionTitle: 'Connectez-vous',
      username: 'Identifiant',
      password: 'Mot de passe',
      forgottenLink: 'Mot de passe oublié ?',
      forgottenTitle: 'Mot de passe oublié ?',
      forgottenField: 'Email du compte',
      forgottenMention: 'Un email sera envoyé à cette adresse afin de vous permettre de changer votre mot de passe',
      forgottenConfirm: 'Un email de récupération vient de vous être envoyé.',
      modifyTitle: 'Changer mon mot de passe',
      modifyPassword: 'Nouveau mot de passe',
      modifyPasswordConfirm: 'Confirmation du mot de passe',
      fisrtConnectionTitle: 'Activation de mon espace client',
      firstConnectionSubtitle: 'Merci de renseigner un mot de passe afin de finaliser l\'activation de votre espace client',
      fisrtConnectionPassword: 'Nouveau mot de passe',
      fisrtConnectionPasswordConfirm: 'Confirmation du mot de passe',
      modifyConfirm: 'Votre mot de passe a bien été modifié',
      firstConnectionConfirm: 'Votre compte a bien été initialisé',
      reactivateAccountTitle: 'Réactivation de mon espace client',
      reactivateAccountSubtitle: 'Vous avez été inactif sur ce compte pendant trop longtemps. Merci de renseigner un mot de passe afin de réactiver votre espace client',
      reactivateAccountConfirm: 'Votre compte a bien été réinitialisé',
    },
    dashboard: {
      title: 'Bienvenue %{firstname}',
      contracts: 'Contrats',
      devis: 'Devis',
      contacts: 'Contacts',
      FAQ: 'Questions fréquentes',
      profil: 'Profil',
      description: 'Retrouvez tous vos documents en quelques clics, où que vous soyez !\n' +
        'Votre espace client vous permet aussi de modifier vos informations personnelles et de réaliser facilement un nouveau devis pour votre prochaine destination. Et si vous avez besoin d\'aide, parcourez la page questions fréquentes ou contactez-nous.',
    },
    profil: {
      title: 'Profil',
      sectionTitle: 'Informations personelles',
      name: 'Nom complet',
      firstname: 'Prénom',
      lastname: 'Nom',
      birthdate: 'Date de naissance',
      phoneNumber: 'Téléphone',
      address: 'Adresse postale',
      addressCompl: 'Complément d\'adresse',
      city: 'Ville',
      country: 'Pays',
      zipCode: 'Code postal',
      email: 'Email',
      password: 'Mot de passe',
      confirmPassword: 'Confirmation du mot de passe',
      warning: {
        on_change: 'Vous pourrez compléter vos futurs formulaires plus rapidement et avec vos informations personnelles mises à jour.',
        current_contract: 'Attention : si vous avez un contrat en cours et renseigné avec les anciennes informations personnelles, veuillez communiquer le(s) changement(s) à ',
        thanks: ' Merci !',
      },
    },
    contract: {
      title: 'Contrats',
      summup: 'Récapitulatif',
      basket: 'Panier',
      adult: 'Adulte(s)',
      teen: 'Enfant(s) (de 2 ans à 17 ans)',
      child: 'Bébé(s) (de moins de 2 ans)',
      catchPhrase: 'Vous repartez en voyage ?',
      btnLabel: 'Réaliser un nouveau devis',
      btnContract: 'Télécharger le contrat',
      btnAttest: 'Télécharger l\'attestation%{number}',
      contractName: 'Votre Contrat.pdf',
      attestName: 'Votre Attestation Assurance Voyage.pdf',
      total: 'Total',
      currency: '%{price}\u00a0€',
      table: {
        date: 'Dates : ',
        country: 'Pays résidentiel\u00a0: ',
        destinations: 'Destination(s)\u00a0: ',
        amount: 'Montant du voyage\u00a0: ',
        buyingDate: 'Date d\'achat\u00a0: ',
        including: 'Ce montant comprend\u00a0: ',
        promoCode: 'Remise de %{percentage}%',
      },
    },
    contact: {
      title: 'Nous contacter',
      noContent: 'Cette page n\'a pas de contenu disponible pour le moment',
    },
  },
  formula: {
    insurance: {
      VOY_TRST_ZEN: 'Assurance Voyage Touristique',
      VOY_TRST_CONFORT: 'Assurance Voyage Touristique',
      VOY_GPR: 'Assurance Voyage Groupe',
      VOY_SCHGN: 'Assurance Voyage Schengen',
      VOY_PRO: 'Assurance Voyage Professionnel',
    },
    names: {
      VOY_TRST_ZEN: 'Voyage Touristique - Formule ZEN',
      VOY_TRST_CONFORT: 'Voyage Touristique - Formule CONFORT',
      VOY_SCHGN: 'Assurance Voyage Schengen',
      VOY_GPR: 'Assurance Voyage Groupe',
      VOY_PRO: 'Assurance Voyage Professionnel',
    },
    shortNames: {
      VOY_TRST_ZEN: 'ZEN',
      VOY_TRST_CONFORT: 'CONFORT',
      VOY_SCHGN: 'Schengen',
      VOY_GPR: 'Groupe',
      VOY_PRO: 'Professionnel',
    },
  },
  errorMessage: {
    DURATION_NEGATIVE: 'La durée du voyage est incohérente',
    RESIDENCE_COUNTRY_IN_SCHENGEN: 'Votre pays de résidence se situe dans l\'espace Schengen',
    RESIDENCE_COUNTRY_NOT_COVERED: 'Votre pays de résidence n\'est pas couvert',
    COUNTRY_NOT_FOUND: 'Le pays selectionné est introuvable',
    COUNTRY_COUPLE_NOT_COVERED: 'Votre pays de résidence ainsi que votre destination sont hors de la zone Schengen',
    NOT_TOURISM_TRAVEL: 'Vous n\'effectuez pas un voyage Touristique',
    RESIDENCE_COUNTRY_OUT_SCHENGEN: 'Votre pays de résidence se trouve en dehors de l\'espace Schengen',
    DESTINATION_COUNTRY_OUT_SCHENGEN: 'Un ou plusieurs pays de destination se trouvent hors de l\'espace Schengen',
    TOO_MANY_PEOPLE: 'Vous êtes un groupe supérieur à 9 personnes',
    TOURISM_INDIVIDUAL_FAMILIAL_TRAVEL: 'Vous réalisez un voyage Touristique, Individuel ou Familial',
    TRAVELERS_COUNT_TOO_LOW: 'Vous êtes un groupe inférieur à 9 personnes',
    LESS_BENEFICIARY_NEEDED: 'Votre voyage compte plus de 50 bénéficiaires ?',
    MORE_BENEFICIARY_NEEDED: 'Le nombre de participants est insuffisant',
    NO_PRODUCT_AVAILABLE: 'Aucune offre n\'est disponible',
    NOT_AVAILABLE_QUOTATION: 'Aucun devis n\'est disponible',
    CANT_TRAVEL_IN_THE_PAST_YET: 'Votre voyage doit être en cours',
    MAXIMUM_DURATION_EXCEEDED: 'Votre voyage est trop long',
    BENEFICIARY_QUANTITY_DOESNT_MATCH: 'Le nombre de bénéficiaires est incohérent',
    ONE_ADULT_MINIMUM: 'Il doit y avoir au moins un adulte durant ce voyage',
    CANT_BE_BORN_IN_THE_FUTURE: 'La date de naissance est incohérente',
    TRAVEL_IN_MORE_THAN_TWO_YEARS: 'Votre voyage débute à une date trop éloignée',
    TRAVEL_TOO_EARLY: 'Votre voyage a débuté depuis trop longtemps',
    NO_TRAVELERS_INFORMATION_FOUND: 'Vous avez mal renseigné les informations des voyageurs',
    CAN_T_BE_SELECTED_IN_THE_PAST: 'Vous êtes en cours de voyage',
    GROUP_OFFER_MAY_NOT_BE_AVAILABLE: 'Vous êtes un groupe supérieur à 9 personnes',
    PRO_OFFER_MAY_NOT_BE_AVAILABLE: 'Vous effectuez un déplacement professionnel',
    NO_RESULT_FOUND: 'Aucun résultat',
    UNKNOWN_ERROR: 'Erreur inconnue',
  },
  wsError: {
    WRONG_LOGIN_OR_PASSWORD: 'L\'identifiant ou le mot de passe sont eronnés',
    ALREADY_EXPIRED_SESSION_TOKEN: 'La durée de validité du lien a expiré. Cliquez sur mot de passe oublié.',
    SESSION_EXPIRED: 'La session a expiré, merci de vous connecter à nouveau',
    MISMATCHING_USER: 'La modification ne concerne pas l\'utilisateur connecté, merci de vous connecter avec le bon compte',
    PASSWORDS_DIFFERENT: 'Les mot de passe renseignés ne sont pas concordants',
    FIELD_REQUIRED: 'Champs requis',
    NOT_INITIALIZED: 'Vous devez activer votre espace client afin de vous connecter',
    PASSWORD_TOO_SHORT: 'Le mot de passe doit contenir 8 caractères minimum',
    PASSWORD_NOT_VALID: 'Le mot de passe doit contenir au moins un caractère spécial, une majuscule et un chiffre',
    INNACTIVE_ACCOUNT: 'Le compte utilisateur n\'est plus actif',
  },
};

export default fr;
