// @flow

import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
// $FlowFixMe
import { Field, Form } from 'react-final-form';
// $FlowFixMe
import { CoreozInputBase } from 'coreoz-form-base';
import { composeValidators, isEmail, required } from '../../services/validator';

type ClientField = {
  name: string,
  type: string,
  label: string,
  placeholder: string,
  description?: ?string,
}

type Props = {
  title: string,
  subtitle?: ?string,
  form?: ?{
    fields: ClientField[],
    submitLabel: string,
    onSubmit: Function,
  },
  back?: ?{
    label: string,
    action: Function,
  },
  validate?: ?string,
};

export default class Login extends React.PureComponent<Props> {
  static defaultProps = {
    form: null,
    back: null,
    validate: null,
    subtitle: null,
  };

  render() {
    return (
      <div className="space-client-form">
        <h2><Translate value={this.props.title} /></h2>
        {
          this.props.subtitle &&
            <p><Translate value={this.props.subtitle} /></p>
        }
        {this.props.form && (
          <Form
            onSubmit={this.props.form.onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                {
                  this.props.form && this.props.form.fields.map((field) => (
                    <div className="form-section" key={field.name}>
                      <h3>
                        <Translate value={field.label} />
                      </h3>
                      <Field
                        name={field.name}
                        type={field.type}
                        placeholder={I18n.t(field.placeholder)}
                        component={CoreozInputBase}
                        validate={required}
                      />
                      {field.description && (
                        <small>
                          <Translate value={field.description} />
                        </small>
                      )}
                    </div>
                  ))
                }
                <div className="button-container">
                  <button className="buttons-footer" type="submit">
                    <Translate value={this.props.form && this.props.form.submitLabel} />
                  </button>
                </div>
              </form>
            )}
          />
        )}
        {this.props.validate &&
        <div className="validate-container">
          <p><i className="fas fa-check-circle icon_green" /></p>
          <p><Translate value={this.props.validate} /></p>
        </div>
        }
        {this.props.back && (
          <div onClick={this.props.back.action} className="button-back">
            <small>
              <i className="fal fa-angle-left icon_green" />
              <Translate value={this.props.back.label} />
            </small>
          </div>
        )}
      </div>

    );
  }
}
