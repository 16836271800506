// @flow

import handleError, {
  jsonResponseCatcher,
  rawResponseCatcher,
  toJsonError,
  unwrap,
} from './handleError';
import { baseApiUrl } from '../const';
import { currentUserSessionToken } from '../services/sessionService';

export function getAuthorization() {
  return `Bearer ${currentUserSessionToken()}`;
}

const stringify = (body) => {
  if (typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
};

export class HttpClient {
  apiExtensionUrl: string;
  headersValue: Object;
  methodvalue: string;
  params: Object[];
  bodyValue: ?string;

  constructor(apiExtensionUrl: string, method: string = 'GET') {
    this.apiExtensionUrl = apiExtensionUrl;
    this.headersValue = {};
    this.methodvalue = method;
    this.params = [];
    this.bodyValue = null;
  }

  headers(headers: Object) {
    Object.assign(this.headersValue, headers);
    return this;
  }

  body(body: string) {
    this.bodyValue = body;
    return this;
  }

  addParam(key: string, value: ?(string | number | boolean)) {
    if (value !== undefined && value !== null) {
      this.params.push({ key, value: String(value) });
    }
    return this;
  }

  jsonBody(objectBody: Object) {
    this.headers({ 'Content-Type': 'application/json' });
    this.body(stringify(objectBody));
    return this;
  }

  apiExtensionUrlWithParams() {
    if (this.params.length > 0) {
      return this.apiExtensionUrl.concat('?').concat(this.params.map(param => `${param.key}=${param.value}`).join('&'));
    }
    return this.apiExtensionUrl;
  }

  execute() {
    // $FlowFixMe
    return fetch(baseApiUrl + this.apiExtensionUrlWithParams(), {
      headers: this.headersValue,
      method: this.methodvalue,
      body: this.bodyValue,
    })
      .catch(rawResponseCatcher)
      .then(handleError);
  }
}

export class RestClient extends HttpClient {
  constructor(apiExtensionUrl: string, method: string = 'GET') {
    super(apiExtensionUrl, method);
    const language = localStorage.getItem("language") || 'FR';
    this.headers({ 'Accept-Language': language });
  }

  execute() {
    return super
      .execute()
      .then(jsonResponseCatcher)
      .catch(toJsonError);
  }
}

export class RestClientAuthenticated extends RestClient {
  constructor(apiExtensionUrl: string, method: string = 'GET') {
    super(apiExtensionUrl, method);
    this.headers({ Authorization: getAuthorization() });
  }
}
