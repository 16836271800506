// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { isMobileDimensions } from '../../services/dimensions';

type Props = {
  input: any,
  fieldValue: number,
  changeValue: Function,
  max: number,
}

function SwingInputNumber(props: Props) {
  const {
    input, fieldValue, changeValue, max,
  } = props;

  const handleChangeBeneficiaryNumber = (inputValue) => {
    if ((inputValue === -1 && fieldValue > 0) || (inputValue !== -1 && fieldValue < max)) {
      changeValue(input.name, parseInt(fieldValue, 10) + inputValue);
    }
  };

  const handleChangeBeneficiaryNumberInput = (value) => {
    changeValue(input.name, value <= max ? value : 0);
  };

  return (
    <div className="beneficiary-field">
      <div className="beneficiary-input">
        {isMobileDimensions()
          ? (
            <div
              role="presentation"
              onClick={() => handleChangeBeneficiaryNumber(-1)}
              className="increment-button"
            >
              <span>-</span>
            </div>
          )
          : (
            <i
              role="presentation"
              className={`${fieldValue === 0 ? 'fas' : 'far'} fa-minus-square`}
              onClick={() => handleChangeBeneficiaryNumber(-1)}
            />
          )}
        <div className="number-beneficiaries-value">
          <input
            type="tel"
            {...input}
            onBlur={(event) => {
              handleChangeBeneficiaryNumberInput(!event.target.value
              || isNaN(event.target.value) ? 0 : parseInt(event.target.value, 10));
            }}
            onChange={(event) => {
              handleChangeBeneficiaryNumberInput(event.target.value);
            }}
          />
        </div>
        {isMobileDimensions()
          ? (
            <div
              role="presentation"
              onClick={() => handleChangeBeneficiaryNumber(1)}
              className="increment-button"
            >
              <span>+</span>
            </div>
          )
          : (
            <i
              role="presentation"
              className={`${fieldValue >= max ? 'fas' : 'far'} fa-plus-square`}
              onClick={() => handleChangeBeneficiaryNumber(1)}
            />
          )}
      </div>
      <h5>
        {I18n.t(`travelInsuranceForm.step1.numberOfBeneficiaries.${input.name}`)}
        {input.name === 'numberOfBabies'
        && <div className="badge">{I18n.t('travelInsuranceForm.step1.numberOfBeneficiaries.free')}</div>}
      </h5>
    </div>
  );
}

export default SwingInputNumber;
