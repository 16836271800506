// @flow

import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import type { Match, RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { DATE_FORMAT, STEP_ROOT_PAGE_ROUTE, TRIP_AMOUNT } from '../../const';
import { CODES, FORMULA_CODES } from '../../services/coverages';
import { formatPrice } from '../../services/formator';
import { gtagService } from '../../services/gtagService';
import type { TripInfos } from '../../types/storage';
import type { Formula, OptionalCoverage, Quote } from '../steps/types/steps';
import { storeStartContract } from '../../state/storage/storageService';
import { fetchQuote, isFutur } from '../../utils';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import { setIsQuoteLoading } from '../../state/submission/submissionService';

type Props = {
  match: Match,
  history: RouterHistory,
  tripInfos: TripInfos,
  validTripInfos: boolean,
  offerCode: string,
  options: [OptionalCoverage],
  formula: Formula,
  step: number,
  startContract: string,
  dispatch: Function,
  quote: Quote,
  isQuoteLoading: Quote,
}

function Informations(props: Props) {
  const {
    match,
    tripInfos,
    offerCode,
    options,
    formula,
    step,
    history,
    startContract,
    dispatch,
    quote,
    validTripInfos,
    isQuoteLoading,
  } = props;

  const handleNextStep = () => {
    dispatch(setIsQuoteLoading(true));
    tripInfos.travelDateRange = ({
      startDate: moment(tripInfos.travelDateRange.startDate).format(DATE_FORMAT.LOCAL_FORMAT),
      endDate: moment(tripInfos.travelDateRange.endDate).format(DATE_FORMAT.LOCAL_FORMAT),
    });
    const valuesToSubmit = {
      ...tripInfos,
      offerCode: offerCode || null,
    };
    dataLayer.showGTM(TAG_EVENT.CLIC_TRAVEl_TYPE(tripInfos.travelType));
    dataLayer.showGTM(TAG_EVENT.CLIC_FIND_COVERAGE);
    fetchQuote(
      valuesToSubmit,
      startContract,
      parseInt(match.params.number, 10),
      history.push,
      dispatch,
    );
  };

  const [showData, setShowData] = useState(false);
  const showButton = tripInfos.expenses
    && (tripInfos.numberOfAdults !== 0 || tripInfos.numberOfKids !== 0)
    && tripInfos.countryOfResidence && tripInfos.destinations
    && tripInfos.destinations.length !== 0 && tripInfos.travelDateRange;

  const cbPreSelected = formula && formula.code === FORMULA_CODES.ZEN && options
    && options.filter((option) => option.offerCode === formula.code).map(
      (option) => option.coverage.code,
    ).includes(CODES.PREMIUM_BK_OPTION);

  const handleUpdateClick = () => {
    if (props.offerCode) {
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1?offerCode=${props.offerCode}`);
    } else {
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  };

  const findContractEffectiveDate = () => {
    if (!tripInfos.travelDateRange) {
      return moment();
    }
    const startDate = moment(tripInfos.travelDateRange.startDate).startOf('day');
    const startDateWithDelay = moment(tripInfos.travelDateRange.startDate).add(90, 'd').startOf('day');
    if (cbPreSelected && isFutur(startDateWithDelay)) {
      return startDateWithDelay;
    }
    if (isFutur(startDate)) {
      return startDate;
    }
    return moment().add(1, 'day').startOf('day');
  };

  useEffect(() => {
    setShowData(step === 1 || step === 4);
    dispatch(storeStartContract(findContractEffectiveDate().format(DATE_FORMAT.LOCAL_FORMAT), false));
  }, [cbPreSelected]);

  useEffect(() => {
    setShowData(step === 1 || isNaN(step) || (step === 2 && quote && quote.error && quote.error.errorCode));
  }, [step]);

  return (
    <div className="informations">
      <div className="header" role="presentation" onClick={() => {
          setShowData(!showData);
          dataLayer.showGTM(TAG_EVENT.CLIC_INFORMATIONS);
        }}
      >
        <div className="title">
          <i className="far fa-list-alt" />
          <h2 className="text-title">{I18n.t('information.title')}</h2>
        </div>
        <i className={showData ? 'fas fa-chevron-down chevron' : 'fas fa-chevron-right chevron'} />
      </div>
      {showData && (
        <div className="data">
          {tripInfos.travelType || offerCode
            ? (
              <div className="recap">
                {(offerCode) && (
                  <h3 className="offer-recap">
                    {I18n.t(`formula.names.${(formula && formula.code) || offerCode}`)}
                  </h3>
                )}
                {tripInfos.travelType &&
                <div className="recap-header">
                  <h3 className="title-recap">{I18n.t('information.travel')}</h3>
                  {step !== 1
                  && (
                    <h3 className="update" role="presentation" onClick={() => {
                      handleUpdateClick();
                      dataLayer.showGTM(TAG_EVENT.CLIC_EDIT_INFOS);
                    }}>
                      {I18n.t('information.update')}
                    </h3>
                  )}
                </div>
                }
                {tripInfos.travelType
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.insuranceType')}</h4>
                    {I18n.t(`travelInsuranceForm.step1.insuranceType.${tripInfos.travelType}`)}
                  </div>
                )}
                {tripInfos.numberOfAdults !== 0 && !isNaN(tripInfos.numberOfAdults)
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.adults')}</h4>
                    {tripInfos.numberOfAdults}
                  </div>
                )}
                {tripInfos.numberOfKids !== 0 && !isNaN(tripInfos.numberOfKids)
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.kids')}</h4>
                    {tripInfos.numberOfKids}
                  </div>
                )}
                {tripInfos.numberOfBabies !== 0 && !isNaN(tripInfos.numberOfBabies)
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.babies')}</h4>
                    {tripInfos.numberOfBabies}
                  </div>
                )}
                {tripInfos.countryOfResidence && (tripInfos.numberOfAdults !== 0
                  || tripInfos.numberOfKids !== 0)
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.residence')}</h4>
                    {tripInfos.countryOfResidence.label}
                  </div>
                )}
                {tripInfos.destinations
                && tripInfos.destinations.length !== 0
                && (tripInfos.numberOfAdults !== 0 || tripInfos.numberOfKids !== 0)
                && tripInfos.countryOfResidence
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.destinations')}</h4>
                    {tripInfos.destinations.filter((v) => !v.isFixed).map((v) => v.label).join(', ')}
                  </div>
                )}

                {tripInfos.travelDateRange
                && (tripInfos.numberOfAdults !== 0 || tripInfos.numberOfKids !== 0)
                && tripInfos.countryOfResidence && tripInfos.destinations
                && tripInfos.destinations.length !== 0
                && (
                  <div>
                    <div className="data-item">
                      <i className="fas fa-diamond" />
                      <h4 className="data-item-title">{I18n.t('information.data.dates')}</h4>
                      <div className="recap-date">
                        {moment(tripInfos.travelDateRange.startDate).format(I18n.t('date.DATE_FORMAT'))}
                        <i className="fas fa-arrow-right" />
                        {moment(tripInfos.travelDateRange.endDate).format(I18n.t('date.DATE_FORMAT'))}
                      </div>
                    </div>
                  </div>
                )}
                {tripInfos.amount && (parseInt(tripInfos.amount) < TRIP_AMOUNT.MAX && parseInt(tripInfos.amount) >= TRIP_AMOUNT.MIN)
                && (tripInfos.numberOfAdults !== 0 || tripInfos.numberOfKids !== 0)
                && tripInfos.countryOfResidence && tripInfos.destinations
                && tripInfos.destinations.length !== 0 && tripInfos.travelDateRange
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.total')}</h4>
                    {formatPrice(tripInfos.amount)}
                  </div>
                )}
                {tripInfos.buyingDate
                  && (tripInfos.numberOfAdults !== 0 || tripInfos.numberOfKids !== 0)
                  && tripInfos.countryOfResidence && tripInfos.destinations
                  && tripInfos.destinations.length !== 0 && tripInfos.travelDateRange
                  && (
                    <div className="data-item">
                      <i className="fas fa-diamond" />
                      <h4 className="data-item-title">{I18n.t('information.data.buying')}</h4>
                      {moment(tripInfos.buyingDate).format(I18n.t('date.DATE_FORMAT'))}
                    </div>
                )}
                {tripInfos.expenses
                && (tripInfos.numberOfAdults !== 0 || tripInfos.numberOfKids !== 0)
                && tripInfos.countryOfResidence && tripInfos.destinations
                && tripInfos.destinations.length !== 0 && tripInfos.travelDateRange
                && (
                  <div className="data-item">
                    <i className="fas fa-diamond" />
                    <h4 className="data-item-title">{I18n.t('information.data.expenses')}</h4>
                    {I18n.t(`travelInsuranceForm.step1.expenses.${tripInfos.expenses.value}`)}
                  </div>
                )}
                {step === 1 && validTripInfos && showButton &&
                <div className="submit">
                  <button type="button" onClick={handleNextStep} disabled={isQuoteLoading}>
                    <h3>{I18n.t(`travelInsuranceForm.step1.${offerCode ? 'submitOfferCode' : 'submit'}`)}</h3>
                    {isQuoteLoading ?
                      <i className="fas fa-circle-notch fa-spin" /> :
                      <i className="far fa-long-arrow-right" />
                    }
                  </button>
                </div>
                }
              </div>
            )
            : (
              <h3 className="no-data">
                {I18n.t('information.noData')}
              </h3>
            )}

        </div>
      )}
    </div>
  );
}

export default withRouter(connect((state) => ({
  tripInfos: state.storage.tripInfos,
  offerCode: state.storage.offerCode,
  options: state.storage.options,
  formula: state.storage.formula,
  startContract: state.storage.startContract,
  quote: state.storage.quote,
  validTripInfos: state.storage.validTripInfos,
  isQuoteLoading: state.submission.isQuoteLoading,
}))(Informations));
