// @flow

import React from 'react';
import { isMobileDimensions } from '../../../services/dimensions';
import type { Formula, Quote } from '../types/steps';
import ResponsiveFormulaTable from './ResponsiveFormulaTable';
import DesktopFormulaTable from './DesktopFormulaTable';

type Props = {
  step: number,
  quote: Quote,
  formula: Formula,
  chosenFormula: Formula,
  calculateAmountPerDay: Function,
  setOptionToSelect: Function,
  totalPrice: number,
  handleChoseFormula: Function,
  sectionRef: HTMLDivElement,
}

function FormulaTable(
  {
    step,
    calculateAmountPerDay,
    quote,
    formula,
    chosenFormula,
    setOptionToSelect,
    totalPrice,
    handleChoseFormula,
    sectionRef,
  }: Props,
): React$Node {

  const isCoverageEmpty = () => quote && Object.keys(quote.coverageWithCategories).length === 0;

  const getFormulaTitleByFormulaName = (title) => {
    return title.substring(title.indexOf('-') + 1);
  };

  if (isMobileDimensions(step) || isCoverageEmpty()) {
    return (
      <ResponsiveFormulaTable
        quote={quote}
        getFormulaTitleByFormulaName={getFormulaTitleByFormulaName}
        calculateAmountPerDay={calculateAmountPerDay}
        formula={formula}
        chosenFormula={chosenFormula}
        setOptionToSelect={setOptionToSelect}
        totalPrice={totalPrice}
        handleChoseFormula={handleChoseFormula}
        sectionRef={sectionRef}
      />
    );
  }
  return (
    <DesktopFormulaTable
      quote={quote}
      getFormulaTitleByFormulaName={getFormulaTitleByFormulaName}
      formula={formula}
      calculateAmountPerDay={calculateAmountPerDay}
      chosenFormula={chosenFormula}
      setOptionToSelect={setOptionToSelect}
      totalPrice={totalPrice}
      handleChoseFormula={handleChoseFormula}
    />
  );
}

export default FormulaTable;
