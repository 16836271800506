// @flow

import moment from 'moment';
import type { TravelerInfos, TripInfos } from './types/storage';
import backgroundImg from './img/background-travel.jpg';
import type { Formula, OptionalCoverage, Quote } from './components/steps/types/steps';
import fileApi from './network/api/fileApi';
import quoteApi from './network/api/quoteApi';
import {
  clearQuote,
  clearQuoteInfos,
  clearTravelerInfos,
  storeMaxPage,
  storeQuote,
  storeStartContract,
  storeTripInfos,
} from './state/storage/storageService';
import { setIsQuoteLoading } from './state/submission/submissionService';
import { ERROR_REASON, ERROR_ROUTE, STEP_ROOT_PAGE_ROUTE } from './const';
import { CODES } from './services/coverages';

export const getQueryParams = (): { [key: string]: string } => {
  const validQueryParams = window.location.search.substring(1)
    .split('&')
    .filter((param) => /^\w+[=]\w+$/g.test(param))
    .join();
  return validQueryParams
    ? JSON.parse(`{"${
      decodeURI(validQueryParams)
        .replace(/"/g, '\\"')
        .replace(/,/g, '","')
        .replace(/=/g, '":"')}"}`)
    : {};
};

export const sourceForImg = (quote: Quote) => {
  if (quote && quote.offers[0]) {
    return quote.urlImageZone || backgroundImg;
  }
  return backgroundImg;
};

export const isPast = (date: moment) => moment(date).diff(moment(), 'days') < 0;

export const isFutur = (date: moment) => moment(date).diff(moment(), 'days') > 0;

export const formatPrice = (price: number) => {
  let strPrice = price.toString();
  const len = strPrice.length;
  if (len > 3) {
    strPrice = `${strPrice.substring(0, len - 3)} ${strPrice.substring(len - 3, len)}`;
  }
  return `${strPrice} €`;
};

export const formatPriceClean = (price: number) => price.toFixed(2).toLocaleString().replace('.', ',');

export const calculateAmountPerDay = (range, totalPrice, discount, finalPrice) => {
  let price = totalPrice;
  if (discount && finalPrice) {
    price = finalPrice;
  }
  return roundUp(price / range, 2);
};

function roundUp(num, precision) {
  precision = Math.pow(10, precision);
  return Math.round(num * precision) / precision;
}

export const findAllOccurrences = (source: string, find: string) => {
  const result = [];
  for (let i = 0; i < source.length; ++i) {
    if (source.substring(i, i + find.length) === find) {
      result.push(i);
    }
  }
  return result;
};

export const calculateDiscount = (totalPrice: number, discount: number) => (
  discount ? Number(parseFloat(discount * totalPrice).toFixed(2)) : 0
);

function isObject(obj: ?Object) {
  return obj !== null && typeof obj === 'object';
}

export function isObjectNotArray(item: ?Object) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target: Object, ...sources: Object) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObjectNotArray(source[key])) {
        // eslint-disable-next-line compat/compat
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        // eslint-disable-next-line compat/compat
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
}

export function isSameAddress(value: TravelerInfos) {
  if (value.beneficiary) {
    const result = value.beneficiary.filter((benef) => benef.isSameAddress === false);
    return result.length === 0;
  }
  return false;
}

export function formatQuoteBodyFromParams(
  chosenFormula: Formula,
  tripInfos: TripInfos,
  selectedOptionalCoverages: OptionalCoverage[],
  selectedOptions: OptionalCoverage[],
) {
  const quoteBody = JSON.parse(JSON.stringify(tripInfos));
  quoteBody.offerCode = chosenFormula.code;
  quoteBody.selectedOptions = formatCoveragesBodyFromParams(
    chosenFormula,
    selectedOptionalCoverages,
    selectedOptions,
  );

  return quoteBody;
}

export function formatCoveragesBodyFromParams(
  chosenFormula: Formula,
  selectedOptionalCoverages: OptionalCoverage[],
  selectedOptions: OptionalCoverage[],
): [] {
  const quoteSelectedOptionalCoverages = selectedOptionalCoverages
    .filter((option) => option.offerCode === chosenFormula.code)
    .map((optionalCoverage) => optionalCoverage.coverage);

  const actualFormulaOptions = selectedOptions
    .filter((option) => option.offerCode === chosenFormula.code);

  const quoteSelectedCoverages = actualFormulaOptions
    .map((optionalCoverage) => optionalCoverage.coverage);

  const twinToAdd = actualFormulaOptions
    .flatMap((selectedCoverageItem) => selectedCoverageItem.coverage)
    .filter((cov) => cov.twinsCoverages !== null)
    .flatMap((i) => i.twinsCoverages);

  return quoteSelectedOptionalCoverages.concat(quoteSelectedCoverages).concat(twinToAdd);
}

export const fetchFile = (url: ?string, filename: ?string) => {
  let finalFileName = filename;
  if (!url) {
    return;
  }
  if (!finalFileName) {
    finalFileName = getFileNameFromUrl(url);
  }
  const fileParams = url.split('/files')[1];
  if (!fileParams) {
    return;
  }
  fileApi.fetchFileByUrl(fileParams)
    .then((data) => data.blob())
    .then(
      (blob) => {
        // $FlowFixMe
        downloadBlob(blob, finalFileName);
      },
    ).catch((e) => console.error(e));
};

export const getFileNameFromUrl = (url: string): string => {
  const splittedUrl = url.split('/');
  return splittedUrl[splittedUrl.length - 1];
};

export const downloadBlob = (blobOrUrl: string, filename: string) => {
  if (typeof blobOrUrl === 'string') {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = blobOrUrl;
    a.download = filename;
    a.click();
  } else if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobOrUrl, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const blob = new Blob([blobOrUrl], { type: 'application/pdf' });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }
};

export const fetchQuote = (tripInfos: TripInfos, startContractDate: string, number: number, redirect: Function, dispatch: Function, errorRedirection: boolean) => {
  quoteApi.postTripInfos(tripInfos)
    .then((data) => data.json())
    .then((data) => {
      if (!errorRedirection) {
        dispatch(clearTravelerInfos());
      }
      dispatch(clearQuoteInfos());
      dispatch(storeQuote(data));
      dispatch(storeStartContract(startContractDate, true));
      dispatch(storeMaxPage(number + 1));
      redirect(`${STEP_ROOT_PAGE_ROUTE}/2`);
    })
    .catch((responseError) => {
      responseError.then(() => {
        dispatch(clearQuoteInfos());
        dispatch(clearQuote());
        dispatch(storeMaxPage(1));
        redirect(`${STEP_ROOT_PAGE_ROUTE}${ERROR_ROUTE}?reason=${ERROR_REASON.noQuote}`);
      });
    })
    .finally(() => {
      dispatch(storeTripInfos(tripInfos, true));
      dispatch(setIsQuoteLoading(false));
    });
};

export const capitalize = (s: any) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isLugageCoverage = (code: string) => code === CODES.LATE_LUGGAGE || code === CODES.LOST_LUGGAGE;

export const missingLugageCoverage = (code: string) => code === CODES.LATE_LUGGAGE ? CODES.LOST_LUGGAGE : CODES.LATE_LUGGAGE;

export const getOptionCoverageFromQuoteByCode = (quote: Quote, code: string) => {
  if (!quote || !quote.coverageWithCategories) {
    return null;
  }
  return Object.entries(quote.coverageWithCategories).map(entry => entry[1]).flat().filter(coverage => {
    if (coverage.code === code) {
      return true;
    }
  })[0];
};

export const transformLastSeparator = (list: string[], separator: string, lastSeparator: string) => {
  const lastElement = list.pop();
  if (list.length > 0) {
    return list.join(separator) + lastSeparator + lastElement;
  }
  return lastElement;
};
