// @flow

import { RestClient } from '../network';

const paymentApi = {
  getIntentKey: (data: Object, mustRefuse) => new RestClient('/payment/intent', 'POST').addParam('error', mustRefuse).jsonBody(data).execute(),
  checkPayment: (data: Object) => new RestClient('/payment/check', 'POST').jsonBody(data).execute(),
};

export default paymentApi;
