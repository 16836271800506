// @flow

import { RestClient } from '../network';

const footerApi = {
  get: (type: ?string) => new RestClient('/site/footer', 'GET')
    .addParam('type', type)
    .execute(),
};

export default footerApi;
