// @flow

import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';

type Field = {
  label: string,
  placeholder: string,
  value: string,
  key: string,
  type: string,
}

type Props = {
  field: Field,
  onSubmit: Function,
  format?: Function,
}

type State = {
  isEditOn: boolean,
  value: string,
  confirmPasswordValue: string,
};

export default class ProfilInput extends React.Component<Props, State> {
  static defaultProps = {
    format: null,
  };

  state = {
    isEditOn: false,
    value: '',
    confirmPasswordValue: '',
  };

  switchEditMode = () => {
    this.setState({
      isEditOn: !this.state.isEditOn,
      value: this.props.field.value,
      confirmPasswordValue: '',
    });
  };

  handleModification = (event: any) => {
    let value = event.target.value;
    if (this.props.format) {
      value = this.props.format(value);
    }
    this.setState({
      value,
    });
  };

  handleModificationConfirmPassword = (event: any) => {
    this.setState({
      confirmPasswordValue: event.target.value,
    });
  };

  onSubmit = () => {
    this.setState({
      isEditOn: false,
    });

    if (!this.isPasswordField()) {
      this.props.onSubmit({
        [this.props.field.key]: this.state.value,
      });
    }
    if (this.isPasswordField()) {
      this.props.onSubmit({
        [this.props.field.key]: this.state.value,
        confirmPassword: this.state.confirmPasswordValue,
      });
    }
  };

  isPasswordField = () => this.props.field.type === 'password';

  render() {
    return (
      <div className="profil-input">
        <div className="profil-left">
          <label className="label"><Translate value={this.props.field.label} /></label>
          {
            this.state.isEditOn ?
              (<input
                onChange={this.handleModification}
                type={this.props.field.type}
                placeholder={I18n.t(this.props.field.placeholder)}
                value={this.state.value}
              />)
              : (!this.isPasswordField() && <p>{this.props.field.value}</p>)
          }
          {
            this.state.isEditOn && this.isPasswordField() &&
            <input
              onChange={this.handleModificationConfirmPassword}
              type={this.props.field.type}
              placeholder={I18n.t('spaceClient.profil.confirmPassword')}
              value={this.state.confirmPasswordValue}
            />
          }
        </div>
        <div className="profil-right">
          <div className={this.state.isEditOn ? 'btn cancel-btn' : ' btn edit-btn'} onClick={this.switchEditMode}>
            <Translate value={this.state.isEditOn ? 'actions.CANCEL' : 'actions.EDIT'} />
          </div>
          {
            this.state.isEditOn && (
              <div className="btn save-btn" onClick={this.onSubmit}>
                <Translate value="actions.SAVE" />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
