// @flow

import React, {useEffect} from 'react';
import * as Sentry from '@sentry/browser';
import { Translate } from 'react-redux-i18n';

type Props = {
  eventId: string,
};

export default class ErrorPage extends React.PureComponent<Props> {
  render() {
    return (
      <div className="error-page">
        <div className="error-Message">
          <h1><Translate value="sentry.TITLE" /></h1>
          <h3><Translate value="sentry.SUBTITLE" /></h3>
          <p>
            <Translate value="sentry.LINK_DESCRIPTION" />
            <button
              onClick={() => Sentry.showReportDialog({ eventId: this.props.eventId })}
            >
              <Translate value="sentry.LINK_ACTION" />
            </button>
          </p>
        </div>
      </div>
    );
  }
}
