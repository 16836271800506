// @flow

import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { fuzzysearch } from '../../services/fuzzySearch';
import Select from 'react-select';
import { levDist } from '../../services/levensteinSort';

type Field = {
  label: string,
  placeholder: string,
  value: string,
  key: string,
  valueList: [{
    label: string,
    value: string,
  }]
}

type Props = {
  field: Field,
  onSubmit: Function,
  format?: Function,
}

type State = {
  isEditOn: boolean,
  value: string,
  confirmPasswordValue: string,
};

class ProfilSelectInput extends React.Component<Props, State> {
  static defaultProps = {
    format: null,
  };

  state = {
    isEditOn: false,
    value: '',
    confirmPasswordValue: '',
  };

  switchEditMode = () => {
    this.setState({
      isEditOn: !this.state.isEditOn,
      value: this.props.field.value,
      confirmPasswordValue: '',
    });
  };

  handleModification = (valueUpdated: any) => {
    let value = valueUpdated && valueUpdated.value;
    if (this.props.format) {
      value = this.props.format(value);
    }
    this.setState({
      value,
    });
  };

  onSubmit = () => {
    this.setState({
      isEditOn: false,
    });

    this.props.onSubmit({
      [this.props.field.key]: this.state.value,
    });
  };

  findValue = (valueKey: string) => this.props.field.valueList.filter(item => valueKey === item.value)[0];

  sortByRelevance = (inputValue: string) => {
    if (inputValue && inputValue !== '') {
      this.props.field.valueList
        .sort((curr, next) =>
          levDist(curr.label, inputValue) - levDist(next.label, inputValue)
        )
    }
  };

  render() {
    const stylesMultiSelect = {
      multiValue: (base, state) => (state.data.isFixed ? (this.props.destinationSelectOpen ? {
        display: 'none',
      } : {
        backgroundColor: 'white !important',
        cursor: 'pointer',
      }) : { ...base }),
      multiValueLabel: (base, state) => (state.hasValue ? (state.data.isFixed ? ({
        fontSize: '16px',
        margin: '0 !important',
      }) : {
        paddingLeft: '5px',
        fontSize: '16px',
        color: 'black !important',
      }) : { ...base }),
      multiValueRemove: (base, state) => (state.data.isFixed ? {
        display: 'none',
      } : null),
    };
    return (
      <div className="profil-input">
        <div className="profil-left">
          <label className="label"><Translate value={this.props.field.label} /></label>
          {
            this.state.isEditOn ?
              (<Select
                value={this.findValue(this.state.value)}
                isClearable
                options={this.props.field.valueList}
                classNamePrefix="swing-select"
                onInputChange={this.sortByRelevance}
                onChange={this.handleModification}
                styles={stylesMultiSelect}
                filterOption={(option, input) => fuzzysearch(input || '', option.label)}
                noOptionsMessage={() => I18n.t('travelInsuranceForm.step1.noOptions')}
              />)
              : (this.props.field.valueList.length > 0 && <p>{this.findValue(this.props.field.value) && this.findValue(this.props.field.value).label}</p>)
          }
        </div>
        <div className="profil-right">
          <div className={this.state.isEditOn ? 'btn cancel-btn' : ' btn edit-btn'} onClick={this.switchEditMode}>
            <Translate value={this.state.isEditOn ? 'actions.CANCEL' : 'actions.EDIT'} />
          </div>
          {
            this.state.isEditOn && (
              <div className="btn save-btn" onClick={this.onSubmit}>
                <Translate value="actions.SAVE" />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default ProfilSelectInput;