// @flow

import React from 'react';
import type { Formula, Quote } from '../../../components/steps/types/steps';
import { FORMULA_CODES, STATUS } from '../../../services/coverages';
import { I18n } from 'react-redux-i18n';
import { formatPrice } from '../../../services/formator';
import { MOBILE_DIMENSIONS } from '../../../const';
import { transformLastSeparator } from '../../../utils';
import type { Coverage } from '../../../types/contractTypes';

type Props = {
  quote: Quote,
  chosenFormula: Formula,
  selectedOptions: Coverage[],
  handleCheckOption: (option: Coverage) => {},
  addOptionIcon: (code: string) => React.Node,
  isComfortAndUniqueAndSpecialCategory: (option: Coverage) => boolean,
  isOptionInFormulaOrOptional: (option: Coverage) => boolean,
}

function OptionStep2(
  {
    quote,
    chosenFormula,
    selectedOptions,
    handleCheckOption,
    addOptionIcon,
    isComfortAndUniqueAndSpecialCategory,
    isOptionInFormulaOrOptional,
  }: Props,
): React {

  const mobileMode = window.innerWidth < MOBILE_DIMENSIONS.MAX_WIDTH;

  const newArrayDataOfObject = Object.values(quote.coverageWithCategories);
  let options = [];
  for (let i = 0; i < newArrayDataOfObject.length; i++) {
    options = options.concat(newArrayDataOfObject[i].filter((item) => item.offerStatus[0].status === STATUS.OPTIONAL && !item.isOption));
  }
  if (mobileMode) {
    options = options.concat(newArrayDataOfObject[0].filter((item) => item.isOption));
  }
  const getForStatus = (option: Coverage, status: string): [] => option.offerStatus.filter(offer => offer.status === status);

  const showOptionContent = (option) => {
    return option.shortDescription.split(' - ')
      .map((description: string, index: number) => (
        // Pour personaliser une option avec un titre et/ou du texte sans puce
        // On ajoute le caractère '_' juste avant le wording
        description.startsWith('_')
          ? (
            <div key={description} className={`option-item ${index === 0 ? 'text-top' : 'text-bottom'}`}>
              <div className="option-item-sub">
                <div>
                  <h3 className="diamond-h3">{description.substring(1)}</h3>
                </div>
              </div>
            </div>
          )
          : (
            <div key={description} className="option-item">
              <i className="fas fa-diamond" />
              <div className="option-item-sub">
                <div>
                  <h3 className="diamond-h3">{description}</h3>
                </div>
              </div>
            </div>
          )
      ))
  }

  return (
    <div className="option no-shadow">
      <div className="option-multi">
        <div className="option-item">
          <div className={mobileMode ? 'box mobile' : 'box'}>
            {
              chosenFormula
              && options.map((option) => {
                  const currentOffer = option.offerStatus.find((offer) => (
                    offer.code === chosenFormula.code
                    && offer.status !== STATUS.TRUE
                    && (
                      offer.code === chosenFormula.code
                      && offer.status !== STATUS.FALSE
                    ) || (
                      offer.status === STATUS.OPTIONAL
                      && offer.code === FORMULA_CODES.CONFORT
                    )
                  ));
                  return currentOffer
                    && (
                      <div
                        key={option.code}
                        className={isOptionInFormulaOrOptional(option) ? 'box-option' : 'box-option disabled'}
                      >
                        <div className="option-title">
                          <div className="option-header">
                            <button
                              aria-label=" "
                              type="button"
                              disabled={!isOptionInFormulaOrOptional(option)}
                              className={
                                currentOffer.status === STATUS.TRUE
                                || selectedOptions.length > 0
                                && selectedOptions.filter((selectedOption) => (selectedOption.offerCode === chosenFormula.code))
                                  .map((item) => item.coverage.code)
                                  .includes(option.code) ? 'select-option-button fas fa-check-square' : 'select-option-button far fa-square'
                              }
                              onClick={() => handleCheckOption(option)}
                            />
                            {
                              option.category === '0'
                              && (
                                addOptionIcon(option.code)
                              )
                            }
                            <h3 className="header-h3">
                              {option.name}
                              {
                                option.twinsCoverages
                                && option.twinsCoverages.map((coverage) => {
                                  return ', ' + coverage.name;
                                })
                              }
                            </h3>
                            <div className="info-icon-container">
                              <i className={`fal fa-info-circle${mobileMode ? ' hidden' : ''}`}>
                                {
                                  option.description
                                  && (
                                    <div className="tooltiptext">
                                      <h3 className="tooltip-h3">{option.description}</h3>
                                      {
                                        option.twinsCoverages && option.twinsCoverages.map((coverage) => {
                                          if (coverage.description) {
                                            return (
                                              <h3 key={coverage.code} className="tooltip-h3">
                                                {coverage.description}
                                              </h3>
                                            );
                                          }
                                        })
                                      }
                                    </div>
                                  )
                                }
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="box-description">
                          {showOptionContent(option)}
                          {
                            option.twinsCoverages
                            && option.twinsCoverages.map((coverage) => (
                              <div key={coverage.code} className="option-item">
                                {coverage.shortDescription &&
                                  (
                                    <>
                                      <i className="fas fa-diamond" />
                                      <div className="option-item-sub">
                                        <div>
                                          <h3 className="diamond-h3">{coverage.shortDescription}</h3>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                            ))
                          }
                          <div className="box-description-sub">
                            {
                              option.linkedCoverages.map((coverage) => (
                                <div key={coverage.code} className="option-item">
                                  <i className="fas fa-diamond" />
                                  <h3 className="diamond-h3">{coverage.name}</h3>
                                </div>
                              ))
                            }
                            {
                              isComfortAndUniqueAndSpecialCategory(option)
                              && (
                                <p>
                                  {I18n.t('travelInsuranceForm.step2.completeOffer.option.notCumulativeOption')}&nbsp;
                                  {
                                    transformLastSeparator(
                                      newArrayDataOfObject[0].filter((option0) => (!option0.isUnique
                                        && option0.offerStatus.find((offer) =>
                                          (offer.code === chosenFormula.code && offer.status !== STATUS.FALSE))
                                      ))
                                        .map((option0) => option0.name),
                                      ', ',
                                      I18n.t('travelInsuranceForm.step2.completeOffer.option.and'),
                                    )
                                  }
                                </p>
                              )
                            }
                          </div>
                        </div>
                        <div className="box-footer">
                          <div className="box-footer-price">
                            <h3 className="price-h3">
                              {
                                currentOffer.status === STATUS.OPTIONAL
                                  ? formatPrice(currentOffer.price)
                                  : (
                                    currentOffer.status === STATUS.TRUE
                                      ? I18n.t('travelInsuranceForm.step2.completeOffer.option.included')
                                      : ''
                                  )
                              }
                            </h3>
                            {
                              currentOffer.price < 0
                              && (
                                <p>{I18n.t('travelInsuranceForm.step2.completeOffer.option.onBasket')}</p>
                              )
                            }
                          </div>
                          {
                            (option.category !== '0'
                              && getForStatus(option, STATUS.TRUE).length > 0)
                              ? (
                                <div>
                                  <h3 className="star-h3">
                                    <i className="fas fa-star" />
                                    {I18n.t('travelInsuranceForm.step2.completeOffer.option.includedInFormula')}
                                    {
                                      getForStatus(option, STATUS.TRUE)
                                        .map((offer) => (` ${I18n.t(`formula.shortNames.${offer.code}`)}`))
                                    }
                                  </h3>
                                </div>
                              ) : (
                                !isOptionInFormulaOrOptional(option)
                                  ? (
                                    <div className="footer-white">
                                      <p>
                                        <i className="fas fa-star" />
                                        {I18n.t('travelInsuranceForm.step2.completeOffer.option.onlyEnableFor')}
                                        {
                                          getForStatus(option, STATUS.OPTIONAL)
                                            .map((offer) => (` ${I18n.t(`formula.shortNames.${offer.code}`)}`))
                                        }
                                      </p>
                                    </div>
                                  ) : ''
                              )
                          }
                        </div>
                      </div>
                    );
                },
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionStep2;
