// @flow

import React, { useEffect, useState } from 'react';
// $FlowFixMe
import { Field, Form } from 'react-final-form';
import type { Match } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { I18n, setLocale } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import { gtagService } from '../../services/gtagService';
import { LANGUAGE, languagesAvailable } from '../../services/languages';
import StepsFooter from '../../components/steps/StepsFooter';
import siteApi from '../../network/api/siteApi';
import type { Theme } from '../../types/faqTypes';
import type { Site } from '../../types/storage';

const changeLanguage = (language, dispatch) => {
  dispatch(setLocale(language.code));
  localStorage.setItem(LANGUAGE, language.code);
};

const initLanguage = (i18nLanguage) => {
  if (!localStorage.getItem(LANGUAGE)) {
    localStorage.setItem(LANGUAGE, i18nLanguage);
  }

  return languagesAvailable.filter(infos => infos.code === localStorage.getItem(LANGUAGE))[0];
};

type Props = {
  match: Match,
  dispatch: Function,
  locale: string,
  site: Site,
};

const Faq = (props: Props) => {
  const lang = localStorage.getItem(LANGUAGE);
  const [query, setQuery] = useState<string>('');
  const [indexSelected, setIndexSelected] = useState<number>(0);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [mailHotline, setMailHotline] = useState<string>('');
  const [dropMenu, setDropMenu] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  (open: boolean);
  const [flagInfos, setFlagInfos] = useState(initLanguage(props.locale));
  (flagInfos: {
    code: string,
    imgPath: string,
  });

  useEffect(() => {
    if (lang) {
      siteApi.fetchFaq(lang, query)
        .then(response => response.json())
        .then(faqResponse => setThemes(faqResponse));

      setIndexSelected(0);
    }

    setMailHotline(props.site.hotlineMail !== null ? props.site.hotlineMail : '');
  }, [lang, query]);

  const searchQuestion = (queryParam) => {
    gtagService.setSearchFaq(queryParam.search);
    setQuery(queryParam.search);
  };

  return (
    <div className="faq-container">
      <div className="faq-page">
        <div className="flag">
          <img
            onClick={() => setOpen(!open)}
            src={flagInfos.imgPath}
            alt=""
          />
          {open &&
          <div className="form-control">
            {
              languagesAvailable.map(language => (
                <div
                  key={language.code}
                  onClick={() => {
                    changeLanguage(language, props.dispatch);
                    setOpen(!open);
                    setFlagInfos(language);
                  }}
                >
                  <img
                    src={language.imgPath}
                    alt={language.code}
                  />
                </div>
              ))
            }
          </div>
          }
        </div>
        <div className="faq-aside-nav">
          <div className="faq-header">
            <div className="faq-header-title">{I18n.t('faq.title')}</div>
            <div className="faq-search">
              <Form
                onSubmit={searchQuestion}
                render={({ handleSubmit, form }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="faq-input">
                      <Field
                        className="form-control"
                        component="input"
                        placeholder={I18n.t('faq.placeholder')}
                        name="search"
                        type="text"
                        autoComplete="off"
                      />
                      <button
                        className="btn-clear"
                        type="button"
                        onClick={() => {
                          setQuery('');
                          form.reset();
                        }}
                      >
                        <i className="fal fa-times" />
                      </button>
                    </div>
                    <button className="btn" type="submit">
                      {I18n.t('faq.search')}
                    </button>
                  </form>
                )}
              />
            </div>
          </div>
          <a className="faq-aside-menu" onClick={() => {
            setDropMenu(!dropMenu);
          }}>
            {I18n.t('faq.category')}
            {dropMenu ? <i className="fas fa-angle-up" /> : <i className="fas fa-angle-down" />}
          </a>
          <div className={'faq-aside-content' + (dropMenu ? ' faq-aside-content--show' : '')}>
            <ul className="drop-menu">
              {React.Children.toArray(themes.map((theme, index) => {
                return (
                  <li className={index === indexSelected ? 'selected' : ''}>
                    <a className={'faq-nav-link'} onClick={() => {
                      dataLayer.showGTM(TAG_EVENT.CLIC_FAV_LINK_FAQ);
                      setIndexSelected(index);
                    }}>
                      {theme.label}
                    </a>
                  </li>
                )
              }))}
            </ul>
            {mailHotline !== '' && <div className="faq-contact">
              <div className="faq-contact-title">{I18n.t('faq.contact')}</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${mailHotline}`}
              >
                {I18n.t('faq.buttonContact')}
              </a>
            </div>}
          </div>
        </div>
        <div className="faq-content">
          {themes.length === 0 &&
          <div
            className="faq-error-message">
            <i class="far fa-question-circle"></i>
            {
              query === '' ?
                <div>{I18n.t('faq.noFaq')}</div> :
                <div>{I18n.t('faq.noResult')}</div>
            }
          </div>
          }
          {React.Children.toArray(themes.map((theme, index) => {
            return (
              <div className={`faq-theme ${index === indexSelected ? 'faq-theme--selected' : ''}`}>
                <h1 className="faq-theme-title">
                  {theme.label}
                </h1>
                {theme.questions.length > 0 && React.Children.toArray(theme.questions.map(question => {
                  return <div className="faq-question-answer">
                    <h3>{question.question}</h3>
                    <div>{question.answer}</div>
                  </div>
                }))}
                {theme.subthemes.length > 0 && React.Children.toArray(theme.subthemes.map(subtheme => {
                  return subtheme.questions.length > 0 &&
                    <div className="faq-subtheme">
                      <h2>{subtheme.label}</h2>
                      {React.Children.toArray(subtheme.questions.map(question => {
                        return <div className="faq-question-answer">
                          <h3>{question.question}</h3>
                          <div>{question.answer}</div>
                        </div>
                      }))}
                    </div>
                }))}
              </div>
            )
          }))}
          {mailHotline !== '' && <div className="faq-contact">
            <div className="faq-contact-title">{I18n.t('faq.contact')}</div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${mailHotline}`}
            >
              {I18n.t('faq.buttonContact')}
            </a>
          </div>}
        </div>
      </div>
      <StepsFooter />
    </div>
  );
};

export default withRouter(connect(state => ({
  locale: state.i18n.locale,
  site: state.storage.site,
}))(Faq));
