// @flow

import React, { useState } from 'react';
import type { Coverage, OfferStatus } from '../types/steps';
import { COVERAGE_CODE, dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { STATUS } from '../../../services/coverages';

type Props = {
  coverage: Coverage,
  addCoverageRow: Function,
  chosenFormulaCode: string,
  isHidden: boolean,
}

function UnfoldableDesktopCoverage({ coverage, addCoverageRow, chosenFormulaCode, isHidden }: Props): React$Node {
  const [isFold, setIsFold] = useState(true);
  const handleClickSubOption = (coverageCode) => {
    if (coverageCode === COVERAGE_CODE.ASSURANCE_RAPATRIEMENT) {
      dataLayer.showGTM(TAG_EVENT.CLIC_ASSURANCE_RAPATRIEMENT);
    }
    if (coverageCode === COVERAGE_CODE.PROTECTION_MALADIE) {
      dataLayer.showGTM(TAG_EVENT.CLIC_PROTECTION_MALADIE);
    }
  };

  return (
    <>
      <tr key={coverage.code} className={isHidden ? 'hidden' : ''}>
        <td
          className="formula-item cursor"
          onClick={() => {
            setIsFold(!isFold);
          }}
        >
          <div className="text-item">
            <i className="fal fa-info-circle">
              {
                coverage.description
                && (
                  <div className="tooltiptext">
                    <h3>{coverage.description}</h3>
                  </div>
                )
              }
            </i>
            <h3 className="formula-item-text" onClick={() => handleClickSubOption(coverage.code)}>
              {coverage.name}
            </h3>
            <i
              className={`fas fa-chevron-${!isFold ? 'down' : 'right'} chevron`}
              onClick={() => handleClickSubOption(coverage.code)}
            />
          </div>
        </td>
        {(coverage.offerStatus).map((offer: OfferStatus) => {
          switch (offer.status) {
            case STATUS.TRUE:
              return (
                <td
                  className={(chosenFormulaCode === offer.code) ? 'border-side' : 'normal'}
                  key={offer.name}
                >
                  <i className="fa fa-check" />
                  <h4>{coverage.shortDescription}</h4>
                </td>
              );
            case STATUS.FALSE:
              return (
                <td
                  className="normal"
                  key={offer.name}>
                  <i className="fa fa-times" />
                </td>
              );
            default:
          }
        })}
      </tr>
      {
        !isFold
        && coverage.linkedCoverages.map((linkedCoverage) => {
          return addCoverageRow(
            linkedCoverage.title,
            linkedCoverage,
          );
        })
      }
    </>
  );
}

export default UnfoldableDesktopCoverage;
