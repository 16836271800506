// @flow

import React, { useState } from 'react';
import type { Coverage, Formula } from '../types/steps';

type Props = {
  coverage: Coverage,
  addMobileCoverageRow: Function,
}

function UnfoldableMobileCoverage({ coverage, addMobileCoverageRow }: Props): React$Node {
  const [isFold, setIsFold] = useState(true);
  return (
    <div
      className="warranty-item opener"
      key={coverage.code}
      onClick={() => setIsFold(!isFold)}
    >
      <>
        <h3>{coverage.name}</h3>
        <i
          className={`fas fa-chevron-${!isFold ? 'down' : 'right'} chevron`}
        />
        {
          !isFold
          && (coverage.linkedCoverages).map((linkedCoverage) => (
            addMobileCoverageRow(linkedCoverage)
          ))
        }
      </>
    </div>
  );
}

export default UnfoldableMobileCoverage;
