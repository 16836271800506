import { CODE_TO_LABEL, CODES } from './coverages';

const event = 'event';
const brand = 'Mutuaide';
const category = 'Voyage';
const currency = 'EUR';

const NB_RETRIES = 5;
const DELAY_RETRY = 100; // In milliseconds

let hasTrackingAnalytics = false;

export const gtagService = {
  sendViewItemList: (quote) => {
    sendViewItemListEvent(quote)
  },
  sendViewItem: (item, formula, isOption) => {
    formula && isOption ?
      sendViewItemEvent(extractOptionFromCoverage(formula, item)) :
      sendViewMultipleItemsEvent(item)
  },
  sendSelectedItem: (item, formula, isOption) => {
    if (!item) {
      return;
    }
    sendSelectContentEvent(
      formula && isOption ? extractOptionFromCoverage(formula, item) : item
    )
  },
  sendSelectedAllItems: (formula, optionalCoverages, options) => {
    sendSelectAllContentEvent(flatListFromFormulaAndOptions(formula, optionalCoverages, options))
  },
  sendAddToCart: (item, formula, isOption) => {
    if (!item) {
      return;
    }
    sendAddToCartEvent(
      formula && isOption ? extractOptionFromCoverage(formula, item) : item,
    );
  },
  sendAddAllToCart: (formula, optionalCoverages, options) => {
    sendAddAllToCartEvent(flatListFromFormulaAndOptions(formula, optionalCoverages, options));
  },
  sendRemoveFromCart: (item, formula, isOption) => {
    sendRemoveFromCartEvent(
      formula && isOption ? extractOptionFromCoverage(formula, item) : item,
    );
  },
  sendRemoveAllFromCart: (formula, optionalCoverages, options) => {
    sendRemoveAllFromCartEvent(flatListFromFormulaAndOptions(formula, optionalCoverages, options))
  },
  sendBeginCheckout: (formula, optionalCoverages, options, promoCode) => {
    sendBeginCheckoutEvent(flatListFromFormulaAndOptions(formula, optionalCoverages, options), promoCode)
  },
  sendSetCheckoutOption: (checked) => {
    sendSetCheckoutOptionEvent(checked)
  },
  sendCheckoutProgress: (formula, optionalCoverages, options, promoCode) => {
    sendCheckoutProgressEvent(flatListFromFormulaAndOptions(formula, optionalCoverages, options), promoCode)
  },
  sendPurchase: (formula, optionalCoverages, options, transactionId, totalPrice, promoCode) => {
    sendPurchaseEvent(flatListFromFormulaAndOptions(formula, optionalCoverages, options), transactionId, totalPrice, promoCode)
  },
  //events step 1
  sendSetTripInfos: (formTrip) => {
    sendSetTripInfos(formTrip)
  },
  //events step 2
  sendOptionsSelected: (selectedOptions) => {
    sendOptionsSelected(selectedOptions)
  },
  setTrackingAnalytics: (trackingAnalytics: boolean) => {
    hasTrackingAnalytics = trackingAnalytics;
    window.dispatchEvent(new Event('tracking-ready'));
  },
  //event FAQ client space
  setSearchFaq: (search) => {
    sendSearchFaq(search)
  }
};

function eventBody(item) {
  return {
    'name': CODE_TO_LABEL[item.code] || item.code,
    'brand': brand,
    'category': category,
    'price': item.totalAmount && item.totalAmount.toString(),
    'quantity': 1,
  }
}

function itemBody(item) {
  return {
    'items': [eventBody(item)]
  }
}

function selectBody(item) {
  return {
    'content_type': 'product',
    'items': [eventBody(item)]
  }
}

function selectItemsBody(itemList) {
  return {
    'content_type': 'product',
    'items': itemList.map((item) => {
      return eventBody(item)
    })
  }
}

function itemListBody(itemList) {
  return {
    'items':
      itemList.map((item) => {
        return eventBody(item)
      })
  }
}

function checkoutBody(itemList) {
  return {
    'items':
      itemList.map((item) => {
        return eventBody(item)
      }),
  }
}

function setCheckoutOptionBody(checked) {
  return {
    'checkout_step': 4,
    'checkout_option': 'Actualité & Offres commerciales',
    'value': checked ? 1 : 0
  }
}

function purchaseBody(itemList, transactionId, totalPrice, promoCode) {
  return {
    'transaction_id': transactionId,
    'value': totalPrice,
    'currency': currency,
    'coupon': promoCode ? promoCode : '',
    'items': itemList.map((item) => {
      return eventBody(item)
    }),
  }
}

function tripInfosBody(formTrip) {
  return {
    'coverage_type': formTrip.values.travelType,
    'travelers': formTrip.values.numberOfAdults + formTrip.values.numberOfBabies + formTrip.values.numberOfKids,
    'residenceCountry': formTrip.values.countryOfResidence.label,
    'destinations': formTrip.values.destinations.map((destination) => {
      return destination.label
    }) ,
    'amount': formTrip.values.amount,
  }
}

function selectedOptionBody(option) {
  return {
        'coverage' : option.offerName,
        'code' : option.coverage.code,
        'option_name' : option.coverage.name
      };
}

function searchFaqBody(search) {
  return {
    'keywords': search,
  }
}

function sendGtag(event, body, currentRetry) {
  if (hasTrackingAnalytics) {
    if (window.dataLayer) {
      window.dataLayer.push({ event, ...body })
    } else if (currentRetry <= NB_RETRIES) {
      // We are trying again because the tracking js may not be loaded / initialized yet
      setTimeout(() => {
        sendGtag(event, body, currentRetry + 1);
      }, DELAY_RETRY);
    }
  }
}

function sendViewItemListEvent(quote) {
  sendGtag('view_item_list', itemListBody(extractOfferAndOptions(quote)), 1)
}

function sendViewItemEvent(item) {
  sendGtag('view_item', itemBody(item), 1);
}

function sendViewMultipleItemsEvent(formula) {
  formula && sendGtag('view_item', itemListBody(extractOfferAndOptionalCoverages(formula)), 1);
}

function sendSelectContentEvent(item) {
  sendGtag('select_content', selectBody(item), 1);
}

function sendSelectAllContentEvent(itemLis) {
  sendGtag('select_content', selectItemsBody(itemLis), 1);
}

function sendAddToCartEvent(item) {
  sendGtag('add_to_cart', itemBody(item), 1);
}

function sendAddAllToCartEvent(itemList) {
  sendGtag('add_to_cart', itemListBody(itemList), 1);
}

function sendRemoveFromCartEvent(item) {
  sendGtag('remove_from_cart', itemBody(item), 1);
}

function sendRemoveAllFromCartEvent(itemList) {
  sendGtag('remove_from_cart', itemListBody(itemList), 1);
}

function sendBeginCheckoutEvent(itemList) {
  sendGtag('begin_checkout', checkoutBody(itemList), 1);
}

function sendCheckoutProgressEvent(itemList) {
  sendGtag('checkout_progress', checkoutBody(itemList), 1);
}

function sendSetCheckoutOptionEvent(checked) {
  sendGtag('set_checkout_option', setCheckoutOptionBody(checked), 1);
}

function sendPurchaseEvent(offer, transactionId, totalPrice, promoCode) {
  sendGtag('purchase', purchaseBody(offer, transactionId, totalPrice, promoCode), 1);
}

function sendSetTripInfos(formTrip) {
  sendGtag('step1Completed', tripInfosBody(formTrip), 1);
}

function sendOptionsSelected(selectedOptions) {
  selectedOptions.map((option) => {
    sendGtag('selectedOptions', selectedOptionBody(option));
  })
}

function sendSearchFaq(search) {
  sendGtag('rechercheFaq', searchFaqBody(search))
}

export function extractOfferAndOptions(quote) {
  let itemList = [...quote.offers];
  quote.offers.forEach((offer) =>
    offer.options.forEach((option) =>
      itemList.push(option)
    )
  );
  return itemList;
}

export function extractOfferAndOptionalCoverages(formula) {
  let itemList = [formula];
  formula.options.forEach((option) => {
    if (isOptionalCoverage(option.code)) {
      itemList.push(option)
    }
  });
  return itemList;
}

function isOptionalCoverage(code) {
  return code !== CODES.SPORT_OPTION && code !== CODES.RELAY_BK_OPTION && code !== CODES.PREMIUM_BK_OPTION
}

export function extractOptionFromCoverage(formula, coverage) {
  return {
    code: coverage.code,
    totalAmount: coverage.offerStatus.filter((offer) => offer.code === formula.code)[0].price,
  };
}

export function flatListFromFormulaAndOptions(formula, optionalCoverages, options) {
  let itemList = [formula];
  optionalCoverages && optionalCoverages.filter(optionItem => optionItem.offerCode === formula.code).forEach((optionItem) => itemList.push(extractOptionFromCoverage(formula, optionItem.coverage)));
  options && options.filter(optionItem => optionItem.offerCode === formula.code).forEach((optionItem) => itemList.push(extractOptionFromCoverage(formula, optionItem.coverage)));
  return itemList;
}
