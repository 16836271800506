// @flow

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import type { Formula, Quote } from '../../../components/steps/types/steps';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { sourceForImg } from '../../../utils';
import type { Site } from '../../../types/storage';
import phoneLogo from '../../../img/phone.png';

type Props = {
  formula: Formula,
  quote: Quote,
  site: Site,
};

function Step5Container(props: Props) {
  const { formula, quote, site } = props;

  useEffect(() => {
    dataLayer.updateFilAriane(5);
  }, []);

  return (
    <div className="step5">
      <div className="top-image">
        <img src={sourceForImg(quote)} alt="background-img" />
      </div>
      <div className="recap-wrapper">
        <div className="recap">
          <div className="icon-header">
            <i className="far fa-check-circle" />
          </div>
          <div className="recap-container">
            <div className="recap-container-header">
              <h1 className="title">{I18n.t('travelInsuranceForm.step5.title')}</h1>
              <h3>{I18n.t('travelInsuranceForm.step5.confirmation', { offerName: formula ? formula.name : '' })}</h3>
            </div>
            <div className="recap-container-email">
              <h3 className="title">{I18n.t('travelInsuranceForm.step5.emailConfirmation.title')}</h3>
              <h3 className="recap-container-email-item">{I18n.t('travelInsuranceForm.step5.emailConfirmation.documents')}</h3>
              {
                site.isClientspaceActive
                && (
                  <h3 className="recap-container-email-item">{I18n.t('travelInsuranceForm.step5.emailConfirmation.links')}</h3>
                )
              }
              {
                site.hotlineMail
                && (
                  <div>
                    <div className="recap-container-email-contact">
                      <h3 className="recap-container-email-item">{I18n.t('travelInsuranceForm.step5.emailConfirmation.emailNotReceived')}</h3>
                      <a className="mail"
                         target="_blank"
                         rel="noopener noreferrer"
                         href={`mailto:${site.hotlineMail}`}
                      >
                        <h3 className="highlighted-text">{site.hotlineMail}</h3>
                      </a>
                    </div>
                  </div>
                )
              }
            </div>
            {
              site.gabrielActivationCode
              && (
                <div className="recap-gabriel">
                  <div className="image">
                    <img className="phone-logo" alt="phone-logo" src={phoneLogo} />
                  </div>
                  <div className="recap-informations">
                    <h5 className="title">
                      {I18n.t('travelInsuranceForm.step5.gabriel.title')}
                    </h5>
                    <h5 className="description">
                      {I18n.t('travelInsuranceForm.step5.gabriel.description', { cdo: site.name })}
                      <span className="code">{site.gabrielActivationCode}</span>
                    </h5>
                    <h6 className="information">
                      {I18n.t('travelInsuranceForm.step5.gabriel.information')}
                    </h6>
                  </div>
                </div>
              )
            }
            <h2>{I18n.t('travelInsuranceForm.step5.goodTravel', { name: site.name })}</h2>
            <div className="button-back">
              <button type="button" onClick={() => {
                dataLayer.showGTM(TAG_EVENT.CLIC_RETOUR_PAYMENT);
              }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={site.logoUrl}
                >
                  <h3>{I18n.t('travelInsuranceForm.step5.buttonBack')}</h3>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect((state) => ({
  formula: state.storage.formula,
  quote: state.storage.quote,
  site: state.storage.site,
}))(Step5Container));
