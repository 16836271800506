// @flow

import moment from 'moment';
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import { formatPrice } from '../../services/formator';
import type { TripInfos } from '../../types/storage';
import type { ContextRouter } from 'react-router-dom';

type Props = {
  ...ContextRouter,
  tripInfo: TripInfos,
}

function TripRecap(props: Props) {
  const handleUpdateClick = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_EDIT_TRIP);
    props.history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
  };

  const beneficiaryNumber = props.tripInfo.numberOfAdults + props.tripInfo.numberOfKids + props.tripInfo.numberOfBabies;

  return (
    <div className="beneficiary-recap">
      <div className="beneficiary-recap-header">
        <p className="title">
          {I18n.t('information.data.title')}
        </p>
        <p className="update" onClick={handleUpdateClick}>
          <Translate value="information.update" />
        </p>
      </div>
      <div className="data">
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data">
            <span>{I18n.t('information.data.beneficiary')}</span>
            <span>{beneficiaryNumber}</span>
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p
            className="beneficiary-data">
            <span>{I18n.t('information.data.residence')}</span>
            <span>{props.tripInfo.countryOfResidence.label}</span>
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p
            className="beneficiary-data">
            <span>{I18n.t('information.data.destinations')}</span>
            <span>{props.tripInfo.destinations.filter((v) => !v.isFixed).map((v) => v.label).join(', ')}</span>
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data date-display">
            <span>{I18n.t('information.data.dates')}</span>
            <div className="recap-date">
              {moment(props.tripInfo.travelDateRange.startDate).format(I18n.t('date.DATE_FORMAT'))}
            </div>
            <i className="fas fa-arrow-right" />
            <div>
              {moment(props.tripInfo.travelDateRange.endDate).format(I18n.t('date.DATE_FORMAT'))}
            </div>
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data">
            <span>{I18n.t('information.data.total')}</span>
            <span>{formatPrice(props.tripInfo.amount)}</span>
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data">
            <span>{I18n.t('information.data.buying')}</span>
            <span>{moment(props.tripInfo.buyingDate).format(I18n.t('date.DATE_FORMAT'))}</span>
          </p>
        </div>
        <div className="data-item">
          <i className="fas fa-diamond" />
          <p className="beneficiary-data">
            <span>{I18n.t('information.data.expenses')}</span>
            <span>{I18n.t(`travelInsuranceForm.step1.expenses.${props.tripInfo.expenses.value}`)}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TripRecap);
