// @flow

import React, { useEffect, useRef, useState } from 'react';
import type { Match } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StepsHeader from './StepsHeader';
import StepsFooter from './StepsFooter';
import Step1Container from '../../pages/steps/step1/Step1Container';
import Step2Container from '../../pages/steps/step2/Step2Container';
import Step3Container from '../../pages/steps/step3/Step3Container';
import Step4Container from '../../pages/steps/step4/Step4Container';
import Stepper from './Stepper';
import Informations from '../informations/Informations';
import Cart from '../informations/Cart';
import StepErrorPage from '../../pages/error/StepErrorPage';
import { resetStoreWithStorageValues } from '../../state/storage/storageService';
import Step5Container from '../../pages/steps/step5/Step5Container';
import { URL_TUNNEL } from '../../const';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';

type Props = {
  match: Match,
  dispatch: Function,
};

const StepsContainer = (props: Props) => {

  const step = props.match.params.number;

  let submitStep3Form: Function;

  const currentScrollPercentage = useRef(0);
  const [scrollProgress] = useState({
    [1]: [],
    [2]: [],
    [3]: [],
    [4]: [],
    [5]: [],
  });

  const handleScroll = () => {
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    currentScrollPercentage.current = (window.scrollY / totalHeight) * 100;
    const currentPercentage = currentScrollPercentage.current;

    if (currentPercentage >= 25 && !scrollProgress[step].includes(25)) {
      scrollProgress[step] = [...scrollProgress[step], 25];
      dataLayer.showGTM(TAG_EVENT.SCROLL(25, step));
    } else if (currentPercentage >= 50 && !scrollProgress[step].includes(50)) {
      scrollProgress[step] = [...scrollProgress[step], 50];
      dataLayer.showGTM(TAG_EVENT.SCROLL(50, step));
    } else if (currentPercentage >= 75 && !scrollProgress[step].includes(75)) {
      scrollProgress[step] = [...scrollProgress[step], 75];
      dataLayer.showGTM(TAG_EVENT.SCROLL(75, step));
    }
  };

  useEffect(() => {
    resetStoreWithStorageValues(props.dispatch);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentScrollPercentage, step]);

  const stepCss = () => {
    if (step === URL_TUNNEL['4']) {
      return 'form-container-step4';
    }
    if (step === URL_TUNNEL['5']) {
      return 'form-container-step5';
    }
    return 'form-container';
  };

  return (
    <>
      <div className="body">
        <StepsHeader />
        {
          step !== URL_TUNNEL['5']
          && (
            <Stepper />
          )
        }
        <div className="custom-container">
          <div className={stepCss()}>
            {
              {
                '1': <Step1Container />,
                '2': <Step2Container />,
                '3': <Step3Container onFormLoaded={(handleSubmit) => (submitStep3Form = handleSubmit)} />,
                '4': <Step4Container />,
                '5': <Step5Container />,
                'error': <StepErrorPage />,
              }[step || URL_TUNNEL['1']]
            }
          </div>
          {step !== URL_TUNNEL['4'] && step !== URL_TUNNEL['5']
          && (
            <div className="side-container">
              <Informations step={Number(step)} />
              {
                (step === URL_TUNNEL['2'] || step === URL_TUNNEL['3'])
                && (
                  <Cart onSubmit={() => submitStep3Form && submitStep3Form()} />
                )
              }
            </div>
          )}
        </div>
      </div>
      <StepsFooter />
    </>
  );
};

export default withRouter(connect()(StepsContainer));
