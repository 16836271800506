// @flow

import { RestClient } from '../network';

const configurationApi = {
  fetchEnv: () => new RestClient('/configuration/environment', 'GET').execute(),
  fetchGoogleApiKey: () => new RestClient('/configuration/google/apikey', 'GET').execute(),
};

export default configurationApi;
