// @flow

export const ERROR_CODES = {
  NO_DESTINATION_COUNTRY: 'NO_DESTINATION_COUNTRY',
  OFFER_NOT_SELECTED: 'OFFER_NOT_SELECTED',
  EXPIRED_CONTROL_TOKEN: 'EXPIRED_CONTROL_TOKEN',
  REDIRECT_TO_OFFER: 'REDIRECT_TO_OFFER',
  NO_RESULT: 'NO_RESULT',
  NO_RESULT_FOUND: 'NO_RESULT_FOUND',
  OTHER_OFFER: 'OTHER_OFFER',
  LESS_BENEFICIARY_NEEDED: 'LESS_BENEFICIARY_NEEDED',
  GROUP_OFFER_MAY_NOT_BE_AVAILABLE: 'GROUP_OFFER_MAY_NOT_BE_AVAILABLE',
};
