// @flow

import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
// $FlowFixMe
import { Field } from 'react-final-form';
import { TRAVELER_TYPE } from '../../../const';
// $FlowFixMe
import { CoreozInputBase } from 'coreoz-form-base';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import {
  composeValidators,
  isName,
  isValidPostCode,
  required,
  validateBirthDate
} from '../../../services/validator';
import { formatDate, onlyText } from '../../../services/formator';
import SwingAddressAutoComplete from '../../form/SwingAddressAutoComplete';
import { initBenefInfos } from '../../../state/storage/storageReducer';
import type { Country, Site } from '../../../types/storage';
import GenderInput from '../../form/GenderInput';
import SwingSelectCountry from '../../form/SwingSelectCountry';

type Props = {
  number: number,
  form: Object,
  values: {
    user: {
      gender: String,
      name: String,
      surname: String,
      birthday: number,
      birthmonth: number,
      birthyear: number,
      email: String,
      phone: String,
      address: String,
      address_supp: String,
      postcode: String,
      city: String,
      country: String,
    },
    beneficiary: Object[],
  },
  countries: Country[],
  countriesOptions: Object[],
  isBeneficiary: boolean,
  loadedApiKey: boolean,
  verifyBenef: Function,
  setVerifyBenefShowError: Function,
  verifyBenefShowError: boolean,
  getCountryByCode: Function,
  site: Site,
};

const Beneficiary = (props: Props) => {
  const { form, values, number, isBeneficiary, loadedApiKey, verifyBenef, setVerifyBenefShowError, verifyBenefShowError, countriesOptions, countries, getCountryByCode, site } = props;

  const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [sameAddress, setSameAddress] = useState<boolean>(
    values.beneficiary &&
    values.beneficiary[number] &&
    values.beneficiary[number].isSameAddress !== undefined ? values.beneficiary[number].isSameAddress : true
  );

  let benefBirthdate = values.beneficiary && values.beneficiary[number] && values.beneficiary[number].birthdate;

  useEffect(() => {
    if (number === 0 && !isBeneficiary) {
      form.change('beneficiary[0]', initBenefInfos);
      form.change('beneficiary[0].isSameAddress', sameAddress);
    }
  }, [isBeneficiary]);

  useEffect(() => {
    if (verifyBenefShowError && verifyBenef(values)) {
      setVerifyBenefShowError(false);
    }
  }, [benefBirthdate]);

  useEffect(() => {
    if (sameAddress) {
      setAddressForBeneficiary(true);
    }
  }, [values.user]);

  const setAddressForBeneficiary = (isSameAddress) => {
    setSameAddress(isSameAddress);
    form.change(`beneficiary[${number}].isSameAddress`, isSameAddress);
    if (isSameAddress) {
      form.change(`beneficiary[${number}].address`, '');
      form.change(`beneficiary[${number}].address_supp`, '');
      form.change(`beneficiary[${number}].postcode`, '');
      form.change(`beneficiary[${number}].city`, '');
      form.change(`beneficiary[${number}].country`, '');
    } else {
      form.change(`beneficiary[${number}].country`, values.user.country);
    }
  };

  return (
    <div className="beneficiary-container">
      <div className="top-container" onClick={() => {
        setShowForm(!showForm);
      }}>
        <div className="info-text">
          <h2 className="main-title">
            {I18n.t('travelInsuranceForm.step3.beneficiaries.subtitle', { number: number + 1 })}
          </h2>
          {!showForm &&
          <div className="sumup-name">
            <h3
              className="first-child">{values.beneficiary && values.beneficiary[number] && values.beneficiary[number].surname}</h3>
            <h3>{values.beneficiary && values.beneficiary[number] && values.beneficiary[number].name}</h3>
          </div>
          }
        </div>
        <i className={`fas fa-chevron-${showForm ? 'down' : 'right'} chevron`} />
      </div>
      <div className={!showForm ? 'hidden' : ''}>
        { number === 0 && (
          <div className="beneficiary">
            <div className="form-section">
              <div className="tickbox">
                <Field
                  name="user.isBeneficiary"
                  component="input"
                  type="checkbox"
                  id="isBeneficiary"
                  className="checkbox"
                />
                <label
                  role="presentation"
                  htmlFor="isBeneficiary"
                  onClick={() => {
                    form.change('beneficiary[0]', values.user);
                    dataLayer.showGTM(TAG_EVENT.CLIC_SUBSCRIBER_IS_BENEFICIARY);
                  }}
                >
                  <i
                    className={values.user && values.user.isBeneficiary ? 'fas fa-check-square' : 'fal fa-square'}
                  />
                  <h3>{I18n.t('travelInsuranceForm.step3.is_beneficiary')}</h3>
                </label>
              </div>
            </div>
          </div>
        )
        }
        <div className={`beneficiary${isBeneficiary ? ' disable-edit' : ''}`}>
          <div className="form-container">
            <GenderInput
              form={form}
              values={values.beneficiary && values.beneficiary[number] ? values.beneficiary[number] : ''}
              formString={`beneficiary[${number}]`}
            />
            <div className="form-section">
              <h3>
                {I18n.t('travelInsuranceForm.step3.name')}
              </h3>
              <Field
                name={`beneficiary[${number}].name`}
                className="name-input"
                type="text"
                component={CoreozInputBase}
                placeholder={I18n.t('travelInsuranceForm.step3.placeholder.name')}
                validate={composeValidators(required, isName)}
                parse={onlyText}
              />
            </div>
            <div className="form-section">
              <h3>
                {I18n.t('travelInsuranceForm.step3.surname')}
              </h3>
              <Field
                name={`beneficiary[${number}].surname`}
                className="name-input"
                type="text"
                component={CoreozInputBase}
                placeholder={I18n.t('travelInsuranceForm.step3.placeholder.surname')}
                validate={composeValidators(required, isName)}
                parse={onlyText}
              />
            </div>
            <div className="form-section">
              <h3>
                {I18n.t('travelInsuranceForm.step3.birthDate')}
              </h3>
              <div>
                <Field
                  name={`beneficiary[${number}].birthdate`}
                  type="tel"
                  className="name-input"
                  component={CoreozInputBase}
                  placeholder={I18n.t('travelInsuranceForm.step3.placeholder.birthdate')}
                  maxLength={10}
                  validate={composeValidators(required, validateBirthDate(false))}
                  parse={formatDate}
                />
              </div>
            </div>
            {!isBeneficiary &&
            <div className="form-section">
              <h3>
                {I18n.t('travelInsuranceForm.step3.beneficiaries.same_address')}
              </h3>
              <Field
                name={`beneficiary[${number}].isSameAddress`}
              >
                {({ input, meta }) => (
                  <div className="address-tick">
                    <div
                      className="tick-item"
                      onClick={() => {
                        setAddressForBeneficiary(true);
                      }}
                    >
                      <i className={sameAddress ? 'fas fa-circle' : 'fal fa-circle'} />
                      <h3>{I18n.t('travelInsuranceForm.step3.beneficiaries.yes')}</h3>
                    </div>
                    <div
                      className="tick-item"
                      onClick={() => {
                        setAddressForBeneficiary(false);
                      }}
                    >
                      <i className={sameAddress ? 'fal fa-circle' : 'fas fa-circle'} />
                      <h3>{I18n.t('travelInsuranceForm.step3.beneficiaries.no')}</h3>
                    </div>
                  </div>
                )}
              </Field>
            </div>
            }
            {!isBeneficiary && !sameAddress &&
            <div>
              <div className="form-section">
                <h3>
                  {I18n.t('travelInsuranceForm.step3.address')}
                </h3>
                <Field
                  name={`beneficiary[${number}].address`}
                  component={SwingAddressAutoComplete}
                  form={form}
                  getCountryByCode={getCountryByCode}
                  travelerType={TRAVELER_TYPE.BENEFICIARY}
                  beneficiaryNumber={number}
                  validate={required}
                  loadedApiKey={loadedApiKey}
                />
                {showAddAddress &&
                <Field
                  name={`beneficiary[${number}].address_supp`}
                  className="address-input"
                  type="text"
                  component={CoreozInputBase}
                />
                }
              </div>
              <div className="add-address" onClick={() => setShowAddAddress(!showAddAddress)}>
                <i className={showAddAddress ? 'fas fa-minus' : 'fas fa-plus'} />
                {I18n.t('travelInsuranceForm.step3.add_address')}
              </div>
              <div className="form-section">
                <h3>
                  {I18n.t('travelInsuranceForm.step3.postcode')}
                </h3>
                <Field
                  name={`beneficiary[${number}].postcode`}
                  className="phone-input"
                  type="text"
                  component={CoreozInputBase}
                  placeholder={I18n.t('travelInsuranceForm.step3.placeholder.postcode')}
                  validate={composeValidators(required, isValidPostCode)}
                />
              </div>
              <div className="form-section">
                <h3>
                  {I18n.t('travelInsuranceForm.step3.city')}
                </h3>
                <Field
                  name={`beneficiary[${number}].city`}
                  className="name-input input_upercase"
                  type="text"
                  component={CoreozInputBase}
                  placeholder={I18n.t('travelInsuranceForm.step3.placeholder.city')}
                  validate={required}
                />
              </div>
              <div className="form-section">
                <h3>
                  {I18n.t('travelInsuranceForm.step3.country')}
                </h3>
                <div className={site.defaultIsoCodeCountry ? 'swing-select swing-select-var' : 'swing-select'}>
                  <Field
                    name={`beneficiary[${number}].country`}
                    validate={required}
                    className="select"
                    type="select"
                  >
                    {({ input, meta }) => (
                      <SwingSelectCountry
                        input={input}
                        meta={meta}
                        classNamePrefix="swing-select"
                        placeholder={I18n.t('travelInsuranceForm.step3.placeholder.country')}
                        countries={countries}
                        isClearable
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beneficiary;
