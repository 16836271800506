// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from "react-redux-i18n";
import FullFormStep2 from './FullFormStep2';
import type { Quote } from '../../../components/steps/types/steps';
import StepErrorPage from '../../error/StepErrorPage';
import StepNoOffer from '../../error/StepNoOffer';
import StepWrongOffer from '../../error/StepWrongOffer';
import { dataLayer } from '../../../services/dataLayer';
import { ERROR_CODES } from '../../../services/errorMessage';
import StepRedirectOffer from '../../error/StepRedirectOffer';

type Props = {
  offerCode: string,
  quote: Quote,
};

function Step2Container(props: Props) {
  const { quote, offerCode } = props;

  const displayProperComponent = () => {
    if (quote && quote.error && quote.error.errorCode) {
      dataLayer.updateErrorCode(I18n.t(`errorMessage.${quote.error.reason}`));

      if (quote.error.errorCode === ERROR_CODES.NO_RESULT) {
        return <StepNoOffer />
      }

      if (quote.error.errorCode === ERROR_CODES.OTHER_OFFER && offerCode) {
        return <StepWrongOffer />
      }

      if (quote.error.errorCode === ERROR_CODES.REDIRECT_TO_OFFER) {
        return <StepRedirectOffer />
      }

      return <StepErrorPage />
    }

    return <FullFormStep2 />;
  };

  return (
    <div className="step2">
      {displayProperComponent()}
    </div>
  );
}

export default withRouter(connect((state) => ({
  offerCode: state.storage.offerCode,
  quote: state.storage.quote,
}))(Step2Container));
