// @flow
import { unwrap } from '../network/handleError';

function hasPermission(permission: string) {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(unwrap(sessionStorage.getItem('currentUser'))).permissions.includes(permission);
  }
  return false;
}

export function isTypeLink(permission: string) {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(unwrap(sessionStorage.getItem('currentUser'))).state.equals(permission);
  }
  return false;
}

export default hasPermission;
