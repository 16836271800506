// @flow
const addRawScriptOnElement = (
  script: HTMLScriptElement,
  element: HTMLElement,
) => {
  element.appendChild(script);
};
export const addRawScriptOnElementByTagName = (
  script: HTMLScriptElement,
  tag: string,
) => {
  const element = document.getElementsByTagName(tag)[0];
  addRawScriptOnElement(script, element);
};
export const addRawScriptOnElementByClassName = (
  script: HTMLScriptElement,
  className: string,
) => {
  const element = document.getElementsByClassName(className)[0];
  addRawScriptOnElement(script, element);
};
const createScriptOnElement = (
  url: string,
  id: ?string,
  callback: ?() => void,
  execute: boolean,
  attrs: { attrName: string, attrVal: string }[],
  element: ?HTMLElement,
) => {
  let done = false;
  if (execute === false) {
    if (typeof callback === 'function') {
      callback();
    }
    return;
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = (id !== undefined && id !== null) ? id : '';
  script.async = true;
  script.src = url;
  if (attrs !== undefined && attrs.length > 0) {
    attrs.forEach((attr) => {
      script.setAttribute(attr.attrName, attr.attrVal);
    });
  }
  if (typeof callback === 'function') {
    const cbFunc = () => {
      const state = script.readyState;
      if (!done && (!state || /loaded|complete/.test(state))) {
        done = true;
        callback();
      }
    };
    script.onreadystatechange = cbFunc;
    script.onload = cbFunc;
  }
  let finalElement = element;
  if (!finalElement) {
    finalElement = document.getElementsByTagName('head')[0];
  }
  addRawScriptOnElement(script, finalElement);
};
export const addScriptOnElementByClassName = (
  url: string,
  id: ?string,
  callback: ?() => void,
  execute: boolean,
  attrs: { attrName: string, attrVal: string }[],
  elementClassName: string,
) => {
  const element = document.getElementsByClassName(elementClassName)[0];
  createScriptOnElement(url, id, callback, execute, attrs, element);
};
export const addScriptOnElementByTagName = (
  url: string,
  id: ?string,
  callback: ?() => void,
  execute: boolean,
  attrs: { attrName: string, attrVal: string }[],
  tag: string,
) => {
  const element = document.getElementsByTagName(tag)[0];
  createScriptOnElement(url, id, callback, execute, attrs, element);
};
