// @flow

import React from 'react';
import {ITEM_TYPES} from "../const";

type Props = {
  location: {
    search: string,
  }
};

type States = {
  loaded: boolean,
}

type CSSType = {
  WrappedComponent: Object,
  getTheme: Function,
}

export function withCSSVariable({ WrappedComponent, getTheme }: CSSType) {
  return class extends React.Component<Props, States> {

    state = { loaded: false }

    componentDidMount() {
      const patriarch: ?HTMLElement = document.getElementById('root');

      getTheme()
        .then(response => response.json())
        .then(theme => {
          // sort pour avoir FONT_URL en dernier parce que new FontFace().load fait quitter le forEach sur edge
          theme.sort((item) => ((item.type === ITEM_TYPES.FONT_URL) ? 1 : -1))
            .forEach(entry => {
              if (entry.type === ITEM_TYPES.FONT_URL) {
                //$FlowFixMe
                new FontFace(entry.name, entry.value)
                  .load()
                  .then(font => {
                    window.document.fonts.add(font);
                  });
              } else if (patriarch) {
                patriarch.style.setProperty(`--${entry.name}`, `${entry.value}`);
              }
            })
          this.setState({ loaded: true })
        })
        .catch((e) => {
          this.setState({ loaded: true });
          console.log(e);
        });
    }

    render() {
      if (this.state.loaded) {
        return <WrappedComponent {...this.props} />
      }
      return null;
    }
  };
}
