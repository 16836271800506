import React, { Component } from 'react';
import Select from 'react-select';
import { I18n } from 'react-redux-i18n';
import { fuzzysearch } from '../../services/fuzzySearch';
import { levDist } from '../../services/levensteinSort';

class SwingSelect extends Component {
  sortByRelevance = (inputValue: string) => {
    this.setState({ inputValue: inputValue, prevValue: this.state.inputValue });
    if (inputValue && inputValue !== '') {
      this.props.options
        .sort((curr, next) =>
          levDist(curr.label, inputValue) - levDist(next.label, inputValue)
        )
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      prevValue: '',
    };
  }

  wrapperClassName = () => {
    if (this.state.inputValue === '' && this.state.prevValue === ''){
      return 'select-wrapper';
    }
    return '';
  }

  render() {
    const stylesMultiSelect = {
      multiValue: (base, state) => (state.data.isFixed ? (this.props.destinationSelectOpen ? {
        display: 'none',
      } : {
        backgroundColor: 'white !important',
        cursor: 'pointer',
      }) : { ...base }),
      multiValueLabel: (base, state) => (state.hasValue ? (state.data.isFixed ? ({
        fontSize: '16px',
        margin: '0 !important',
      }) : {
        paddingLeft: '5px',
        fontSize: '16px',
        color: 'black !important',
      }) : { ...base }),
      multiValueRemove: (base, state) => (state.data.isFixed ? {
        display: 'none',
      } : null),
    };


    return (
      <div className={this.wrapperClassName()}>
        <Select
          {...this.props.input}
          {...this.props}
          onInputChange={this.sortByRelevance}
          styles={stylesMultiSelect}
          filterOption={(option, input) => fuzzysearch(input || '' , option.label)}
          noOptionsMessage={() => I18n.t('travelInsuranceForm.step1.noOptions')}
        />
        <div className="error-custom">{this.props.meta.touched && this.props.meta.error}</div>
      </div>
    );
  }
}

export default SwingSelect;
