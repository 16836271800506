import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';
import { i18nReducer } from 'react-redux-i18n';
import customNotificationReducer from './notifications/notificationReducer';
import storageReducer from './storage/storageReducer';
import loginReducer from './login/loginReducer';
import submissionReducer from './submission/submissionReducer';

export default combineReducers({
    i18n: i18nReducer,
    notifications: notificationsReducer(),
    submission: submissionReducer,
    customNotification: customNotificationReducer,
    storage: storageReducer,
  login: loginReducer,
});
