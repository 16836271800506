// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import Select from 'react-select';
import LogApiDetails from './LogApiDetails';
import type { LogList } from '../../types/LogApiTypes';
import logApi from '../../network/api/logApi';

type Props = {}

type State = {
  logList: LogList,
  isLoading: boolean,
  apiFilter: string,
  statusCodeFilter: string,
}

class LogApi extends React.Component<Props, State> {
  state = {
    logList: [],
    isLoading: false,
    apiFilter: null,
    statusCodeFilter: null,
    urlFilter: null,
    methodFilter: null,
  };

  updateLogs = () => {
    this.setState({
      isLoading: true,
    });
    logApi
      .fetchAllWithFilters(
        150,
        this.state.methodFilter,
        this.state.statusCodeFilter,
        this.state.apiFilter,
        this.state.urlFilter,
        null,
        null,
      )
      .then(response => response.json())
      .then((responseJson) => {
        this.setState({ logList: responseJson });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount() {
    this.updateLogs();
  }

  filterLogList = () => {
    if (this.state.isLoading) {
      return (<div className="loading"><i className="fa fa-circle-notch fa-spin" /></div>);
    }
    if (this.state.logList.length < 1) {
      return (<div className="no-content"> {I18n.t('LOG_API.NO_CONTENT')} </div>);
    }
    return this.state.logList.map(log => (
      <LogApiDetails log={log} key={log.id} />
    ));
  };

  render() {
    return (
      <div className="custom-container">
        <div className="log-api">
          <h1 className="content-title">
            {I18n.t('LOG_API.TITLE')}
          </h1>
          <div className="content">
            <Select
              options={
                this.state.logList
                  .map(value => value.api)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map(log => ({
                    value: log,
                    label: log,
                  }))
              }
              isClearable
              isSearchable
              placeholder={I18n.t('LOG_API.FILTER_NAME')}
              onChange={e => {
                this.setState({ apiFilter: e ? e.value : null }, this.updateLogs);
              }}
            />
            <Select
              options={
                this.state.logList
                  .map(value => value.statusCode)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map(log => ({
                    value: log,
                    label: log,
                  }))
              }
              isClearable
              isSearchable
              placeholder={I18n.t('LOG_API.FILTER_STATUS')}
              onChange={e => {
                this.setState({ statusCodeFilter: e ? e.value : null }, this.updateLogs);
              }}
            />
            <Select
              options={
                this.state.logList
                  .map(value => value.url)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map(log => ({
                    value: log,
                    label: log,
                  }))
              }
              isClearable
              isSearchable
              placeholder={I18n.t('LOG_API.FILTER_URL')}
              onChange={e => {
                this.setState({ urlFilter: e ? e.value : null }, this.updateLogs);
              }}
            />
            <Select
              options={
                this.state.logList
                  .map(value => value.method)
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map(log => ({
                    value: log,
                    label: log,
                  }))
              }
              isClearable
              isSearchable
              placeholder={I18n.t('LOG_API.FILTER_METHOD')}
              onChange={e => {
                this.setState({ methodFilter: e ? e.value : null }, this.updateLogs);
              }}
            />
            {
              this.filterLogList(this.state.logList)
            }
          </div>
        </div>
      </div>
    );
  }
}

export default LogApi;
