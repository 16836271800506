// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { STATUS } from '../../services/coverages';
import type { Coverage, Formula, Quote } from '../steps/types/steps';
import { formatPrice } from '../../services/formator';
import quoteApi from '../../network/api/quoteApi';
import type { TripInfos } from '../../types/storage';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';
import PromoCode from './PromoCode';
import { setIsQuoteFileLoading } from '../../state/submission/submissionService';
import { buildUrl, FILE } from '../../services/footerUrl';
import { calculateDiscount, downloadBlob, fetchFile, formatQuoteBodyFromParams } from '../../utils';

type Props = {
  quote: Quote,
  selectedOptions: Object,
  chosenFormula: Formula,
  formula: Formula,
  options: Coverage[],
  selectedOptionalCoverages: Object[],
  tripInfos: TripInfos,
  isMobile: boolean,
  totalPrice: number,
  dispatch: Function,
  discount: number,
  handleButtonClick: Function,
  hasCoverage: boolean,
  isQuoteFileLoading: boolean,
  step: number,
  calculateAmountPerDay: () => number,
};

function Sumup(props: Props) {
  const {
    selectedOptions,
    chosenFormula,
    selectedOptionalCoverages,
    quote,
    tripInfos,
    isMobile,
    dispatch,
    formula,
    options,
    totalPrice,
    discount,
    hasCoverage,
    isQuoteFileLoading,
    calculateAmountPerDay,
    step,
  } = props;

  const getPositivePrice = (price) => (price < 0 ? price * -1 : price);

  const getPriceTranslateValue = (price) => (price < 0 ? 'travelInsuranceForm.step2.recap.priceNegative' : 'travelInsuranceForm.step2.recap.price');

  const getNbBeneficiaries = () => {
    return tripInfos.numberOfAdults + tripInfos.numberOfKids + tripInfos.numberOfBabies;
  };

  const getStatusFromOffer = (coverage: Coverage, formula: Formula): string  => {
    const offerStatus = coverage.offerStatus.find((offer) => offer.code === formula.code);
    if (offerStatus) {
      return offerStatus.status;
    }
    return '';
  }

  const coverages: Coverage[] = [];

  if (quote && chosenFormula) {
    Object.values(quote.coverageWithCategories)
      .flat()
      // $FlowFixMe
      .forEach((coverage: Coverage) => {
        const status = getStatusFromOffer(coverage, chosenFormula);
        if (coverage.name !== '' && (status === STATUS.TRUE
          || (status === STATUS.OPTIONAL && selectedOptionalCoverages.map(
            (optionalCoverage) => optionalCoverage.coverage.code,
          ).includes(coverage.code)))) {
          coverages.push({
            name: coverage.name, code: coverage.code, status, offerStatus: coverage.offerStatus,
          });
        }
      });
    coverages.sort((coverage) => ((coverage.status === STATUS.OPTIONAL) ? 1 : -1));
  }

  const downloadQuote = () => {
    dispatch(setIsQuoteFileLoading(true));
    dataLayer.showGTM(TAG_EVENT.PROPOSITION);
    let quoteBody = formatQuoteBodyFromParams(
      chosenFormula,
      tripInfos,
      selectedOptionalCoverages,
      selectedOptions,
    );
    quoteApi.getQuoteFile(quoteBody)
      .then((data) => data.blob())
      .then((pdfData) => {
        downloadBlob(pdfData, 'Notre Proposition Tarifaire.pdf');
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setIsQuoteFileLoading(false));
      });
  };

  const getCurrentOfferStatus = (coverage) => {
    const offerStatus = coverage.offerStatus.filter(
      (offerStatusItem) => offerStatusItem.name === chosenFormula.name)[0];

    if (!offerStatus) {
      return {};
    }
    return offerStatus;
  };

  return (
    <div className={hasCoverage ? 'sumup-container' : 'sumup-container order'}>
      {step === 2
      && (
        <div className="recap">
          {quote && chosenFormula && (
            <div>
              <div className="recap-title">
                <h1
                  className="included-warranty">{I18n.t(`travelInsuranceForm.step2.recap.${hasCoverage ? 'title' : 'titleQuote'}`)}</h1>
              </div>
              <div>
                <div className="recap-content">
                  <div className="recap-content-header">
                    <h2 className="offer-name">{chosenFormula.name}</h2>
                    <br />
                    <div className="offer-price-container">
                      <h1 className="offer-price">
                        {formatPrice(totalPrice - calculateDiscount(totalPrice, discount))}
                      </h1>
                      {getNbBeneficiaries() > 1 &&
                      <h3>
                        {I18n.t('travelInsuranceForm.step2.recap.beneficiaries', { nbrBeneficiaries: getNbBeneficiaries() })}
                      </h3>
                      }
                    </div>
                    <div className="offer-price-details">
                      <h2>{I18n.t('travelInsuranceForm.step2.choseOffer.amountPerDay', { amount: formatPrice(calculateAmountPerDay()) })}</h2>
                    </div>
                    {!hasCoverage &&
                    <h2 className="specific-wording-recap">
                      {I18n.t('travelInsuranceForm.step2.recap.specificWording.part1')}
                      <span
                        className={formula && formula.cgUrl ? '' : 'disabled'}
                        onClick={() => formula && formula.cgUrl && fetchFile(buildUrl(options, formula, FILE.CG), null)}
                      >
                        {I18n.t('travelInsuranceForm.step2.recap.specificWording.cg')}
                      </span>
                      {I18n.t('travelInsuranceForm.step2.recap.specificWording.part2')}
                      <span
                        className={formula && formula.dinUrl ? '' : 'disabled'}
                        onClick={() => formula && formula.dinUrl && fetchFile(buildUrl(options, formula, FILE.DIN), null)}
                      >
                        {I18n.t('travelInsuranceForm.step2.recap.specificWording.din')}
                      </span>
                      {I18n.t('travelInsuranceForm.step2.recap.specificWording.part3')}
                    </h2>
                    }
                  </div>
                  <div className="recap-content-body">
                    {hasCoverage &&
                    <div>
                      <div className="recap-section-header">
                        <h3>{I18n.t('travelInsuranceForm.step2.recap.formule')}</h3>
                        <hr />
                      </div>
                      <div className="recap-section-body">
                        <h2 className="offer-name">{chosenFormula.name}</h2>
                      </div>
                    </div>
                    }
                    {
                      isMobile
                        ? (selectedOptionalCoverages.filter(
                          (option) => option.offerCode === chosenFormula.code,
                          ).length > 0
                          && (
                            <div>
                              <div className="recap-section-header">
                                <h3>{I18n.t('travelInsuranceForm.step2.recap.warrantiesOptional')}</h3>
                                <hr />
                              </div>
                              <div className="recap-section-body">
                                {coverages.map((coverage) => (
                                  coverage.status === STATUS.OPTIONAL
                                  && (
                                    <div
                                      className="recap-warranty-item"
                                      key={coverage.code}
                                    >
                                      <i className="fa fa-plus" />
                                      <div
                                        className="optional-warranty"
                                      >
                                        <>
                                          <h3>{coverage.name}</h3>
                                          <h3 className="recap-warranty-item-price">
                                            {
                                              I18n.t('travelInsuranceForm.step2.recap.price', {
                                                price: formatPrice(getCurrentOfferStatus(coverage).price),
                                              })
                                            }
                                          </h3>
                                          {
                                            coverage.twinsCoverages
                                            && coverage.twinsCoverages.map(
                                              (twinCoverage) => (
                                                <h3 key={twinCoverage.code}><p>{twinCoverage.name}</p></h3>
                                              ),
                                            )
                                          }
                                        </>
                                      </div>
                                    </div>
                                  )
                                ))}
                              </div>
                            </div>
                          )
                        ) : (
                          <div>
                            {
                              coverages.length > 0
                              && (
                                <div className="recap-section-header">
                                  <h3>{I18n.t('travelInsuranceForm.step2.recap.warranties')}</h3>
                                  <hr />
                                </div>
                              )
                            }
                            <div className="recap-section-body">
                              {coverages.map((coverage) => (
                                <div
                                  className="recap-warranty-item"
                                  key={coverage.code}
                                >
                                  <i
                                    className={coverage.status === STATUS.OPTIONAL ? 'fa fa-plus' : 'fa fa-check'} />
                                  <div
                                    className={coverage.status === STATUS.OPTIONAL ? 'optional-warranty' : 'included-warranty'}
                                  >
                                    {coverage.status === STATUS.OPTIONAL ? (
                                        <>
                                          <h3>{coverage.name}</h3>
                                          <h3 className="recap-warranty-item-price">
                                            {I18n.t('travelInsuranceForm.step2.recap.price', {
                                              price: formatPrice(coverage.offerStatus.find(
                                                (offerStatus) => offerStatus.name
                                                  === chosenFormula.name,
                                              ).price),
                                            })}
                                          </h3>
                                        </>
                                      )
                                      : <h3>{coverage.name}</h3>
                                    }
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                    }
                    {
                      selectedOptions.filter(
                        (option) => option.offerCode === chosenFormula.code,
                      ).length > 0
                      && (
                        <div>
                          <div className="recap-section-header">
                            <h3>{I18n.t('travelInsuranceForm.step2.recap.options')}</h3>
                            <hr />
                          </div>
                          {selectedOptions.filter(
                            (option) => option.offerCode === chosenFormula.code,
                          ).map((option) => (
                            <div key={option.coverage.code} className="recap-section-body">
                              <div className="recap-warranty-item" key={option.coverage.code}>
                                <i className="fa fa-plus" />
                                <h3
                                  className="optional-warranty"
                                >
                                  {option.coverage.name}
                                </h3>
                                <h3 className="recap-warranty-item-price">
                                  {I18n.t(getPriceTranslateValue(getCurrentOfferStatus(option.coverage).price),
                                    {
                                      price: formatPrice(getPositivePrice(getCurrentOfferStatus(option.coverage).price)),
                                    })}
                                </h3>
                              </div>
                              {
                                option.coverage.twinsCoverages && option.coverage.twinsCoverages.map((twinCoverage) =>
                                  <div className="recap-warranty-item sub" key={twinCoverage.code}>
                                    <i className="fa fa-plus invisible" />
                                    <h3 className="optional-warranty">{twinCoverage.name}</h3>
                                  </div>,
                                )
                              }
                            </div>
                          ))}
                        </div>
                      )
                    }
                    <PromoCode isBasket={false} />
                    <div className="download">
                      <button type="button" disabled={isQuoteFileLoading}
                              className="download-button"
                              onClick={downloadQuote}>
                        {isQuoteFileLoading ?
                          <i className="fas fa-circle-notch fa-spin" /> :
                          <i className="fas fa-download" />
                        }
                        <h3>{I18n.t('travelInsuranceForm.step2.recap.download')}</h3>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="triangle" />
              </div>
              <div className="subscription">
                <div className="almost-done">
                  <h1>{I18n.t('travelInsuranceForm.step2.recap.almostFinished')}</h1>
                </div>
                <div className="subscribe-now">
                  <h2>{I18n.t('travelInsuranceForm.step2.recap.subscribeOnlineNow')}</h2>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default connect((state) => ({
  tripInfos: state.storage.tripInfos,
  offerCode: state.storage.offerCode,
  formula: state.storage.formula,
  options: state.storage.options,
  totalPrice: state.storage.totalPrice,
  discount: state.storage.discount,
  quote: state.storage.quote,
  isQuoteFileLoading: state.submission.isQuoteFileLoading,
}))(Sumup);
