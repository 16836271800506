// @flow

import React from 'react';
import logApi from '../../network/api/logApi';
import LogApiTileDetails from './LogApiTileDetails';
import { I18n } from 'react-redux-i18n';
import type { Log } from '../../types/logApiTypes';

type Props = {
  logId: String,
}
type State = {
  logDetailsRequest: ?Log,
  logDetailsResponse: ?Log,
}

class LogApiContainerDetails extends React.Component<Props, State> {
  state = {
    logDetailsRequest: null,
    logDetailsResponse: null,
  };

  componentDidMount() {
    logApi
      .fetchById(this.props.logId)
      .then(response => response.json())
      .then((responseJson) => {
        this.setState({
          logDetailsRequest: {
            ...responseJson,
            headerList: responseJson.headerRequest,
            body: responseJson.bodyRequest,
            isCompleteText: responseJson.isCompleteTextRequest,

          },
          logDetailsResponse: {
            ...responseJson,
            headerList: responseJson.headerResponse,
            body: responseJson.bodyResponse,
            isCompleteText: responseJson.isCompleteTextResponse,
          },
        });
      });
  }

  render() {
    if (!this.state.logDetailsRequest && !this.state.logDetailsResponse) {
      return false;
    }
    if (this.state.logDetailsRequest.headerList.headers.length === 0 && !this.state.logDetailsRequest.body &&
      this.state.logDetailsResponse.headerList.headers.length === 0 && !this.state.logDetailsResponse.body) {
      return (<div className="empty-container">{I18n.t('apiLog.empty')}</div>);
    }
    return (
      <div>
        <LogApiTileDetails isRequest infos={this.state.logDetailsRequest} />
        <LogApiTileDetails isRequest={false} infos={this.state.logDetailsResponse} />
      </div>
    );
  }
}

export default LogApiContainerDetails;
