// @flow

import React from 'react';
import { Translate, Localize } from 'react-redux-i18n';
import type { Beneficiary as BeneficiaryType } from '../../../types/contractTypes';

type Props = {
  beneficiaries: BeneficiaryType[],
  icon: string,
  title: string,
};

export default class Beneficiary extends React.PureComponent<Props> {
  render() {
    const { icon, title, beneficiaries } = this.props;
    if (beneficiaries.length === 0) {
      return false;
    }
    return (
      <div className="table beneficiary">
        <div className="table-header">
          <div>
            <i className={icon} />
            <Translate value={title} />
          </div>
          <span className="count">{beneficiaries.length}</span>
        </div>
        <div className="table-body">
          {
            beneficiaries.map((beneficiary) => (
              <div className="table-row" key={`${beneficiary.firstname}_${beneficiary.lastname}`}>
                <div className="label">{`${beneficiary.firstname} ${beneficiary.lastname}`}</div>
                <div className="value"><Localize value={beneficiary.birthdate} dateFormat="date.DATE_FORMAT" /></div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
