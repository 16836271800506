// @flow

import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import { connect } from 'react-redux';
import type { Quote } from '../../components/steps/types/steps';
import FormFooter from '../../components/steps/footer/FormFooter';
import type { Site, TripInfos } from '../../types/storage';
import ErrorMessageDisplay from './ErrorMessageDisplay';
import { dataLayer } from '../../services/dataLayer';

type Props = {
  offerCode: string,
  dispatch: Function,
  quote: Quote,
  history: RouterHistory,
  tripInfos: TripInfos,
  site: Site,
};

const StepNoOffer = (props: Props) => {
  const { history, site } = props;

  useEffect(() => {
    dataLayer.updateFilAriane(-1);
  }, []);

  const handleGoBackClick = () => {
    if (props.offerCode) {
      history.push(STEP_ROOT_PAGE_ROUTE + '/1?offerCode=' + props.offerCode);
    } else {
      history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  };

  return (
    <div className="step-error-page">
      <div className="step-error-page-content">
        <ErrorMessageDisplay wantShowErrorReason={true} wantShowContact={true} />
        <div className="button-block">
          <button className="button-back-step" onClick={handleGoBackClick}>
            <i className="far fa-edit" />
            <h3>{I18n.t('errors.noQuote.buttonEdit')}</h3>
          </button>
          <button className="button-back" onClick={() => window.open(site.logoUrl, '_blank')}>
            <h3>{I18n.t('errors.noQuote.buttonBack')}</h3>
          </button>
        </div>
      </div>
      <FormFooter />
    </div>
  );
};

export default withRouter(connect((state) => ({
  offerCode: state.storage.offerCode,
  quote: state.storage.quote,
  tripInfos: state.storage.tripInfos,
  site: state.storage.site,
}))(StepNoOffer));
