// @flow

import React from 'react';
import { Translate } from 'react-redux-i18n';
import type { SellingInformation } from '../../../types/contractTypes';
import { formatPriceClean } from '../../../utils';

type Props = {
  contract: SellingInformation,
};

class Basket extends React.PureComponent<Props> {

  findCoverageName = (warranty) => {
    const coverageList = this.props.contract.offerIntermediateBean.includedCoverages
      .concat(this.props.contract.offerIntermediateBean.optionalCoverages);
    return coverageList.filter(coverage => coverage.warrantyFormulaId == warranty.warrantyFormulaId)[0].name;
  };

  applyDecimal = (value) => {
    if (value % 1 === 0) {
      return value.toFixed(0);
    }
    return value.toFixed(2);
  };

  getDiscountAmount = (percentage, optionalWarranties, priceFormula) => {
    const sumWarranty = optionalWarranties.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.price), 0);
    return this.applyDecimal(Math.round(percentage * (sumWarranty + priceFormula) * 100) / 100);
  };

  render() {
    return (
      <div className="table basket">
        <div className="table-header">
          <div>
            <i className="fal fa-shopping-cart" />
            <Translate value="spaceClient.contract.basket" />
          </div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="label">{this.props.contract.offerIntermediateBean.productName}</div>
            <div className="value">
              <Translate
                value="spaceClient.contract.currency"
                price={formatPriceClean(this.props.contract.priceFormula)}
              />
            </div>
          </div>
          {
            this.props.contract.optionalWarranties.map(warranty => (
              <div className="table-row" key={warranty.warrantyPriceId}>
                <div className="label">
                  {this.findCoverageName(warranty)}
                </div>
                <div className="value">
                  <Translate
                    value="spaceClient.contract.currency"
                    price={formatPriceClean(warranty.price)}
                  />
                </div>
              </div>
            ))
          }
          {
            this.props.contract.promoPercentage
            && (
              <div className="table-row">
                <div className="label">
                  <Translate
                    value="spaceClient.contract.table.promoCode"
                    percentage={this.applyDecimal((this.props.contract.promoPercentage * 100))}
                  />
                </div>
                <div className="value">
                  <Translate
                    value="spaceClient.contract.currency"
                    price={'-' + this.getDiscountAmount(this.props.contract.promoPercentage, this.props.contract.optionalWarranties, this.props.contract.priceFormula)}
                  />
                </div>
              </div>
            )
          }
          <div className="table-row bold">
            <div className="label"><Translate value="spaceClient.contract.total" /></div>
            <div className="value">
              <Translate
                value="spaceClient.contract.currency"
                price={formatPriceClean(this.props.contract.pricePaid)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Basket;
