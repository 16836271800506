// @flow

import React, { useEffect, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import type { Match, RouterHistory } from 'react-router-dom';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import { FORMULA, OPTIONAL_COVERAGES, OPTIONS } from '../../state/storage/storageReducer';
import {
  clearTravelerInfos,
  storeDiscount,
  storeFinalPrice,
  storeFormulaInStorage,
  storeMaxPage,
  storeStartContract,
  storeTotalPrice,
  storeTripInfos,
} from '../../state/storage/storageService';
import { CODES, FORMULA_CODES } from '../../services/coverages';
import { formatPrice } from '../../services/formator';
import quoteApi from '../../network/api/quoteApi';
import type { Coverage, Formula, OptionalCoverage, Quote } from '../steps/types/steps';
import type { TripInfos } from '../../types/storage';
import { store } from '../../network/reduce';
import { dataLayer, TAG_EVENT } from '../../services/dataLayer';

import PromoCode from './PromoCode';
import { setIsQuoteFileLoading } from '../../state/submission/submissionService';
import {
  downloadBlob, formatQuoteBodyFromParams,
  getOptionCoverageFromQuoteByCode,
  isLugageCoverage,
  missingLugageCoverage,
} from '../../utils';
import { gtagService } from '../../services/gtagService';

type Props = {
  match: Match,
  history: RouterHistory,
  onSubmit: Function,
  totalPrice: number,
  finalPrice: number,
  dispatch: Function,
  formula: Formula,
  optionalCoverages: OptionalCoverage[],
  options: Coverage[],
  tripInfos: TripInfos,
  discount: number,
  startContract: string,
  quote: Quote,
  validTravelerInfos: boolean,
  isQuoteFileLoading: boolean,
};

function Cart(props: Props) {
  const {
    match, history, totalPrice, onSubmit, finalPrice, dispatch, formula, optionalCoverages,
    options, tripInfos, discount, startContract, quote, validTravelerInfos, isQuoteFileLoading,
  } = props;

  const downloadQuote = () => {
    dispatch(setIsQuoteFileLoading(true));
    dataLayer.showGTM(TAG_EVENT.PROPOSITION);
    const quoteBody = formatQuoteBodyFromParams(formula, tripInfos, optionalCoverages, options);
    quoteApi.getQuoteFile(quoteBody)
      .then((data) => data.blob())
      .then((pdfData) => {
        downloadBlob(pdfData, 'Notre Proposition Tarifaire.pdf');
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setIsQuoteFileLoading(false));
      });
  };

  const handleButtonClick = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_SOUSCRIPTION);
    gtagService.sendBeginCheckout(formula, optionalCoverages, options, tripInfos.promoCode);
    gtagService.sendOptionsSelected(options);
    dispatch(storeMaxPage(parseInt(match.params.number, 10) + 1));
    dispatch(clearTravelerInfos());
    dispatch(storeTripInfos(tripInfos, true));
    dispatch(storeStartContract(startContract, true));
    dispatch(storeFormulaInStorage(formula, options, optionalCoverages));
    dispatch(storeDiscount(discount, true));
    history.push(`${STEP_ROOT_PAGE_ROUTE}/${parseInt(step + 1, 10)}`);
  };

  const handleNextStep = () => {
    dispatch(storeMaxPage(parseInt(match.params.number, 10) + 1));
    onSubmit();
  };

  const calculateDiscount = () => (discount ? (discount * totalPrice) : 0);

  const calculateFinalPriceWithDiscount = () => {
    const cleanerNumber = 100000; // use this method to avoid well known js problem substracting decimal number
    return ((totalPrice * cleanerNumber) - (calculateDiscount() * cleanerNumber)) / cleanerNumber;
  };

  const step = parseInt(match.params.number, 10);
  const showCart = !(step === 2 && quote && quote.error && quote.error.errorCode);

  const [showData, setShowData] = useState(true);
  let lateLugageCoverage = getOptionCoverageFromQuoteByCode(quote, CODES.LATE_LUGGAGE);
  let lostLugageCoverage = getOptionCoverageFromQuoteByCode(quote, CODES.LOST_LUGGAGE);

  const getPriceFromCoverage = (coverage: Coverage): number => {
    const offerStatus = coverage.offerStatus.find(
      (offerStatus) => offerStatus.code === formula.code
    );
    if (offerStatus) {
      return offerStatus.price;
    }
    return 0;
  }

  useEffect(() => {
    if (!formula) {
      return;
    }
    let sum = 0;
    sum += formula.totalAmount;
    if (optionalCoverages) {
      sum = optionalCoverages.filter((option) => option.offerCode === formula.code)
        .reduce(
          (acc, item: OptionalCoverage) => acc + getPriceFromCoverage(item.coverage),
          sum,
        );
    }

    if (options) {
      sum = options.filter((option) => option.offerCode === formula.code).reduce(
        (acc, item: OptionalCoverage) => acc + getPriceFromCoverage(item.coverage),
        sum,
      );
    }

    dispatch(storeTotalPrice(parseFloat(sum.toFixed(2))));
  }, [formula, optionalCoverages, options]);

  useEffect(() => {
    if (!formula || !totalPrice) {
      return;
    }
    if (discount) {
      dispatch(storeFinalPrice(calculateFinalPriceWithDiscount()));
    } else {
      dispatch(storeFinalPrice(totalPrice));
    }
  }, []);

  useEffect(() => {
    if (!formula || !totalPrice) {
      return;
    }
    if (discount) {
      dispatch(storeFinalPrice(calculateFinalPriceWithDiscount()));
    } else {
      dispatch(storeFinalPrice(totalPrice));
    }
  }, [discount, totalPrice]);

  const handleDeleteItem = (itemType, item) => {
    deleteFromCart(itemType, item);
  };

  const deleteFromCart = (itemType: string, item: OptionalCoverage) => {
    let filteredOptionalCoverages = [];
    let filteredOptions = [];
    switch (itemType) {
      case FORMULA:
        dispatch(store(FORMULA, undefined));
        break;
      case OPTIONAL_COVERAGES:
        filteredOptionalCoverages = optionalCoverages || [];
        if (isLugageCoverage(item.coverage.code)) {
          filteredOptionalCoverages = filteredOptionalCoverages.filter(
            (optionalCoveragesItems) => optionalCoveragesItems.coverage.code !== item.coverage.code
              && !optionalCoveragesItems.coverage.twinsCoverages,
          );
          // On supprime manuellement l'option manquante au tracking
          // On ne doit pas dissocier l'option 'bagage en retard' et 'bagage perdu'
          const missingCoverage = getOptionCoverageFromQuoteByCode(quote, missingLugageCoverage(item.coverage.code));
          missingCoverage && gtagService.sendRemoveFromCart(missingCoverage, formula, true);
        } else {
          filteredOptionalCoverages = filteredOptionalCoverages.filter(
            (optionalCoveragesItems) => optionalCoveragesItems.coverage.code !== item.coverage.code,
          );
        }
        gtagService.sendRemoveFromCart(item.coverage, formula, true);
        dispatch(store(OPTIONAL_COVERAGES, filteredOptionalCoverages));
        break;
      case OPTIONS:
        filteredOptions = options || [];
        filteredOptions = filteredOptions.filter(
          (selectedOption) => (selectedOption.coverage.code !== item.coverage.code
            || selectedOption.offerCode !== formula.code),
        );
        gtagService.sendRemoveFromCart(item.coverage, formula, true);
        dispatch(store(OPTIONS, filteredOptions));
        break;
      default:
    }
  };

  const getPositivePrice = (price: number): number => (price < 0 ? price * -1 : price);
  const getPriceTranslateValue = (price): string => (price < 0 ? 'basket.optionNegativePrice' : 'basket.optionPrice');

  const isLuggageCoverageAndTaken = (item) => isLugageCoverage(item.coverage.code) && lostLugageCoverage;
  const getCurrentPriceFromCoverage = (coverage: Coverage): number => getPriceFromCoverage(coverage);

  return (
    <div>
      {step !== 1 && showCart
      && (
        <div className="basket">
          <div className="header" role="presentation" onClick={() => setShowData(!showData)}>
            <div className="title">
              <i className="fal fa-shopping-cart" />
              <h2 className="text-title">{I18n.t('basket.title')}</h2>
            </div>
            <i
              className={showData ? 'fas fa-chevron-down chevron' : 'fas fa-chevron-right chevron'} />
          </div>
          {step === 4
          && (
            <div className="recap-header">
              <p className="title-recap"><Translate value="basket.title" /></p>
            </div>
          )}
          {showData
          && (
            <div className="data">
              {formula
                ? (
                  <>
                    <div className="data-items">
                      <div className="data-title">
                        <div className="formula-name">
                          <h3>{formula.name}</h3>
                        </div>
                        <h3
                          className="price"
                        >
                          {
                            I18n.t('basket.formulaPrice', { price: formatPrice(formula.totalAmount) })
                          }
                        </h3>
                      </div>
                      {(optionalCoverages.filter(
                        (optionalCoverage: OptionalCoverage) => (
                          optionalCoverage.offerCode === formula.code
                        ),
                      )).map((item: OptionalCoverage) => {
                        if (item.coverage.twinsCoverages) {
                          return null;
                        }
                        return (
                          <div className="data-item" key={item.coverage.code}>
                            <div className="option-items">
                              <div className="option-name">
                                {
                                  step === 2
                                  && (
                                    <i
                                      role="presentation"
                                      className="fa fa-times"
                                      onClick={() => handleDeleteItem(OPTIONAL_COVERAGES, item)}
                                    />
                                  )}
                                <div>
                                  <h4>
                                    {
                                      isLuggageCoverageAndTaken(item)
                                        ? lostLugageCoverage.name
                                        : ''
                                    }
                                  </h4>
                                  {
                                    isLuggageCoverageAndTaken(item)
                                      ? (
                                        <h4>+{lateLugageCoverage.name}</h4>
                                      ) : (
                                        <h4>{item.coverage.name}</h4>
                                      )
                                  }
                                </div>
                              </div>
                              <div className="item-price">
                                {
                                  isLuggageCoverageAndTaken(item)
                                    ? (
                                      <h4 className="price">
                                        {
                                          I18n.t(getPriceTranslateValue(getCurrentPriceFromCoverage(lostLugageCoverage)),
                                            {
                                              price: formatPrice(getPositivePrice(getCurrentPriceFromCoverage(lostLugageCoverage))),
                                            },
                                          )
                                        }
                                      </h4>
                                    ) : (
                                      <h4 className="price">
                                        {
                                          I18n.t(getPriceTranslateValue(getCurrentPriceFromCoverage(item.coverage)),
                                            {
                                              price: formatPrice(getPositivePrice(getCurrentPriceFromCoverage(item.coverage))),
                                            },
                                          )
                                        }
                                      </h4>
                                    )
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {
                        options.filter((option: OptionalCoverage) => (
                          option.offerCode === formula.code))
                          .map((item: OptionalCoverage) => (
                            <div className="data-item" key={item.coverage.code}>
                              <div className="option">
                                {
                                  step === 2
                                  && (
                                    <i
                                      role="presentation"
                                      className="fa fa-times"
                                      onClick={() => handleDeleteItem(OPTIONS, item)}
                                    />
                                  )
                                }
                                <div>
                                  <h4>{item.coverage.name}</h4>
                                  {
                                    item.coverage.twinsCoverages
                                    && item.coverage.twinsCoverages.map((coverage) => (
                                        <h4 key={coverage.code}>{coverage.name}</h4>
                                      ),
                                    )
                                  }
                                </div>
                              </div>
                              <h4 className="price">
                                {
                                  I18n.t(getPriceTranslateValue(getCurrentPriceFromCoverage(item.coverage)),
                                    {
                                      price: formatPrice(getPositivePrice(getCurrentPriceFromCoverage(item.coverage))),
                                    },
                                  )
                                }
                              </h4>
                            </div>
                          ))
                      }
                      {
                        step === 4
                        && moment(startContract).isValid()
                        && formula.code === FORMULA_CODES.ZEN
                        && (
                          <div className="data-item">
                            <h4 className="data-item-title">
                              {I18n.t('information.data.datesContract')}
                              {moment(startContract).format(I18n.t('date.DATE_FORMAT')) + (moment(startContract).hour() === 12 ? I18n.t('information.data.midday') : '')}
                            </h4>
                          </div>
                        )
                      }
                      <PromoCode isBasket />
                    </div>
                    <div className="basket-footer">
                      <hr />
                      <div className="total">
                        <h2 className="total-title">{I18n.t('basket.total')}</h2>
                        <h2
                          className="total-amount">
                          {I18n.t('basket.formulaPrice', { price: formatPrice(discount ? finalPrice : totalPrice) })}
                        </h2>
                      </div>
                      {
                        step === 2
                        && (
                          <div className="submit">
                            <button type="button" onClick={handleButtonClick}>
                              <h3>{I18n.t('travelInsuranceForm.step2.submit')}</h3>
                              <i className="far fa-long-arrow-right" />
                            </button>
                            <div
                              className="download"
                              onClick={() => !isQuoteFileLoading && downloadQuote()}
                            >
                              <h3>{I18n.t('travelInsuranceForm.step2.recap.download')}</h3>
                            </div>
                          </div>
                        )
                      }
                      {
                        step === 3
                        && validTravelerInfos
                        && (
                          <div className="submit">
                            <button type="button" onClick={handleNextStep}>
                              <h3>{I18n.t('travelInsuranceForm.step3.submit')}</h3>
                              <i className="far fa-long-arrow-right" />
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </>
                ) : (
                  <div className="no-data">
                    <h3>{I18n.t('basket.noData')}</h3>
                  </div>
                )
              }
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(connect((state) => ({
  formula: state.storage.formula,
  optionalCoverages: state.storage.optionalCoverages,
  options: state.storage.options,
  totalPrice: state.storage.totalPrice,
  finalPrice: state.storage.finalPrice,
  tripInfos: state.storage.tripInfos,
  discount: state.storage.discount,
  startContract: state.storage.startContract,
  quote: state.storage.quote,
  validTravelerInfos: state.storage.validTravelerInfos,
  isQuoteFileLoading: state.submission.isQuoteFileLoading,
}))(Cart));
