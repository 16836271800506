// @flow

import React, { useEffect, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
// $FlowFixMe
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import FormFooter from '../../../components/steps/footer/FormFooter';
import Informations from '../../../components/informations/Informations';
import BeneficiaryRecap from '../../../components/beneficiaryrecap/BeneficiaryRecap';
import TripRecap from '../../../components/tripRecap/TripRecap';
import { STEP_ROOT_PAGE_ROUTE } from '../../../const';
import Cart from '../../../components/informations/Cart';
import type { Beneficiary, TravelerInfos, TripInfos } from '../../../types/storage';
import CheckoutForm from './CheckoutForm';
import type { Quote } from '../../../components/steps/types/steps';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import { sourceForImg } from '../../../utils';
import paymentLogo from '../../../img/payment.png';

type Props = {
  ...ContextRouter,
  travelerInfos: TravelerInfos,
  tripInfos: TripInfos,
  totalPrice: number,
  quote: Quote,
}

function Step4Container(props: Props) {

  const {
    travelerInfos, tripInfos, totalPrice, quote,
  } = props;

  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    dataLayer.updateFilAriane(4);
  }, []);

  const handleGoBackClick = () => {
    dataLayer.showGTM(TAG_EVENT.CLIC_RETOUR);
    props.history.push(`${STEP_ROOT_PAGE_ROUTE}/3`);
  };

  return (
    <div className="step4">
      <div className="top-image">
        <img src={sourceForImg(quote)} alt="background-img" />
      </div>
      <div className="title">
        <h1>
          <Translate value="travelInsuranceForm.step4.title" />
        </h1>
        <h3 className="no-bold">
          <Translate value="travelInsuranceForm.step4.description" mail={travelerInfos.user.email} />
        </h3>
      </div>
      <div className="body">
        <div className="left-body">
          <div className="step4-recap">
            <div className="section-header">
              <h2 className="title">
                <Translate value="travelInsuranceForm.step4.recap.title" />
              </h2>
            </div>
            <Cart />
            {showMore && [
              <Informations step={parseInt(props.match.params.number, 10)} />,
              travelerInfos.beneficiary.map((beneficiary: Beneficiary, index) => (
                <>
                  <hr />
                  <BeneficiaryRecap beneficiary={beneficiary} user={travelerInfos.user} index={index + 1} />
                </>
              )),
              <>
                <hr />
                <TripRecap tripInfo={tripInfos} />
              </>
            ]}
          </div>
          <div className="show-more">
            <button
              type="button"
              className="show click-me"
              onClick={() => {
                setShowMore(!showMore);
                showMore
                  ? dataLayer.showGTM(TAG_EVENT.CLIC_SHOW_LESS)
                  : dataLayer.showGTM(TAG_EVENT.CLIC_SHOW_MORE);
              }}
            >
              <Translate
                value={showMore ? 'travelInsuranceForm.step4.showLess' : 'travelInsuranceForm.step4.showMore'}
              />
            </button>
          </div>
        </div>
        <div className="right-body">
          <div className="payment">
            <div className="section-header">
              <h2 className="title">
                <Translate value="travelInsuranceForm.step4.payment.title" />
              </h2>
            </div>
            <div className="form-container">
              <CheckoutForm amount={totalPrice} />
            </div>
            <img className="payment-logo" alt="payment-logo" src={paymentLogo} />
          </div>
          <div className="renunciation">
            <h6>
              <Translate value="travelInsuranceForm.step4.renonciation" />
              <p>
                <Translate value="travelInsuranceForm.step4.contract" />
              </p>
            </h6>
          </div>
        </div>
      </div>
      <div className="bottom-button">
        <div className="back">
          <button aria-label=" " type="button" onClick={handleGoBackClick}>
            <i className="far fa-long-arrow-left" />
            <h2>
              {I18n.t('travelInsuranceForm.step2.goBack')}
            </h2>
          </button>
        </div>
      </div>
      <FormFooter />
    </div>
  );
}

export default withRouter(connect((state) => ({
  tripInfos: state.storage.tripInfos,
  formula: state.storage.formula,
  travelerInfos: state.storage.travelerInfos,
  totalPrice: state.storage.totalPrice,
  quote: state.storage.quote,
  locale: state.i18n.locale,
}))(Step4Container));
