// @flow

import { capitalize, findAllOccurrences } from '../utils';

export const isNumber = (value: string, previousValue: string) => {
  return !value || numberRegexp.test(value) ? value : previousValue;
};

const numberRegexp = RegExp('^([0-9]{1,6})$|^([0-9]{1,6}(,|\\.)([0-9]{1,2})?)$');

const textRegexp = RegExp('[^\',. \\-A-Za-zÀ-ÖØ-öø-ÿ]');

const nonDigitRegexp = RegExp('([^\\d])');

export const onlyText = (value: string) => (value ? capitalize(value).replace(textRegexp, '') : value);

export const formatDate = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d\/]/g, '');
  const occurrences = findAllOccurrences(onlyNums, '/');
  if (onlyNums.length === 3 && occurrences.length === 0) {
    return `${onlyNums.substring(0, 2)}/${onlyNums.substring(2, 3)}`;
  } else if (onlyNums.length === 6 && occurrences.length === 1) {
    return `${onlyNums.substring(0, 5)}/${onlyNums.substring(5, 6)}`;
  }

  const date = onlyNums.split('/');

  date[0] = removeNonDigit(0, 31)(date[0]);
  if (date[1]) {
    date[1] = removeNonDigit(0, 12)(date[1]);
  }

  return date.reduce((acc, value) => `${acc}/${value}`);
};
export const removeNonDigit = (minNumber: ?number, maxNumber: ?number) => (value: string) => {
  if (!value) {
    return value;
  }

  let returnValue = value.replace(nonDigitRegexp, '');
  const numberInserted = parseInt(returnValue, 10);
  if (maxNumber && numberInserted > maxNumber) {
    return maxNumber.toString();
  }
  if (minNumber && numberInserted < minNumber) {
    return minNumber.toString();
  }
  return returnValue;
};

export const formatPrice = (value: number, currencyLabel: string = '€') => {
  if (value) {
    let price = value.toString().replace('.', ',');
    let pos = price.indexOf(',');
    if (pos === -1) {
      return numberWithSpaces(price) + ` ${currencyLabel}`
    }
    price = parseFloat(value.toString().replace(',', '.')).toFixed(2).toString().replace('.', ',');
    return  numberWithSpaces(price, true) + ` ${currencyLabel}`
  } else return `0 ${currencyLabel}`
};

const numberWithSpaces = (value: string, hasComma: boolean) => {
  if (!hasComma) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  const parts = value.split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(",");
}

export const removeDot = (value: number) => {
  return parseInt(value.toString().replace('.', ''))
};

export const formatPercent = (value: number) => {
  if (!value) {
    return '';
  }
  if ((value * 100) % 1 === 0) {
    return (value*100).toFixed(0).concat('%')
  }
  return (value * 100).toFixed(2).concat('%')
}

export const removeUnderscore = (value: string) => value
  .split('_')
  .reduce((acc, val) => `${acc}${capitalize(val)}`);
