// @flow

import React, { useEffect, useRef, useState } from 'react';
import Sticky from 'react-sticky-el';
import { I18n } from 'react-redux-i18n';
import { formatPrice } from '../../../services/formator';
import { calculateDiscount } from '../../../utils';
import type { Coverage, Formula, Quote } from '../types/steps';
import { STATUS } from '../../../services/coverages';
import UnfoldableMobileCoverage from './UnfoldableMobileCoverage';
import { connect } from 'react-redux';
import type { Site } from '../../../types/storage';

type Props = {
  quote: Quote,
  getFormulaTitleByFormulaName: Function,
  calculateAmountPerDay: Function,
  formula: Formula,
  chosenFormula: Formula,
  setOptionToSelect: Function,
  totalPrice: number,
  handleChoseFormula: Function,
  sectionRef: HTMLDivElement,
  site: Site,
}

function ResponsiveFormulaTable(
  {
    quote,
    getFormulaTitleByFormulaName,
    calculateAmountPerDay,
    formula,
    chosenFormula,
    setOptionToSelect,
    handleChoseFormula,
    totalPrice,
    sectionRef,
    site,
  }: Props,
): React$Node {

  const ref = useRef(null);

  const [selectedTab, setSelectedTab] = useState(chosenFormula || quote.offers[quote.offers.length - 1]);
  const [scrollOffset, setScrollOffset] = useState();

  const handleScroll = () => {
    setScrollOffset(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const disabledSticky = () => !scrollOffset || scrollOffset < 300;

  const getStatusFromOffer = (coverage: Coverage, formula: Formula): string  => {
    const offerStatus = coverage.offerStatus.find((offer) => offer.name === formula.name);
    if (offerStatus) {
      return offerStatus.status;
    }
    return '';
  }

  const addMobileCoverageRow = (
    coverage: Coverage,
    formula: Formula,
  ) => {
    const status = getStatusFromOffer(coverage, formula);
    if (status === STATUS.TRUE) {
      if (!coverage.linkedCoverages || coverage.linkedCoverages.length < 1) {
        return (
          <div
            className="warranty-item"
            key={coverage.code}
          >
            <i className="fa fa-check" />
            <h3>
              {coverage.name}
              {
                coverage.twinsCoverages && coverage.twinsCoverages.map((twinsCoverage) => (
                  <p key={twinsCoverage.code}>{twinsCoverage.name}</p>
                ))
              }
            </h3>
            <h4>
              {coverage.shortDescription}
              {
                coverage.twinsCoverages && coverage.twinsCoverages.map((twinsCoverage) => (
                  <p key={coverage.code}>{twinsCoverage.shortDescription}</p>
                ))
              }
            </h4>
          </div>
        );
      }
      return (
        <UnfoldableMobileCoverage
          coverage={coverage}
          addMobileCoverageRow={
            (foldableCoverage) => addMobileCoverageRow(foldableCoverage, formula)
          }
        />
      );
    } else if (status === STATUS.FALSE) {
      return (
        <div
          className="warranty-item"
          key={coverage.code}
        >
          <i className="fa fa-times" />
          <h3>
            {coverage.name}
            {
              coverage.twinsCoverages
              && coverage.twinsCoverages.map((twinsCoverage) => (
                <p key={twinsCoverage.code}>{twinsCoverage.name}</p>
              ))
            }
          </h3>
        </div>
      );
    } else if (status === STATUS.OPTIONAL) {
      return (
        <div
          className="warranty-item"
          key={coverage.code}
        >
          <div className="optional-tick">
            <h4>{I18n.t('travelInsuranceForm.step2.choseOffer.optional')}</h4>
            <h3>
              {coverage.name}
              {
                coverage.twinsCoverages
                && coverage.twinsCoverages.map((twinsCoverage) => (
                  <p key={twinsCoverage.name}>{twinsCoverage.name}</p>
                ))
              }
            </h3>
          </div>
        </div>
      );
    }
  };

  const addMobileCategoryRow = (title: string) => {
    return (
      <div className="warranty-section" key={title}><h2>{title}</h2></div>
    );
  };

  const selectFormula = (offer: Formula) => {
    setSelectedTab(offer);
    handleChoseFormula(offer);
    // To trigger sticky button good position
    window.scrollBy(0, 1);
  };

  const getProperAmountPerDay = (offer: Coverage): string => {
    return formatPrice(formula && formula.name === offer.name ? calculateAmountPerDay() : offer.totalAmountPerDay)
  };

  const getProperCurrencyRateAmount = (offer: Coverage): string => {
    const price = formula && formula.name === offer.name ? totalPrice - calculateDiscount() : offer.totalAmount;
    return formatPrice(Math.ceil(price * site.currencyRate), site.currencyLabel);
  };

  return (
    <div className="tab">
      <div className="tab-header">
        <div className="tabs-header">
          <div className="tab-buttons">
            {quote.offers.map((offer, index) => [
              (
                <button
                  aria-label=" "
                  type="button"
                  className={selectedTab.code === offer.code ? 'selected' : ''}
                  onClick={() => selectFormula(offer)}
                  key={offer.code}
                >
                  <i className={selectedTab === offer ? 'fas fa-circle' : 'far fa-circle'} />
                  <div
                    className={`tab-subTitle${index !== quote.offers.length - 1 ? ' hide' : ''}`}
                  >
                    <i className="fas fa-star" />
                    <p>{I18n.t('travelInsuranceForm.step2.choseOffer.completeOffer')}</p>
                  </div>
                  <h3 className={index !== quote.offers.length - 1 ? 'tab-title' : ''}>
                    {getFormulaTitleByFormulaName(offer.name)}
                  </h3>
                  <div className="formula-header">
                    <h2 className="formula-price">
                      {I18n.t(
                        'travelInsuranceForm.step2.choseOffer.totalAmount',
                        { amount: formatPrice(formula && formula.name === offer.name ? totalPrice - calculateDiscount() : offer.totalAmount) },
                      )}
                    </h2>
                    {site.currencyLabel && site.currencyRate
                      ? (
                        <h3 className="formula-price-details custom-currency">
                          {I18n.t(
                            'travelInsuranceForm.step2.choseOffer.amountWithCurrencyRate',
                            { amount: getProperCurrencyRateAmount(offer) })}
                        </h3>
                      )
                      : (
                        <h3 className="formula-price-details">
                          {I18n.t(
                            'travelInsuranceForm.step2.choseOffer.amountPerDay',
                            { amount: getProperAmountPerDay(offer)})}
                        </h3>
                      )
                    }
                  </div>
                </button>
              ),
            ])}
          </div>
        </div>
      </div>
      <div className="formula-content" ref={ref}>
        {
          Object.entries(quote.coverageWithCategories).map(
            (category: [string, Coverage[]]) => {
              if (category['0'] === '0') {
                setOptionToSelect(category);
                return;
              }
              return [
                addMobileCategoryRow(quote.categoryWithKeys[category[0]]),
                category[1].map((coverage: Coverage) => (
                  addMobileCoverageRow(
                    coverage,
                    selectedTab,
                  )
                )),
              ];
            },
          )
        }
      </div>
      {chosenFormula && chosenFormula.options.length > 0 &&
        (
          <div className="formula-bottom-button">
            <Sticky mode="bottom" disabled={disabledSticky()}>
              <button
                aria-label=" "
                type="button"
                className="chose-button full-size"
                hidden={!chosenFormula}
                onClick={() => {
                  handleChoseFormula(selectedTab);
                  sectionRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                {
                  chosenFormula && (
                    <h3>
                      {I18n.t('travelInsuranceForm.step2.choseOffer.customizeFormula')} {I18n.t(`formula.shortNames.${chosenFormula.code}`)}
                    </h3>
                  )
                }
              </button>
            </Sticky>
          </div>
        )
      }
    </div>
  );
}

export default connect((state) => ({
  site: state.storage.site,
}))(ResponsiveFormulaTable);
