// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { dataLayer, TAG_EVENT } from '../../../services/dataLayer';
import type { Coverage, Formula } from '../types/steps';
import { buildUrl, FILE } from '../../../services/footerUrl';
import { gtagService } from '../../../services/gtagService';
import { fetchFile } from '../../../utils';

type Props = {
  formula: Formula,
  options: Coverage[],
};

function FormFooterItemRight(props: Props) {
  const { formula, options } = props;

  const sendEvent = () => {
    if (formula) {
      gtagService.sendViewItem(formula);
    }
  };

  return (
    <div className="rightContainer">
      <div className="footer-form-item">
        <div className="dl-title">
          <i className="far fa-arrow-to-bottom" />
          <h2>{I18n.t('footerForm.download_title')}</h2>
        </div>
        <button
          className={formula && formula.dinUrl ? '' : 'disabled'}
          title={!formula ? I18n.t('footerForm.popupTitle') : ''}
          onClick={() => {
            formula && formula.dinUrl && fetchFile(buildUrl(options, formula, FILE.DIN), null);
            dataLayer.showGTM(TAG_EVENT.CLIC_DIN);
            sendEvent();
          }}
          type="button"
        >
          <i className="fal fa-file icon" />
          <h3>{I18n.t('footerForm.dowload_din')}</h3>
        </button>
        <button
          className={formula && formula.cgUrl ? '' : 'disabled'}
          title={!formula ? I18n.t('footerForm.popupTitle') : ''}
          onClick={() => {
            formula && formula.cgUrl && fetchFile(buildUrl(options, formula, FILE.CG), null);
            dataLayer.showGTM(TAG_EVENT.CLIC_NOTICE_INFORMATION);
            sendEvent();
          }}
          type="button"
        >
          <i className="fal fa-file icon" />
          <h3>{I18n.t('footerForm.download_CGUV')}</h3>
        </button>
      </div>
    </div>
  );
}

export default withRouter(connect((state) => ({
  formula: state.storage.formula,
  options: state.storage.options,
}))(FormFooterItemRight));
