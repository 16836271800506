import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { I18n } from 'react-redux-i18n';
import { fuzzysearch } from '../../services/fuzzySearch';
import { levDist, normalizeValue, sortAlphabetically } from '../../services/levensteinSort';
import { Country } from '../../types/storage';

type Props = {
  name: string,
  className: string,
  validate: Function,
  countries: Country[],
  classNamePrefix: string,
  placeholder: string,
  destinationSelectOpen: boolean,
  isMulti: boolean,
  isClearable: boolean,
  backspaceRemovesValue: boolean,
  onMenuOpen: () => void,
  onMenuClose: () => void,
  input: any,
  meta: {
    touched: any,
    error: any,
  }
};

export default function SwingSelectCountry(props: Props) {
  const initOptions = props.countries
    ? props.countries
      .map((c) => ({ id: c.id, label: c.label, value: c.isoCode, code: c.iso2Code }))
    : [];

  const [inputValue, setInputValue] = useState('');
  const [prevValue, setPrevValue] = useState('');
  const [initialOptions, setInitialOptions] = useState(initOptions);
  const [options, setOptions] = useState(undefined);

  useEffect(() => {
    setInitialOptions(initOptions);
  }, [props.countries]);

  const sortByRelevance = (newValue: string) => {
    setPrevValue(inputValue);
    setInputValue(newValue);
    if (newValue && newValue !== '') {
      const normalizedNewValue = normalizeValue(newValue);

      const regexOptions = [...initialOptions]
        .filter((option) => {
          const normalizedOption = normalizeValue(option.label);
          return normalizedOption.toUpperCase().startsWith(normalizedNewValue.toUpperCase());
        });

      const remainingOptions = [...initialOptions]
        .filter((option) => {
          const normalizedOption = normalizeValue(option.label);
          return !normalizedOption.toUpperCase().startsWith(normalizedNewValue.toUpperCase());
        });

      const levOptions = [...remainingOptions]
        .sort((curr, next) =>
          levDist(curr.label, inputValue) - levDist(next.label, inputValue)
        )
        .sort((curr, next) => sortAlphabetically(curr.label, next.label));

      setOptions(regexOptions.concat(levOptions));
    } else {
      setOptions(undefined);
    }
  };

  const wrapperClassName = () => {
    if (inputValue === '' && prevValue === '') {
      return 'select-wrapper';
    }
    return '';
  }

  const stylesMultiSelect = {
    multiValue: (base, state) => (state.data.isFixed ? (props.destinationSelectOpen ? {
      display: 'none',
    } : {
      backgroundColor: 'white !important',
      cursor: 'pointer',
    }) : { ...base }),
    multiValueLabel: (base, state) => (state.hasValue ? (state.data.isFixed ? ({
      fontSize: '16px',
      margin: '0 !important',
    }) : {
      paddingLeft: '5px',
      fontSize: '16px',
      color: 'black !important',
    }) : { ...base }),
    multiValueRemove: (base, state) => (state.data.isFixed ? {
      display: 'none',
    } : null),
  };

  return (
    <div className={wrapperClassName()}>
      <Select
        {...props}
        {...props.input}
        options={options ? options : initialOptions}
        onInputChange={sortByRelevance}
        styles={stylesMultiSelect}
        filterOption={(option, inputValue) => fuzzysearch(inputValue || '', option.label)}
        noOptionsMessage={() => I18n.t('travelInsuranceForm.step1.noOptions')}
      />
      <div className="error-custom">{props.meta.touched && props.meta.error}</div>
    </div>
  );
}
