// @flow

import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import {DATE_FORMAT} from "../const";

export const currentAge = (birthdate: string): number => {
  if (birthdate && moment().isAfter(moment(birthdate, DATE_FORMAT.FORMAT))) {
    return moment().diff(moment(birthdate, DATE_FORMAT.FORMAT), 'years');
  }
  return 0;
};

export const currentAgeFromTimestamp = (birthdate: string): number => {
  if (birthdate && moment().isAfter(moment(birthdate))) {
    return moment().diff(moment(birthdate), 'years');
  }
  return 0;
};

const majorMoment = () => moment().subtract(18, 'years');

export const minYear = 1900;

export const maxYear = new Date().getFullYear();

export const majorYear = new Date().getFullYear() - 18;

export const babyAge = 2;

export const childAge = 18;

export const adultAge = 150;

// length of -> "JJ/MM/AAAA"
export const dateFormatLength = 10;
