// @flow

import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import { CoreozInputBase } from 'coreoz-form-base';
import { required } from '../../../services/validator';
import { TRAVELER_TYPE } from '../../../const';
import SwingAddressAutoComplete from '../../../components/form/SwingAddressAutoComplete';

type Props = {
  form: Object,
  getCountryByCode: () => void,
  loadedApiKey: boolean,
  countryCode: string,
};

function AddressItem(props: Props) {

  const { form, getCountryByCode, loadedApiKey, countryCode } = props;

  const [showAddAddress, setShowAddAddress] = useState<boolean>(false);

  return (

    <>
      <div className="form-section">
        <h3>
          {I18n.t('travelInsuranceForm.step3.address')}
        </h3>
        <Field
          component={SwingAddressAutoComplete}
          type="text"
          name="user.address"
          validate={required}
          form={form}
          getCountryByCode={getCountryByCode}
          travelerType={TRAVELER_TYPE.USER}
          beneficiaryNumber={null}
          loadedApiKey={loadedApiKey}
          countryCode={countryCode}
        />
        {
          showAddAddress
          && (
            <Field
              name="user.address_supp"
              className="address-input"
              type="text"
              component={CoreozInputBase}
            />
          )}
      </div>
      <div className="add-address" role="presentation">
        <div onClick={() => setShowAddAddress(!showAddAddress)} className="add-address-sub">
          <i className={showAddAddress ? 'fas fa-minus' : 'fas fa-plus'} />
          <h3>{I18n.t(`travelInsuranceForm.step3.${showAddAddress ? 'sub_address' : 'add_address'}`)}</h3>
        </div>
      </div>
    </>
  );
}

export default AddressItem;
