// @flow

import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import NotificationsSystem from 'reapop';
import { connect } from 'react-redux';
// $FlowFixMe
import 'react-date-range/dist/styles.css'; // main style file React date range
// $FlowFixMe
import 'react-date-range/dist/theme/default.css'; // theme css file React date range
import * as Sentry from '@sentry/browser';
// $FlowFixMe
import { withErrorHandler } from 'coreoz-error-handler';
import {
  CLIENT_PAGE_ROUTE,
  ENVIRONMENT,
  FAQ_ROUTE,
  INACTIVE_ROUTE,
  LOG_ROUTE, MUTUAIDE_URL,
  STEP_PAGE_ROUTE,
  STEP_ROOT_PAGE_ROUTE,
} from './const';
import ConfirmationModal from './components/modaleNotification/ConfirmationModal';
import theme from './components/modaleNotification/theme/index';
import configurationApi from './network/api/configurationApi';
import ErrorPage from './components/ErrorPage';
import StepsContainer from './components/steps/StepsContainer';
import { withCSSVariable } from './HOC/HOCCSSVariable';
import themeApi from './network/api/themeApi';
import {
  authorizeNavigation,
  checkValidityStorage,
  storeSite,
} from './state/storage/storageService';
import {
  dataLayer,
  initializeFilAriane,
  TAG_EVENT,
  updateDataLayerAtRefresh,
} from './services/dataLayer';
import Login from './pages/clientSpace/Login';
import siteApi from './network/api/siteApi';
import SiteNotActive from './pages/error/SiteNotActive';
import Faq from './pages/faq/Faq';
import LogApi from './pages/logApi/LogApi';
import { gtagService } from './services/gtagService';
import type { Site, TravelerInfos, TripInfos } from './types/storage';
import {
  addScriptOnElementByClassName,
  addRawScriptOnElementByTagName,
  addScriptOnElementByTagName,
} from './services/trackUtils';

let eventId = '';

type Props = {
  dispatch: Function,
  location: Location,
  history: RouterHistory,
  utmCampaign: string,
  tripInfos: TripInfos,
  travelerInfos: TravelerInfos,
  maxPage: number,
};

type State = {
  siteLoaded: boolean,
  isClientSpaceActive: boolean,
};

const onError = (error, info) => {
  Sentry.withScope((scope) => {
    scope.setExtras(info);
    eventId = Sentry.captureException(error);
  });
};

class App extends React.Component<Props, State> {
  state = {
    siteLoaded: false,
    isClientSpaceActive: false,
  };

  setupOnetrust = (oneTrustKey: string) => {
    addScriptOnElementByTagName(
      'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      null,
      null,
      true,
      [
        {
          attrName: 'charset',
          attrVal: 'UTF-8',
        },
        {
          attrName: 'data-domain-script',
          attrVal: oneTrustKey,
        },
        {
          attrName: 'data-document-language',
          attrVal: 'true',
        },
      ],
      'head',
    );

    const scriptOptanon = document.createElement('script');
    scriptOptanon.setAttribute('type', 'text/javascript');
    scriptOptanon.appendChild(document.createTextNode(`
    var active = true;
    function OptanonWrapper() {
      OneTrust.OnConsentChanged(function(el) {window.location.reload();});
    }`));
    addRawScriptOnElementByTagName(scriptOptanon, 'head');

    // Handle cookies category 1
    const scriptOptanonCategory1 = document.createElement('script');
    scriptOptanonCategory1.setAttribute('type', 'text/plain');
    scriptOptanonCategory1.classList.add('optanon-category-1');
    scriptOptanonCategory1.appendChild(
      document.createTextNode('window.dispatchEvent(new Event(\'accept-cookies\'));'),
    );
    addRawScriptOnElementByTagName(scriptOptanonCategory1, 'body');

    // Handle cookies category 2
    const scriptOptanonCategory2 = document.createElement('script');
    scriptOptanonCategory2.setAttribute('type', 'text/plain');
    scriptOptanonCategory2.classList.add('optanon-category-2');
    scriptOptanonCategory2.appendChild(
      document.createTextNode('window.dispatchEvent(new Event(\'update-cookies\'));'),
    );
    addRawScriptOnElementByTagName(scriptOptanonCategory2, 'body');
  };

  activateTracking = (response: Site) => {
    // If GTAG is present, it must have analytics key to work
    if (response.trackingGoogleKey && response.trackingAnalyticsKey) {
      window.dataLayer = window.dataLayer || [];
      addScriptOnElementByTagName(
        `https://www.googletagmanager.com/gtm.js?id=${response.trackingGoogleKey}`,
        '',
        () => {
          window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          dataLayer.setTrackingGoogle(true);
          // Send gtag of the actual step when activating tracking
          const step = window.location.pathname.split("/").pop();
          if (step === 1 || 5) {
            dataLayer.updateFilAriane(step);
          }
          // Send gtag to track the referer
          if (document.referrer.includes(MUTUAIDE_URL)) {
            dataLayer.showGTM(TAG_EVENT.REFERER_MUTUAIDE);
          }
          if(this.props.utmCampaign != null) {
            if(this.props.utmCampaign === 'LienFaq' || this.props.utmCampaign === 'LienNouveauDevis' || this.props.utmCampaign === 'LienEspaceClient') {
              dataLayer.showGTM(TAG_EVENT.CLIC_LINK_MAIL(this.props.utmCampaign));
            }
          }
        },
        true,
        [],
        'head',
      );
      addScriptOnElementByTagName(
        `https://www.googletagmanager.com/gtag/js?id=${response.trackingAnalyticsKey}`,
        '',
        () => {
          window.gtag = function gtag() {
            window.dataLayer.push(arguments);
          };
          gtag('js', new Date());
          gtag('config', response.trackingAnalyticsKey);
          gtagService.setTrackingAnalytics(true);
        },
        true,
        [],
        'head',
      );
    }
    // If there is no GTAG, Analytics have to work on standalone
    if (!response.trackingGoogleKey && response.trackingAnalyticsKey) {
      window.GoogleAnalyticsObject = 'ga';
      window.ga = window.ga || function () {
        window.ga.q = window.ga.q || [];
        window.ga.q.push(arguments);
      };
      window.ga.l = new Date();
      addScriptOnElementByTagName(
        'https://www.google-analytics.com/analytics.js',
        'optanon-category-C0002',
        () => {
          const uaCreate = { cookieExpires: 34128000 };
          window.ga('create', response.trackingAnalyticsKey, uaCreate);
          window.ga('send', 'pageview');

          gtagService.setTrackingAnalytics(true);
        },
        true,
        [],
        'head',
      );
    }
    if (response.trackingAdobeUrl) { // Dans le cas d'un tracking adobe
      addScriptOnElementByClassName(
        response.trackingAdobeUrl,
        'optanon-category-C0001',
        () => {
          dataLayer.setTrackingAdobe(true);
          initializeFilAriane();
          // On envoie les informations du datalayer après avoir ajouté le script
          this.sendDataLayerInformations();
        },
        true,
        [],
        'optanon-category-1',
      );
    } else { // Sinon on envoie quand même les informations du datalayer
      this.sendDataLayerInformations();
    }
  };

  updateTracking = (response: Site) => {
    if (response.trackingAdobeUrl) { // Dans le cas d'un tracking adobe
      dataLayer.setAnonymization(false);
    }
  };

  sendDataLayerInformations = () => {
    dataLayer.populateFixedValues();
    updateDataLayerAtRefresh(this.props.tripInfos, this.props.travelerInfos);
  }

  handleTrackingCreation = (response: Site) => {
    window.dataLayer = window.dataLayer || [];
    if (response.oneTrustKey) {
      this.setupOnetrust(response.oneTrustKey);
      window.addEventListener('accept-cookies', () => this.activateTracking(response));
      window.addEventListener('update-cookies', () => this.updateTracking(response));
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      window.scrollTo(0, 0);
    }

    return true;
  }

  componentDidMount() {
    const {
      history, dispatch,
    } = this.props;
    checkValidityStorage(history, dispatch);
    siteApi.fetchSite()
      .then((response) => response.json())
      .then((response: Site) => {
        dispatch(storeSite(response));
        if (response.hostPrefix && !response.isActive) {
          history.push(INACTIVE_ROUTE);
        } else {
          if (response.keywords) {
            const meta = document.createElement('meta');
            meta.name = 'keywords';
            meta.content = response.keywords;
            document.getElementsByTagName('head')[0].appendChild(meta);
          }
          this.handleTrackingCreation(response);
        }
        this.setState({
          siteLoaded: true,
          isClientSpaceActive: response.isClientspaceActive,
        });
      })
      .catch(() => this.setState({ siteLoaded: true }));

    configurationApi
      .fetchEnv()
      .then((response) => response.text())
      .then((responseJson) => {
        if (responseJson !== 'development' && responseJson !== 'int') {
          Sentry.init({
            dsn: 'https://34cb80c5c63c416abd74cac88cb57208@sentry.io/1856402',
            release: `swing-tunnel@${String(process.env.REACT_APP_GIT_SHA)}`,
            environment: ENVIRONMENT[responseJson],
            ignoreErrors: [
              'Error: Failed to load script https://assets.adobedtm.com/854382efcf85/3992de38f56a/336054901548/EX68d11c8a40f746338ed0ef2c889021cf-libraryCode_source.min.js'
            ]
          });
        }
      });
  }

  render() {
    const {
      maxPage, location,
    } = this.props;
    const {
      isClientSpaceActive, siteLoaded,
    } = this.state;
    if (!siteLoaded) {
      return false;
    }
    return (
      <div id="app">
        <NotificationsSystem theme={theme} />
        <ConfirmationModal />
        <div className="container">
          <div className="content-container">
            <main id="main-content">
              <Switch>
                <Route
                  path={STEP_PAGE_ROUTE}
                  render={() => (
                    authorizeNavigation(location.pathname.split(`${STEP_ROOT_PAGE_ROUTE}/`)[1])
                      ? <StepsContainer />
                      : <Redirect to={`${STEP_ROOT_PAGE_ROUTE}/${maxPage}`} />
                  )}
                />
                <Route
                  path={CLIENT_PAGE_ROUTE}
                  render={() => (
                    <Login isClientSpaceActive={isClientSpaceActive} />
                  )}
                />
                <Route path={FAQ_ROUTE} render={() => (<Faq />)} />
                <Route path={INACTIVE_ROUTE} render={() => (<SiteNotActive />)} />
                <Route path={LOG_ROUTE} render={() => (<LogApi />)} />
                <Route path="*" render={() => (<Redirect to={`${STEP_ROOT_PAGE_ROUTE}/1`} />)} />
              </Switch>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default withCSSVariable({
  WrappedComponent: withErrorHandler(
    withRouter(
      connect((state) => ({
        locale: state.i18n.locale,
        maxPage: state.storage.maxPage,
        tripInfos: state.storage.tripInfos,
        travelerInfos: state.storage.travelerInfos,
      }))(App),
    ),
    (<ErrorPage eventId={eventId} />),
    onError,
  ),
  getTheme: () => themeApi.get(),
});
