// @flow

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { STEP_ROOT_PAGE_ROUTE } from '../../const';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import type { Site } from '../../types/storage';

type Props = {
  site: Site
};

const SiteNotActive = (props: Props) => {
  useEffect(() => {
    if (props.site.isActive) {
      props.history.push(`${STEP_ROOT_PAGE_ROUTE}/1`);
    }
  }, []);

  return (
    <div class="inactive-container">
      <i className="fas fa-exclamation-triangle"></i>
      <p>{I18n.t('errors.inactive.title')}</p>
      <p>{I18n.t('errors.inactive.description')}</p>
    </div>
  );
};

export default withRouter(connect(state => ({
  site: state.storage.site,
}))(SiteNotActive));
